.BuyForm {
  display: flex;
  flex-grow: 1;
}

@media (max-width: 840px) {
  .BuyForm {
    max-width: 480px;
    justify-content: center;
  }
}

.BuyForm-amount {
  margin-right: 16px;
}

.BuyForm-buttonAddToWaitList {
  margin-right: 16px;
}

@media (max-width: 840px) {
  .BuyForm-buttonAddToWaitList {
    margin-right: 0;
    text-align: center;
  }
}

.BuyForm-toBasket {
  margin-right: 16px;
}

.BuyForm-more {
  flex-grow: 0;
}

.BuyForm-submit {
  flex-grow: 1;
}
