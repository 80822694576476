.Profile_Layout {
  margin: 48px;
}

@media (max-width: 840px) {
  .Profile_Layout {
    margin: 0;
  }
}

.Profile_Layout-grid {
  display: flex;
  margin: -16px;
}

@media (max-width: 840px) {
  .Profile_Layout-grid {
    display: block;
    margin: 0;
  }
}

.Profile_Layout-side {
  flex: 0 0 264px;
  max-width: 264px;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .Profile_Layout-side {
    display: none;
  }
}

.Profile_Layout-main {
  flex: 1 1 auto;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .Profile_Layout-main {
    flex: auto;
    padding: 0;
  }
}
