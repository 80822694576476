.IngredientGrid {
}

.IngredientGrid-group {
  margin: -24px;
  display: flex;
  flex-wrap: wrap;
}

.IngredientGrid-item {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 24px;
}

@media screen and (max-width: 840px) {
  .IngredientGrid-item {
    padding: 8px;
  }
}
