.CardReviews {
  display: flex;
  align-items: center;
}

@media (max-width: 840px) {
  .CardReviews {
    justify-content: space-between;
  }
}

.CardReviews-info {
  margin-right: 10px;
}

@media (max-width: 840px) {
  .CardReviews-info {
    display: none;
  }
}
