.ProblemSkin_Reasons {
}

.ProblemSkin_Reasons-title {
  text-align: center;
  font-weight: bold;
  font-size: 38px;
  margin: 100px 0 50px;
}

@media (max-width: 1100px) {
  .ProblemSkin_Reasons-title {
    font-size: 24px;
    margin: 64px 0 32px;
  }
}

.ProblemSkin_Reasons-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  /* display: grid; */
  /* grid-auto-rows: 1fr; */
  /* grid-auto-rows: auto; */
  /* grid-template-rows: repeat(3, minmax(500px, auto)); */
}

.ProblemSkin_Reasons-item {
  /* height: 420px; */
  height: 29vw;
  min-height: 420px;
}
@media (max-width: 1100px) {
  .ProblemSkin_Reasons-item {
    height: auto;
    min-height: inherit;
  }
}
