.MobileNavigationButton {
  display: flex;
  padding: 15px;
  background-color: transparent;
  align-items: center;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease-out;
  font-weight: bold;
  font-family: inherit;
  outline: none;
}

.isTabUsing .MobileNavigationButton:focus {
  outline: auto;
}

.MobileNavigationButton:hover {
  background-color: #ebf0f6;
}
.MobileNavigationButton-icon {
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 10px;
}
.MobileNavigationButton-title {
  display: block;
  font-size: 16px;
}
