.ColorChoice {
}

.ColorChoice-title {
  font-size: 14px;
  margin-bottom: 8px;
  text-transform: lowercase;
}

@media (max-width: 840px) {
  .ColorChoice-title {
    text-transform: none;
  }
}

.ColorChoice-value {
  text-transform: none;
}

@media (max-width: 840px) {
  .ColorChoice-value {
    display: none;
  }
}

.ColorChoice-switch {
}
