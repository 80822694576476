.InputCode {
  font-size: 24px;
  appearance: none;
  outline: none;
  display: inline-block;
  border: none;
  color: #262931;
  box-sizing: border-box;
  margin: 0;
  font-family: monospace;
  width: 120px;
  cursor: default;
  padding: 0 8px;
}
