.GlobalNavigation {
  margin: -16px;
  padding: 0;
}

.GlobalNavigation-item {
  list-style: none;
  display: inline-block;
  padding: 16px;
  font-size: 14px;
}
