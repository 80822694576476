.replyForm {
}

.replyForm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.replyForm-label {
  font-size: 14px;
}

.replyForm-labelTex {
  padding-right: 8px;
}

.replyForm-form {
}

.replyForm-labelInfo {
  max-width: 200px;
}
