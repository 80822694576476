.FilterOption {
  padding: 8px 24px;
  border-radius: 22px;
  border: 2px solid transparent;
  color: #000;
  transition: background-color 0.2s ease-out, border 0.2s ease-out;
  text-decoration: none;
  outline: none;
}

.FilterOption:hover {
  background-color: #e5ecf2;
}

.isTabUsing .FilterOption:focus {
  border: 2px solid #e5ecf2;
}

.FilterOption:active {
  border: 2px solid #e2ebf3;
  background-color: #e2ebf3;
}

@media (max-width: 840px) {
  .FilterOption {
    padding: 8px;
    border: none;
    font-size: 14px;
  }

  .FilterOption:hover {
    background-color: transparent;
  }

  .FilterOption:active {
    border: none;
    background-color: transparent;
  }
}

@media (max-width: 420px) {
  .FilterOption {
    padding: 4px;
    font-size: 12px;
  }
}
