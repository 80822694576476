.BannerFeaturedSlider {
}

.BannerFeaturedSlider-content {
  margin-bottom: 18px;
}

@media (max-width: 1100px) {
  .BannerFeaturedSlider-content {
    margin-bottom: 0;
  }
}

.BannerFeaturedSlider-list {
}

.BannerFeaturedSlider-item {
}

@media (max-width: 480px) {
  .BannerFeaturedSlider-list {
    margin-bottom: 4px;
  }
}

.BannerFeaturedSlider-navigation {
  text-align: center;
  font-size: 0;
}

.BannerFeaturedSlider-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 840px) {
  .BannerFeaturedSlider-controls {
    justify-content: center;
  }
}

.BannerFeaturedSlider-control {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 16px 0;
  cursor: pointer;
  outline: none;
  appearance: none;
}

.BannerFeaturedSlider-control:disabled {
  cursor: default;
}

.isTabUsing .BannerFeaturedSlider-control:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0 0 4px rgba(218, 220, 221, 0.7);
}

.BannerFeaturedSlider-rightHolder {
  position: relative;
}

.BannerFeaturedSlider-counter {
  position: absolute;
  left: -32px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
}

.bannerFeaturedSlider-pagination {
  left: 50%;
  margin-bottom: 12px;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  transition: 0.2s ease-out;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 100px;
  padding: 0;
}

.bannerFeaturedSlider-paginationOuterHolder {
  position: relative;
  overflow: hidden;
}

.bannerFeaturedSlider-paginationHolder {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  transition: 0.2s ease-out;
}

.bannerFeaturedSlider-paginationItem {
  margin: 4px;
  display: inline-flex;
  align-items: center;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.bannerFeaturedSlider-paginationItem--hidden {
  display: none;
  /* width: 0px;
  height: 0px;
  opacity: 0;
  visibility: hidden; */
}
