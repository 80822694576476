.SizeChoice {
}

.SizeChoice-titleHolder {
  font-size: 14px;
  display: inline-flex;
  align-items: baseline;
  text-transform: lowercase;
  margin-bottom: 8px;
}

@media (max-width: 840px) {
  .SizeChoice-titleHolder {
    text-transform: none;
  }
}

.SizeChoice-title {
  flex: 0 0 auto;
  margin-right: 32px;
}

@media (max-width: 840px) {
  .SizeChoice-title {
    display: none;
  }
}

.SizeChoice-titleLink {
  flex: 0 0 auto;
}

.SizeChoice-value {
  text-transform: none;
}

.SizeChoice-switch {
}
