.FeedbackCall {
  font-size: 18px;
  font-weight: bold;
  color: #262931;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8eaec;
  border-bottom: 1px solid #e8eaec;
  padding: 32px 0;
  margin-bottom: 32px;
}

@media (max-width: 840px) {
  .FeedbackCall {
    display: block;
    text-align: center;
  }
}

.FeedbackCall-action {
  margin-left: 16px;
}

@media (max-width: 840px) {
  .FeedbackCall-action {
    margin-left: 0;
    margin-top: 24px;
  }
}

.FeedbackCall-title {
  margin-right: 8px;
}

@media (max-width: 840px) {
  .FeedbackCall-title {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
