.ActivityDetails {
  overflow: hidden;
}

.ActivityDetails-holder {
  margin: -30px;
}

@media (max-width: 840px) {
  .ActivityDetails-holder {
    margin: -16px;
  }
}

.ActivityDetails-item {
  padding: 30px;
}

@media (max-width: 840px) {
  .ActivityDetails-item {
    padding: 16px;
  }
}
