.NavigationDrawer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.NavigationDrawer--isOpened {
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.NavigationDrawer-header {
  padding: 0;
}

.NavigationDrawer-authorizedPanel {
}

.NavigationDrawer-unauthorizedPanel {
}

.NavigationDrawer-loader {
  position: relative;
  padding-top: 92px;
}

.NavigationDrawer-catalog {
  flex: 0 0 auto;
}

.NavigationDrawer-pages {
  background-color: #f4f9ff;
  flex: 1 1 auto;
  padding-bottom: 40px;
}

.NavigationDrawer-footer {
  margin-top: auto;
}

.NavigationDrawer-footer:hover {
  background-color: #ebf0f6;
}
