.PickPoint {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  padding: 24px 16px;
  box-sizing: border-box;
  text-align: left;
  font-family: inherit;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease-out;
}

@media (max-width: 840px) {
  .PickPoint {
    padding: 16px 0;
    font-size: 16px;
    cursor: default;
    transition: none;
  }
}

@media (max-width: 600px) {
  .PickPoint {
    display: block;
  }
}

.PickPoint--selected {
  background-color: #fffdeb;
  cursor: default;
}

.PickPoint:hover {
  background-color: #edf0f5;
}

@media (max-width: 840px) {
  .PickPoint:hover {
    background-color: transparent;
  }
}

.PickPoint--selected:hover {
  background-color: #fffdeb;
}

.PickPoint:focus {
  background-color: #edf0f5;
}

@media (max-width: 840px) {
  .PickPoint:focus {
    background-color: transparent;
  }
}

.PickPoint--selected:focus {
  background-color: #fffdeb;
}

.PickPoint:active {
  background-color: #e5e9f1;
}

@media (max-width: 840px) {
  .PickPoint:active {
    background-color: transparent;
  }
}

.PickPoint--selected:active {
  background-color: #fffdeb;
}

.PickPoint-titleCol {
  flex: 1 1 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .PickPoint-titleCol {
    flex: 1 1 auto;
  }
}

@media (max-width: 600px) {
  .PickPoint-titleCol {
    margin-bottom: 8px;
  }
}

.PickPoint-title {
  display: block;
  font-weight: 700;
}

@media (max-width: 840px) {
  .PickPoint-title {
    margin-bottom: 8px;
  }
}

.PickPoint-addressCol {
  flex: 0 0 30%;
  padding: 0 16px;
  box-sizing: border-box;
}

.PickPoint-address {
  display: block;
}

.PickPoint-scheduleCol {
  flex: 0 0 25%;
  padding: 0 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .PickPoint-scheduleCol {
    flex: 0 0 240px;
  }
}

@media (max-width: 600px) {
  .PickPoint-scheduleCol {
    display: flex;
    flex-direction: column;
  }
}

.PickPoint-scheduleList {
  display: block;
}

@media (max-width: 600px) {
  .PickPoint-scheduleList {
    order: -1;
    margin-bottom: 8px;
  }
}

.PickPoint-scheduleItem {
  display: block;
  margin-bottom: 4px;
}

@media (max-width: 840px) {
  .PickPoint-scheduleItem {
    margin-bottom: 0;
  }
}

.PickPoint-paymentCol {
  flex: 0 0 20%;
  padding: 0 16px;
  box-sizing: border-box;
}

.PickPoint-payment {
  display: block;
}

@media (max-width: 840px) {
  .PickPoint-payment {
    margin-bottom: 8px;
  }
}

@media (max-width: 600px) {
  .PickPoint-payment {
    order: 1;
    margin-bottom: 0;
  }
}

.PickPoint-paymentCaption {
  display: none;
}

@media (max-width: 600px) {
  .PickPoint-paymentCaption {
    display: inline;
  }
}

.PickPoint-paymentAvailability {
}
