.NextArticle {
  display: flex;
  align-items: center;
}

@media (max-width: 840px) {
  .NextArticle {
    display: flex;
    flex-direction: column-reverse;
  }
}

.NextArticle-leftColumn {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 24px;
  box-sizing: border-box;
  text-align: center;
}

@media (max-width: 840px) {
  .NextArticle-leftColumn {
    flex: 0 0 auto;
    max-width: none;
    padding: 8px 0;
    padding-right: 56px;
  }
}

@media (max-width: 480px) {
  .NextArticle-leftColumn {
    padding-right: 48px;
  }
}

.NextArticle-rightColumn {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 24px;
  box-sizing: border-box;
  text-align: center;
}

@media (max-width: 840px) {
  .NextArticle-rightColumn {
    flex: 0 0 auto;
    max-width: none;
    padding: 8px 0;
    padding-left: 56px;
  }
}

@media (max-width: 480px) {
  .NextArticle-rightColumn {
    padding-left: 48px;
  }
}

.NextArticle-separatorColumn {
  flex: 0 0 0;
  width: 0;
  max-width: 0;
}

@media (max-width: 840px) {
  .NextArticle-separatorColumn {
    display: none;
  }
}

.NextArticle-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 380px;
  text-align: left;
}

.NextArticle-previousArrowHolder {
  margin-right: 32px;
}

@media (max-width: 480px) {
  .NextArticle-previousArrowHolder {
    margin-right: 24px;
  }
}

.NextArticle-nextArrowHolder {
  margin-left: 32px;
}

@media (max-width: 480px) {
  .NextArticle-nextArrowHolder {
    margin-right: 24px;
  }
}

.NextArticle-arrow {
  display: inline-block;
  text-decoration: none;
  padding: 16px;
  border: 1px solid #d1d5da;
  border-radius: 25px;
  line-height: 1;
  color: #818690;
  background-color: transparent;
  transition: background-color 0.2s ease-out, border 0.2s ease-out;
  outline: none;
}

@media (max-width: 840px) {
  .NextArticle-arrow {
    padding: 0;
    border: none;
  }
}

.NextArticle-arrow:hover {
  background-color: rgba(216, 216, 216, 0.2);
}

@media (max-width: 840px) {
  .NextArticle-arrow:hover {
    background-color: transparent;
  }
}

.isTabUsing .NextArticle-arrow:focus {
  border: 1px solid #818690;
}

@media (max-width: 840px) {
  .isTabUsing .NextArticle-arrow:focus {
    border: none;
  }
}

.NextArticle-arrow:active {
  background-color: rgba(220, 220, 220, 0.4);
}

@media (max-width: 840px) {
  .NextArticle-arrow:active {
    background-color: transparent;
  }
}

.NextArticle-arrow::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.NextArticle-contentHolder {
}

.NextArticle-caption {
  margin-bottom: 16px;
  font-weight: 300;
  color: #262931;
}

@media (max-width: 1100px) {
  .NextArticle-caption {
    margin-bottom: 8px;
  }
}

.NextArticle-title {
  margin: 0;
  line-height: 1.2;
  font-size: 18px;
  font-weight: 700;
  color: #262931;
}

@media (max-width: 480px) {
  .NextArticle-title {
    font-size: 16px;
  }
}

.NextArticle-separator {
  height: 80px;
  border-right: 1px solid #d7deea;
}
