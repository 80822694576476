.EmptyBasket {
  text-align: center;
}

.EmptyBasket-message {
  margin-bottom: 30px;
}

.EmptyBasket-action {
}
