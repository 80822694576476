.HeaderButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 4px;
  color: inherit;
  border: none;
  border-radius: 24px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  margin: 0;
  transition: background-color 0.2s ease-out;
}

.HeaderButton--loading {
  pointer-events: none;
}

.HeaderButton:hover {
  background-color: #f6f8fb;
}

@media (max-width: 1100px) {
  .HeaderButton:hover {
    background-color: transparent;
  }
}

.isTabUsing .HeaderButton:focus {
  background-color: #edf0f5;
}

@media (max-width: 1100px) {
  .isTabUsing .HeaderButton:focus {
    background-color: transparent;
  }
}

.HeaderButton:active {
}

.HeaderButton-icon {
  display: inline-block;
  position: relative;
}

.HeaderButton-counter {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: -12px;
  line-height: 1;
}

.HeaderButton-caption {
  display: inline-block;
  padding: 0 8px;
}
