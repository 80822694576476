.ComplainForm {
}

.ComplainForm-description {
  margin-bottom: 42px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.ComplainForm-inputFile {
  position: relative;
}

.ComplainForm-inputFileLink {
}

.ComplainForm-inputFileTitle {
  position: absolute;
  top: 100%;
  left: 35px;
}
