.Dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(37, 40, 53, 0.7);
  overflow: auto;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  text-align: left;
}

.Dialog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 8vh 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .Dialog-container {
    padding: 16px;
  }
}

.Dialog-window {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 3px;
  box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

@media (max-width: 1100px) {
  .Dialog-window {
    width: 60%;
  }
}

@media (max-width: 680px) {
  .Dialog-window {
    width: 100%;
  }
}

.Dialog--overflowVisible .Dialog-window {
  overflow: visible;
}

.Dialog--expanded .Dialog-window {
  width: 100%;
}

.Dialog-header {
  padding: 16px 32px;
}

@media (max-width: 720px) {
  .Dialog-header {
    padding: 8px 16px;
  }
}

.Dialog-title {
  margin: 0;
  margin-right: 24px;
  padding-top: 1px;
  line-height: 24px;
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 1100px) {
  .Dialog-title {
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .Dialog-title {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .Dialog-title {
    padding-top: 0;
    font-size: 16px;
  }
}

.Dialog-closeButton {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 18px;
  vertical-align: top;
  line-height: 1;
  z-index: 1;
}

@media (max-width: 640px) {
  .Dialog-closeButton {
    top: 8px;
    right: 8px;
  }
}

@media (max-width: 480px) {
  .Dialog-closeButton {
    top: 0px;
    right: 0px;
    font-size: 16px;
  }
}

.Dialog-closeButton:hover {
  color: #848fa5;
}

.Dialog-content {
  overflow: hidden;
}

.Dialog--overflowVisible .Dialog-content {
  overflow: visible;
}

.Dialog-footer {
  margin-top: auto;
  padding: 0px 35px 25px;
}

.Dialog-actionList {
  display: flex;
  justify-content: flex-end;
  margin: -5px;
  padding-top: 20px;
}

.Dialog-action {
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
  max-width: 50%;
}

@media screen and (max-width: 480px) {
  .Dialog-actionList {
    flex-direction: column;
  }
  .Dialog-action {
    flex: 1 0 100%;
    margin: 5px;
    max-width: calc(100% - 10px);
  }
}
