.MassMediaList-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 840px) {
  .MassMediaList-content {
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
  }
}

.MassMediaList-item {
  flex: 0 1 33%;
  padding: 42px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .MassMediaList-item {
    width: 90%;
    border-bottom: 1px solid #e8eaec;
    padding: 0;
  }
}
