.BonusInput {
  display: flex;
  align-items: center;
  /* margin-bottom: 24px; */
  /* flex-direction: column; */
}

@media (max-width: 840px) {
  .BonusInput {
    flex-wrap: wrap;
  }
}

.BonusInput-field {
  flex: 1 1 auto;
  max-width: 140px;
}

@media (max-width: 840px) {
  .BonusInput-field {
    flex: 0 1 100%;
    max-width: none;
  }
}

.BonusInput-action {
  margin-left: 16px;
  min-width: 160px;
}

.BonusInput-slider {
  flex: 1 1 auto;
  order: -1;
  margin-right: 16px;
  max-width: 240px;
}

@media (max-width: 840px) {
  .BonusInput-slider {
    flex: 0 1 100%;
    margin-right: 0;
    max-width: none;
    padding: 16px 0;
  }
}

.BonusInput-acceptWrapper {
  display: inline-block;
  height: 40px;
  padding: 2px;
  box-sizing: border-box;
  overflow: hidden;
}

.BonusInput-accept {
  display: inline-block;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  border: none;
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #e74a5a;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-out;
}

.BonusInput--disabled .BonusInput-accept {
  background-color: #dae1ec;
}

.isTabUsing .BonusInput-accept:focus {
  box-shadow: 0 0 0 2px rgba(47, 124, 192, 0.54);
}

.BonusInput-unAccept {
  display: inline-block;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  border: none;
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #dae1ec;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-out;
}

.isTabUsing .BonusInput-unAccept:focus {
  box-shadow: 0 0 0 2px rgba(47, 124, 192, 0.54);
}

.BonusInput-accept:disabled {
  opacity: 0.5;
  cursor: default;
}

.BonusInput-loader {
  position: relative;
  height: 56px;
  flex: 0 0 100%;
}
