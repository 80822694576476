.ConsistencyContent {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ConsistencyContent-ingredients {
  margin-bottom: 16px;
}

.ConsistencyContent-item:last-child {
  margin-bottom: 0;
}

.ConsistencyContent-ingredientTitles {
  line-height: 1.8;
}

.ConsistencyContent-link {
  color: inherit;
  text-decoration: none;
}

.ConsistencyContent-link:hover {
  text-decoration: underline;
}
