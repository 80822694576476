.Article_Footer {
  margin-top: 32px;
}

@media (max-width: 480px) {
  .Article_Footer {
    margin-top: 24px;
  }
}

.Article_Footer-author {
  margin-bottom: 24px;
  font-weight: 700;
}

@media (max-width: 480px) {
  .Article_Footer-author {
    margin-bottom: 16px;
  }
}

.Article_Footer-actionGroup {
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  border-top: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
}

@media (max-width: 480px) {
  .Article_Footer-actionGroup {
    padding: 24px 0;
  }
}

.Article_Footer-socialNetworkColumn {
  flex: 0 0 auto;
}

.Article_Footer-likeColumn {
  flex: 0 0 auto;
}

.Article_Footer-socialNetworkList {
  margin: 0;
  padding: 0;
}

.Article_Footer-socialNetworkItem {
  display: inline-block;
  margin-right: 24px;
  list-style: none;
  cursor: default;
}

@media (max-width: 480px) {
  .Article_Footer-socialNetworkItem {
    margin-right: 40px;
  }
}

@media (max-width: 400px) {
  .Article_Footer-socialNetworkItem {
    margin-right: 32px;
  }
}

.Article_Footer-socialNetworkItem:last-child {
  margin-right: 0;
}
