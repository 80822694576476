.ArticleAction {
  text-align: center;
}

.ArticleAction-button {
  margin-bottom: 8px;
}

.ArticleAction-amount {
  font-size: 14px;
  font-weight: 300;
  color: #444952;
}

@media (max-width: 480px) {
  .Article_Footer-amount {
    line-height: 1.2;
  }
}

.ArticleAction--direction-row .ArticleAction-button {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 12px;
  vertical-align: middle;
}

.ArticleAction--direction-row .ArticleAction-amount {
  display: inline-block;
  vertical-align: middle;
}
