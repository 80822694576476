.DeclarationCard {
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
  transition: 0.2s ease-out;
  border: none;
  font-family: inherit;
  padding-bottom: 16px;
  transition: 0.2s ease-out;
}

.DeclarationCard:hover {
  transform: scale(1.05);
}

@media (max-width: 840px) {
  .DeclarationCard:hover {
    transform: scale(1);
  }
}

.isTabUsing .DeclarationCard:focus {
}

.DeclarationCard-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(211, 211, 211, 0.678);
  opacity: 0;
  transition: 0.2s ease-out;
  z-index: 1;
  /* color: #fff; */
  font-size: 24px;
  font-weight: bold;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DeclarationCard:hover .DeclarationCard-overlay {
  opacity: 1;
}

.DeclarationCard-content {
  position: relative;
}

.DeclarationCard-imageHolder {
  margin: 0 0 16px 0;
}

.DeclarationCard-image {
}

.DeclarationCard-title {
  font-size: 16px;
  text-align: center;
}

.DeclarationCard_Dialog {
  padding: 32px;
}

.DeclarationCard_Dialog-title {
  margin: 16px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 840px) {
  .DeclarationCard_Dialog-title {
    font-size: 16px;
  }
}

.DeclarationCard-imageHolder {
}
