.FilterItem {
}

.FilterItem {
  display: inline-block;
  border: none;
  outline: none;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
  color: #000;
  margin-right: 40px;
  padding: 8px 0;
  font-size: inherit;
  font-family: inherit;
}

.FilterItem::after {
  content: '';
  position: absolute;
  display: block;
  height: 3px;
  bottom: 0;
  width: 24px;
  left: 50%;
  margin-left: -12px;
  transform: scaleX(0);
  background-color: #ffd12c;
  transition: transform 0.2s ease-out;
}

.FilterItem:hover::after {
  transform: scaleX(1);
}

.FilterItem--isActive {
  cursor: default;
}

.FilterItem--isActive:after {
  transform: scaleX(1);
}
