.NavigationBackButton {
  -webkit-appearance: none;
  background: none;
  border: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  font-family: inherit;
  color: #848fa5;
  line-height: 24px;
  padding: 8px 0;
  margin: 0;
  position: relative;
}

.NavigationBackButton-icon {
  position: absolute;
  padding: 8px;
  left: 0;
  top: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.NavigationBackButton-title {
  display: block;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 24px;
}
