.LatestArticlesSection {
  padding-bottom: 32px;
}

.LatestArticlesSection-item {
  margin-bottom: 64px;
}

.LatestArticlesSection-item:last-child {
  margin-bottom: 0;
}

.LatestArticlesSection-showMore {
  text-align: center;
}
