.ProductNavigation {
  background-color: #f5f8fb;
  padding-top: 30px;
}
.ProductNavigation-fullContainer {
  background-color: #f5f8fb;
  padding: 20px 0;
  z-index: 100;
  transition: box-shadow 0.2s ease-out;
}
.ProductNavigation-fullContainer--isSticky {
  /*padding: 20px 0;*/
  background-color: #fff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}
.ProductNavigation-container {
  padding: 0 20px;
  margin: 0 auto;
}

.ProductNavigation-nav {
}
