.CallToAuth {
  min-width: 390px;
  /*может задать width: 390px; ? */
}

.CallToAuth-checkedListTitle {
  font-size: 14px;
  margin: 0;
  font-weight: 700;
}

.CallToAuth-checkedList {
  margin-top: 16px;
}

.CallToAuth-footer {
  margin-top: 24px;
}
