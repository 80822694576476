.DashList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.DashList--withListMark {
  padding: 0 24px;
  list-style: disc;
}

.DashList-item {
}
