.ProfileButton {
  position: relative;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
  z-index: 2;
  transition: all 0.2s ease-out;
  border-radius: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.ProfileButton--isLoading {
  pointer-events: none;
}

.isTabUsing .ProfileButton:focus {
  background-color: rgba(229, 236, 242, 0.6);
}

.ProfileButton-name {
  padding-right: 8px;
  padding-left: 16px;
  transition: color 0.2s ease-out;
  line-height: 1;
}

.ProfileButton:hover .ProfileButton-name {
  color: #9899a3;
}

.ProfileButton-photoWrapper {
  border-radius: 18px;
  overflow: hidden;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 4px;
  transition: 0.2s ease-out;
}

.ProfileButton:hover .ProfileButton-photoWrapper {
  /* box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f6d45a; */
}

.ProfileButton-photo {
  width: 100%;
  height: auto;
}

.ProfileButton-counter {
  position: absolute;
  top: -2px;
  right: -4px;
  z-index: 3;
}

.ProfileButton-letter {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 4px;
  box-sizing: border-box;
  line-height: 32px;
  text-align: center;
  border-radius: 18px;
  font-size: 18px;
  font-weight: 700;
  background-color: #e5ecf2;
}

.ProfileButton-loader {
  width: 40px;
  height: 40px;
  position: relative;
}
