.GiftProductBottomSheet {
}

.GiftProductBottomSheet-loader {
  min-height: 120px;
  position: relative;
}

.GiftProductBottomSheet-title {
  margin: 0;
  line-height: 1.2;
  font-size: 18px;
  letter-spacing: 0.3px;
}

.GiftProductBottomSheet-list {
  margin: 0;
  padding: 0;
}

.GiftProductBottomSheet-item {
  border-bottom: 1px solid #dbe1ec;
  list-style: none;
}

.GiftProductBottomSheet-action {
  padding: 8px 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 200;
}
