.BaseEditable {
  display: inline-flex;
  align-items: center;
}

.BaseEditable--isEditing {
}

.BaseEditable--disabled {
}

.BaseEditable-option {
}

.BaseEditable-option {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  outline: none;
  appearance: none;
  text-align: left;
}

.BaseEditable-optionValue {
  line-height: 1.2;
  border-bottom: 1px dotted currentColor;
}

.BaseEditable--disabled .BaseEditable-optionValue {
  border-bottom: none;
}

.BaseEditable-optionPlaceholder {
  color: #999;
  line-height: 1.2;
  border-bottom: 1px dotted currentColor;
}

.BaseEditable-actionGroup {
  margin-left: 24px;
}

.BaseEditable-actionsHolder {
  white-space: nowrap;
}

.BaseEditable-action {
  margin-right: 16px;
  display: inline-block;
}

.BaseEditable-action:last-child {
  margin-right: 0;
}

.BaseEditable-button {
  display: inline-block;
  margin: -8px;
  padding: 8px;
  border: none;
  line-height: 1;
  font-family: inherit;
  font-size: 1.25em;
  background-color: transparent;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  appearance: none;
  opacity: 0;
  transition: color 0.2s ease-out, opacity 0.2s ease-out;
}

.BaseEditable-button:disabled {
  cursor: not-allowed;
}

.BaseEditable--isEditing .BaseEditable-button {
  opacity: 0.5;
}

.BaseEditable:hover .BaseEditable-button {
  opacity: 0.8;
}

.BaseEditable-button:focus {
  opacity: 1;
}

.BaseEditable-button:hover {
  color: #25bc8b;
}

.BaseEditable-button:disabled:hover {
  color: #c0c0c0;
}
