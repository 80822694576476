.EmailFeedback {
}

.EmailFeedback-gratitudeGroup {
  margin-bottom: 32px;
}

@media (max-width: 840px) {
  .EmailFeedback-gratitudeGroup {
    margin-bottom: 24px;
  }
}

.EmailFeedback-comment {
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: 8px;
  line-height: 1.3;
  font-size: 24px;
  text-align: center;
}

@media (max-width: 840px) {
  .EmailFeedback-comment {
    max-width: 560px;
    margin-bottom: 4px;
    font-size: 20px;
  }
}

.EmailFeedback-iconHolder {
  text-align: center;
}

.EmailFeedback-icon {
  display: inline-block;
  animation: wiggle 2s linear infinite;
}

.EmailFeedback-form {
}

.EmailFeedback-optionList {
  margin: 0;
  padding: 0;
}

.EmailFeedback-option {
  margin-bottom: 8px;
  list-style: none;
}

.EmailFeedback-option:last-child {
  margin-bottom: 0;
}

.EmailFeedback-optionTextarea {
  max-width: 640px;
  margin-top: 16px;
}

.EmailFeedback-button {
  text-align: center;
}

@keyframes wiggle {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
