.UserMenu {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: -7px;
}

.UserMenu-item {
  display: inline-flex;
  margin: 7px;
  position: relative;
}

.UserMenu-item:last-child {
  margin-right: 0;
}

.UserMenu-icon {
  height: 20px;
}
.UserMenu-counter {
  position: absolute;
  top: -2px;
  right: -4px;
  z-index: 3;
}
