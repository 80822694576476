.ProductSectionList {
  padding-top: 64px;
}

@media (max-width: 480px) {
  .ProductSectionList {
    padding-top: 32px;
  }
}

.ProductSectionList-description {
  max-width: 660px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 64px;
  font-size: 18px;
}

@media (max-width: 840px) {
  .ProductSectionList-description {
    margin-bottom: 42px;
    font-size: 14px;
    margin-top: 8px;
  }
}

.ProductSectionList-groupTitle {
  text-align: center;
  font-size: 28px;
  margin-top: 0;
  margin-bottom: 42px;
}

@media (max-width: 840px) {
  .ProductSectionList-groupTitle {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.ProductSectionList-groupContent {
  display: flex;
  flex-wrap: wrap;
  /* margin: -16px; */
}

@media (max-width: 640px) {
  .ProductSectionList-groupContent {
    /* margin: -8px; */
  }
}

.ProductSectionList-groupContent--halfWidth {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 16px), 1fr));
  grid-auto-rows: 1fr;
  margin: 0;
  grid-gap: 16px;
  box-sizing: border-box;
}
.ProductSectionList-groupContent--halfWidth > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.ProductSectionList-groupContent--halfWidth::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.ProductSectionList-groupContent--halfWidth .ProductSectionList-groupItem {
  max-width: 100%;
}

.ProductSectionList-groupContent--halfWidth .ProductSectionList-groupItemInnerWrapper {
}

.ProductSectionList-groupContent--halfWidth
  .ProductSectionList-groupItemInnerWrapper
  .ProductSetCard {
}

.ProductSectionList-group {
  margin-bottom: 84px;
  flex: 1 1 auto;
}

@media (max-width: 840px) {
  .ProductSectionList-group {
    margin-bottom: 42px;
  }
}

.ProductSectionList-groupItem {
  flex-grow: 0;
  flex-basis: 25%;
  max-width: 25%;
  padding: 16px;
  box-sizing: border-box;
  overflow: visible;
}

.ProductSectionList-groupItem--halfWidth {
  padding: 0;
  flex-basis: 50%;
  max-width: 50%;
}

.ProductSectionList--wide .ProductList-groupItem {
  flex-basis: 25%;
  max-width: 25%;
}

.ProductSectionList--isLanding .ProductSectionList-itemInnerWrapper {
  height: 100%;
  background-color: #fff;
}

@media (max-width: 1100px) {
  .ProductSectionList-groupItem {
    flex-basis: 33.33%;
    min-width: 33.33%;
    height: 50vw;
  }
}

@media (max-width: 840px) {
  .ProductSectionList-groupItem {
    flex-basis: 50%;
    min-width: 50%;
    height: auto;
    padding: 8px;
  }
  .ProductSectionList-groupItem--halfWidth {
    padding: 0;
  }
}

.ProductSectionList-mediaCotainer {
  max-width: 480px;
  margin: 0 auto;
}

.ProductSectionList--mediaCotainerHorizontal {
  max-width: 820px;
}

.ProductSectionList-groupItemInnerWrapper {
  height: 100%;
}
