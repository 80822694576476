.SubscribeChannel {
}

.SubscribeChannel-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

@media (max-width: 840px) {
  .SubscribeChannel-header {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.SubscribeChannel-triggerHolder {
  position: relative;
  margin-right: 32px;
}

@media (max-width: 840px) {
  .SubscribeChannel-triggerHolder {
    margin-right: 0;
    margin-left: 32px;
  }
}

.SubscribeChannel-triggerState {
  position: absolute;
  left: 0;
  bottom: -24px;
  color: #c7c9d6;
  font-size: 14px;
}

.SubscribeChannel-title {
  margin: 0;
  line-height: 26px;
  font-size: 18px;
}

.SubscribeChannel-topicList {
  margin: 0;
  padding-left: 80px;
}

.SubscribeChannel-topicItem {
  margin-bottom: 8px;
  list-style: none;
}

.SubscribeChannel-topicItem:last-child {
  margin-bottom: 0;
}
