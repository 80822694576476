.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 37, 48, 0.5);
  height: 100%;

  z-index: 900;
  visibility: visible;
}

.Overlay--scrollable {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.Overlay-content {
  max-height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 0 0 auto;
}

.Overlay--scrollable .Overlay-content {
  position: static;
  flex: 0 0 auto;
}

.Overlay--fullOppened .Overlay-content {
  flex: 1 0 auto;
  max-height: 100%;
}

.Overlay--scrollable .Overlay-spacer {
  min-height: calc(100% - 320px);
  /* min-height: 20vh; */
  position: relative;
  z-index: -1;
  pointer-events: none;
  flex: 1 1 auto;
}

.Overlay--scrollable.Overlay--fullOppened .Overlay-spacer {
  min-height: auto;
}

.Overlay--withoutSpacer.Overlay--fullOppened .Overlay-spacer {
  height: 0;
  min-height: 0;
  flex: 0 1 auto;
}
