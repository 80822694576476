.MainHeaderMobile {
  padding: 16px 0;
  overflow: hidden;
  /*border-bottom: 1px solid #e8eaec;*/
}

@media (max-width: 1100px) {
  .MainHeaderMobile {
    padding: 16px 0;
  }
}

@media (max-width: 840px) {
  .MainHeaderMobile {
    padding: 12px 0;
  }
}

.MainHeaderMobile-content {
  display: flex;
  align-items: center;
}
.MainHeaderMobile-leftCol {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.MainHeaderMobile-rightCol {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.MainHeaderMobile-trigger {
  position: relative;
  z-index: 422;
  margin-right: 20px;
}

@media (max-width: 480px) {
  .MainHeaderMobile-trigger {
    margin-right: 16px;
  }
}

@media (max-width: 400px) {
  .MainHeaderMobile-trigger {
    margin-right: 12px;
  }
}

@media (max-width: 380px) {
  .MainHeaderMobile-trigger {
    margin-right: 8px;
  }
}

@media (max-width: 360px) {
  .MainHeaderMobile-trigger {
    margin-right: 6px;
  }
}

.MainHeaderMobile-trigger:last-child {
  margin-right: 0;
}

.MainHeaderMobile-trigger--inverted {
  color: #fff;
}

.MainHeaderMobile-logoCol {
  flex: 0 0 300px;
  max-width: 300px;
}

@media (max-width: 640px) {
  .MainHeaderMobile-logoCol {
    flex: 0 0 140px;
    max-width: 140px;
  }
}

@media (max-width: 480px) {
  .MainHeaderMobile-logoCol {
    flex: 0 0 120px;
    max-width: 120px;
  }
}

.MainHeaderMobile-buttonLoader {
  width: 32px;
  height: 32px;
  position: relative;
}
