.ProblemSkin_Products-Item {
  display: flex;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-Item {
    flex-direction: column;
  }
}

.ProblemSkin_Products-Item_imageHolder {
  flex: 1 1 50%;
  /* background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProblemSkin_Products-Item--mirrored .ProblemSkin_Products-Item_imageHolder {
  order: 1;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-Item--mirrored .ProblemSkin_Products-Item_imageHolder {
    order: inherit;
  }
}

.ProblemSkin_Products-Item_image {
  max-width: 500px;
}

.ProblemSkin_Products-Item_info {
  flex: 1 1 50%;
  background-color: rgba(244, 244, 244, 0.7);
  padding: 54px 94px 86px 84px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-Item_info {
    padding: 42px 14px;
  }
}

.ProblemSkin_Products-Item_title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
@media (max-width: 1100px) {
  .ProblemSkin_Products-Item_title {
    font-size: 20px;
    margin-bottom: 18px;
  }
}

.ProblemSkin_Products-Item_properties {
  margin-bottom: auto;
  padding-left: 0;
}

.ProblemSkin_Products-Item_property {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
  display: block;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-Item_property {
    font-size: 16px;
    line-height: 24px;
  }
}

.ProblemSkin_Products-Item_property::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #8e3387;
  margin-right: 8px;
  transform: translateY(-4px);
}

.ProblemSkin_Products-Item_showMore {
  margin-bottom: 24px;
}
/* @media (max-width: 1100px) {
  .ProblemSkin_Products-Item_showMore {
    display: none;
  }
} */

.ProblemSkin_Products-Item_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-Item_footer {
    flex-direction: column;
  }
}

.ProblemSkin_Products-Item_price {
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-Item_price {
    margin-bottom: 20px;
  }
}

.ProblemSkin_Products-Item_addToBasket {
}

.ProblemSkin_Products-Item_defaultPrice {
  font-size: 18px;
  color: #818690;
  margin-right: 16px;
}

.ProblemSkin_Products-setProduct_price .ProblemSkin_Products-Item_defaultPrice {
  font-size: 20px;
}

.ProblemSkin_Products-Item_currentPrice {
  color: #8e3387;
  font-size: 24px;
  font-weight: bold;
}
.ProblemSkin_Products-setProduct_price .ProblemSkin_Products-Item_currentPrice {
  font-size: 26px;
}

.ProblemSkin_Products-Item--hasDiscount .ProblemSkin_Products-Item_currentPrice {
  color: #e95958;
}
