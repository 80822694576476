.LB_Images {
  display: flex;
  flex-direction: column;
}

.LB_Images-main {
  position: relative;
  max-width: 80%;
  z-index: 1;
}

.LB_Images-secondary {
  max-width: 48%;
  margin-left: auto;
  transform: translateY(-17%);
  margin-bottom: -5%;
  z-index: 1;
}

@media (max-width: 840px) {
  .LB_Images-secondary {
    transform: translateY(-50%);
    margin-bottom: -30%;
  }
}

.LB_Images-circle {
  position: absolute;
  width: 280px;
  height: 280px;
  bottom: -5%;
  left: 20%;
}

@media (max-width: 840px) {
  .LB_Images-circle {
    left: -15%;
    width: 200px;
    height: 200px;
    bottom: -10%;
  }
}
