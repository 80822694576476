.AvatarPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #e5ecf2;
  font-size: inherit;
  text-transform: uppercase;
  font-weight: bold;
}
