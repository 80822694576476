.AdditionalInfoItem {
  display: inline-block;
  color: #000;
}

.AdditionalInfoItem-title {
  margin: 0;
  margin-bottom: 16px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 300;
  color: #808792;
}

.AdditionalInfoItem-list {
  display: inline-block;
}

.AdditionalInfoItem-item {
  display: inline-block;
  margin-right: 16px;
}

.AdditionalInfoItem-item:last-child {
  margin-right: 0;
}
