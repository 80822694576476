.QuickFilter {
  line-height: 1.6;
}

.QuickFilter-item {
  padding: 8px 0;
  cursor: pointer;
}

.QuickFilter-item:hover {
  color: #25bc8b;
}

.QuickFilter-active {
  padding: 8px 0;
  font-weight: bold;
  cursor: default;
}
