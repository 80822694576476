.ShopsGrid {
  display: flex;
  margin: -8px;
}

@media (max-width: 1100px) {
  .ShopsGrid {
    flex-direction: column-reverse;
    margin: -16px;
  }
}

.ShopsGrid-listCol {
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 8px;
}

@media (max-width: 1100px) {
  .ShopsGrid-listCol {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 16px;
  }
}

.ShopsGrid-alertHolder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ShopsGrid-alert {
}

.ShopsGrid-alertTitle {
  margin: 0;
  margin-bottom: 24px;
  line-height: 1.2;
  font-size: 24px;
  text-align: center;
}

.ShopsGrid-searchPanel {
  margin-bottom: 32px;
  padding-right: 16px;
}

@media (max-width: 1100px) {
  .ShopsGrid-searchPanel {
    margin-bottom: 0;
    padding-right: 0;
  }
}

.ShopsGrid-searchField {
  margin-bottom: 8px;
}

.ShopsGrid-list {
  margin: 0;
  padding: 0;
  padding-right: 16px;
}

@media (max-width: 1100px) {
  .ShopsGrid-list {
    padding-right: 0;
  }
}

.ShopsGrid-item {
  list-style: none;
  border-bottom: 1px solid #d1d5da70;
  transition: background-color 0.2s ease-out;
}

.ShopsGrid-item:first-child {
  border-top: 1px solid #d1d5da70;
}

@media (max-width: 1100px) {
  .ShopsGrid-item:first-child {
    border-top: none;
  }
}

.ShopsGrid-item:last-child {
}

@media (max-width: 1100px) {
  .ShopsGrid-item:last-child {
    border-bottom: none;
  }
}

.ShopsGrid-loader {
  position: relative;
  height: 100%;
}

@media (max-width: 1100px) {
  .ShopsGrid-loader {
    height: 120px;
  }
}

@media (max-width: 640px) {
  .ShopsGrid-loader {
    height: 60px;
  }
}

.ShopsGrid-anotherCityChoice {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .ShopsGrid-anotherCityChoice {
    display: block;
  }
}

.ShopsGrid-mapCol {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 8px;
}

@media (max-width: 1100px) {
  .ShopsGrid-mapCol {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 16px;
    order: -1;
  }
}

.ShopsGrid-mapHolder {
  position: sticky;
  top: 16px;
}

@media (max-width: 1100px) {
  .ShopsGrid-mapHolder {
    position: static;
  }
}

.ShopsGrid-mapSquare {
  position: relative;
  padding-top: 100%;
}

@media (max-width: 1100px) {
  .ShopsGrid-mapSquare {
    padding-top: 0;
    height: 480px;
  }
}

@media (max-width: 640px) {
  .ShopsGrid-mapSquare {
    height: 360px;
  }
}

.ShopsGrid-mapSizer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  border-radius: 3px;
}
