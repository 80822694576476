.PackingDialog {
  width: 1170px;
  max-width: 100%;
  padding: 32px;
  padding-top: 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .PackingDialog {
    padding: 24px;
    padding-top: 0;
  }
}

@media (max-width: 640px) {
  .PackingDialog {
    padding: 16px;
    padding-top: 0;
  }
}

.PackingDialog-static {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px;
}

.PackingDialog-staticItem {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 32px 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .PackingDialog-staticItem {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.PackingDialog--single .PackingDialog-staticItem {
  flex: 0 0 100%;
  max-width: 100%;
}
