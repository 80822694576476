.PromoCodeWithButton {
  display: flex;
}

@media (max-width: 480px) {
  .PromoCodeWithButton {
    flex-direction: column;
  }
}

.PromoCodeWithButton-label {
  padding: 9px 56px;
  border-radius: 25px;
  border: 1px dashed #262931;
  box-sizing: content-box;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: text;
  text-align: center;
}

.PromoCodeWithButton--notSupporterCopy {
  cursor: default;
}

.PromoCodeWithButton--promoCodeCopied {
  cursor: default;
}

.PromoCodeWithButton--inverted .PromoCodeWithButton-label {
  border: 1px dashed currentColor;
  color: inherit;
}

.PromoCodeWithButton--onOverlay .PromoCodeWithButton-label {
  border: 1px dashed #fff;

  position: relative;
  overflow: hidden;
}

.PromoCodeWithButton--onOverlay .PromoCodeWithButton-label::before {
  content: '';
  display: block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  color: inherit;
  background-color: currentColor;
  opacity: 0.4;
}

.PromoCodeWithButton--size-small .PromoCodeWithButton-label {
  padding: 9px 26px;
  font-size: 12px;
}

.PromoCodeWithButton--size-normal .PromoCodeWithButton-label {
  font-size: 16px;
}

.PromoCodeWithButton-title {
  margin-right: 8px;
  user-select: none;
}

.PromoCodeWithButton--onOverlay .PromoCodeWithButton-title {
  color: #fff;
}

.PromoCodeWithButton-input {
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  border: none;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  width: 100%;
  outline: none;
  font-size: 24px;
  cursor: text;
  font-family: inherit;
  height: 30px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
}

.PromoCodeWithButton--size-small .PromoCodeWithButton-input {
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}

.PromoCodeWithButton--size-normal .PromoCodeWithButton-input {
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}

.PromoCodeWithButton--promoCodeCopied .PromoCodeWithButton-input {
  cursor: default;
}

.PromoCodeWithButton--notSupporterCopy .PromoCodeWithButton-input {
  cursor: text;
}

.PromoCodeWithButton--onOverlay .PromoCodeWithButton-input {
  color: #ffd12c;
}

.PromoCodeWithButton--inverted .PromoCodeWithButton-input {
}

.PromoCodeWithButton-copyButton {
  margin-left: 16px;
  min-width: 230px;
  color: #262931;
}

@media (max-width: 480px) {
  .PromoCodeWithButton-copyButton {
    margin-left: 0;
    margin-top: 8px;
  }
}

.PromoCodeWithButton--size-small .PromoCodeWithButton-copyButton {
  min-width: 160px;
}
