.Media {
  margin: 0;
  padding: 0;
  position: relative;
}

.Media--withRatio {
  width: 100%;
  display: block;
}

.Media--stretch-vertical {
  height: 100%;
}

.Media-picture {
  display: block;
  visibility: hidden;
  position: absolute;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.Media--loaded .Media-picture {
  visibility: visible;
  position: static;
  opacity: 1;
}

.Media--withRatio .Media-picture {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.Media--stretch-vertical .Media-picture {
  height: 100%;
}

.Media-placeholderHolder {
  position: relative;
  display: block;
}

.Media-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Media--loaded .Media-placeholderHolder {
  display: none;
}

.Media-picture img {
  vertical-align: middle;
}

.Media--withRatio img {
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.Media--stretch-horizontal .Media-picture img {
  width: 100%;
}

.Media--stretch-vertical .Media-picture img {
  height: 100%;
}

.Media-video {
}

.Media-video--coverPoster {
  position: relative;
}

.Media-videoCover {
}

.Media-video--coverPoster .Media-videoCover {
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Media-videoWindow {
  width: 100%;
  vertical-align: middle;
  cursor: inherit;
}

.Media-video--noInteractive .Media-videoWindow {
  cursor: inherit;
}

.Media-buttonPlay {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.Media-iconPlay {
  display: block;
  fill: currentColor;
  color: inherit;
  height: 100%;
}

.Media-caption {
  text-align: center;
}

.Media-youTubeHolder {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.Media-youTube {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
