.NotificationCounter {
  display: inline-block;
  height: 16px;
  min-width: 16px;
  padding: 0 5px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e95958;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
  vertical-align: bottom;
}
