.ColorSwitch {
  display: inline-flex;
  margin: -8px;
  vertical-align: top;
}

.ColorSwitch-item {
  padding: 8px;
}

.ColorSwitch-option {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  color: inherit;
  background-color: currentColor;
  outline: none;
  cursor: pointer;
}

.ColorSwitch-option--checked {
  box-shadow: 0 0 0 3px #ffffff, 0 0 0 4px currentColor;
  cursor: default;
  transition: box-shadow 0.2 ease-out;
}

.isTabUsing .ColorSwitch-option--checked:focus {
  box-shadow: 0 0 0 3px #ffffff, 0 0 0 5px currentColor;
}

.ColorSwitch-option--disabled {
  opacity: 0.3;
  transition: opacity 0.2s ease-out;
  cursor: not-allowed;
  position: relative;
}

.ColorSwitch-option--disabled:hover {
  opacity: 1;
}

.ColorSwitch-option--disabled:hover::after {
  opacity: 1;
  visibility: visible;
}

.isTabUsing .ColorSwitch-option--disabled:focus {
  outline: none;
}
