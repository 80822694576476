.SectionDefault {
  margin: 72px 0;
}

.SectionDefault--overflowHidden {
  overflow: hidden;
  margin: 0;
  padding: 48px 0;
}

@media screen and (max-width: 1100px) {
  .SectionDefault--overflowHidden {
    padding: 0;
  }
}

.SectionDefault:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1100px) {
  .SectionDefault {
    margin: 64px 0;
  }
}

@media screen and (max-width: 640px) {
  .SectionDefault {
    margin: 48px 0;
  }
}

.SectionDefault--noGap {
  margin: 0;
}

.SectionDefault--highlightBackground {
  background-color: #f6f8fb;
}

.SectionDefault-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: #262931;
  margin: 0;
  margin-bottom: 48px;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .SectionDefault-title {
    margin-bottom: 40px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 640px) {
  .SectionDefault-title {
    margin-bottom: 32px;
    font-size: 22px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 400px) {
  .SectionDefault-title {
    margin-bottom: 24px;
    font-size: 18px;
  }
}

.SectionDefault-link {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.SectionDefault-linkText {
  display: inline-block;
}

.SectionDefault-linkIconHolder {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  padding-left: 8px;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

@media screen and (max-width: 1100px) {
  .SectionDefault-linkIconHolder {
    visibility: visible;
    z-index: auto;
    opacity: 1;
    transition: none;
  }
}

.SectionDefault-link:hover .SectionDefault-linkIconHolder {
  visibility: visible;
  z-index: auto;
  opacity: 1;
}

.SectionDefault-linkIcon {
  display: inline-block;
  width: 28px;
}

@media screen and (max-width: 640px) {
  .SectionDefault-linkIcon {
    width: 24px;
  }
}

@media screen and (max-width: 400px) {
  .SectionDefault-linkIcon {
    width: 20px;
  }
}

.SectionDefault-buttonMore {
  margin-top: 24px;
  padding: 0 16px;
  text-align: center;
}

@media (max-width: 640px) {
  .SectionDefault-buttonMore {
    margin-top: 16px;
  }
}
