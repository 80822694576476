.ButtonTooltip {
  position: relative;
}

.ButtonTooltip-info {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-75%, -15px);
  z-index: 4;
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 440px) {
  .ButtonTooltip-info {
    transform: translate(-50%, -15px);
  }
}

.ButtonTooltip--isActive .ButtonTooltip-info {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.ButtonTooltip-button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  width: 42px;
  height: 42px;
  font-size: 25px;
  border-radius: 50%;
  bottom: 90px;
  right: 80px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  padding: 0;
}

.ButtonTooltip-iconHolder {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.ButtonTooltip-button:hover,
.isTabUsing .ButtonTooltip-button:focus {
  background-color: rgba(255, 209, 44, 0.5);
  color: #262931;
}

.ButtonTooltip-button::after,
.ButtonTooltip-button::before {
  content: '';
  font-size: 25px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transition: transform 0.2s ease-out;
  transform: scale(0);
}

.ButtonTooltip-button::after {
  z-index: 2;
  width: 16px;
  height: 16px;
  margin-left: -8px;
  margin-top: -8px;
  background-color: rgba(255, 209, 44, 0.5);
}

.ButtonTooltip-button::before {
  z-index: 3;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  margin-top: -2px;
  background-color: #fff343;
}

.ButtonTooltip--isActive .ButtonTooltip-button {
  background-color: rgba(255, 209, 44, 0.3);
  transform: scale(1.2);
  color: transparent;
}

.ButtonTooltip--isActive .ButtonTooltip-button:after,
.ButtonTooltip--isActive .ButtonTooltip-button:before {
  transform: scale(1);
}
