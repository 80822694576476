.FeedbackThanks {
}

.FeedbackThanks-comment {
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
}

.FeedbackThanks-button {
  text-align: center;
}
