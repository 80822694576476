.Review {
  font-size: 14px;
}

.Review-header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.Review-name {
  font-weight: bold;
  font-size: 16px;
  margin-right: 16px;
}

.Review-rating {
}

.Review-date {
  font-weight: 400;
  color: #cacacc;
  margin-left: auto;
}

.Review-text {
  margin-top: 8px;
  margin-bottom: 16px;
}

.Review-assessment {
  margin-bottom: 16px;
}

.Review-rate {
  flex-grow: 1;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.Review-footer {
  display: flex;
  flex: 1 1 auto;
}

.Review-question {
  margin-right: 10px;
}

@media (max-width: 840px) {
  .Review-question {
    display: none;
  }
}

.Review-button {
  margin: 0 5px;
}

.Review-replies {
  padding-left: 48px;
  border-top: 1px solid #edf0f5;
  margin-top: 8px;
}

.Review-replyItem {
  border-bottom: 1px solid #edf0f5;
  padding: 16px 0;
}

.Review-replyItem:last-child {
  border-bottom: none;
}

.Review-replyForm {
  padding: 16px 0;
}
