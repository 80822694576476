.Breadcrumbs {
  padding: 24px 0;
  font-size: 14px;
  color: #a1a6af;
  cursor: default;
  display: flex;
  align-items: center;
}

.Breadcrumbs-linkPrevious {
  text-decoration: none;
  color: inherit;
}

.Breadcrumbs-linkPrevious:hover {
  text-decoration: underline;
}

.Breadcrumbs-linkCurrent {
  color: #454545;
}

.Breadcrumbs-arrow {
  margin: 0 8px;
  color: inherit;
  width: 8px;
  height: 8px;
  display: inline-block;
  box-sizing: border-box;
  fill: #a1a6af;
}
