.BonusHistoryTableMobile {
  border-top: 1px solid #d7deea;
  border-bottom: 1px solid #d7deea;
}

.BonusHistoryTableMobile-transactionList {
  margin: 0;
  padding: 0;
}

.BonusHistoryTableMobile-transaction {
  list-style: none;
  border-bottom: 1px solid #d7deea;
}

.BonusHistoryTableMobile-transaction:last-child {
  border-bottom: none;
}
