.YoutubeVideo {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 3px;
}

.YoutubeVideo-trigger {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.YoutubeVideo-player {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
