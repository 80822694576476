.ProductConsistency {
  display: flex;
  margin: 0;
}

@media screen and (max-width: 840px) {
  .ProductConsistency {
    flex-wrap: wrap;
  }
}

.ProductConsistency-colInfo {
  flex: 0 0 350px;
  max-width: 350px;
  box-sizing: border-box;
  padding-right: 16px;
}

@media screen and (max-width: 840px) {
  .ProductConsistency-colInfo {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 16px;
  }
}

.ProductConsistency-colIngredients {
  flex: 1 1 auto;
  padding: 16px;
}

@media screen and (max-width: 840px) {
  .ProductConsistency-colIngredients {
    flex: 0 0 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}

.ProductConsistency-title {
  margin: 0;
  margin-bottom: 24px;
  line-height: 1.2;
}

.ProductConsistency-natureIngredients {
  color: #25bc8b;
  margin-bottom: 24px;
  line-height: 2;
}
.ProductConsistency-syntheticIngredients {
  margin-bottom: 24px;
  line-height: 2;
}
