.VideoDialog {
  width: 640px;
  padding: 64px 0 0;
}

@media (max-width: 1100px) {
  .VideoDialog {
    width: auto;
  }
}

@media (max-width: 640px) {
  .VideoDialog {
    padding: 48px 0 0;
  }
}

@media (max-width: 480px) {
  .VideoDialog {
    padding: 40px 0 0;
  }
}
