.ArticleBanner {
  position: relative;
  overflow: hidden;
  height: 480px;
}

@media (max-width: 840px) {
  .ArticleBanner {
    height: 320px;
  }
}

.ArticleBanner:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 41, 49, 0.4);
}

.ArticleBanner-background {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: zoomin 40s ease-in-out infinite;
}

.ArticleBanner-contentHolder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArticleBanner-content {
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 32px;
}

@media (max-width: 840px) {
  .ArticleBanner-content {
    padding: 16px;
  }
}

.ArticleBanner-title {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  max-width: 560px;
  line-height: 48px;
  margin: 0 0 24px 0;
}

@media (max-width: 840px) {
  .ArticleBanner-title {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

.ArticleBanner-description {
  text-align: center;
  margin-bottom: 24px;
  font-size: 18px;
}

@media (max-width: 840px) {
  .ArticleBanner-description {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.ArticleBanner-toArticle {
  display: inline-block;
  text-align: center;
}

@keyframes zoomin {
  5% {
    transform: scale(1);
    transform-origin: left center;
  }
  15% {
    transform-origin: left center;
  }
  30% {
    transform: scale(1.06);
  }
  70% {
    transform: scale(1.06);
  }
  85% {
    transform-origin: right center;
  }
  95% {
    transform: scale(1);
    transform-origin: right center;
  }
}
