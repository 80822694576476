.EditUserName {
  display: flex;
  align-items: center;
  position: relative;
  /* height: 40px; */
}

.EditUserName-editButton {
  margin-left: 16px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 0.8em;
  line-height: 1;
  padding: 8px;
  margin: -8px;
  margin-left: 8px;
  color: #848fa5;
  opacity: 0.6;
}

.EditUserName:hover .EditUserName-editButton {
  opacity: 1;
}
