.BonusHistoryScene_Desktop {
}

.BonusHistoryScene_Desktop-loader {
  position: relative;
  padding: 160px 0;
}

.BonusHistoryScene_Desktop-filter {
  margin-bottom: 24px;
}

.BonusHistoryScene_Desktop-table {
}
