.PersonalInfoForm {
}

.PersonalInfoForm-note {
  margin-bottom: 24px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 300;
  color: #262931;
  text-transform: lowercase;
}

.PersonalInfoForm-notification {
  background-color: #ecf9ff;
  padding: 8px 16px;
  border-radius: 3px;
}

@media (max-width: 840px) {
  .PersonalInfoForm-note {
    padding: 8px 0;
    margin-bottom: 0;
  }
}

.PersonalInfoForm-form {
}

.PersonalInfoForm-checkboxRow {
  display: flex;
  align-items: center;
}

.PersonalInfoForm-checkbox {
  margin-right: 8px;
}

.PersonalInfoForm-info {
}

.PersonalInfoForm-infoSign {
  display: inline-block;
  text-align: center;
  width: 18px;
  height: 18px;
  border-radius: 8px;
  border: 1px solid #ffd12c;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.PersonalInfoForm-infoSign:hover {
  color: #fff;
  background-color: #ffd12c;
}

.PersonalInfoForm-button {
  text-align: right;
}

.PersonalInfoForm-appendedIcon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #848fa5;
}
