.ProblemSkin_FormSection {
  padding: 80px 16px;
  position: relative;
}

.ProblemSkin_FormSection::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(27, 27, 27, 0.4);
}

.ProblemSkin_FormSection-container {
  max-width: 830px;
  border-radius: 22px;
  background-color: #fff;
  margin: 0 auto;
  padding: 64px 86px;
  position: relative;
}
@media (max-width: 1100px) {
  .ProblemSkin_FormSection-container {
    padding: 36px 14px;
  }
}

.ProblemSkin_FormSection-form {
  margin: 0 auto;
  max-width: 470px;
}

.ProblemSkin_FormSection-topText .Content {
  font-size: 24px !important;
  text-align: center;
  margin-bottom: 42px;
}

@media (max-width: 1100px) {
  .ProblemSkin_FormSection-topText .Content {
    font-size: 16px !important;
    margin-bottom: 16px;
  }
}

.ProblemSkin_FormSection-bottomText {
  font-size: 18px;
  line-height: 1.333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}

@media (max-width: 1100px) {
  .ProblemSkin_FormSection-bottomText {
    font-size: 14px;
  }
}
