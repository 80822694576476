.ProblemSkin_Products-setProduct {
  background: linear-gradient(107.59deg, #cfcfd0 1.17%, #f4f4f4 106.01%), #f1f1f1;
  padding: 32px 0;
}

.ProblemSkin_Products-setProduct-container {
  /* max-width: 1100px;
    margin: 0 auto;
    padding: 16px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  /* justify-content: space-between; */
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-setProduct-container {
    display: flex;
    flex-direction: column;
  }
}

.ProblemSkin_Products-setProduct_info {
  text-align: center;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-setProduct_info {
    flex-basis: 100%;
  }
}

.ProblemSkin_Products-setProduct_title {
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 12px;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-setProduct_title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 8px;
  }
}

.ProblemSkin_Products-setProduct_description {
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 36px;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-setProduct_description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

.ProblemSkin_Products-setProduct_price {
  margin-bottom: 36px;
}
.ProblemSkin_Products-addToBasket {
}

.ProblemSkin_Products-setProduct-visual {
  flex: 1 1 50%;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-setProduct-visual {
    order: -1;
    flex-basis: 100%;
    max-width: 80%;
    margin: auto;
  }
}

.ProblemSkin_Products-setProduct-imageHolder {
}
