.Dropdown {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 hsla(0, 0%, 0%, 0.1);
  max-width: 320px;
  position: absolute;
  z-index: 200;
}

.Dropdown--fixed {
  position: fixed;
}
