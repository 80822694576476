.Tooltip {
  position: absolute;
  z-index: 100;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 16px 0 rgba(47, 47, 71, 0.1);
}

.Tooltip--size-small {
}

.Tooltip--top {
  margin-top: -12px;
}

.Tooltip--top::before {
  left: 50%;
  bottom: 0;
}

.Tooltip--right {
  margin-right: -12px;
}

.Tooltip--right::before {
  top: 50%;
  left: 0;
}

.Tooltip--alignment-center {
}

.Tooltip--alignment-left {
  transform: translateX(calc(50% - 24px));
}

.Tooltip--alignment-right {
  transform: translateX(calc(-50% + 24px));
}

.Tooltip::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 16px;
  height: 16px;
  margin: -8px;
  border-radius: 0 0 0 3px;
  box-sizing: border-box;
  background-color: #fff;
  transform: rotate(-45deg);
  box-shadow: 0 2px 16px 0 rgba(47, 47, 71, 0.1);
}

.Tooltip-box {
  padding: 16px 24px;
  background-color: #fff;
  border-radius: inherit;
  position: relative;
}

.Tooltip--size-small .Tooltip-box {
  padding: 8px 16px;
}

.Tooltip--alignment-left::before {
  right: auto;
  left: 24px;
}

.Tooltip--alignment-right::before {
  left: auto;
  right: 24px;
}

.Tooltip-title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.Tooltip--size-small .Tooltip-title {
  font-size: 14px;
  margin-bottom: 0;
}

.Tooltip-button {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #5f646c;
}

.Tooltip-content {
  position: relative;
  min-width: 180px;
  font-size: 14px;
  color: #444952;
}
