.Availability {
  display: inline-flex;
  align-items: center;
  color: #868d97;
  vertical-align: middle;
}

.Availability--is-true {
  color: #25bc8b;
}

.Availability-icon {
  width: 1em;
  height: 1em;
  margin-right: 6px;
}
