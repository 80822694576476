.UTMBanner {
  display: flex;
}

.UTMBanner-content {
  width: 100%;
}

.UTMBanner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 970px) {
  .UTMBanner-header {
    flex-direction: column;
  }
}

.UTMBanner-leftCol {
  margin-right: 16px;
}

@media (max-width: 970px) {
  .UTMBanner-leftCol {
    text-align: center;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.UTMBanner-rightCol {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 420px) {
  .UTMBanner-rightCol {
    justify-content: center;
  }
}

.UTMBanner-promo {
  display: flex;
  align-items: center;
  color: #fff;
}

.UTMBanner-promoTitle {
  margin-right: 16px;
}

.UTMBanner-infoTitle {
  color: #fff;
  margin-right: 16px;
}

.UTMBanner-title {
  color: #ffd12c;
  font-size: 24px;
  font-weight: bold;
  flex-basis: 100%;
  max-width: 100%;
}

.UTMBanner-infoTrigger {
  background-color: transparent;
  padding: 0;
  border: none;
  margin-left: 16px;
  border-radius: 50%;
  outline: none;
}

@media (max-width: 970px) {
  .UTMBanner-infoTrigger {
    margin-left: 0;
  }
}

.UTMBanner-infoTriggerHolder {
  margin-right: 16px;
}

@media (max-width: 420px) {
  .UTMBanner-infoTriggerHolder {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 16px;
    margin-right: 0;
    order: 1;
    display: flex;
    justify-content: center;
  }
}

.isTabUsing .UTMBanner-infoTrigger:focus {
  box-shadow: 0 0 0 2px rgba(47, 124, 192, 0.54);
}
