.OpeningShopsBanner {
  position: relative;
  margin-bottom: 64px;
}

/* .OpeningShopsBanner-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
} */

.OpeningShopsBanner-title {
  font-size: 38px;
  padding-top: 10%;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 13%;
  top: 0;
}

@media (max-width: 1200px) {
  .OpeningShopsBanner-title {
    padding-top: 7%;
  }
}

@media (max-width: 840px) {
  .OpeningShopsBanner-title {
    position: static;
  }
}

.OpeningShopsBanner-labels {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 42%;
}

@media (max-width: 840px) {
  .OpeningShopsBanner-labels {
    position: static;
  }
}

.OpeningShopsBanner-imagesHolder {
  display: flex;
}

.OpeningShopsBanner-images {
  margin-left: auto;
  width: 57.2%;
}

@media (max-width: 840px) {
  .OpeningShopsBanner-images {
    width: 100%;
  }
}

.OpeningShopsBanner-bottom {
  position: absolute;
  right: calc(1200px / 2 - 110vw / 2);
  bottom: -10vw;
}

@media (max-width: 1250px) {
  .OpeningShopsBanner-bottom {
    right: -10vw;
    bottom: -15vw;
  }
}
@media (max-width: 840px) {
  .OpeningShopsBanner-bottom {
    bottom: -15vw;
  }
}

.OpeningShopsBanner-newCircle {
  height: 25vw;
  width: 25vw;
  max-width: 350px;
}

@media (max-width: 840px) {
  .OpeningShopsBanner-newCircle {
    /* height: 220px;
    width: 220px; */
    width: 45vw;
    height: 45vw;
  }
}
