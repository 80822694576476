.MainCard-title {
  font-size: 24px;
  margin: 0;
}

@media screen and (max-width: 770px) {
  .MainCard-title {
    font-size: 20px;
    line-height: 20px;
  }
}

.MainCard-link {
  font-size: 14px;
  margin-bottom: 5px;
}

.MainCard-currentPrice {
  font-size: 28px;
}

.MainCard-previousPrice {
  font-size: 21px;
}
