.InstagramSocialNetwork {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.InstagramSocialNetwork-title {
  color: #444952;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 700;
  margin: 0 auto;
  margin-bottom: 32px;
  text-align: center;
  max-width: 480px;
}

.InstagramSocialNetwork-actionInstagram {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}

@media (max-width: 480px) {
  .InstagramSocialNetwork-actionInstagram {
    flex-direction: column;
  }
}

.InstagramSocialNetwork-action {
  display: inline-block;
}

.InstagramSocialNetwork-instagramButton {
  display: inline-block;
  margin-right: 32px;
}

@media (max-width: 480px) {
  .InstagramSocialNetwork-instagramButton {
    margin-right: 0;
    margin-bottom: 32px;
  }
}

.InstagramSocialNetwork-separator {
  margin: 0 auto;
  width: 264px;
  height: 1px;
  background-color: #ececec;
  margin-bottom: 42px;
}

.InstagramSocialNetwork-counterList {
  margin: 0;
  padding: 0;
}

@media (max-width: 480px) {
  .InstagramSocialNetwork-counterList {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    margin: -12px;
  }
}

.InstagramSocialNetwork-counter {
  display: inline-block;
  margin-right: 8px;
  list-style: none;
}

@media (max-width: 480px) {
  .InstagramSocialNetwork-counter {
    margin-right: 0;
    padding: 12px;
    box-sizing: border-box;
  }
}

/* @media (max-width: 480px) {
  .InstagramSocialNetwork-counter--align-right {
    text-align: right;
  }
} */

.InstagramSocialNetwork-counter:last-child {
  margin-right: 0;
}

.InstagramSocialNetwork-counterPreloader {
  position: relative;
  padding: 43px 0;
}
