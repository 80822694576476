.Activity {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Activity-card {
  align-self: stretch;
}

.Activity-arrow {
  width: 50px;
  margin: 32px 0;
}

@media (max-width: 840px) {
  .Activity-arrow {
    width: 35px;
    margin: 18px 0;
  }
}

.Activity-details {
}
