.ProductFilter {
}

.ProductFilter-header {
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  outline: none;
}

.isTabUsing .ProductFilter-header:focus {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px rgba(47, 124, 192, 0.54);
}

.ProductFilter-name {
  flex: 0 0 auto;
  margin-right: auto;
}

.ProductFilter-indicator {
  flex: 0 0 auto;
  font-weight: 700;
  font-size: 20px;
}

.ProductFilter-options {
  padding: 16px 0 24px;
  user-select: none;
}

.ProductFilter-amount {
  font-size: 14px;
  color: #818690;
  white-space: nowrap;
}

.ProductFilter-amount:before {
  content: '( ';
  display: inline-block;
}

.ProductFilter-amount:after {
  content: ' )';
  display: inline-block;
}
