.PaymentForm {
}

.PaymentForm-radioList {
  margin-bottom: 24px;
}

@media (max-width: 840px) {
  .PaymentForm-radioList {
    padding-top: 32px;
  }
}

.PaymentForm-loader {
  min-height: 24px;
  position: relative;
}
