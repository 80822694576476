.ArticleList {
  overflow: hidden;
}

.ArticleList-itemsHolder {
  margin: -24px;
}

.ArticleList-item {
  padding: 24px;
}
