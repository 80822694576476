.Trigger {
  display: inline-flex;
}

.Trigger--expanded {
  display: flex;
}

.Trigger-item {
  font: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 16px 36px;
  outline: none;
  box-sizing: border-box;
  line-height: 16px;

  color: #aeb5ba;
  border: 1px solid #e6eaf1;
  border-right-width: 0;

  transition: background-color 0.2s ease-out;
}

.Trigger-item:first-child {
  border-radius: 25px 0 0 25px;
}

.Trigger-item:last-child {
  border-radius: 0 25px 25px 0;
  border-right-width: 1px;
}

.Trigger-item:hover {
  background-color: #f4f8ff;
}

.Trigger--expanded .Trigger-item {
  flex: 0 0 50%;
  box-sizing: border-box;
}

.Trigger--size-small .Trigger-item {
  padding: 11px 16px;
}

.Trigger-item--active {
  background-color: #e6eaf1;
  color: inherit;
  cursor: default;
}

.Trigger-item--active:hover {
  background-color: #e6eaf1;
}
