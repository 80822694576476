.VideoTrigger {
  position: relative;
  font-size: 120px;
}

@media (max-width: 840px) {
  .VideoTrigger {
    font-size: 100px;
  }
}

@media (max-width: 640px) {
  .VideoTrigger {
    font-size: 80px;
  }
}

@media (max-width: 480px) {
  .VideoTrigger {
    font-size: 64px;
  }
}

.VideoTrigger--expandedClickArea {
}

.VideoTrigger--keepRatio {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.VideoTrigger-image {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.VideoTrigger-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: inherit;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
}

.VideoTrigger--expandedClickArea .VideoTrigger-button {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.VideoTrigger-icon {
  display: inline-block;
  line-height: 1;
  color: #fff;
}
