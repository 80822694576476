.ButtonRemove {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  border-radius: 50%;
  padding: 8px;
  margin: -8px;
  box-sizing: content-box;
  vertical-align: top;

  line-height: 1;
  opacity: 1;
}

.ButtonRemove:not(:disabled):hover {
  opacity: 0.6;
}

.isTabUsing .ButtonRemove:focus {
  opacity: 1;
  outline: none;
  box-shadow: 0 0 0 2px #edf0f5;
}

.ButtonRemove-icon {
}

.ButtonRemove:disabled {
  opacity: 0.8;
  cursor: default;
}
