.Article_Header {
  margin-bottom: 64px;
}

@media (max-width: 1100px) {
  .Article_Header {
    margin-bottom: 48px;
  }
}

@media (max-width: 840px) {
  .Article_Header {
    margin-bottom: 40px;
  }
}

@media (max-width: 640px) {
  .Article_Header {
    margin-bottom: 32px;
  }
}

.Article_Header-metaInfo {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 300;
  color: #262931;
  text-align: center;
}

@media (max-width: 640px) {
  .Article_Header-metaInfo {
    margin-bottom: 8px;
  }
}

.Article_Header-publicationDate {
}

.Article_Header-separator {
  display: inline-block;
  margin: 0 8px;
  font-size: 18px;
  color: #000;
}

.Article_Header-readingTime {
}

.Article_Header-hint {
  color: #848fa5;
}

.Article_Header-title {
  margin: 0;
  line-height: 1.2;
  font-size: 36px;
  text-align: center;
  margin-bottom: 32px;
}

@media (max-width: 1100px) {
  .Article_Header-title {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .Article_Header-title {
    margin-bottom: 24px;
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .Article_Header-title {
    margin-bottom: 8px;
    font-size: 24px;
  }
}

.Article_Header-author {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

@media (max-width: 640px) {
  .Article_Header-author {
    margin-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .Article_Header-author {
    margin-bottom: 16px;
  }
}

.Article_Header-imageHolder {
  max-height: 60vh;
  overflow: hidden;
}

.Article_Header-image {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
