.MainHeader {
  padding: 24px 0;
  border-bottom: 1px solid #e8eaec;
}

.MainHeader-content {
  display: flex;
  align-items: center;
}

.MainHeader-searchCol {
}

.MainHeader-searchCol,
.MainHeader-userMenuCol {
  flex: 0 0 calc(50% - 150px);
}

.MainHeader-logoCol {
  flex: 0 0 300px;
}
