.RateButton {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  font-size: 14px;
  background-color: transparent;
  border: none;
  padding: 0;
  transition: 0.3s;
  cursor: pointer;
  margin: -2px;
  text-decoration: none;
  outline: none;
  font-family: inherit;
}

.RateButton:disabled {
  cursor: default;
}

.RateButton--like {
  color: #25bc8b;
}

.RateButton--dislike {
  color: #e95959;
}

.RateButton--active {
  cursor: default;
}

.RateButton-icon {
  display: flex;
  align-items: center;
  padding: 2px;
  width: 20px;
  height: 20px;
}

.RateButton-text {
  padding: 2px;
  color: #cacacc;
  transition: color 0.2s ease-out;
}

.RateButton:hover .RateButton-text {
  color: inherit;
}

.RateButton:disabled:hover .RateButton-text {
  color: #cacacc;
}
