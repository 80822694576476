/*  MobileFooterNavigation  */
.GlobalNavigationMobile {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.GlobalNavigationMobile-item {
}

.GlobalNavigationMobile-link {
  text-decoration: none;
  padding: 12px;
  display: block;
  color: #252932;
}
