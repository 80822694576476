.CategoryNavigationPanel {
  display: flex;
  margin: -16px;
}

.CategoryNavigationPanel-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 25%;
  max-width: 25%;
  box-sizing: border-box;
  padding: 16px;
}
