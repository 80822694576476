.StatusIndicator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: top;
}

.StatusIndicator-icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.StatusIndicator--empty .StatusIndicator-icon {
  color: #d1d6dc;
}

.StatusIndicator--waiting .StatusIndicator-icon {
  color: #ffd801;
}

.StatusIndicator--success .StatusIndicator-icon {
  color: #00be8b;
}

.StatusIndicator--canceled .StatusIndicator-icon {
  color: #fc5c54;
}
