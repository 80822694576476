.DropdownSelect {
  width: 100%;
  position: relative;
}

.DropdownSelect-current {
  position: relative;
  z-index: 3;
  outline: none;
  overflow: hidden;
}

.DropdownSelect-input {
  align-items: center;
  background-color: transparent;
  position: relative;
  z-index: 3;
  overflow: hidden;
  height: 50px;
  border-radius: 25px;
}

.DropdownSelect--size-small .DropdownSelect-input {
  height: 40px;
  border-radius: 20px;
}

.DropdownSelect-activeOption {
  margin-right: auto;
  font-size: 14px;
}

.DropdownSelect-currentIcon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DropdownSelect-arrowIcon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0);
  transform-origin: 50%;
  transition: transform 0.2s ease-out;
}

.DropdownSelect--isOpened {
  z-index: 5;
}

.DropdownSelect--isOpened .DropdownSelect-arrowIcon {
  transform: rotate(180deg);
}

/* options */

.DropdownSelect-bottom {
  position: relative;
}

.DropdownSelect-optionListHolder {
  top: -25px;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 25px;
  border: 1px solid #dce4eb;
  border-top: 0;
  background-color: #fff;
  z-index: 2;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  max-height: 340px;
}

.DropdownSelect-optionList {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

.DropdownSelect-option {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #dce4eb;
}

.DropdownSelect-option:last-child {
  border-bottom: 0;
}

.DropdownSelect-icon {
  width: 16px;
  height: 16px;
  z-index: 0;
}

.DropdownSelect-optionError {
  display: inline-block;
  margin: 0;
  box-sizing: border-box;
  font-style: italic;
  color: #999;
  font-size: 14px;
  width: 100%;
  padding: 8px 16px;
  line-height: 24px;
  border-bottom: 1px solid #dce4eb;
}

.DropdownSelect-optionError:last-child {
  border-bottom: 0;
}
