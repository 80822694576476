.ProfileAvatar {
  font-size: inherit;
  width: 100%;
  height: 100%;
}

.ProfileAvatar-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
  /** тут не справился с верхним отступом */
  font-size: 0;
}

.ProfileAvatar-imagePlaceholder {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: inherit;
  transition: 0.2s font-size ease-out;
  text-transform: uppercase;
}
