.LB_Action {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.LB_Action-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(12deg) translate(90px, 16px);
  transform-origin: right bottom;
}

@media (max-width: 1100px) {
  .LB_Action-header {
    transform: rotate(12deg) translate(70px, 50px);
  }
}
@media (max-width: 1000px) {
  .LB_Action-header {
    transform: rotate(12deg) translate(60px, 50px);
  }
}
@media (max-width: 960px) {
  .LB_Action-header {
    transform: rotate(12deg) translate(50px, 60px);
  }
}
@media (max-width: 920px) {
  .LB_Action-header {
    transform: rotate(12deg) translate(40px, 60px);
  }
}
@media (max-width: 870px) {
  .LB_Action-header {
    transform: rotate(12deg) translate(30px, 65px);
  }
}
@media (max-width: 840px) {
  .LB_Action-header {
    transform: none;
  }
}

.LB_Action-description {
  margin-bottom: 16px;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  /* font-weight: 300; */
  font-size: 14px;
  max-width: 340px;
  text-align: center;
}
.LB_Action-arrow {
  width: 30px;
  margin-bottom: 16px;
  position: relative;
  transform: rotate(30deg);
  transform-origin: right top;
}

@media (max-width: 840px) {
  .LB_Action-arrow {
    transform: none;
  }
}

.LB_Action-button {
  background-color: #a04ab0;
  border: none;
  padding: 18px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-out;
  font-style: italic;
  display: flex;
}

.LB_Action-button:hover {
  background-color: #a269ac;
}

.LB_Action-buttonIcon {
  width: 24px;
  margin-right: 8px;
}

.LB_Action-buttonText {
}
