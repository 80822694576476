.TopLandingBanner {
  text-align: center;
  height: 90px;
  background: linear-gradient(to right, #dc296a, #b6aadf 50%, #b6aadf 100%);
}

.TopLandingBanner-imageHolder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .TopLandingBanner {
    height: 80px;
  }
}

@media (max-width: 920px) {
  .TopLandingBanner {
    height: 70px;
  }
}

@media (max-width: 860px) {
  .TopLandingBanner {
    /* padding: 8px; */
    height: auto;
  }
}

/* @media (max-width: 680px) {
  .TopLandingBanner {
    height: 80px;
  }
}

@media (max-width: 620px) {
  .TopLandingBanner {
    height: 70px;
  }
}

@media (max-width: 520px) {
  .TopLandingBanner {
    height: 60px;
  }
}

@media (max-width: 460px) {
  .TopLandingBanner {
    height: 50px;
  }
}

@media (max-width: 380px) {
  .TopLandingBanner {
    height: 40px;
  }
} */

.TopLandingBanner-image {
  height: 100%;
  width: 100%;
}
