.CategoryPanel {
  display: flex;
}

.CategoryPanel-buttonBackCol {
  margin-right: 8px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #f8f8fc;
}

.CategoryPanel-listCol {
  flex: 1 1 auto;
  border-radius: 3px;
  overflow: hidden;
  background-color: #f8f8fc;
}

.CategoryPanel-list {
  display: flex;
  margin: 0;
  padding: 0;
  cursor: default;
}

.CategoryPanel-item {
  position: relative;
  list-style: none;
  overflow: hidden;
}

.CategoryPanel-item::after {
  content: '';
  position: absolute;
  top: 12px;
  bottom: 12px;
  right: 0;
  border-right: 1px solid #e5e5ec;
}

.CategoryPanel-item:last-child::after {
  display: none;
}
