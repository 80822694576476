.MarkerLink {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: #cacacc;
  transition: 0.3s;
  cursor: pointer;
  margin: -2px;
  text-decoration: none;
}
.MarkerLink--like {
  align-items: flex-end;
  fill: #e95959;
}

.MarkerLink:hover {
  /*color: #25bc8b;*/
  color: inherit;
}

.MarkerLink-ico {
  display: flex;
  align-items: center;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.MarkerLink-text {
  padding: 2px;
}

.MarkerLink--under::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ffd12c;
}
