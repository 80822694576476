.Profile_GeneralScene {
}

.Profile_GeneralScene-panel {
  max-width: 600px;
}

.Profile_GeneralScene-mobile {
}
.Profile_GeneralScene-mobileGeneral {
}
.Profile_GeneralScene-mobileForm {
}
