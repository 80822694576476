.RedirectPayment {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.RedirectPayment-loader {
  margin-bottom: 240px;
}

@media (max-width: 480px) {
  .RedirectPayment-loader {
    margin-bottom: 120px;
  }
}

.RedirectPayment-title {
  margin: 0;
  padding: 0;
  font-size: 24px;
  text-align: center;
}

@media (max-width: 480px) {
  .RedirectPayment-title {
    font-size: 18px;
  }
}
