/*  CatalogNavigationMobile  */

.CatalogNavigationMobile {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.CatalogNavigationMobile-item {
  border-top: 1px solid #ebf0f6;
  display: flex;
  flex-wrap: wrap;
}
.CatalogNavigationMobile-controls {
  flex: 1 0 100%;
  max-width: 100%;
  display: flex;
}
.CatalogNavigationMobile-item--active .CatalogNavigationMobile-submenu {
  display: block;
}
.CatalogNavigationMobile-item--active .CatalogNavigationMobile-iconSizer {
  transform: rotate(90deg);
}
.CatalogNavigationMobile-item--active .CatalogNavigationMobile-submenuTrigger::before {
  opacity: 0;
}

.CatalogNavigationMobile-subitem {
  padding-left: 12px;
}

.CatalogNavigationMobile-subitem .CatalogNavigationMobile-link {
  font-size: 14px;
}

.CatalogNavigationMobile-subitem--active .CatalogNavigationMobile-submenu {
  display: block;
}

.CatalogNavigationMobile-subitem .CatalogNavigationMobile-iconSizer {
  transform: rotate(0deg);
  width: 10px;
  height: 10px;
}

.CatalogNavigationMobile-subitem--active .CatalogNavigationMobile-iconSizer {
  transform: rotate(90deg);
}
.CatalogNavigationMobile-sybitem--active .CatalogNavigationMobile-submenuTrigger::before {
  opacity: 0;
}

.CatalogNavigationMobile-link {
  border: none;
  font-family: inherit;
  outline: none;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  color: #252932;
  padding: 12px;
  flex-grow: 1;
  background-color: transparent;
  transition: background-color 0.2s ease-out;
}

.CatalogNavigationMobile-link:hover {
  background-color: #f4f9ff;
}

.CatalogNavigationMobile-item--highlight .CatalogNavigationMobile-link {
  color: #e95958;
}

.CatalogNavigationMobile-submenuTrigger {
  padding: 0 15px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: background-color 0.2s ease-out;
  font-family: inherit;
}
.CatalogNavigationMobile-submenuTrigger:hover {
  background-color: #f4f9ff;
}
.isTabUsing .CatalogNavigationMobile-submenuTrigger:focus {
  background-color: #ebf0f6;
}
.CatalogNavigationMobile-submenuTrigger::before {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateY(-50%);
  width: 1px;
  height: 75%;
  left: 0;
  top: 50%;
  background-color: #ebf0f6;
}

.CatalogNavigationMobile-iconSizer {
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #252932;
  transition: transform 0.2s ease-out;
}

.CatalogNavigationMobile-submenu {
  /*display: none;*/
  padding: 0;
  margin: 0;
  list-style-type: none;
  flex-basis: 100%;
  max-width: 100%;
}
.CatalogNavigationMobile-submenuItem {
}
.CatalogNavigationMobile-submenuLink {
  text-decoration: none;
  padding: 10px 25px;
  font-size: 14px;
  color: #252932;
  display: block;
  transition: background-color 0.2s ease-out;
}
.CatalogNavigationMobile-submenuLink:hover {
  background-color: #f4f9ff;
}
