.App {
  font-family: 'Proxima Nova', sans-serif;
  color: #262931;
  margin: 0;
  line-height: 1.6;
  font-size: 16px;
  position: relative;
}

.App-wrapper {
  position: relative;
  transition: transform 0.2s ease-out;
  min-height: 100%;
}

.App-wrapper::before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #111;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  z-index: 420;
}

.App--navigationOpened {
  overflow: hidden;
  height: 100vh;
}

.App--navigationOpened .App-wrapper::before {
  opacity: 0.5;
  visibility: visible;
  pointer-events: auto;
}

.App--navigationOpened .App-wrapper {
  transform: translateX(280px);
}

.App-navigation {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  width: 280px;
  z-index: 102;
  transform: translateX(-280px);
  box-shadow: 1px 0px 5px 0px transparent;
  /*display: none;*/
  transition: transform 0.2s ease-out, box-shadow 0.2s linear;
}

.App--navigationOpened .App-navigation {
  transform: translateX(0);
}

.App-header {
  /* overflow: hidden; */
}

@media (max-width: 840px) {
  .App:not(.App--url-faq) .App-header {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 400;
    transition: transform ease-out 0.2s, box-shadow ease-out 0.2s;
  }

  .App--noMobileSticky .App-header {
    position: relative;
    /* transform: none; */
  }

  .App--hideHeader .App-header {
    transform: translateY(-100%);
  }

  .App--noMobileSticky.App--noMobileSticky .App-header {
    /* position: relative; */
    transform: none;
  }

  .App--raisedHeader:not(.App--noMobileSticky) .App-header {
    box-shadow: 0px 0px 24px 0 rgba(47, 47, 47, 0.12);
  }

  .App--navigationOpened .App-header {
    position: static;
    z-index: auto;
  }
}
