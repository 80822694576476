.CatalogNavigation {
  /*border-top: 1px solid #e8eaec;*/
  box-sizing: border-box;
  border-bottom: 1px solid #e8eaec;
}

.CatalogNavigation--url-error-404,
.CatalogNavigation--url-category,
.CatalogNavigation--hasBorderBottom {
  border-bottom: 1px solid #e8eaec;
}

@media (max-width: 1100px) {
  .CatalogNavigation {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (max-width: 950px) {
  .CatalogNavigation {
    /*display: none;*/
  }
}

.CatalogNavigation-list {
  margin: 0;
  padding: 16px 0;
  display: flex;
  list-style: none;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .CatalogNavigation-list {
    padding: 16px 8px;
  }
}

.CatalogNavigation-listItem {
  flex: 0 0 auto;
  padding: 0;
}

@media (max-width: 840px) {
  .CatalogNavigation-listItem {
    padding: 0 8px;
  }
}

.CatalogNavigation-link {
  display: inline-block;
  text-decoration: none;
  color: #262931;
  box-sizing: border-box;
  text-align: center;
  padding: 4px;
  width: 100%;
  transition: all 0.2s linear;
  font-size: 16px;
  line-height: 24px;
  position: relative;
}

.isTabUsing .CatalogNavigation-link:focus {
  outline: none;
}

.CatalogNavigation-link::after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 0px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #ffd12c;
  transition: width 0s ease-out;
}

@media (max-width: 840px) {
  .CatalogNavigation-link::after {
    transition: none;
  }
}

.isTabUsing .CatalogNavigation-link:focus::after {
  width: 3px;
}

.CatalogNavigation-link--active {
}

.CatalogNavigation-link--active:hover {
  opacity: 1;
}

.CatalogNavigation-link--active::after,
.isTabUsing .CatalogNavigation-link--active:focus::after {
  width: 24px;
  transition-duration: 0.2s;
}

@media (min-width: 840px) {
  .CatalogNavigation-link:hover::after {
    width: 24px;
    transition-duration: 0.2s;
  }
}

/* .CatalogNavigation-link--highlighted {
  color: #e95958;
} */

.CatalogNavigation-panelHolder {
  position: relative;
}

.CatalogNavigation-panelBlock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 297;
  background-color: #fff;
  box-sizing: border-box;
}

.CatalogNavigation-panelBlockShadow {
  position: absolute;
  height: 24px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: -24px;
  overflow: hidden;
  z-index: 298;
}

.CatalogNavigation-panelBlockShadow::before {
  content: '';
  position: absolute;
  height: 24px;
  bottom: 24px;
  left: 0;
  right: 0;
  display: block;
  box-shadow: 0px 0px 24px 0 rgba(47, 47, 47, 0.12);
  z-index: 298;
}

.CatalogNavigation-panelBlockInner {
  position: relative;
  z-index: 300;
  overflow: hidden;
  max-height: 100%;
  box-sizing: border-box;
}

.CatalogNavigation-panelBlockInner::after {
  content: '';
  border-top: 1px solid #e8eaec;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.CatalogNavigation-panel {
  position: relative;
  padding: 24px 0;
}

.CatalogNavigation-listItemValue--widthIcon {
  display: flex;
  align-items: center;
}
