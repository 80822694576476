.SearchOverlay {
  /* background-color: #fff; */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  background-color: rgba(37, 40, 53, 0.7);
}

.SearchOverlay-content {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  background-color: #fff;
  box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.5);
}

.SearchOverlay-field {
  flex: 0 0 auto;
  position: relative;
  border-radius: inherit;
  box-sizing: border-box;
  z-index: 1;
  border-bottom: 1px solid #dfe3ea;
}

.SearchOverlay-input {
  appearance: none;
  height: 50px;
  padding: 8px 48px;
  line-height: 34px;
  font-size: 16px;
  font-family: inherit;
  color: inherit;
  width: 100%;
  box-sizing: border-box;
  border: 0;
}

.SearchOverlay-input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.SearchOverlay-input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.SearchOverlay-input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.SearchOverlay-input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.SearchOverlay-input[type='search']::-webkit-search-decoration,
.SearchOverlay-input[type='search']::-webkit-search-cancel-button,
.SearchOverlay-input[type='search']::-webkit-search-results-button,
.SearchOverlay-input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.SearchOverlay-input:focus {
  outline: none;
}

.SearchOverlay-prepended {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SearchOverlay-appended {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SearchOverlay-button {
  appearance: none;
  width: 36px;
  height: 36px;
  font-size: 24px;
  line-height: 1;
  border-radius: 18px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-out;
  color: #8e939d;
}

.SearchOverlay-body {
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  overflow-y: auto;
}

.SearchOverlay-loader {
  min-height: 80px;
  position: relative;
}

.SearchOverlay-result {
  margin-bottom: 32px;
}

.SearchOverlay-resultList {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 32px;
}

.SearchOverlay-resultItem {
  border-top: 1px solid #dfe3ea;
}

.SearchOverlay-resultItem:first-child {
  border-top: none;
}

.SearchOverlay-recent {
}

.SearchOverlay-recentTitle {
  font-weight: bold;
  padding: 8px 16px;
}

.SearchOverlay-recentList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.SearchOverlay-recentItem {
  border-bottom: 1px solid #dfe3ea;
}

.SearchOverlay-recentItem:last-child {
  border-bottom: none;
}
