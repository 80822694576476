.InstagramCarousel {
  position: relative;
}

@media (max-width: 1100px) {
  .InstagramCarousel {
    overflow-y: hidden;
    overflow-x: auto;
  }
}

.InstagramCarousel-content {
  z-index: 2;
  position: relative;
  margin: -16px;
  overflow: hidden;
}

@media (max-width: 1100px) {
  .InstagramCarousel-content {
    display: inline-flex;
    vertical-align: top;
    overflow: visible;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .InstagramCarousel-content {
    margin: 0;
  }
}

.InstagramCarousel-item {
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .InstagramCarousel-item {
    flex: 0 0 28%;
    max-width: 28%;
    box-sizing: border-box;
    padding: 8px;
  }
}

@media (max-width: 720px) {
  .InstagramCarousel-item {
    flex: 0 0 80%;
    max-width: 80%;
    box-sizing: border-box;
    padding: 4px;
  }
}

.InstagramCarousel-controls {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: -45px;
  left: -45px;
  margin-top: -15px;
  display: flex;
  justify-content: space-between;
}

.InstagramCarousel-prev,
.InstagramCarousel-next {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #000;
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
  height: 100%;
  width: 120px;
  box-sizing: content-box;
  outline: none;
}

.InstagramCarousel-prev {
  text-align: right;
  margin-left: -120px;
}

.InstagramCarousel-next {
  text-align: left;
  margin-right: -120px;
}

.InstagramCarousel-prev:hover,
.InstagramCarousel-next:hover {
  opacity: 1;
}

.InstagramCarousel--inLanding .ProductCarousel-underControls {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  z-index: 2;
}

.InstagramCarousel--inLanding .ProductCarousel-underControl {
  background-color: #8e3387;
  opacity: 0.6;
  padding: 16px;
  border-radius: 50%;
}

.InstagramCarousel--inLanding .ProductCarousel-underControl:hover {
  opacity: 1;
  cursor: pointer;
}

.InstagramCarousel--inLanding .ProductCarousel-underControl svg {
  fill: white;
}
