.GiftProductDialog {
  width: 1170px;
  max-width: 100%;
  padding: 32px;
  padding-top: 16px;
  box-sizing: border-box;
}

.GiftProductDialog--static {
  width: auto;
}

.GiftProductDialog-static {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px;
}

.GiftProductDialog-staticItem {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 32px 16px;
  box-sizing: border-box;
}

.GiftProductDialog--single .GiftProductDialog-staticItem {
  flex: 0 0 100%;
  max-width: 100%;
}

.GiftProductDialog-loader {
  position: relative;
  min-height: 180px;
}

@media (max-width: 840px) {
  .GiftProductDialog {
    padding: 24px;
    padding-top: 0;
  }
}

@media (max-width: 640px) {
  .GiftProductDialog {
    padding: 16px;
    padding-top: 0;
  }
}
