.SortingBottomSheet {
  height: 100%;
  padding-bottom: 50px;
}

.SortingBottomSheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SortingBottomSheet-headerTitle {
  font-size: 22px;
  font-weight: 600;
}

.SortingBottomSheet-headerClose {
}

.SortingBottomSheet-sortingsListHolder {
}

.SortingBottomSheet-content {
}

.SortingBottomSheet-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: -16px;
}

.SortingBottomSheet-item {
  padding: 16px;
  position: relative;
  /* border-top: 1px solid #e6e7e9; */
  border-bottom: 1px solid #e6e7e9;
}

.SortingBottomSheet-label {
}

.SortingBottomSheet-label::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
