.ArticleActionBar {
  padding: 18px;
  border: 1px solid #edf0f5;
  border-radius: 3px;
}

.ArticleActionBar-title {
  margin: 0;
  margin-bottom: 8px;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 700;
  color: #262931;
  text-align: center;
}

.ArticleActionBar-actionList {
  margin: 0;
  padding: 0;
}

.ArticleActionBar-actionItem {
  margin-bottom: 24px;
  list-style: none;
  text-align: center;
}

.ArticleActionBar-actionItem:last-child {
  margin-bottom: 0;
}
