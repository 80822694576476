.EditablePhoneNew-text {
  line-height: 1.2;
  border-bottom: 1px dotted currentColor;
}

.EditablePhoneNew-placeholder {
  color: #999;
  line-height: 1.2;
  border-bottom: 1px dotted currentColor;
}
