.SubcategorySliderCard {
  padding-top: 36%;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #f8f8fc;
}

.SubcategorySliderCard--withBanner {
  background-color: #262931;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.SubcategorySliderCard--withBanner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 41, 49, 0.6);
  z-index: 1;
}

.SubcategorySliderCard-titleHolder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  z-index: 2;
}

.SubcategorySliderCard-title {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  line-height: 1.2;
}

@media (min-width: 840px) {
  .SubcategorySliderCard-title {
    font-size: 18px;
  }
}

.SubcategorySliderCard--withBanner .SubcategorySliderCard-title {
  color: #fff;
}
