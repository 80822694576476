.TimetableItem {
  padding: 28px 0;
}

.TimetableItem-holder {
  display: flex;
}

@media (max-width: 840px) {
  .TimetableItem-holder {
    display: block;
  }
}

.TimetableItem-date {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 170px;
  max-width: 170px;
  box-sizing: border-box;
  margin-right: 10%;
  display: flex;
  align-items: center;
}

.TimetableItem-dateHolder {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  min-width: 135px;
}

@media (max-width: 840px) {
  .TimetableItem-dateHolder {
    flex-direction: row;
    margin-bottom: 16px;
  }
}

.TimetableItem-day {
  font-size: 27em;
  margin-bottom: 8px;
  line-height: 1;
}

@media (max-width: 840px) {
  .TimetableItem-day {
    font-size: 14em;
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.TimetableItem-month {
  font-size: 12em;
  line-height: 1;
}

@media (max-width: 840px) {
  .TimetableItem-month {
    font-size: 11em;
  }
}

.TimetableItem-location {
  color: #262931;
  flex-grow: 5;
  margin-right: 24px;
}

.TimetableItem--light .TimetableItem-location {
  color: #fff;
}

@media (max-width: 840px) {
  .TimetableItem-location {
    margin-right: 0;
    margin-bottom: 38px;
  }
}

.TimetableItem-city {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 13em;
}

.TimetableItem-place {
  font-size: 9em;
  text-transform: uppercase;
  font-family: 'Akzidenz-Grotesk Pro Med Ext';
}

.TimetableItem-address {
  font-size: 8em;
  font-family: 'Akzidenz-Grotesk Pro Med Ext';
}

.TimetableItem-action {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 25%;
  /* flex-basis: 25%; */
  box-sizing: border-box;
}
