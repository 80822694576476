.RollUp {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #595065;
  padding: 30px 0;
  z-index: 998;
}

@media (max-width: 640px) {
  .RollUp {
    padding: 16px 0;
  }
}

.RollUp-content {
}

.RollUp-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 32px;
  color: #fff;
  opacity: 0.7;
  transition: opacity 0.2s ease-out;
}

@media (max-width: 1340px) {
  .RollUp-close {
    transform: none;
    top: 6px;
    right: 6px;
  }
}

.RollUp-close:hover {
  opacity: 1;
}
