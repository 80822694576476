.Product_Grid {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -16px;
}

@media (max-width: 840px) {
  .Product_Grid {
    margin: -8px;
  }
}

.Product_Grid-item {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .Product_Grid-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 840px) {
  .Product_Grid-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 8px;
  }
}
