.SectionReviewTop {
  padding: 40px 0;
  background-color: #f6f8fb;
}

.SectionReviewTop-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: #444952;
  margin: 0;
  margin-bottom: 48px;
  text-align: center;
}
