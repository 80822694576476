.FAQNavigation {
  border-bottom: 1px solid #dfe3ea;
}

.FAQNavigation-isSticky {
  box-shadow: 0px 0px 24px 0 rgba(47, 47, 47, 0.12);
  border: none;
}

.FAQNavigation-container {
  -webkit-overflow-scrolling: touch;
}

.FAQNavigation-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 840px) {
  .FAQNavigation-list {
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 -16px;
  }
  .FAQNavigation-list::-webkit-scrollbar {
    display: none;
  }
  .FAQNavigation-list {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.FAQNavigation-item {
  flex: 1 1 auto;
}

.FAQNavigation-anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.FAQNavigation-anchorText {
  position: relative;
  padding: 18px 0;
  font-weight: 600;
  font-size: 16px;
  transition: 0.2s ease-out;
}

.FAQNavigation-anchor:hover .FAQNavigation-anchorText {
  /* text-shadow: 0.5px 0 0 currentColor; */
}

.FAQNavigation-anchor--isActive:hover .FAQNavigation-anchorText {
  text-shadow: none;
}

@media (max-width: 840px) {
  .FAQNavigation-anchorText {
    padding: 16px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.FAQNavigation-anchorText::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #ffd801;
  bottom: -2px;
  opacity: 0;
  transition: 0.2s ease-out;
  transform: translateY(-16px);
  /* border-radius: 4px; */
}

@media (max-width: 840px) {
  .FAQNavigation-anchorText::after {
    bottom: 0;
  }
}

.FAQNavigation-anchor:hover .FAQNavigation-anchorText::after {
  opacity: 1;
  transform: translateY(0);
  /* text-shadow: 1px 0 0 currentColor; */
}

@media (max-width: 840px) {
  .FAQNavigation-anchor:hover .FAQNavigation-anchorText::after {
    text-shadow: none;
  }
}

.FAQNavigation-anchor--isActive .FAQNavigation-anchorText::after {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 840px) {
  .FAQNavigation-anchor--isActive .FAQNavigation-anchorText::after {
    text-shadow: none;
  }
}
