.CardPrice {
  display: inline-flex;
  align-items: center;
  margin: -4px;
}

.CardPrice--notAvailable {
  color: #dbe1ec;
}

.CardPrice-current {
  font-size: 32px;
  font-weight: 700;
  padding: 4px;
}

@media (max-width: 840px) {
  .CardPrice-current {
    font-size: 18px;
  }
}

.CardPrice-previous {
  font-size: 24px;
  padding: 4px;
}

@media (max-width: 840px) {
  .CardPrice-previous {
    font-size: 18px;
  }
}
