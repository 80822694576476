.ConfirmPasswordRecoveryErrorDialog-content {
  padding: 48px;
  box-sizing: border-box;
}

.ConfirmPasswordRecoveryErrorDialog-info {
  margin-bottom: 16px;
}

.ConfirmPasswordRecoveryErrorDialog-recover {
  text-align: center;
}
