.InstagramGalleryList {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
}

@media (max-width: 480px) {
  .InstagramGalleryList {
    margin: -8px;
  }
}

.InstagramGalleryList-item {
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 16px;
  position: relative;
}

@media (max-width: 480px) {
  .InstagramGalleryList-item {
    padding: 8px;
  }
}
