.Toast {
  position: relative;
  padding: 12px 16px;
  border-left: 3px solid transparent;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 hsla(0, 0%, 0%, 0.1);
  border-radius: 3px;
}

@media (max-width: 640px) {
  .Toast {
    font-size: 15px;
  }
}

.Toast--state-success {
  border-left: 3px solid #25bc8b;
}

.Toast--state-fail {
  border-left: 3px solid #fd686c;
}

.Toast--state-loading {
  border-left: 3px solid #f7d10e;
}

.Toast--state-notification {
  border-left: 3px solid #dbe1ec;
}

.Toast-header {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  padding-right: 24px;
}

.Toast-icon {
  flex: 0 0 auto;
  margin-right: 8px;
}

.Toast-titleHolder {
  line-height: 24px;
}

.Toast-title {
  flex: 1 1 auto;
  margin: 0;
  line-height: 24px;
}

.Toast-titleText {
  display: inline-block;
  line-height: 1.2;
  font-size: 18px;
}

.Toast-buttonCloseHolder {
  position: absolute;
  top: 4px;
  right: 4px;
}

@media (max-width: 840px) {
  .Toast-buttonCloseHolder {
    top: 2px;
    right: 2px;
  }
}

.Toast-buttonClose {
  display: inline-block;
  padding: 4px;
  margin: 0;
  border-radius: 12px;
  border: 1px solid transparent;
  line-height: 1;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease-out, border 0.2s ease-out;
}

.Toast-buttonClose:hover {
  background-color: #edf0f5;
}

@media (max-width: 840px) {
  .Toast-buttonClose:hover {
    background-color: transparent;
  }
}

.isTabUsing .Toast-buttonClose:focus {
  border: 1px solid #edf0f5;
}

.Toast-buttonClose:active {
  background-color: #dbe1ec;
}

@media (max-width: 840px) {
  .Toast-buttonClose:active {
    background-color: transparent;
  }
}

.Toast-content {
  line-height: 1.4;
}

.Toast--withIcon .Toast-content {
  padding-left: 32px;
}

.Toast-actionList {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  margin-top: 8px;
  padding: 0;
}

@media (max-width: 640px) {
  .Toast-actionList {
    justify-content: flex-end;
  }
}

.Tosat-actionItem {
  margin-right: 8px;
  list-style: none;
}

.Tosat-actionItem:last-child {
  margin-right: 0;
}

.Toast-action {
  display: inline-block;
  min-width: 80px;
  padding: 4px 8px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 300;
  border: 1px solid #edf0f5;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease-out, border 0.2s ease-out;
}

@media (max-width: 640px) {
  .Toast-action {
    padding: 6px 8px;
    font-size: 15px;
  }
}

.Toast-action:hover {
  background-color: #edf0f5;
}

.isTabUsing .Toast-action:focus {
  border: 1px solid #dbe1ec;
}

.Toast-action:active {
  background-color: #dbe1ec;
}

.Toast-action--variant-primary {
  background-color: #edf0f5;
}

.Toast-action--variant-primary:hover {
}

.isTabUsing .Toast-action--variant-primary:focus {
}

.Toast-action--variant-primary:active {
}
