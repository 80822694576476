.ProductPreview {
  display: flex;
}

.ProductPreview-buttonRemove {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  margin: -8px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  border-radius: 50%;
  outline: none;
  font-size: 16px;
  transition: color 0.2s ease-out, box-shadow 0.2s ease-out;
  box-shadow: 0 0 0 2px transparent, 0 0 0 4px transparent;
  outline: none;
}

.ProductPreview-buttonRemove:not(:disabled):hover {
  color: #a1a6af;
}

.isTabUsing .ProductPreview-buttonRemove:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(47, 124, 192, 0.54);
}

.ProductPreview-buttonRemove:disabled {
  opacity: 0.8;
  cursor: default;
}

.ProductPreview-imageHolder {
  flex: 0 0 auto;
  width: 72px;
  margin-right: 8px;
}

@media (max-width: 420px) {
  .ProductPreview-imageHolder {
    width: 56px;
  }
}

.ProductPreview-imageWrapper {
  display: inline-block;
}

.ProductPreview-contentHolder {
  flex: 1 1 auto;
  position: relative;

  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.ProductPreview-info {
  margin-bottom: 8px;
}

.ProductPreview-features {
  margin-bottom: 4px;
}

.ProductPreview-description {
  margin-right: 30px;
  margin-bottom: 4px;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  line-height: 1.4;
}

.ProductPreview-title {
  margin: 0;
  margin-bottom: 4px;
  font-size: 14px;
  color: #848fa5;
  font-weight: 400;
}

.ProductPreview-selectedVariant {
  font-size: 14px;
  line-height: 1.4;
  color: #848fa5;
  margin-bottom: 4px;
}

@media (max-width: 840px) {
  .ProductPreview-selectedVariant {
    margin-bottom: 4px;
  }
}

.ProductPreview-labelList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 4px;
}

.ProductPreview-link {
  color: inherit;
  text-decoration: none;
}

.ProductPreview-purchaseInformation {
  display: flex;
  align-items: center;
}

.ProductPreview-quantity {
  font-size: 14px;
}

.ProductPreview-amountHolder {
  /**TODO: temporary solution */
  margin: -7px;
}

.ProductPreview-price {
  margin-left: auto;
  font-size: 14px;
}

.ProductPreview-purchaseInformationTotal {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 8px;
}

.ProductPreview-giftLabel {
  font-size: 13px;
  font-weight: 700;
  color: #25bc8b;
  margin-left: auto;
}
