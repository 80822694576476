.CategoryFilterPanel_SelectItem {
  min-width: 320px;
  box-sizing: border-box;
  background-color: #fff;
}

.CategoryFilterPanel_SelectItem-list {
  margin: 0;
  padding: 8px 0;
}

.CategoryFilterPanel_SelectItem-listItem {
  list-style: none;
}

.CategoryFilterPanel_SelectItem-button {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 8px 16px;
  border: none;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
  background-color: transparent;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  outline: none;
  appearance: none;
  text-decoration: none;
  display: block;
}

.isTabUsing .CategoryFilterPanel_SelectItem-button:focus {
  background-color: #f8f8fc;
}

.CategoryFilterPanel_SelectItem-button:hover {
  background-color: #f8f8fc;
}

.CategoryFilterPanel_SelectItem-button:active {
}

.CategoryFilterPanel_SelectItem-button--active {
  font-weight: 700;
}

.CategoryFilterPanel_SelectItem-buttonTitle {
}

.CategoryFilterPanel_SelectItem-buttonAmount {
  display: inline-block;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 300;
  color: #818690;
  white-space: nowrap;
}
