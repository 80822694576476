.ListDescription {
  font-size: 14px;
  text-align: left;
}

.ListDescription-item {
  margin-bottom: 16px;
}

.ListDescription-item:last-child {
  margin-bottom: 0;
}

.ListDescription-name {
  font-weight: bold;
  color: #262931;
  margin-right: 5px;
}

.ListDescription-name:after {
  content: ' :';
  display: inline-block;
}

.ListDescription-value {
  color: #818690;
}

.ListDescription--review .ListDescription-value {
  color: #262931;
}
