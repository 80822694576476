.FieldBottomLabeled {
  position: relative;
}

.FieldBottomLabeled-label {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 13px;
  color: #848fa5;
  margin-left: 24px;
}

.FieldBottomLabeled--size-small .FieldBottomLabeled-label {
  margin-left: 16px;
}

.FieldBottomLabeled-input {
}
