.MobileProfileForm-iconHolder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileProfileForm-editPhoneTip {
  margin-top: 24px;
  line-height: 1.4;
}

.MobileProfileForm-editPhoneSeparator {
  display: block;
  margin: 6px 0;
}

.MobileProfileForm-editPhoneButton {
  display: inline;
  padding: 0;
  margin: 0;
  border: none;
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  outline: none;
  appearance: none;
  text-align: left;
  text-decoration: underline;
}
