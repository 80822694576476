.ProblemSkin_AboutCompany {
  padding-bottom: 100px;
}

@media (max-width: 1100px) {
  .ProblemSkin_AboutCompany {
    padding-bottom: 24px;
  }
}

.ProblemSkin_AboutCompany-title {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  padding: 100px 0 64px;
}

@media (max-width: 1100px) {
  .ProblemSkin_AboutCompany-title {
    font-size: 24px;
    padding: 64px 0 32px;
  }
}

.ProblemSkin_AboutCompany-main {
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_AboutCompany-main {
    flex-direction: column;
  }
}

.ProblemSkin_AboutCompany-imageHolder {
  border-radius: 22px;
  overflow: hidden;
  margin-right: 100px;
  flex: 1 1 30%;
}

@media (max-width: 1100px) {
  .ProblemSkin_AboutCompany-imageHolder {
    order: 1;
    margin-right: 0;
    margin-top: 32px;
    max-width: 300px;
  }
}

.ProblemSkin_AboutCompany-image {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.ProblemSkin_AboutCompany-descriptionHolder {
  flex: 1 1 70%;
  display: flex;
  align-items: center;
}

.ProblemSkin_AboutCompany-description {
}
