.OpeningActions {
}

.OpeningActions-title {
  font-size: 30px;
}

.OpeningActions-list {
}
.OpeningActions-item {
  padding-top: 3%;
  padding-bottom: 3%;
}
