.LabelGroup {
  margin: -15px;
  display: flex;
}
@media screen and (max-width: 420px) {
  .LabelGroup {
    flex-direction: column;
  }
}
.LabelGroup-item {
  padding: 15px;
  display: block;
  font-size: 14px;
}
.LabelGroup-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
