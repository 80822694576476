.SearchOverlay_Product {
  position: relative;
  padding: 8px 16px;
  display: flex;
  transition: background-color 0.2s ease-out;
}

.SearchOverlay_Product:hover {
  background-color: #f7fbff;
}

.SearchOverlay_Product-text {
  flex: 1 1 auto;
}

.SearchOverlay_Product-price {
  flex: 0 0 auto;
  margin-left: auto;
  padding-left: 16px;
  font-weight: 700;
}

.SearchOverlay_Product-description {
  color: inherit;
  text-decoration: none;
}

.SearchOverlay_Product-description::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.SearchOverlay_Product-title {
  color: #848fa5;
  opacity: 0.8;
}
