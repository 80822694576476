.Notification {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
}

.Notification--largeGap {
  padding: 32px;
}

.Notification--marginBottom {
  margin-bottom: 8px;
}

@media (max-width: 840px) {
  .Notification {
    font-size: 14px;
  }
}

.Notification--primary {
  background-color: #fcf1c9;
  line-height: 16px;
  font-size: 16px;
  /* text-transform: uppercase; */
}

@media (max-width: 600px) {
  .Notification--primary {
  }
}

@media (max-width: 480px) {
  .Notification--primary {
    font-size: 12px;
    padding: 12px 8px;
  }
}

@media (max-width: 400px) {
  .Notification--primary {
    padding: 8px 8px;
  }
}

.Notification--error {
  background-color: rgba(255, 110, 111, 0.46);
}
.Notification--info {
  background-color: #f7fbff;
}
.Notification--success {
  background-color: rgba(157, 133, 190, 0.51);
}
.Notification-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

@media (max-width: 1240px) {
  .Notification-icon {
    display: none;
  }
}

.Notification-text {
  display: inline-block;
  vertical-align: middle;
}

.Notification-wrapper {
  text-align: center;
}
