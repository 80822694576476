.CallbackDialog {
  padding: 0 32px 60px;
  max-width: 600px;
}
.CallbackDialog-title {
  margin-top: 32px;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: bold;
}

@media (max-width: 840px) {
  .CallbackDialog-title {
    font-size: 24px;
    line-height: 24px;
  }
}

.CallbackDialog-info {
}

.CallbackDialog-warningInfo {
  margin-bottom: 32px;
}

.CallbackDialog-form {
  margin: -8px;
}

.CallbackDialog-formItem {
  padding: 8px;
}
