.SpecialOfferAll {
}

.SpecialOfferAll-section {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e8eaec;
}

.SpecialOfferAll-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.SpecialOfferAll-content {
}

.SpecialOfferAll-rule {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 42px;
}

.SpecialOfferAll-button {
  text-align: center;
}

.SpecialOfferAll-gift {
  position: relative;
}

.SpecialOfferAll-giftImage {
  width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: top;
}

.SpecialOfferAll-giftLinkList {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding: 107px 24px 73px 24px;
}

@media (max-width: 730px) {
  .SpecialOfferAll-giftLinkList {
    padding: 14.6vw 3.4vw 9.9vw 3.4vw;
  }
}

.SpecialOfferAll-giftLink {
  flex: 1 1 33.333%;
  display: block;
  color: transparent;
}
