.DeprecatedSpecialOffer {
}

.DeprecatedSpecialOffer-content {
  margin-bottom: 56px;
}

.DeprecatedSpecialOffer-rule {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.DeprecatedSpecialOffer-button {
  text-align: center;
}
