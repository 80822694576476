.BonusScene {
}

.BonusScene-loader {
  position: relative;
  padding: 160px 0;
}

.BonusScene-metaInformation {
  margin-bottom: 12px;
}

.BonusScene-accountNumber {
  display: inline-block;
  margin-right: 16px;
}

.BonusScene-accountStatus {
  display: inline-block;
}

.BonusScene-suggestion {
  margin-top: 24px;
}

.BonusScene-suggestionText {
  margin-bottom: 16px;
  font-size: 18px;
}

@media (max-width: 640px) {
  .BonusScene-suggestionText {
    font-size: 16px;
  }
}

.BonusScene-suggestionButton {
  text-align: center;
}

.BonusScene-cardGridHolder {
  overflow: hidden;
  margin-bottom: 16px;
}

.BonusScene-cardGrid {
  display: flex;
  margin: -8px;
}

@media (max-width: 1100px) {
  .BonusScene-cardGrid {
    flex-wrap: wrap;
  }
}

@media (max-width: 840px) {
  .BonusScene-cardGrid {
    flex-wrap: nowrap;
  }
}

@media (max-width: 720px) {
  .BonusScene-cardGrid {
    display: block;
  }
}

.BonusScene-card {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 8px;
}

@media (max-width: 1100px) {
  .BonusScene-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 840px) {
  .BonusScene-card {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media (max-width: 720px) {
  .BonusScene-card {
    max-width: 100%;
  }
}
