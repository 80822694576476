.LocalBreadcrumbs {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.LocalBreadcrumbs-separator {
  padding: 0 5px;
}

.LocalBreadcrumbs-link {
  text-decoration: none;
  color: #818690;
  position: relative;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.LocalBreadcrumbs-item {
  display: inline;
}

.LocalBreadcrumbs-link::after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: currentColor;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.LocalBreadcrumbs-link:hover::after {
  opacity: 0.5;
}

.LocalBreadcrumbs-link--isActive {
  color: #262931;
}

.LocalBreadcrumbs-link--isActive:hover {
  cursor: default;
}

.LocalBreadcrumbs-link--isActive:hover::after {
  opacity: 0;
}
