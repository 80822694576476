.PaymentMethodsMobile {
  padding: 16px 0;
  border-bottom: 1px solid #e8e9eb;
}

@media (max-width: 840px) {
  .PaymentMethodsMobile {
    border-top: 1px solid #e8e9eb;
  }
}

.PaymentMethodsMobile-title {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 300;
  color: #808792;
}

.PaymentMethodsMobile-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PaymentMethodsMobile-item {
  height: 35px;
}

@media (max-width: 420px) {
  .PaymentMethodsMobile-item {
    height: 24px;
  }
}
