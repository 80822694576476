.BonusHistoryTableMobile_Transaction {
}

.BonusHistoryTableMobile_Transaction--active {
  animation-name: blink;
  animation-duration: 2s;
}

@keyframes blink {
  0% {
    background-color: transparent;
  }
  10% {
    background-color: rgba(255, 218, 78, 0.2);
  }
  100% {
    background-color: transparent;
  }
}

.BonusHistoryTableMobile_Transaction-info {
  padding: 16px 0;
}

.BonusHistoryTableMobile_Transaction-titleHolder {
  display: flex;
  justify-content: space-between;
}

.BonusHistoryTableMobile_Transaction-title {
  margin-right: 16px;
}

.BonusHistoryTableMobile_Transaction-bonuses {
  color: #25bc8b;
  font-weight: 700;
}

.BonusHistoryTableMobile_Transaction-bonuses--type-income {
}

.BonusHistoryTableMobile_Transaction-bonuses--type-expense {
  color: #e74a5a;
}

.BonusHistoryTableMobile_Transaction-date {
  color: #848fa5;
}

.BonusHistoryTableMobile_Transaction-extra {
  color: #848fa5;
}

.BonusHistoryTableMobile_Transaction-button {
  display: inline-block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-family: inherit;
  background-color: transparent;
  border: none;
  border-top: 1px solid #d7deea;
  outline: none;
  cursor: pointer;
  color: #848fa5;
}

.BonusHistoryTableMobile_Transaction-buttonText {
  display: inline-block;
  margin-right: 16px;
}

.BonusHistoryTableMobile_Transaction-buttonIcon {
  display: inline-block;
  line-height: 1;
}

.BonusHistoryTableMobile_Transaction-orderDetails {
  padding: 16px 0;
  border-top: 1px dashed #d7deea;
}
