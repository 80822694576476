.ProductToolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #fff;
  box-shadow: 0px 0px 24px 0 rgba(47, 47, 47, 0.12);
  z-index: 400;

  transform: translateY(100%);
  transition: transform 0.2s ease-out;
}

.ProductToolbar--opened {
  transform: translateY(0);
}

.ProductToolbar-box {
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (min-width: 420px) {
  .ProductToolbar-box {
    padding: 8px 0;
  }
}

@media (min-width: 840px) {
  .ProductToolbar-box {
    padding: 16px 0;
  }
}

.ProductToolbar-mediaCol {
  flex: 0 0 auto;
  margin-left: -8px;
}

@media (min-width: 420px) {
  .ProductToolbar-mediaCol {
    flex: 0 0 auto;
    margin-right: 8px;
  }
}

@media (min-width: 840px) {
  .ProductToolbar-mediaCol {
    flex: 0 0 auto;
    margin-right: 32px;
  }
}

.ProductToolbar-mediaHolder {
  width: 64px;
}

.ProductToolbar-titleGroup {
}

.ProductToolbar-title {
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  color: #848fa5;
}

.ProductToolbar-description {
  margin: 0;
}

.ProductToolbar-priceCol {
}

@media (min-width: 840px) {
  .ProductToolbar-priceCol {
    margin-left: auto;
    margin-right: 32px;
  }
}

.ProductToolbar-priceHolder {
  font-size: 16px;
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

@media (min-width: 420px) {
  .ProductToolbar-priceHolder {
    font-size: 18px;
  }
}

@media (min-width: 840px) {
  .ProductToolbar-priceHolder {
    font-size: 20px;
  }
}

.ProductToolbar-volumeHolder {
  display: inline-block;
  vertical-align: middle;
}

.ProductToolbar-inBasketCol {
  margin-right: 16px;
}

.ProductToolbar-inBasket {
  font-size: 14px;
  color: #848fa5;
}

@media (min-width: 840px) {
  .ProductToolbar-inBasket {
    font-size: 16px;
  }
}

.ProductToolbar-actionCol {
  display: flex;
  margin-left: auto;
}

@media (min-width: 840px) {
  .ProductToolbar-actionCol {
    margin-left: 0;
  }
}

.ProductToolbar-action {
  margin-right: 8px;
  position: relative;
}

@media (min-width: 840px) {
  .ProductToolbar-action {
    margin-right: 16px;
  }
}

.ProductToolbar-action:last-child {
  margin-right: 0;
}

.ProductToolbar-badge {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: -12px;
  line-height: 1;
}

.ProductToolbar-ABTest {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.ProductToolbar-ABTest-toBasket {
  margin-right: 8px;
  flex: 1 1 auto;
  max-width: 360px;
}

.ProductToolbar-ABTest-toFavourite {
}

.ProductToolbar-ABTest-toFavourite--inFavourite {
  color: #e95958;
}
