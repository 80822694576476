.BonusDeadline {
  display: flex;
  align-items: top;
  color: #848fa5;
}

.BonusDeadline-icon {
  margin-right: 8px;
  line-height: 1;
  font-size: 24px;
}

.BonusDeadline-info {
  padding-top: 1px;
  line-height: 1.4;
}

.BonusDeadline-amount {
}

.BonusDeadline-time {
}
