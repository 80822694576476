.ActionsGigt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
}

.ActionsGigt-imageHolder {
  height: 100%;
  box-sizing: border-box;
}

.ActionsGigt-image {
  padding: 4px;
  background-color: #fff;
  border-radius: 50%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActionsGigt-image figure {
  width: 100%;
  /* height: 100%; */
  padding: 16px;
}

.ActionsGigt-title {
  position: absolute;
  bottom: 0;
  /* background: #a04ab0; */
  transform: rotate(5deg);
  left: 50%;
  /* transform: translateX(-50%); */
  padding: 8px 16px;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  font-size: 18px;
  text-align: center;
  line-height: 1.2;
  left: -7%;
  right: -7%;
  bottom: 8%;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 2px 3px 1px rgba(15, 15, 15, 0.5);
  z-index: 1;
}

@media (max-width: 640px) {
  .ActionsGigt-title {
    transform: none;
  }
}

/* .ActionsGigt-title::after { */
.ActionsGigt-titleBG {
  position: absolute;
  content: '';
  left: -8px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #a04ab0;
  z-index: -1;
  transform: skew(-15deg);
}
