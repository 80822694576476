.IconHeart {
  display: block;
  fill: currentColor;
  /* stroke: currentColor; */
  color: inherit;
  height: 100%;
  /* width: auto; */
}

.IconHeart--active {
  fill: currentColor;
}
