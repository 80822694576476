.DialogSection {
}

.DialogSection-title {
  line-height: 1.2;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 12px;
}

@media (max-width: 640px) {
  .DialogSection-title {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .DialogSection-title {
    margin-bottom: 8px;
    font-size: 20px;
  }
}
