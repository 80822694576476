.PromoCode {
  padding: 12px 40px;
  border-radius: 25px;
  border: 1px dotted #262931;
  box-sizing: content-box;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}

.PromoCode--expanded {
  width: 100%;
}

.PromoCode--notSupporterCopy {
  cursor: default;
}

.PromoCode--promoCodeCopied {
  cursor: default;
}

.PromoCode--inverted {
  border: 1px dotted #fff;
  color: #fff;
}

.PromoCode--onOverlay {
  border: 1px dotted #fff;

  position: relative;
  overflow: hidden;
}

.PromoCode--onOverlay::before {
  content: '';
  display: block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  color: inherit;
  background-color: currentColor;
  opacity: 0.4;
}

.PromoCode--size-small {
  padding: 12px 32px;
  font-size: 12px;
}

.PromoCode-holder {
  display: flex;
  align-items: center;
  line-height: 24px;
  position: relative;
}

.PromoCode--size-small .PromoCode-holder {
  line-height: 14px;
}

.PromoCode-title {
  margin-right: 8px;
  user-select: none;
}

.PromoCode--onOverlay .PromoCode-title {
  color: #fff;
}

.PromoCode-input {
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  border: none;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  outline: none;
}

.PromoCode--promoCodeCopied .PromoCode-input {
  cursor: default;
}

.PromoCode--notSupporterCopy .PromoCode-input {
  cursor: text;
}

.PromoCode--onOverlay .PromoCode-input {
  color: #ffd12c;
}

.PromoCode--inverted .PromoCode-input {
}

.PromoCode-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.2s opacity ease-out;
  line-height: 1;
}

.PromoCode:hover .PromoCode-icon {
  opacity: 1;
}

@media (max-width: 840px) {
  .PromoCode-icon {
    opacity: 1;
  }
}

.PromoCode--promoCodeCopied .PromoCode-icon {
  opacity: 1;
}

.PromoCode--onOverlay .PromoCode-icon {
  color: #ffd12c;
}

.PromoCode--dashed {
  border-style: dashed;
}
