.OrderInDetailScene {
}

.OrderInDetailScene-header {
  padding-bottom: 8px;
  border-bottom: 1px solid #dbe1ec;
}

.OrderInDetailScene-back {
  margin-bottom: 32px;
}

@media (max-width: 840px) {
  .OrderInDetailScene-back {
    display: none;
  }
}

.OrderInDetailScene-headerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .OrderInDetailScene-headerBottom {
    align-items: flex-start;
  }
}

.OrderInDetailScene-title {
  margin: 0;
  line-height: 1.2;
}

.OrderInDetailScene-titleNumber {
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
}

@media (max-width: 1100px) {
  .OrderInDetailScene-titleNumber {
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .OrderInDetailScene-titleNumber {
    display: block;
    margin-bottom: 4px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .OrderInDetailScene-titleDate {
    font-size: 16px;
  }
}

.OrderInDetailScene-titleDate {
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
}

@media (max-width: 1100px) {
  .OrderInDetailScene-titleDate {
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .OrderInDetailScene-titleDate {
    display: block;
    font-size: 16px;
    font-weight: 300;
  }
}

@media (max-width: 480px) {
  .OrderInDetailScene-titleDate {
    font-size: 14px;
  }
}

.OrderInDetailScene-status {
}

@media (max-width: 480px) {
  .OrderInDetailScene-status {
    font-size: 14px;
  }
}

.OrderInDetailScene-details {
  margin-bottom: 8px;
  padding: 16px 0;
}

.OrderInDetailScene-detailItem {
  margin-bottom: 16px;
}

.OrderInDetailScene-detailItem:last-child {
  margin-bottom: 0;
}

.OrderInDetailScene-detailTitle {
  margin: 0;
  margin-bottom: 4px;
  line-height: 1.2;
}

.OrderInDetailScene-detailInfo {
}

.OrderInDetailScene-payment {
  font-weight: bold;
  font-size: 18px;
  padding: 16px 0 32px;
}

@media (max-width: 840px) {
  .OrderInDetailScene-payment {
    display: none;
  }
}

.OrderInDetailScene-price {
  margin-left: 16px;
  font-size: 21px;
}

.OrderInDetailScene-table {
}

.OrderInDetailScene-repeatOrder {
  text-align: right;
  margin-top: 21px;
}
