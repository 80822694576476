.HeaderInfo {
  /* empty */
}

.HeaderInfo-list {
  padding: 0;
  margin: -16px;
}

.HeaderInfo-item {
  list-style: none;
  display: inline-block;
  padding: 16px;
  font-size: 14px;
}

.HeaderInfo-selectCity {
}
