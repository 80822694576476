.Landing {
  /* background: linear-gradient(
      90.27deg,
      rgba(95, 207, 210, 0.6) -23.58%,
      rgba(240, 181, 255, 0.6) 120.81%
    ),
    #ffffff; */
  /* background-color: red; */
  overflow: hidden;

  position: relative;
  padding-bottom: 32px;
}

.Landing-content {
  position: relative;
  z-index: 1;
}

.Landing-BG {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.Landing-bottomInfo {
  padding-top: 32px;
  padding-bottom: 32px;
  color: #fff;
}
