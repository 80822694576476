.BasketPreviewPanelSection {
  margin-top: 8px;
  margin-bottom: 16px;
}

.BasketPreviewPanel {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border: 1px solid #dbe1ec;
  position: relative;
}

@media (max-width: 400px) {
  .BasketPreviewPanel {
    padding: 16px 8px;
  }
}

@media (max-width: 1080px) {
  .BasketPreviewPanel {
    flex-direction: column;
    align-items: flex-start;
  }
}

.BasketPreviewPanel-narrowColumn {
  flex: 1 1 20%;
  box-sizing: border-box;
}

.BasketPreviewPanel-narrowColumn {
  margin-bottom: 16px;
}

@media (max-width: 460px) {
  .BasketPreviewPanel-narrowColumn {
    margin-bottom: 8px;
  }
}

.BasketPreviewPanel-wideColumn {
  flex: 1 1 80%;
  padding-left: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1160px) {
  .BasketPreviewPanel-wideColumn {
    padding-left: 8px;
  }
}

@media (max-width: 1080px) {
  .BasketPreviewPanel-wideColumn {
    padding-left: 0;
    flex: 1 1 auto;
    width: 100%;
  }
}

@media (max-width: 840px) {
  .BasketPreviewPanel-wideColumn {
    flex-direction: column;
  }
}

.BasketPreviewPanel-product {
  display: flex;
  align-items: center;
}

@media (max-width: 840px) {
  .BasketPreviewPanel-product {
    margin-bottom: 24px;
  }
}

@media (max-width: 460px) {
  .BasketPreviewPanel-product {
    margin-bottom: 16px;
  }
}

.BasketPreviewPanel-productMediaList {
  margin-right: 16px;
}

@media (max-width: 460px) {
  .BasketPreviewPanel-productMediaList {
    margin-right: 8px;
  }
}

.BasketPreviewPanel-orderAmount {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 460px) {
  .BasketPreviewPanel-orderAmount {
    font-size: 14px;
  }
}
.BasketPreviewPanel-excessAmountHolder {
  position: relative;
}

.BasketPreviewPanel-excessAmount {
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #dbe1ec;
  cursor: default;
}

@media (max-width: 460px) {
  .BasketPreviewPanel-excessAmount {
    width: 28px;
    height: 22px;
    font-size: 12px;
  }
}

.BasketPreviewPanel-basketLinkColumn {
}

@media (max-width: 840px) {
  .BasketPreviewPanel-basketLinkColumn {
    margin-bottom: 24px;
  }
}

@media (max-width: 460px) {
  .BasketPreviewPanel-basketLinkColumn {
    margin-bottom: 16px;
  }
}

.BasketPreviewPanel-basketLink {
  font-weight: 700;
  text-decoration: none;
  position: relative;
}

@media (max-width: 460px) {
  .BasketPreviewPanel-basketLink {
    font-size: 14px;
  }
}

.BasketPreviewPanel-basketLink::after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 1px;
  background-color: #000;
}

.BasketPreviewPanel-item {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  vertical-align: top;
}

@media (max-width: 460px) {
  .BasketPreviewPanel-item {
    margin-right: 8px;
  }
}

@media (max-width: 380px) {
  .BasketPreviewPanel-item {
    margin-right: 4px;
  }
}

.BasketPreviewPanel-item:last-child {
  margin-right: 0;
}

.BasketPreviewPanel-productButton {
  position: relative;
  background-color: #fff;
  border: 1px solid #dbe1ec;
  padding: 0;
  height: 48px;
  width: 48px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: inherit;
  outline: none;
  vertical-align: top;
}

.BasketPreviewPanel-productButton--gift {
  cursor: default;
  /* pointer-events: none; */
}

@media (max-width: 460px) {
  .BasketPreviewPanel-productButton {
    height: 42px;
    width: 42px;
  }
}

.BasketPreviewPanel-productButton:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #dbe1ec;
  opacity: 0.3;
  transition: opacity 0.2s ease-out;
}

.BasketPreviewPanel-productButton--gift:after {
  opacity: 0;
}

.BasketPreviewPanel-productButton:hover:after {
  opacity: 0;
}

.BasketPreviewPanel-amountLabel {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdc611;
  font-weight: 700;
}

.BasketPreviewPanel-orderCost {
}

@media (max-width: 460px) {
  .BasketPreviewPanel-orderCost {
    font-size: 12px;
  }
}

.BasketPreviewPanel-buttonCloseWrapper {
  position: absolute;
  right: 8px;
  top: 8px;
}

.BasketPreviewPanel-buttonClose {
  cursor: pointer;
  color: #848fa5;
  background-color: #fff;
  border: none;
  outline: none;
}

.BasketPreviewPanel-tooltip {
  display: flex;
  flex-direction: column;
  margin: -16px -24px;
  border-radius: 3px;
  overflow: hidden;
}

.BasketPreviewPanel-tooltipElementHolder {
  display: flex;
  align-items: center;
}

.BasketPreviewPanel-tooltipLink {
  text-decoration: none;
  /* margin-bottom: 8px; */
  width: 100%;
  transition: 0.2s ease-out;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}
.BasketPreviewPanel-tooltipElementHolder:not(.BasketPreviewPanel-tooltipElementHolder--gift)
  .BasketPreviewPanel-tooltipLink:hover {
  background-color: rgba(219, 225, 236, 0.5);
}
.BasketPreviewPanel-tooltipElementHolder:last-child .BasketPreviewPanel-tooltipLink {
  margin-bottom: 0;
}

.BasketPreviewPanel-tooltipLinkLabel {
  margin-right: 8px;
  font-weight: bold;
  color: #25bc8b;
}

.BasketPreviewPanel-tooltipLinkDescription {
  /* text-decoration: underline; */
  display: flex;
  align-items: center;
}

.BasketPreviewPanel-tooltipLinkTitle {
  font-size: 12px;
  color: #848fa5;
  text-decoration: none;
}

.BasketPreviewPanel-productTooltip {
  text-align: center;
  max-width: 300px;
}
.BasketPreviewPanel-productTooltipDescription {
}
.BasketPreviewPanel-productTooltipTitle {
  color: #848fa5;
}

.BasketPreviewPanel-tooltipLinkImage {
  width: 54px;
  height: 54px;
  margin-right: 16px;
  position: relative;
}

.BasketPreviewPanel-tooltipLinkAmountLabel {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdc611;
  font-weight: 700;
  z-index: 1;
}
