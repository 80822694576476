.BottomBanner {
  position: relative;
  padding-top: 62%;
  margin-top: 40px;
}

@media (max-width: 840px) {
  .BottomBanner {
    padding-top: 120%;
  }
}

.BottomBanner-imageHolder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.BottomBanner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BottomBanner-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.BottomBanner-contentInner {
  height: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;

  padding-left: 16px;
  padding-right: 16px;
}

.BottomBanner-title {
  margin-bottom: 6vw;
  font-size: 30px;
}

@media (max-width: 1250px) {
  .BottomBanner-title {
    margin-bottom: 5vw;
  }
}

@media (max-width: 840px) {
  .BottomBanner-title {
    margin-bottom: 1vw;
  }
}

.BottomBanner-mainTitle {
  font-size: 48px;
  text-align: center;
  transform: translateY(-2vw) rotate(-8deg);
  font-style: italic;
}

/* @media (max-width: 1250px) {
  .BottomBanner-mainTitle {
    font-size: 90px;
  }
}
@media (max-width: 840px) {
  .BottomBanner-mainTitle {
    font-size: 64px;
  }
}
@media (max-width: 640px) {
  .BottomBanner-mainTitle {
    font-size: 48px;
  }
} */
