.FooterNavigation {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 720px) {
  .FooterNavigation {
    flex-direction: column;
  }
}

.FooterNavigation-item {
  flex: 0 0 25%;
  max-width: 25%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

@media (max-width: 720px) {
  .FooterNavigation-item {
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 1px solid #d1d5da;
  }
}

.FooterNavigation-item:last-child {
  border: none;
}
.FooterNavigation-contacts {
  margin-top: auto;
}
