.Ingredient {
  position: relative;
  outline: none;
  display: block;
  padding-bottom: 24px;
}

@media screen and (max-width: 840px) {
  .Ingredient {
    text-align: center;
  }
}

.Ingredient-image {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.Ingredient-fullImage {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.Ingredient--withFullImage .Ingredient-image {
  opacity: 0;
}

.Ingredient--withFullImage .Ingredient-fullImage {
  opacity: 1;
}

.Ingredient-button {
  position: absolute;
  bottom: 90px;
  right: 80px;
}

.Ingredient-link {
  display: inline-block;
  position: absolute;
  bottom: 8px;
  right: 0;
  font-size: 21px;
  transition: all 0.2s ease-out;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  appearance: none;
  border: none;
  cursor: pointer;
}

.Ingredient-link:hover {
  color: rgba(233, 89, 89, 0.44);
}
.Ingredient-link:active {
  color: #e95959;
}

.isTabUsing .Ingredient-link:focus {
  color: #e95959;
}

@media screen and (max-width: 840px) {
  .Ingredient-link {
    font-size: 16px;
    font-weight: 400;
    position: static;
  }

  .Ingredient-link::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .Ingredient-link {
    font-size: 14px;
    position: static;
  }
}

.Ingredient-linkText {
  display: inline-block;
  margin-right: 16px;
}

@media screen and (max-width: 480px) {
  .Ingredient-linkText {
    margin-right: 0;
  }
}

.Ingredient-linkIcon {
  display: inline-block;
  height: 12px;
  position: relative;
  transition: transform 0.2s ease-out;
  color: inherit;
}

@media screen and (max-width: 480px) {
  .Ingredient-linkIcon {
    display: none;
  }
}

.Ingredient-link:hover .Ingredient-linkIcon {
  transform: translateX(5px);
}

.Ingredient-link:focus .Ingredient-linkIcon {
  transform: translateX(5px);
}

.Ingredient-description {
  position: relative;
  min-height: 80px;
  padding-left: 16px;
  max-width: 360px;
  margin-top: 8px;
}

.Ingredient-description::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 0;
  border-left: 2px solid #ffd500;
}
