.FAQList {
}

.FAQList-categoryHolder {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.FAQList-categoryItem {
}

.FAQList-category {
}
.FAQList-categoryTitle {
  margin-bottom: 24px;
  margin-top: 64px;
  font-size: 28px;
  font-weight: bold;
}
@media (max-width: 840px) {
  .FAQList-categoryTitle {
    text-align: center;
    font-size: 20px;
    margin-top: 42px;
    margin-bottom: 18px;
  }
}

.FAQList-categoryItem:first-child .FAQList-categoryTitle {
  margin-top: 0;
}

.FAQList-categoryQuestions {
}

.FAQList-questionTitle {
  padding: 14px 0;
  padding-right: 16px;
}
@media (max-width: 840px) {
  .FAQList-questionTitle {
    padding: 10px 0;
    font-size: 14px;
  }
}

.FAQList-categoryQuestions .AccordionItem-title {
  white-space: inherit;
  text-align: left;
}

.FAQList-questionDescription {
}

@media (max-width: 840px) {
  .FAQList-questionDescription {
    font-size: 14px;
  }
}
