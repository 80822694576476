.SuccessfulOrder {
}

.SuccessfulOrder-mainInfo {
}

.SuccessfulOrder-orderNumber {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.SuccessfulOrder-description {
  font-size: 18px;
  text-align: center;
  max-width: 520px;
  margin: 0 auto;
}

@media (max-width: 640px) {
  .SuccessfulOrder-description {
    margin-bottom: 16px;
  }
}

.SuccessfulOrder-back {
  text-align: center;
  margin-top: 16px;
}

@media (max-width: 840px) {
  .SuccessfulOrder-back {
    margin-bottom: 32px;
  }
}

@media (max-width: 640px) {
  .SuccessfulOrder-back {
    display: flex;
    flex-direction: column-reverse;
    max-width: 420px;
    margin: 0 auto;
    margin-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .SuccessfulOrder-back {
    margin-bottom: 16px;
  }
}

.SuccessfulOrder-deliveryInformation {
  color: #25bc8b;
  margin: 18px 0;
}

.SuccessfulOrder-informingMessage {
  font-size: 16px;
  text-align: center;
  max-width: 570px;
  margin: 24px auto 0;
}

@media (max-width: 840px) {
  .SuccessfulOrder-informingMessage {
    text-align: left;
    margin: 0;
  }
}

.SuccessfulOrder-informingOptions {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.SuccessfulOrder-optionItem {
  margin-right: 40px;
}

.SuccessfulOrder-orderListTitle {
  margin: 24px 0;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 640px) {
  .SuccessfulOrder-orderListTitle {
    margin: 24px 0 16px;
  }
}

@media (max-width: 480px) {
  .SuccessfulOrder-orderListTitle {
    margin: 16px 0;
  }
}

.SuccessfulOrder-orderList {
  margin-bottom: 16px;
}

@media (max-width: 640px) {
  .SuccessfulOrder-orderList {
    margin-bottom: 32px;
  }
}

@media (max-width: 480px) {
  .SuccessfulOrder-orderList {
    margin-bottom: 24px;
  }
}

@media (max-width: 420px) {
  .SuccessfulOrder-orderList {
    margin-bottom: 16px;
  }
}

@media (max-width: 620px) {
  .SuccessfulOrder-informingOptions {
    flex-direction: column;
  }
  .SuccessfulOrder-optionItem {
    margin-right: 0;
    padding: 16px 0;
  }
}

.SuccessfulOrder-optionItem:last-child {
  margin-right: 0;
}

.SuccessfulOrder-userInfoTable {
  border-top: 1px solid #dbe1ec;
  padding-top: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}

@media (max-width: 840px) {
  .SuccessfulOrder-userInfoTable {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 16px;
  }
}

@media (max-width: 480px) {
  .SuccessfulOrder-userInfoTable {
    margin-bottom: 8px;
  }
}

.SuccessfulOrder-commentHolder {
  margin-bottom: 48px;
  line-height: 1.4;
  font-size: 16px;
}

@media (max-width: 840px) {
  .SuccessfulOrder-commentHolder {
    margin-bottom: 32px;
  }
}

@media (max-width: 480px) {
  .SuccessfulOrder-commentHolder {
    line-height: 1.3;
  }
}

.SuccessfulOrder-commentText {
}

.SuccessfulOrder-commentButton {
  display: inline-block;
  margin-left: 8px;
}

.SuccessfulOrder-contacts {
  border-bottom: 1px solid #dbe1ec;
  text-align: center;
  font-size: 18px;
  padding-bottom: 24px;
}

.SuccessfulOrder-contactsTitle {
  margin-bottom: 16px;
}
.SuccessfulOrder-contactsPhone {
}
.SuccessfulOrder-contactsFooter {
  margin-top: 24px;
  font-weight: bold;
}

.SuccessfulOrder-subscribe {
  padding-top: 32px;
}

.SuccessfulOrder-subscribeButton {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.SuccessfulOrder-action {
  margin-right: 16px;
  display: inline-block;
}

@media (max-width: 640px) {
  .SuccessfulOrder-action {
    flex: 0 1 auto;
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.SuccessfulOrder-action:last-child {
  margin-right: 0;
}

@media (max-width: 640px) {
  .SuccessfulOrder-action:last-child {
    margin-top: 0;
  }
}

.SuccessfulOrder-backupLink {
  text-align: center;
  margin: 16px 0;
}

/* .SuccessfulOrder-backupLink a {
  max-width: 400px;
  overflow: hidden;
  display: block;
  margin: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #4466ff;
} */

.SuccessfulOrder-getPamentLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  color: #25bc8b;
  font-weight: bold;
}

.SuccessfulOrder-getPamentLink_text {
}

.SuccessfulOrder-getPamentLink_loader {
  margin-left: 8px;
  position: relative;
  width: 25px;
  height: 25px;
}

.SuccessfulOrder-paimentCounter {
  text-align: center;
}
