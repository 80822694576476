.BonusCardStatus {
  color: #848fa5;
}

.BonusCardStatus--activated {
}

.BonusCardStatus-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  color: #e95958;
  vertical-align: middle;
}

.BonusCardStatus--activated .BonusCardStatus-icon {
  color: #00c090;
}

.BonusCardStatus-title {
  line-height: 1;
  vertical-align: middle;
}
