.MassMediaArticle-link {
  text-decoration: none;
}

.MassMediaArticle-logo {
  text-align: center;
}

.MassMediaArticle-image {
  width: 100%;
}

@media (max-width: 840px) {
  .MassMediaArticle-image {
    width: 80%;
  }
}

.MassMediaArticle-date {
  text-align: center;
  margin-bottom: 8px;
}

.MassMediaArticle-title {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 18px;
}

@media (max-width: 840px) {
  .MassMediaArticle-title {
    margin-bottom: 64px;
  }
}

@media (max-width: 480px) {
  .MassMediaArticle-title {
    margin-bottom: 24px;
  }
}
