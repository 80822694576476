.FormData {
}

.FormData-fields {
}

.FormData-field {
  padding: 8px 0;
  border-bottom: 1px solid #d2d5da;
}

.FormData-fieldLabel {
  font-size: 14px;
  color: #868d97;
}

.FormData-fieldValue {
  font-weight: bold;
}

.FormData-actionEdit {
  padding: 8px 0;
}

.FormData-fieldPlaceholder {
  color: #868d97;
  font-weight: normal;
}
