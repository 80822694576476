.DateField {
  display: flex;
}

.DateField-wideCol {
  flex: 0 0 auto;
  width: 160px;
  margin-right: 8px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .DateField-wideCol {
    width: 180px;
  }
}

.DateField-narrowCol {
  flex: 0 0 auto;
  width: 80px;
  margin-right: 8px;
  box-sizing: border-box;
}

.DateField-wideCol:last-child {
  margin-right: 0;
}

.DateField-narrowCol:last-child {
  margin-right: 0;
}
