.EmailConfirmDialog {
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 840px) {
  .EmailConfirmDialog {
    padding: 50px 16px;
  }
}

.EmailConfirmDialog-icon {
  font-size: 74px;
  line-height: 74px;
  margin-bottom: 8px;
}

@media (max-width: 840px) {
  .EmailConfirmDialog-icon {
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 0px;
  }
}

.EmailConfirmDialog-title {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
}

@media (max-width: 840px) {
  .EmailConfirmDialog-title {
    font-size: 18px;
    margin-bottom: 24px;
  }
}
