.PhoneNumber {
  display: inline-block;
  line-height: 20px;
  font-size: 18px;
  color: #262931;
  text-decoration: none;
  pointer-events: none;
}

.PhoneNumber--bold {
  font-weight: bold;
}

@media (max-width: 640px) {
  .PhoneNumber {
    pointer-events: auto;
  }
}
