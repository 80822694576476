.GalleryPaginationButton {
  background-color: #f3f3f3;
  border: 1px solid #cacacc;
  border-radius: 3px;
  padding: 0;
  height: 48px;
  width: 48px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  transition: all 0.2s ease-out;
  font-family: inherit;
}

.GalleryPaginationButton:hover {
  /*transform: scale(1.1);*/
  background-color: transparent;
}

.GalleryPaginationButton:focus {
  outline: none;
  /* FIXME: set focus styles */
}

.GalleryPaginationButton::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: #262931;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.GalleryPaginationButton:hover::after {
  /* opacity: 0.3; */
  opacity: 0;
}

.GalleryPaginationButton--active {
  border-color: #ffd12c;
  background-color: transparent;
}

.GalleryPaginationButton--active:hover::after {
  opacity: 0;
}

.GalleryPaginationButton-play {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  transition: 0.3s;
}

.GalleryPaginationButton-play::before {
  content: '';
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffd12c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 10;
}

.GalleryPaginationButton-play::after {
  content: '';
  display: block;
  transition: 0.3s;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5.5px 0 5.5px 8px;
  border-color: transparent;
  border-left-color: #262931;
  top: 50%;
  left: 50%;
  margin-top: -5.5px;
  margin-left: -3px;
  z-index: 10;
}
