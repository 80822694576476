.CircleBorderedImage {
  padding: 1em;
  font-size: inherit;
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .CircleBorderedImage--inverted {
    flex-direction: column;
  }
}

.CircleBorderedImage-imageHolder {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  /* animation: rotateQ 2s ease infinite; */
}

@media (max-width: 840px) {
  .CircleBorderedImage-imageHolder {
    margin-right: 0;
  }
}

.CircleBorderedImage-circle:nth-child(1) {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(-6%, 3%);
  border: 1px solid #a04ab0;
  z-index: 1;
  border-radius: 50%;
}

.CircleBorderedImage--inverted .CircleBorderedImage-circle:nth-child(1) {
  transform: translate(6%, -3%);
}

.CircleBorderedImage-circle:nth-child(2) {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(-3%, -6%);
  border: 1px solid #46cacc;
  z-index: 1;
  border-radius: 50%;
}

.CircleBorderedImage--inverted .CircleBorderedImage-circle:nth-child(2) {
  transform: translate(3%, 6%);
}

.CircleBorderedImage--inverted .CircleBorderedImage-imageHolder {
}

@media (max-width: 840px) {
  .CircleBorderedImage--inverted .CircleBorderedImage-imageHolder {
    margin-left: 0;
  }
}

.CircleBorderedImage-imageSizer {
  position: relative;
  padding-top: 100%;
  box-sizing: border-box;
  width: 100%;
}

.CircleBorderedImage-imageInnerHolder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
}

.CircleBorderedImage-image {
  background-color: #fff;
  border-radius: 50%;
  /* padding: 35px; */
  padding: 1.5em;
  position: relative;
  z-index: 2;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
