.ScrollBar {
  position: relative;
  height: 8px;
  background-color: transparent;
  border-radius: 8px;
  padding: 3px 0;
}

.ScrollBar::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  left: 0;
  right: 0;
  background-color: #eff0f2;
}

.ScrollBar-active {
  position: absolute;
  left: 0;
  top: 3px;
  bottom: 3px;
  background-color: #c7d3e1;
  border-radius: 8px;
}

/* .ScrollBar-active::before {
  position: absolute;
  content: '';
  border: 1px solid rgb(85, 85, 85);
  left: -4px;
  right: -4px;
  top: -4px;
  bottom: -4px;
  border-radius: 16px;
}  */

.ScrollBar-before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.ScrollBar-after {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
