.OrderTable {
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

@media screen and (max-width: 840px) {
  .OrderTable {
    display: block;
  }
}

.OrderTable-header {
  display: table-row;
  border-bottom: 1px solid #f3f3f3;
  color: #848fa5;
}

@media screen and (max-width: 840px) {
  .OrderTable-header {
    display: none;
  }
}

.OrderTable-header .OrderTable-cell:last-child .OrderTable-subCell {
  /* width: 100px; */
}

.OrderTable-row {
  display: table-row;
  border-bottom: 1px solid #f3f3f3;
  text-decoration: none;
  transition: background-color 0.2s ease-out;
}

.OrderTable-row:hover {
  background-color: #f7fbff;
}

@media screen and (max-width: 840px) {
  .OrderTable-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 16px 8px;
  }
}

.OrderTable-cell {
  display: table-cell;
  padding: 24px 16px 24px 8px;
}

@media screen and (max-width: 840px) {
  .OrderTable-cell {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
    box-sizing: border-box;
    padding: 0;
  }
}

.OrderTable-header .OrderTable-cell {
  padding: 8px;
}

.OrderTable-cell--status {
  min-width: 200px;
  /* background-color: olivedrab; */
  /* text-align: center; */
}

@media screen and (max-width: 840px) {
  .OrderTable-cell--status {
    flex: 0 0 60%;
    max-width: 60%;
    order: 2;
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    font-size: 14px;
    min-width: 0;
  }
}

.OrderTable-cell--number {
  /* background-color: royalblue; */
}

@media screen and (max-width: 840px) {
  .OrderTable-cell--number {
    flex: 0 0 40%;
    max-width: 40%;
    order: 1;
  }
}

.OrderTable-row .OrderTable-cell--number {
  font-weight: bold;
}

.OrderTable-cell--date {
  text-align: center;
  /* background-color: pink; */
}

@media screen and (max-width: 840px) {
  .OrderTable-cell--date {
    font-size: 14px;
    order: 3;
    text-align: left;
  }
}

.OrderTable-cell--price {
  text-align: center;
  /* background-color: salmon; */
}

@media screen and (max-width: 840px) {
  .OrderTable-cell--price {
    order: 4;
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
  }
}

.OrderTable-cell--trackingNumber {
  text-align: center;
  /* background-color: salmon; */
}

@media (max-width: 840px) {
  .OrderTable-cell--trackingNumber {
    text-align: left;
    order: 5;
    font-weight: bold;
    /* display: flex; */
    display: none;
  }
  .OrderTable-cell--trackingNumber::before {
    content: 'Трек-номер -';
    font-weight: normal;
    margin-right: 4px;
    /* text-decoration: underline; */
  }
}

.OrderTable-subCell {
}

@media screen and (max-width: 840px) {
  .OrderTable-subCell {
    padding: 0;
  }
}

.OrderTable-cell--price .OrderTable-subCell {
  min-width: 70px;
  display: inline-flex;
  justify-content: flex-end;
}

.OrderTable-cell--status .OrderTable-subCell {
  display: flex;
  align-content: center;
}

@media screen and (max-width: 840px) {
  .OrderTable-cell--status .OrderTable-subCell {
    /* max-width: 50%; */
    /* max-width: 140px; */
  }
}

.OrderTable-arrow {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 840px) {
  .OrderTable-arrow {
    position: absolute;
    display: block;
    margin: 0;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    width: 20px;
  }
}
