.Accordion {
}

.Accordion-itemHolder {
  border-top: 1px solid #ccc;
}

.Accordion-itemHolder:last-child {
  border-bottom: 1px solid #ccc;
}
