.InputRating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.InputRating-label {
  width: 27px;
  height: 27px;
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  transition: 0.3s;
  cursor: pointer;
  color: transparent;
  fill: none;
  stroke: #fed835;
}
.InputRating-label:focus,
.InputRating-label:hover,
.InputRating-label:hover ~ .InputRating-label,
.InputRating-radio:checked ~ .InputRating-label {
  color: #fed835;
}
.InputRating-radio {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
