.SectionProductUsage {
  padding: 50px 0;
}

@media (max-width: 840px) {
  .SectionProductUsage {
    padding: 0;
  }
}

.SectionProductUsage-videoContainer {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 40px;
  cursor: pointer;
}

.SectionProductUsage-title {
  text-align: center;
  font-size: 24px;
}

.SectionProductUsage-description {
  font-size: 16px;
  margin: 0;
}
