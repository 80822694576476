.IconCheck {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  top: -10%;
}

.IconCheck::after {
  content: '';
  top: 50%;
  left: 50%;
  width: 0.8em;
  height: 0.4em;
  margin-top: -0.3em;
  margin-left: -0.4em;
  border-left: 1px solid;
  border-bottom: 1px solid;
  position: absolute;
  transform: rotate(-45deg);
}
