.BannerFeaturedSlider_NavigationButton {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  border: 2px solid transparent;
  background-color: transparent;
  margin: 0 5px;
  padding: 4px;
  line-height: 8px;
  border-radius: 14px;
  transition: all 0.2s ease-out;
}

@media (max-width: 480px) {
  .BannerFeaturedSlider_NavigationButton {
    line-height: 6px;
    border-radius: 9px;
  }
}

.BannerFeaturedSlider_NavigationButton:hover {
  background-color: rgba(235, 235, 235, 0.6);
}

.isTabUsing .BannerFeaturedSlider_NavigationButton:focus {
  border-color: #ebebeb;
}

.BannerFeaturedSlider_NavigationButton:active {
  background-color: #ebebeb;
}

.BannerFeaturedSlider_NavigationButton--isActive {
  cursor: default;
}

.BannerFeaturedSlider_NavigationButton--isActive:hover {
  background-color: transparent;
}

.BannerFeaturedSlider_NavigationButton--isActive:active {
  background-color: transparent;
}

.BannerFeaturedSlider_NavigationButton-dot {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  background-color: #d1d5da;
  position: relative;
  z-index: 2;
  vertical-align: top;
}

@media (max-width: 480px) {
  .BannerFeaturedSlider_NavigationButton-dot {
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }
}

.BannerFeaturedSlider_NavigationButton:active .BannerFeaturedSlider_NavigationButton-dot {
  /* background-color: #b8b8b8; */
}

.BannerFeaturedSlider_NavigationButton--isActive .BannerFeaturedSlider_NavigationButton-dot {
  background-color: #444952;
}

.BannerFeaturedSlider_NavigationButton--isActive:active .BannerFeaturedSlider_NavigationButton-dot {
  /* background-color: #d1d5da; */
}
