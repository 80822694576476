.OrderProduct {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: -8px;
  font-size: 14px;
}

.OrderProduct-imageGroup {
  flex: 0 0 88px;
  max-width: 88px;
  box-sizing: border-box;
  padding: 8px;
}

.OrderProduct-image {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.OrderProduct-contentGroup {
  flex: 1 1 auto;
  padding: 8px;
  padding-top: 4px;
}

.OrderProduct-description {
  margin-bottom: 4px;
  line-height: 1.4;
  color: #262931;
}

.OrderProduct-title {
  margin: 0;
  margin-bottom: 4px;
  line-height: 1.2;
}

.OrderProduct-link {
  font-size: 14px;
  font-weight: 300;
  color: #848fa5;
  text-decoration: none;
}

.OrderProduct-selectedVariant {
  margin-bottom: 4px;
  line-height: 1.4;
  font-weight: 300;
  color: #848fa5;
}

.OrderProduct-link:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ProductPreview-purchaseDetails {
  color: #262931;
}

.OrderProduct-amount {
  display: inline-block;
  margin-right: 4px;
  font-weight: 300;
}

.OrderProduct-price {
  display: inline-block;
  margin-left: 4px;
}

.OrderProduct--separatePrice .OrderProduct-contentGroup {
  display: flex;
}

.OrderProduct--separatePrice .OrderProduct-contentCol:last-child {
  flex: 1 1 auto;
  text-align: right;
  white-space: nowrap;
  margin-left: 16px;
}

@media (max-width: 840px) {
  .OrderProduct--separatePrice .OrderProduct-contentGroup {
    display: block;
  }
  .OrderProduct--separatePrice .OrderProduct-contentCol:last-child {
    text-align: left;
    margin-left: 0;
  }
}
