.QuarantinLogo {
  position: relative;
  background-color: #fff;
  height: 40px;
  width: 56px;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .QuarantinLogo {
    transform: scale(0.6);
  }
}

.QuarantinLogo-absolutePoitionWrapper {
  position: absolute;
  top: 10px;
  left: 10px;
}

@media (max-width: 480px) {
  .QuarantinLogo-absolutePoitionWrapper {
    top: 6px;
  }
}

@media (max-width: 480px) {
  .QuarantinLogo-absolutePoitionWrapper {
    top: 8px;
  }
}

.QuarantinLogo-content {
  position: relative;
  background-color: #fff;
  height: 36px;
  width: 36px;
}

@media (max-width: 400px) {
  .QuarantinLogo-content {
    height: 32px;
    width: 32px;
  }
}

.QuarantinLogo-item {
  position: absolute;
  width: 0;
  height: 0;
  border: 14px solid transparent;
}

@media (max-width: 480px) {
  .QuarantinLogo-item {
    border: 12px solid transparent;
  }
}

.QuarantinLogo-itemPink {
  border-top: 14px solid #e83f78;
  transform: rotate(0deg) translateY(-2px);
  z-index: 3;
  animation: roofRotate 1s linear;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  transform-origin: top center;
}

@media (max-width: 480px) {
  .QuarantinLogo-itemPink {
    border-top: 12px solid #e83f78;
    left: -1px;
    animation: roofRotate 1s ease-out;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
  }
}

@keyframes roofRotate {
  from {
    transform: rotate(0deg) translateY(-2px);
  }
  to {
    transform: rotate(180deg) translateY(2px);
  }
}

.QuarantinLogo-itemYellow {
  border-top: 14px solid #ffe923;
  transform: rotate(180deg) translateY(-2px);
  z-index: 0;
}

@media (max-width: 480px) {
  .QuarantinLogo-itemYellow {
    border-top: 12px solid #ffe923;
    left: -1px;
  }
}

.QuarantinLogo-itemBlue {
  border-top: 14px solid #66bed3;
  transform: rotate(-90deg) translateY(-2px);
  z-index: 2;
}

@media (max-width: 480px) {
  .QuarantinLogo-itemBlue {
    border-top: 12px solid #66bed3;
  }
}

.QuarantinLogo-itemViolet {
  border-top: 14px solid #8e659d;
  transform: rotate(90deg) translateY(-2px);
  z-index: 1;
}

@media (max-width: 480px) {
  .QuarantinLogo-itemViolet {
    border-top: 12px solid #8e659d;
    left: -2px;
  }
}

.QuarantinLogo-line {
  position: absolute;
  height: 8%;
  border-radius: 8px;
  width: 42px;
  background-color: #000;
  top: 12px;
  left: -7px;
}

@media (max-width: 480px) {
  .QuarantinLogo-line {
    height: 6%;
    width: 36px;
    top: 11px;
  }
}

.QuarantinLogo-inclineLeft {
  transform: rotateZ(45deg) rotateY(0deg);
}

.QuarantinLogo-inclineRight {
  transform: rotateZ(-45deg) rotateY(0deg);
}

/* body {
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
} */
