.BonusHistoryTable {
  border: 1px solid #d7deea;
}

.BonusHistoryTable--withoutBorder {
  border: none;
}

.BonusHistoryTable-emptyMessage {
}

.BonusHistoryTable-header {
  border-bottom: 1px solid #d7deea;
}

.BonusHistoryTable-transactionList {
  margin: 0;
  padding: 0;
}

.BonusHistoryTable-transaction {
  border-bottom: 1px solid #d7deea;
  list-style: none;
}

.BonusHistoryTable-transaction:last-child {
  border-bottom: none;
}
