.Amount {
  display: inline-block;
  outline: none;
  text-align: center;
}

.Amount:focus {
}

.Amount--isFocused {
}

.Amount-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 1;
  color: inherit;
}

.Amount-button:disabled {
  cursor: default;
  color: #dbe1ec;
}

.Amount--isInputFocused .Amount-button {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.Amount-buttonStroke {
  display: flex;
  border: 1px solid #dbe1ec;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

/* .Amount--isInputFocused .Amount-button:hover {
  opacity: 1;
} */
