.ProductCardSlider_VideoMiniature {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ProductCardSlider_VideoMiniature-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  font-size: 32px;
  color: #fff;
}
