.GeneralForm-infoIcon {
  display: inline-block;
  margin-left: 24px;
  line-height: 1;
  vertical-align: middle;
}

.GeneralForm-phoneTooltipContent {
  display: inline-block;
  width: 240px;
}

.GeneralForm-phoneTooltipSeparator {
  display: block;
  margin: 4px 0;
}

.GeneralForm-phoneTooltipButton {
  display: inline;
  padding: 0;
  margin: 0;
  border: none;
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  outline: none;
  appearance: none;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}
