.FieldsetAddress {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.FieldsetAddress-streetCol {
  flex: 1 1 100%;
  padding: 8px;
  box-sizing: border-box;
}

.FieldsetAddress-houseCol {
  flex: 1 1 50%;
  padding: 8px;
  box-sizing: border-box;
}

.FieldsetAddress-apartmentCol {
  flex: 1 1 50%;
  padding: 8px;
  box-sizing: border-box;
}
