.ProblemSkin_Solutions {
  margin-bottom: 8vw;
}
@media (max-width: 1100px) {
  .ProblemSkin_Solutions {
    margin-bottom: 50px;
  }
}

.ProblemSkin_Solutions-title {
  font-weight: bold;
  font-size: 38px;
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: 120px;
  text-align: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_Solutions-title {
    font-size: 24px;
    margin: 64px 0 0;
  }
}

.ProblemSkin_Solutions-inner {
  position: relative;
}

.ProblemSkin_Solutions-photoSection {
  font-size: 10px;
  display: flex;
  justify-content: center;
}

@media (max-width: 660px) {
  .ProblemSkin_Solutions-photoSection {
    font-size: 1.5vw;
  }
}
.ProblemSkin_Solutions-outerCircle {
  margin: 1em;
  border-radius: 50%;
  border: 1px solid rgba(142, 51, 134, 0.2);
  display: inline-block;
}

.ProblemSkin_Solutions-innerCircle {
  margin: 8em;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid rgba(142, 51, 134, 0.3);
}

.ProblemSkin_Solutions-imageHolder {
  width: 44em;
  height: 44em;
  box-sizing: border-box;
  margin: 8em;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.ProblemSkin_Solutions-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.ProblemSkin_Solutions-listSection {
  padding-top: 24px;
}

/* @media(min-width:1250px) {
  .ProblemSkin_Solutions-listSection {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;bottom: 0;
    opacity: 0.7;
  }
} */

.ProblemSkin_Solutions-list {
}

.ProblemSkin_Solutions-item {
  max-width: 550px;
  margin: auto;
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  padding: 24px;
}
.ProblemSkin_Solutions-itemPointer {
  display: none;
}

@media (min-width: 1250px) {
  .ProblemSkin_Solutions-item {
    position: absolute;
    width: 300px;
    box-sizing: border-box;
    /* left: -50px; */
  }

  .ProblemSkin_Solutions-itemPointer {
    display: block;
    position: absolute;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .ProblemSkin_Solutions-item:nth-child(1) {
    top: 220px;
  }
  .ProblemSkin_Solutions-item:nth-child(1) .ProblemSkin_Solutions-item_Visual {
    top: -125px;
    right: -90px;
  }

  .ProblemSkin_Solutions-item:nth-child(1) .ProblemSkin_Solutions-itemPointer {
    width: 114px;
    height: 58px;

    top: -65px;
    left: 170px;
  }

  .ProblemSkin_Solutions-item:nth-child(2) {
    top: 500px;
  }
  .ProblemSkin_Solutions-item:nth-child(2) .ProblemSkin_Solutions-item_Visual {
    top: 60px;
    right: -180px;
  }
  .ProblemSkin_Solutions-item:nth-child(2) .ProblemSkin_Solutions-itemPointer {
    width: 102px;
    height: 29px;

    top: 160px;
    left: 310px;
  }

  .ProblemSkin_Solutions-item:nth-child(3) {
    top: 250px;
    right: 0;
  }
  .ProblemSkin_Solutions-item:nth-child(3) .ProblemSkin_Solutions-itemPointer {
    width: 114px;
    height: 58px;

    top: -65px;
    left: 40px;
  }

  .ProblemSkin_Solutions-item:nth-child(3) .ProblemSkin_Solutions-item_Visual {
    /* top: 60px; */
    left: -65px;
  }
  .ProblemSkin_Solutions-item:nth-child(4) {
    top: 600px;
    right: 0;
  }
  .ProblemSkin_Solutions-item:nth-child(4) .ProblemSkin_Solutions-item_Visual {
    top: -30px;
    left: -190px;
  }
  .ProblemSkin_Solutions-item:nth-child(4) .ProblemSkin_Solutions-itemPointer {
    width: 102px;
    height: 29px;

    top: 70px;
    left: -110px;
  }
}

.ProblemSkin_Solutions-item:last-child {
  margin-bottom: 0;
}

.ProblemSkin_Solutions-item_Visual {
}

@media (min-width: 1250px) {
  .ProblemSkin_Solutions-item_Visual {
    position: absolute;
    top: -120px;
  }
}

.ProblemSkin_Solutions-item_ImageHolder {
  width: 100px;
  height: 100px;
  padding: 16px;
  background: #ebebeb;
  border-radius: 50%;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.ProblemSkin_Solutions-item_Image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.ProblemSkin_Solutions-item_Info {
}
