.Social_FollowersCounter {
  display: inline-block;
  min-width: 100px;
  text-align: center;
}

.Social_FollowersCounter--lineDirection {
  display: flex;
  align-items: center;
}

.Social_FollowersCounter--size-small {
  min-width: 80px;
}

@media (max-width: 400px) {
  .Social_FollowersCounter {
    min-width: 80px;
  }
}

.Social_FollowersCounter-button {
  margin-bottom: 8px;
}

.Social_FollowersCounter--lineDirection .Social_FollowersCounter-button {
  margin-bottom: 0px;
  margin-right: 16px;
}

.Social_FollowersCounter--size-small .Social_FollowersCounter-button {
  margin-bottom: 4px;
}

@media (max-width: 400px) {
  .Social_FollowersCounter-button {
    margin-bottom: 4px;
  }
}

.Social_FollowersCounter-amount {
  font-size: 18px;
  font-weight: 300;
  color: #2f333b;
}

.Social_FollowersCounter--lineDirection .Social_FollowersCounter-amount {
  display: inline-block;
}

@media (max-width: 400px) {
  .Social_FollowersCounter-amount {
    font-size: 16px;
  }
}

.Social_FollowersCounter--size-small .Social_FollowersCounter-amount {
  font-size: 16px;
}
