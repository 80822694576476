.Questionnaire {
  padding-top: 24px;
  position: relative;
}

.Questionnaire-error {
  position: absolute;
  top: 0;
  left: 0;
  color: #e95958;
}

.Questionnaire-title {
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: bold;
}

.Questionnaire-description {
  font-size: 14px;
  max-width: 400px;
  color: #818690;
}

.Questionnaire-list {
  list-style-type: none;
  margin: 0;
  padding: 16px 0;
}

.Questionnaire-option {
  margin-bottom: 16px;
}

.Questionnaire-fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

.Questionnaire-legend {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
}

.Questionnaire-optionChoice {
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  display: flex;
}

@media (max-width: 960px) {
  .Questionnaire-optionChoice {
    flex-direction: column;
  }
}

.Questionnaire-optionChoiceVariant {
  padding: 8px 0;
  display: inline-flex;
  margin-right: 32px;
}

.Questionnaire-optionChoiceVariant:last-child {
  margin-right: 0;
}

@media (max-width: 960px) {
  .Questionnaire-optionChoiceVariant {
    margin-right: 0;
  }
}

.Questionnaire-optionChoiceVariantLabel {
}

.Questionnaire-submit {
}

@media (max-width: 840px) {
  .Questionnaire-submit {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 18px;
    background-color: #fff;
    box-shadow: 0px 0px 24px 0 rgba(47, 47, 47, 0.12);
    text-align: center;
  }
}

.Questionnaire-confirmDialog {
  padding: 32px;
}
