.Tile {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(48, 49, 54, 0.1);
  border-radius: 4px;
}

.Tile-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 8px;
  padding: 8px;
  padding-bottom: 0;
  flex-shrink: 0;
  flex-grow: 0;
}

@media screen and (max-width: 840px) {
  .Tile-header {
    margin: 4px;
    padding: 4px;
  }
}

.Tile-header--multiline {
  align-items: flex-start;
}

.Tile-title {
  margin: 0 16px;
  overflow: hidden;

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;

  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.Tile-header--multiline .Tile-title {
}

.Tile-actions {
  flex: 0 0 auto;
  color: #a1a6af;
  margin-left: auto;
  margin-right: 8px;
}

.Tile-item {
  display: inline-block;
  margin-right: 4px;
}

.Tile-item:last-child {
  margin-right: 0;
}

.Tile-contentHolder {
  padding: 32px;
  padding-top: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 840px) {
  .Tile-contentHolder {
    padding: 16px;
    padding-top: 0;
  }
}

.Tile-content {
  flex-grow: 1;
}
