.Subscribe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 64px 0;
}

@media (max-width: 640px) {
  .Subscribe {
    padding: 48px 0;
  }
}

@media (max-width: 1100px) {
  .Subscribe {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
}

.Subscribe-hint {
  font-size: 18px;
  font-weight: 700;
  flex: 0 1 auto;
  text-align: center;
}

@media (max-width: 1100px) {
  .Subscribe-hint {
    margin-bottom: 24px;
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .Subscribe-hint {
    margin-bottom: 24px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .Subscribe-hint {
    margin-bottom: 24px;
    font-size: 16px;
  }
}

.Subscribe-form {
  flex: 0 1 600px;
}

@media (max-width: 1100px) {
  .Subscribe-form {
    flex: 0 0 auto;
  }
}

.Subscribe-formContent {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 560px) {
  .Subscribe-formContent {
    display: flex;
    flex-direction: column;
  }
}

.Subscribe-field {
  flex: 1 1 auto;
}

@media (max-width: 560px) {
  .Subscribe-field {
    margin-bottom: 16px;
  }
}

.Subscribe-button {
  flex: 0 0 auto;
  margin-left: 16px;
}

@media (max-width: 560px) {
  .Subscribe-button {
    flex: 1 1 auto;
    margin-left: 0;
  }
}

.Subscribe-confirmation {
  flex: 1 1 auto;
}

.Subscribe-confirmation {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

@media (max-width: 560px) {
  .Subscribe-confirmation {
    align-items: flex-start;
  }
}
