.NavigationLink {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  font-size: inherit;
  color: inherit;
  margin: 0 -3px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 1.5;
  outline: none;
  font-weight: inherit;
  font-family: inherit;
}

@media screen and (max-width: 840px) {
  .NavigationLink {
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid #dbe1ec;
  }
}

@media screen and (max-width: 840px) {
}

.NavigationLink:hover {
  color: #666;
}

.NavigationLink-text {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px;
  font-weight: inherit;
}

.NavigationLink-text::after {
  content: '';
  position: absolute;
  display: block;
  background-color: currentColor;
  opacity: 0;
  height: 1px;
  right: 3px;
  left: 3px;
  bottom: 2px;
  transition: opacity 0.2s ease-out;
}

@media screen and (max-width: 840px) {
  .NavigationLink-text::after {
    content: none;
  }
}

.NavigationLink:active .NavigationLink-text::after {
  opacity: 0;
}

.NavigationLink:hover .NavigationLink-text::after {
  opacity: 1;
}

.NavigationLink-title {
}

.NavigationLink-label {
  margin-left: 8px;
}

.NavigationLink-icon {
}

@media (max-width: 840px) {
  .NavigationLink-icon {
    flex: 0 0 auto;
    display: inline-block;
    padding: 0 4px;
    line-height: 1;
  }
}
