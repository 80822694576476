.InlineField {
  cursor: default;
}

.InlineField-label {
  display: inline-block;
  margin-right: 32px;
  vertical-align: middle;
  color: #262931;
}

.InlineField-instance {
  display: inline-block;
  vertical-align: middle;
}

.InlineField-error {
  display: inline-block;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 300;
  color: #ff0000;
}
