.SearchOverlay_RecentProduct {
  display: flex;
  overflow: hidden;
  position: relative;
}

.SearchOverlay_RecentProduct-media {
  flex: 0 0 auto;
  width: 80px;
  box-sizing: content-box;
  padding: 16px;
}

@media (max-width: 480px) {
  .SearchOverlay_RecentProduct-media {
    width: 48px;
  }
}

.SearchOverlay_RecentProduct-text {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 16px 0;

  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.SearchOverlay_RecentProduct-price {
  flex: 0 0 auto;
  margin-left: auto;
  font-weight: 700;
  padding: 16px 0;
  padding-left: 16px;
  padding-right: 16px;
}

.SearchOverlay_RecentProduct-image {
}

.SearchOverlay_RecentProduct-description {
  color: inherit;
  text-decoration: none;
}

.SearchOverlay_RecentProduct-description::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.SearchOverlay_RecentProduct-title {
  color: #848fa5;
  opacity: 0.8;
}
