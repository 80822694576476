.PromoProduct {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  font-size: 14px;
  overflow: hidden;
}

.PromoProduct-imageCol {
  flex: 0 0 48px;
  max-width: 48px;
  margin-right: 8px;
  box-sizing: border-box;
}

.PromoProduct-image {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.PromoProduct-contentCol {
  flex: 1 1 auto;
}

.PromoProduct-description {
  margin-bottom: 4px;
  line-height: 1.4;
  color: #262931;
}

.PromoProduct-title {
  margin: 0;
  margin-bottom: 4px;
  line-height: 1.2;
}

.PromoProduct-link {
  font-weight: 300;
  color: #848fa5;
  text-decoration: none;
  font-size: 14px;
}

.PromoProduct-link:after {
  position: absolute;
  z-index: 1;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PromoProduct-selectedVariant {
  font-weight: 300;
  color: #848fa5;
}

.PromoProduct-actionCol {
  flex: 0 0 auto;
}

.PromoProduct-buttonHolder {
  position: relative;
  z-index: 10;
  top: -8px;
  padding: 8px;
}

.PromoProduct-button {
  display: inline-block;
  margin: 0;
  padding: 6px;
  border: 1px solid #dbe1ec;
  border-radius: 15px;
  line-height: 12px;
  font-size: 12px;
  background-color: transparent;
  color: inherit;
  vertical-align: top;
  appearance: none;
  outline: none;
}
