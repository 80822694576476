.AdditionalInformationFooter {
  display: flex;
  flex-wrap: wrap;
}

.AdditionalInformationFooter-item {
  flex: 0 0 25%;
  max-width: 25%;
}

.AdditionalInformationFooter-item--expanded {
  /* empty */
}

.AdditionalInformationFooter-item--orderChange {
  /* empty */
}

@media (max-width: 720px) {
  .AdditionalInformationFooter-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    text-align: center;
  }

  .AdditionalInformationFooter-item--expanded {
    order: 2;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 16px;
  }
}

@media (max-width: 624px) {
  .AdditionalInformationFooter-item {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    margin: 12px 0;
  }

  .AdditionalInformationFooter-item--orderCharge {
    order: 1;
  }

  .AdditionalInformationFooter-item--expanded {
    align-self: center;
  }
}

@media (max-width: 424px) {
  .AdditionalInformationFooter-item {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
}

/**/

.AdditionalInformationFooter-wrap {
  display: block;
}

@media (max-width: 424px) {
  .AdditionalInformationFooter-wrap {
    display: flex;
  }
}

@media (max-width: 400px) {
  .AdditionalInformationFooter-wrap {
    display: block;
  }
}

.AdditionalInformationFooter-content {
  margin-bottom: 16px;
}

@media (max-width: 424px) {
  .AdditionalInformationFooter-content {
    padding: 0;
  }
}

@media (max-width: 424px) {
  .AdditionalInformationFooter-content {
    margin-right: 8px;
  }
}

@media (max-width: 624px) {
  .AdditionalInformationFooter-content {
    margin-bottom: 8px;
  }
}

@media (max-width: 424px) {
  .AdditionalInformationFooter-content {
    margin-bottom: 0;
    line-height: 40px;
  }
}

@media (max-width: 400px) {
  .AdditionalInformationFooter-content {
    margin-bottom: 8px;
    line-height: 24px;
  }
}

.AdditionalInformationFooter-visualPart {
  cursor: default;
  margin: -8px -4px;
  color: #262931;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.AdditionalInformationFooter-icon {
  display: inline-block;
  margin-right: 8px;
  color: #000;
}

.AdditionalInformationFooter-icon:last-child {
  margin-right: 0;
}

.AdditionalInformationFooter-iconHolder {
  display: inline-block;
  margin-right: 8px;
  height: 40px;
  width: 40px;
  border: solid 1px #d1d5da;
  box-sizing: border-box;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  border-radius: 20px;
}

.AdditionalInformationFooter-iconHolder:last-child {
  margin-right: 0;
}

.AdditionalInformationFooter-iconSizer {
  height: 35px;
  padding: 8px 4px;
  display: inline-block;
}
/* .AdditionalInformationFooter-iconSizer:last-child {
  margin-right: 0;
} */
