@font-face {
  font-family: 'Proxima Nova';
  src: url(assets/fonts/ProximaNova/ProximaNova-Regular.woff2) format('woff2'),
    url(assets/fonts/ProximaNova/ProximaNova-Regular.woff) format('woff');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(assets/fonts/ProximaNova/ProximaNova-Bold.woff2) format('woff2'),
    url(assets/fonts/ProximaNova/ProximaNova-Bold.woff) format('woff');
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(assets/fonts/ProximaNova/ProximaNova-Light.woff2) format('woff2'),
    url(assets/fonts/ProximaNova/ProximaNova-Light.woff) format('woff');
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Bold Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.eot');
  src: local('Akzidenz-Grotesk Pro BoldCndIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldExIt.eot');
  src: local('Akzidenz-Grotesk Pro BoldExtIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldExIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldExIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldExIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldExIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldExIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.eot');
  src: local('Akzidenz-Grotesk Pro Cnd'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Bold.eot');
  src: local('Akzidenz-Grotesk Pro Bold'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Bold'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Bold.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Bold.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Light Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.eot');
  src: local('Akzidenz-Grotesk Pro LightCndIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Light Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightEx.eot');
  src: local('Akzidenz-Grotesk Pro Light Ext'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightEx'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightEx.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightEx.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightEx.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightEx.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Med Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdEx.eot');
  src: local('Akzidenz-Grotesk Pro Med Ext'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdEx'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdEx.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdEx.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdEx.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdEx.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.eot');
  src: local('Akzidenz-Grotesk Pro CndIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Light.eot');
  src: local('Akzidenz-Grotesk Pro Light'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Light'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Light.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Light.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Bold Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.eot');
  src: local('Akzidenz-Grotesk Pro Bold Cnd'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro XBd Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.eot');
  src: local('Akzidenz-Grotesk Pro XBdCndIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Super';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Super.eot');
  src: local('Akzidenz-Grotesk Pro Super'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Super'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Super.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Super.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Super.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Super.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Med';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Md.eot');
  src: local('Akzidenz-Grotesk Pro Med'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Md'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Md.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Md.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Md.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-ExtItalic.eot');
  src: local('Akzidenz-Grotesk Pro ExtItalic'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-ExtItalic'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-ExtItalic.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-ExtItalic.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-ExtItalic.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-ExtItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Med';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdIt.eot');
  src: local('Akzidenz-Grotesk Pro MedItal'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-It.eot');
  src: local('Akzidenz-Grotesk Pro Italic'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-It'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-It.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-It.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-It.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdIt.eot');
  src: local('Akzidenz-Grotesk Pro ExtraBldIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldEx.eot');
  src: local('Akzidenz-Grotesk Pro Bold Ext'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldEx'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldEx.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldEx.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldEx.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldEx.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Med Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.eot');
  src: local('Akzidenz-Grotesk Pro MedCndIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldIt.eot');
  src: local('Akzidenz-Grotesk Pro BoldIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBd.eot');
  src: local('Akzidenz-Grotesk Pro ExtraBold'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBd'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBd.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBd.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBd.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBd.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Light Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.eot');
  src: local('Akzidenz-Grotesk Pro Light Cnd'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Med Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MedExtIt.eot');
  src: local('Akzidenz-Grotesk Pro MedExtIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MedExtIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MedExtIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MedExtIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MedExtIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MedExtIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Super';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-SuperItalic.eot');
  src: local('Akzidenz-Grotesk Pro SuperIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-SuperItalic'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-SuperItalic.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-SuperItalic.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-SuperItalic.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-SuperItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Ext.eot');
  src: local('Akzidenz-Grotesk Pro Ext'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Ext'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Ext.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Ext.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Ext.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Ext.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro XBd Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.eot');
  src: local('Akzidenz-Grotesk Pro XBd Cnd'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Regular.eot');
  src: local('Akzidenz-Grotesk Pro Regular'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Regular'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Regular.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Regular.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Med Cnd';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.eot');
  src: local('Akzidenz-Grotesk Pro Med Cnd'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightIt.eot');
  src: local('Akzidenz-Grotesk Pro LightIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Light Ext';
  src: url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LigExtIt.eot');
  src: local('Akzidenz-Grotesk Pro LightExtIt'),
    local('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LigExtIt'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LigExtIt.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LigExtIt.woff2') format('woff2'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LigExtIt.woff') format('woff'),
    url('assets/fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LigExtIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'RFDewi';
  src: url('assets/fonts/RFDewi/RFDewiExtended-Black.eot');
  src: local('RFDewi'), local('assets/fonts/RFDewi/AkzidenzGroteskPro-LigExtIt'),
    url('assets/fonts/RFDewi/RFDewiExtended-Black.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/RFDewi/RFDewiExtended-Black.woff2') format('woff2'),
    url('assets/fonts/RFDewi/RFDewiExtended-Black.woff') format('woff'),
    url('assets/fonts/RFDewi/RFDewiExtended-Black.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'RF Dewi';
  src: url('assets/fonts/RFDewi/RFDewi-BlackItalic.woff2') format('woff2'),
    url('assets/fonts/RFDewi/RFDewi-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

/*
@font-face {
  font-family: 'PT Serif';
  src: url(assets/fonts/PTSerif/PTSerif-Regular.woff2) format('woff2'),
    url(assets/fonts/PTSerif/PTSerif-Regular.woff) format('woff');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'PT Serif';
  src: url(assets/fonts/PTSerif/PTSerif-Bold.woff2) format('woff2'),
    url(assets/fonts/PTSerif/PTSerif-Bold.woff) format('woff');
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: 'PT Serif';
  src: url(assets/fonts/PTSerif/PTSerif-Italic.woff2) format('woff2'),
    url(assets/fonts/PTSerif/PTSerif-Italic.woff) format('woff');
  font-style: italic;
  font-display: fallback;
} */

body {
  margin: 0;
  padding: 0;
  font-family: Proxima Nova, sans-serif;
  color: #262931;
  line-height: 1.6;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #262931;
}

input[autocomplete='off']::-webkit-credentials-auto-fill-button,
input[autocomplete='off']::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
  display: none;
}

.BODY_product #jvlabelWrap,
.BODY_product #jivo-player,
.BODY_product #jcont,
.BODY_product jdiv {
  display: none !important;
}

.BODY_drawRollUpOpen #jvlabelWrap,
.BODY_drawRollUpOpen #jivo-player,
.BODY_drawRollUpOpen #jcont,
.BODY_drawRollUpOpen jdiv {
  display: none !important;
}
