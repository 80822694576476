.IconCross {
  width: 1em;
  height: 1em;
  vertical-align: top;
  position: relative;
  display: inline-block;
  color: inherit;
}

.IconCross:before,
.IconCross:after {
  content: '';
  display: inline-block;
  color: inherit;
  background-color: currentColor;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 50%;
  top: 50%;
}

.IconCross:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.IconCross:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
