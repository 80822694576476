.OrderScene {
}

.OrderScene-filter {
  margin-bottom: 50px;
}

.OrderScene-table {
}

.OrderScene-emptyComment {
  margin-bottom: 32px;
}

@media (max-width: 840px) {
  .OrderScene-emptyComment {
    text-align: center;
  }
}

.OrderScene-emptyButton {
  text-align: center;
}

.OrderScene-loader {
  position: relative;
  padding: 120px 0;
}
