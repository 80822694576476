.Consistency {
}

.Consistency-title {
  font-size: 24px;
}

.Consistency-ingredientLists {
  margin-bottom: 48px;
}

.Consistency-legend {
}
