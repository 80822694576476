.LB_Address {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  padding-right: 42px;
  padding-left: 42%;
  position: relative;
  /* z-index: -1; */
}

@media (max-width: 840px) {
  .LB_Address {
    padding-left: 25px;
    padding-right: 0;
  }
}

.LB_Address::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  bottom: -8px;
  border: 2px solid #000;
  z-index: -1;
  transform: skew(-15deg);
}

.LB_Address::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  bottom: -8px;
  border-right: 2px solid #000;
  z-index: 1;
  transform: skew(-15deg);
}

.LB_Address-iconHolder {
  margin: 0 16px;
}

.LB_Address-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #a04ab0;
  border-radius: 50%;
  color: #fff;
  box-sizing: border-box;
}

.LB_Address-list {
}

.LB_Address-item {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  font-size: 12px;
}
