.VolumeSwitch {
  display: flex;
  margin: -5px;
  position: relative;
}

.VolumeSwitch-item {
  margin: 5px;
}

.VolumeSwitch-option {
  border: 1px solid #cacacc;
  padding: 5px 20px;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  outline: none;
}

.VolumeSwitch-option--small {
  border: 1px solid #dbe1ec;
  padding: 0px 8px;
  font-size: 14px;
}

.VolumeSwitch-option:hover {
}

.isTabUsing .VolumeSwitch-option:focus {
  box-shadow: 0 0 0 3px #ffd90144;
}

.VolumeSwitch-option--disabled {
  color: #d9d9db;
  cursor: not-allowed;
  border-color: #d9d9db;
  position: relative;
}

.VolumeSwitch-option--checked {
  border-color: #ffd801;
  cursor: default;
}

.VolumeSwitch-option--single {
  cursor: default;
}

.VolumeSwitch-option--disabled:hover::after {
  opacity: 1;
}

.VolumeSwitch-num {
}

.VolumeSwitch-unit {
}

.VolumeSwitch-option--disabled:focus {
  outline: none;
}

.isTabUsing .VolumeSwitch-cross {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: inherit;
  stroke: currentColor;
  stroke-width: 1;
}
