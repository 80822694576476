.FooterContacts {
  display: flex;
  flex-direction: column;
}

.FooterContacts-tel {
  display: inline-block;
  margin-bottom: 12px;
  line-height: 20px;
  font-size: 18px;
  color: #262931;
  text-decoration: none;
  pointer-events: none;
  background-color: transparent;
  transition: background-color 0.2s ease-out;
}

@media (max-width: 1100px) {
  .FooterContacts-tel {
    margin-bottom: 24px;
    padding: 12px 32px;
    border: 1px solid #e8eaec;
    border-radius: 25px;
    pointer-events: auto;
  }
}

.FooterContacts-icon {
  display: none;
}

@media (max-width: 1100px) {
  .FooterContacts-icon {
    display: inline-block;
    margin-right: 12px;
    vertical-align: middle;
  }
}

.FooterContacts-number {
  display: inline-block;
  line-height: 24px;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 640px) {
  .FooterContacts-number {
    font-size: 18px;
  }
}

.FooterContacts-schedule {
  display: inline-block;
  line-height: 16px;
  font-size: 14px;
  color: #818690;
}

@media (max-width: 1100px) {
  .FooterContacts-schedule {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .FooterContacts-schedule {
    font-size: 14px;
  }
}
