.SectionReviewHeader {
  margin-bottom: 42px;
  padding-top: 16px;
}

.SectionReviewHeader-summary {
}

.SectionReviewHeader-setReview {
  text-align: center;
  margin-top: 24px;
}

.SectionReviewHeader-title {
  text-align: center;
  font-size: 24px;
}
