.SearchField {
  position: relative;
  max-width: 240px;
  transition: max-width 0.1s ease-out;
}

.SearchField--wide {
  max-width: 320px;
}

.SearchField-button {
  position: absolute;
  top: 50%;
  right: 14px;
  border-radius: 16px;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  transform: translateY(-50%);
  cursor: pointer;
  outline: none;
  transition: all 0.2s linear;
  color: #8e939d;
  padding: 0;
}

.SearchField-button:hover {
  /*background-color: rgba(235, 235, 235, 0.5);*/
  color: #000;
}

.SearchField-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px;
}
