.CitySelectDropdown {
  padding: 24px;
}

.CitySelectDropdown-title {
  font-size: 18px;
  margin-bottom: 16px;
}

.CitySelectDropdown-city {
  font-weight: bold;
}

.CitySelectDropdown-controls {
  display: flex;
  align-items: center;
  margin: -8px;
  margin-bottom: 10px;
}
.CitySelectDropdown-control {
  padding: 8px;
  flex: 0 0 auto;
  box-sizing: border-box;
  min-width: 100px;
}

.CitySelectDropdown-info {
  font-size: 14px;
  color: #a2a7b0;
}
