.CitiesWithShopsBottomSheet {
  padding-top: 24px;
}

.CityChoiceBottomSheet-list {
  margin: 0;
  padding: 0;
}

.CityChoiceBottomSheet-item {
  list-style: none;
}

.CityChoiceBottomSheet-item--active {
}

.CitiesWithShopsBottomSheet-button {
  display: inline-block;
  width: 100%;
  padding: 8px;
  margin: 0;
  border: none;
  font-size: 14px;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  box-sizing: border-box;
  appearance: none;
  text-align: left;
  outline: none;
}

.CityChoiceBottomSheet-item--active .CitiesWithShopsBottomSheet-button {
  font-weight: 700;
}

.CitiesWithShopsBottomSheet-country {
  color: #9197a1;
}
