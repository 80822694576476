.GeneralTile {
  display: flex;
  align-items: center;
}

.GeneralTile-avatarEditor {
}

.GeneralTile-avatarHolder {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

@media screen and (max-width: 840px) {
  .GeneralTile-avatarHolder {
    width: 60px;
    height: 60px;
  }
}

.GeneralTile-avatarEditorOverlay {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  line-height: 150px;
  opacity: 0;
  transform: scale(1.3);
  border-radius: 50%;
  background-color: rgba(133, 144, 166, 0.5);
  box-shadow: inset 0px 0px 35px 15px rgba(133, 144, 166, 1);
  width: 100%;
  height: 100%;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out;
}

@media screen and (max-width: 840px) {
  .GeneralTile-avatarEditorOverlay {
    font-size: 12px;
    line-height: 60px;
  }
}

.GeneralTile-avatarHolder:hover .GeneralTile-avatarEditorOverlay {
  opacity: 1;
  transform: scale(1);
}

.GeneralTile-noLoadPhoto .GeneralTile-avatarHolder {
  box-shadow: 0 0 10px 0 rgba(48, 49, 54, 0.22);
}

.GeneralTile-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-weight: bold;
  transition: 0.2s transform ease-out;
}

.GeneralTile-uploadLabel {
}

.GeneralTile-avatarHolder:hover .GeneralTile-uploadLabel {
  bottom: 0;
}

.GeneralTile-uploadInput {
  position: absolute;
  top: -999999px;
}
.GeneralTile-info {
  margin-left: 40px;
}

@media screen and (max-width: 840px) {
  .GeneralTile-info {
    margin-left: 16px;
  }
}

.GeneralTile-name {
  font-size: 24px;
}

@media screen and (max-width: 840px) {
  .GeneralTile-name {
    font-size: inherit;
  }
}

.GeneralTile-login {
  display: flex;
  align-items: center;
  font-size: 14px;
}

@media screen and (max-width: 840px) {
  .GeneralTile-login {
    font-size: 12px;
  }
}

.GeneralTile-loginTitle {
  margin-right: 10px;
  color: #818690;
}

.GeneralTile-loginData {
  font-weight: bold;
  color: #444952;
}

/* .GeneralTile-imagePlaceholder {
  text-align: center;
  position: relative;

  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 95px;
  transition: 0.2s font-size ease-out;
  text-transform: uppercase;
}

@media screen and (max-width: 840px) {
  .GeneralTile-imagePlaceholder {
    line-height: 60px;
    font-size: 40px;
  }
} */

.GeneralTile-avatar {
  font-size: 95px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 840px) {
  .GeneralTile-avatar {
    font-size: 40px;
  }
}
