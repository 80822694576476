.LB_AddressNew {
  display: flex;
  /* align-items: center; */
  padding: 16px;
  background-color: #fff;
  padding-right: 42px;
  padding-left: 5%;
  position: relative;
  /* z-index: -1; */
}

@media (max-width: 840px) {
  .LB_AddressNew {
    padding-left: 25px;
    padding-right: 16px;
  }
}

.LB_AddressNew::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  bottom: -22px;
  border: 2px solid #000;
  z-index: -1;
  transform: skew(-15deg);
}

.LB_AddressNew::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  bottom: -22px;
  border-right: 2px solid #000;
  z-index: 1;
  transform: skew(-15deg);
}

.LB_AddressNew-iconHolder {
  margin: 0 16px;
}

@media (max-width: 840px) {
  .LB_AddressNew-iconHolder {
    margin: 0 8px;
  }
}

.LB_AddressNew-icon {
  /* margin-top: 24px; */
  margin-top: 8px;
  margin-right: 16px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #a04ab0;
  border-radius: 50%;
  color: #fff;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .LB_AddressNew-icon {
    margin-top: 0;
    margin-right: 8px;
    width: 50px;
    height: 50px;
    padding: 10px;
  }
}

.LB_AddressNew-main {
}

.LB_AddressNew-title {
  line-height: 1.4;
  font-size: 28px;
  text-transform: uppercase;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  margin-bottom: 16px;
}

.LB_AddressNew-list {
}

.LB_AddressNew-item {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  font-size: 16px;
  margin-bottom: 16px;
}

.LB_AddressNew-itemDate {
  /* display: inline-block; */
  margin-right: 16px;
}

.LB_AddressNew-itemLocation {
  /* display: inline-block; */
}
