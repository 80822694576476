.NavigationLabel {
  background-color: #ffd200;
  display: inline-block;
  border-radius: 25px;
  padding: 2px 10px;
  line-height: 1.2;
  vertical-align: middle;
  font-size: 0.875em;
  color: #222222;
}
