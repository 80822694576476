.MainTabs {
}

.MainTabs--titlesAlignment-center {
}

.MainTabs--titlesAlignment-left {
}

.MainTabs--titlesAlignment-right {
}

.MainTabs-list {
  margin-bottom: 8px;
}

@media (max-width: 840px) {
  .MainTabs-list {
    display: flex;
    overflow-x: auto;
  }
}

.MainTabs--titlesAlignment-center .MainTabs-list {
  display: flex;
  justify-content: center;
}

.MainTabs--titlesAlignment-right .MainTabs-list {
  display: flex;
  justify-content: flex-end;
}

.MainTabs-tab {
  border: none;
  outline: none;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
  color: #000;
  margin-right: 40px;
  padding: 8px 0;
  font-size: inherit;
  font-family: inherit;
}

.MainTabs-tab:last-child {
  margin-right: 0;
}

.MainTabs-tab:after {
  content: '';
  position: absolute;
  display: block;
  height: 3px;
  bottom: 0;
  width: 24px;
  left: 50%;
  margin-left: -12px;
  transform: scaleX(0);
  background-color: #ffd12c;
  transition: transform 0.2s ease-out;
}

.MainTabs-tab:focus {
}

.MainTabs-tab:hover::after {
  transform: scaleX(1);
}

.MainTabs-tab--isActive {
  cursor: default;
}

.MainTabs-tab--isActive:after {
  transform: scaleX(1);
}

@media (max-width: 840px) {
  .MainTabs-tab {
    flex: 0 0 auto;
    margin-right: 24px;
  }
}
