.SizeSwitch {
  display: inline-flex;
  margin: -4px;
}

.SizeSwitch-item {
  padding: 4px;
}

.SizeSwitch-option {
  border: 1px solid #cacacc;
  padding: 8px 12px;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  line-height: 22px;
  font-size: 14px;
  outline: none;
  min-width: 40px;
  box-sizing: border-box;
  text-align: center;
}

.SizeSwitch-option:hover {
}

.isTabUsing .SizeSwitch-option:focus {
  box-shadow: 0 0 0 4px #ffd02844;
}

.SizeSwitch-option--disabled {
  color: #d9d9db;
  cursor: not-allowed;
  border-color: #d9d9db;
  position: relative;
  /* background: linear-gradient(to top right, transparent calc(50% - 1px), #d9d9db, transparent calc(50% + 1px)); */
}

.SizeSwitch-option--checked {
  border-color: #ffd228;
  font-weight: bold;
  cursor: default;
}

.SizeSwitch-option--disabled:hover::after {
  opacity: 1;
}

.isTabUsing .SizeSwitch-option--disabled:focus {
  outline: none;
  box-shadow: none;
}

.SizeSwitch-value {
  margin: -0.03em;
}

.SizeSwitch-cross {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: inherit;
  stroke: currentColor;
  stroke-width: 1;
}
