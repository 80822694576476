/*  UnauthorizedMenu  */
.UnauthorizedMenu {
  display: flex;
}

.UnauthorizedMenu-item {
  position: relative;
}
.UnauthorizedMenu-item:first-child::after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  background-color: #ebf0f6;
  height: 45%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.UnauthorizedMenu-item:last-child {
  flex-grow: 1;
}

.UnauthorizedMenu-link {
}
