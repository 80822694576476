@media (max-width: 420px) {
  .BonusConditions {
    font-size: 14px;
  }
}

.BonusConditions-appealContent {
  font-size: 24px;
}

.BonusConditions p {
  font-size: 16px;
}

.BonusConditions ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.BonusConditions li {
  margin-bottom: 8px;
}

.BonusConditions-tableContainer {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.BonusConditions-table {
  border: 1px solid #000;
  flex: 0 1 auto;
  display: flex;
}

.BonusConditions-tableColumn {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #000;
}

.BonusConditions-tableColumn:last-child {
  border: none;
}

.BonusConditions-tableHeaderCell {
  padding: 8px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #000;
}

@media (max-width: 932px) {
  .BonusConditions-tableHeaderCell {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .BonusConditions-tableHeaderCell {
    font-size: 14px;
    padding: 4px;
    height: 72px;
  }
}

@media (max-width: 360px) {
  .BonusConditions-tableHeaderCell {
    font-size: 12px;
  }
}

.BonusConditions-tableCell {
  padding: 8px;
  border-bottom: 1px solid #000;
}

@media (max-width: 932px) {
  .BonusConditions-tableCell {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  .BonusConditions-tableCell {
    height: 164px;
  }
}

@media (max-width: 480px) {
  .BonusConditions-tableCell {
    font-size: 14px;
    padding: 4px;
  }
}

@media (max-width: 360px) {
  .BonusConditions-tableCell {
    font-size: 12px;
  }
}

.BonusConditions-tableCell:last-child {
  border: none;
}
