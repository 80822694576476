.IngredientDetails {
  display: flex;
  margin: -24px;
}

@media (max-width: 1100px) {
  .IngredientDetails {
    flex-direction: column;
  }
}

.IngredientDetails-previewCol {
  flex: 0 0 480px;
  max-width: 480px;
  padding: 24px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .IngredientDetails-previewCol {
    flex: 1 1 auto;
    max-width: none;
    display: flex;
    align-items: center;
    margin: -16px;
  }
}

@media (max-width: 640px) {
  .IngredientDetails-previewCol {
    flex-direction: column;
    align-items: stretch;
  }
}

.IngredientDetails-visualGroup {
  margin-bottom: 48px;
}

@media (max-width: 1100px) {
  .IngredientDetails-visualGroup {
    flex: 0 0 400px;
    max-width: 400px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 0;
  }
}

@media (max-width: 800px) {
  .IngredientDetails-visualGroup {
    position: relative;
    flex: 0 0 320px;
    max-width: 320px;
    box-sizing: border-box;
    margin-bottom: 0;
  }
}

@media (max-width: 640px) {
  .IngredientDetails-visualGroup {
    flex: 0 1 auto;
    max-width: none;
  }
}

.IngredientDetails-imageHolder {
}

.IngredientDetails-image {
  width: 100%;
  display: block;
  height: auto;
}

.IngredientDetails-propertyHolder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.IngredientDetails-property {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 1100px) {
  .IngredientDetails-property {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .IngredientDetails-property {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .IngredientDetails-property {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .IngredientDetails-property {
    font-size: 14px;
  }
}

.IngredientDetails-line {
  width: 32px;
  border-top: 1px solid #fed835;
  border-bottom: 1px solid #fed835;
  margin-right: 16px;
}

.SectionIngredients-contentGroup {
}

@media (max-width: 1100px) {
  .SectionIngredients-contentGroup {
    flex: 1 1 auto;
    padding: 16px;
  }
}

.IngredientDetails-descriptionCol {
  flex: 1 1 auto;
  padding: 24px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .IngredientDetails-descriptionCol {
    margin-top: 32px;
  }
}

@media (max-width: 640px) {
  .IngredientDetails-descriptionCol {
    margin-top: 16px;
  }
}
