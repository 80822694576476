.OpeningAction {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 3%;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .OpeningAction {
    flex-direction: column;
    padding-left: 0;
  }
}

.OpeningAction--inverted {
  flex-direction: row-reverse;
  padding-right: 3%;
  padding-left: 0;
}

@media (max-width: 840px) {
  .OpeningAction--inverted {
    flex-direction: column;
    padding-right: 0;
  }
}

.OpeningAction-imageHolder {
  margin-right: 50px;
  position: relative;
  flex-basis: 50%;
  min-width: 250px;
  /* animation: rotateQ 2s ease infinite; */
}

@media (max-width: 840px) {
  .OpeningAction-imageHolder {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
/* 
.OpeningAction-imageHolder::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(-6%, 3%);
  border: 2px solid #a04ab0;
  z-index: 1;
  border-radius: 50%;
} */

/* .OpeningAction--inverted .OpeningAction-imageHolder::after {
  transform: translate(6%, -3%);
}

.OpeningAction-imageHolder::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(-3%, -6%);
  border: 2px solid #46cacc;
  z-index: 1;
  border-radius: 50%;
} */

/* .OpeningAction--inverted .OpeningAction-imageHolder::before {
  transform: translate(3%, 6%);
} */

.OpeningAction--inverted .OpeningAction-imageHolder {
  margin-left: 50px;
  margin-right: 0;
}

@media (max-width: 840px) {
  .OpeningAction--inverted .OpeningAction-imageHolder {
    margin-left: 0;
  }
}

.OpeningAction-image {
  /* background-color: #fff; */
  max-width: 600px;
  min-width: 200px;
  /* border-radius: 50%;
  padding: 35px; */
  position: relative;
  z-index: 2;
}

@media (max-width: 840px) {
  .OpeningAction-image {
    /* max-width: 200px; */
  }
}

.OpeningAction-title {
  flex-grow: 1;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
}

.OpeningAction--light .OpeningAction-title {
  color: #fff;
}

@media (max-width: 840px) {
  .OpeningAction-title {
    margin-bottom: 32px;
    font-size: 14px;
    text-align: center;
  }
}

@keyframes rotateQ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
