.SectionArticleDetails {
  margin: 64px 0;
}

@media (max-width: 840px) {
  .SectionArticleDetails {
    margin: 32px 0;
  }
}

@media (max-width: 640px) {
  .SectionArticleDetails {
    margin: 24px 0;
  }
}

@media (max-width: 480px) {
  .SectionArticleDetails {
    margin: 16px 0;
  }
}
