.InstagramGalleryTile {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  outline: none;
  position: relative;
  vertical-align: middle;
  border-radius: 3px;
  overflow: hidden;
}

.InstagramGalleryTile-image {
  width: 100%;
  background-position: center;
  background-size: cover;
  transition: 0.7s ease-out;
  position: relative;
}

.InstagramGalleryTile-image::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #262932;
  opacity: 0.3;
  transition: opacity 0.5s;
  z-index: 1;
}

.isTabUsing .InstagramGalleryTile:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(47, 124, 192, 0.54);
}

.isTabUsing .InstagramGalleryTile:focus .InstagramGalleryTile-image {
  transform: scale(1.1);
}

.isTabUsing .InstagramGalleryTile:focus .InstagramGalleryTile-image::before {
  opacity: 0;
}

.InstagramGalleryTile:hover .InstagramGalleryTile-image::before {
  opacity: 0;
}

.InstagramGalleryTile:hover .InstagramGalleryTile-image {
  transform: scale(1.1);
}

.InstagramGalleryTile-icon {
  position: absolute;
  z-index: 100;
  color: #fff;
  right: 12px;
  top: 12px;
}

.InstagramGalleryTile-userName {
  position: absolute;
  z-index: 100;
  bottom: 12px;
  left: 12px;
  color: #fff;
  text-decoration: none;
}
