.OrderSummary {
}

.OrderSummary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.OrderSummary-item:last-child {
  margin-bottom: 0;
}

.OrderSummary-title {
}

.OrderSummary-value {
}

.OrderSummary-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 840px) {
  .OrderSummary-total {
    margin-top: 8px;
  }
}

.OrderSummary-totalTitle {
}

.OrderSummary-totalValue {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
}
