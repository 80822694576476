.PasswordChangeScene {
  /* position: relative; */
}

.PasswordChangeScene-header {
  position: relative;
}

.PasswordChangeScene-title {
  margin-bottom: 36px;
  margin-top: 16px;
  font-size: 36px;
  letter-spacing: 0.9px;
  text-align: center;
}

@media (max-width: 840px) {
  .PasswordChangeScene-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.PasswordChangeScene-errorMessage {
  position: absolute;
  text-align: center;
  color: #e95958;
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
}

@media (max-width: 840px) {
  .PasswordChangeScene-errorMessage {
    top: 30px;
  }
}

.PasswordChangeScene-form {
  max-width: 450px;
  margin: auto;
  text-align: center;
}
