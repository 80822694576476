.ToastStack {
  position: fixed;
  z-index: 600;
  top: 0;
  right: 0;
  padding: 16px;
}

.ToastStack--alignment-left {
  right: auto;
  left: 0;
}

.ToastStack--alignment-center {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.ToastStack--alignment-right {
}

.ToastStack--verticalAlignment-top {
}

.ToastStack--verticalAlignment-bottom {
  top: auto;
  bottom: 0;
}

.ToastStack-item {
  max-width: 420px;
  min-width: 240px;
  margin-bottom: 16px;
  animation-name: toast-entering-left;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.ToastStack--alignment-left .ToastStack-item {
  animation-name: toast-entering-right;
}

.ToastStack--alignment-center .ToastStack-item {
  animation-name: toast-entering-right;
}

.ToastStack--alignment-center.ToastStack--verticalAlignment-top .ToastStack-item {
  animation-name: toast-entering-top;
}

.ToastStack--alignment-center.ToastStack--verticalAlignment-bottom .ToastStack-item {
  animation-name: toast-entering-top;
}

.ToastStack-item--exiting {
  animation-name: toast-exiting-right;
}

.ToastStack--alignment-left .ToastStack-item--exiting {
  animation-name: toast-exiting-left;
}

.ToastStack--alignment-center.ToastStack--verticalAlignment-top .ToastStack-item--exiting {
  animation-name: toast-exiting-left;
}

.ToastStack--alignment-center.ToastStack--verticalAlignment-bottom .ToastStack-item--exiting {
  animation-name: toast-exiting-left;
}

.ToastStack-item:last-child {
  margin-bottom: 0;
}

@keyframes toast-entering-left {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes toast-entering-bottom {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toast-entering-top {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes toast-exiting-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(50%);
    opacity: 0;
  }
}

@keyframes toast-exiting-left {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-50%);
    opacity: 0;
  }
}
