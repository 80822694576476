.ButtonFavorite {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  outline: none;
  border-radius: 50%;
  padding: 8px;
  margin: -8px;
  box-sizing: content-box;
  vertical-align: top;
  line-height: 1;
}

.ButtonFavorite-iconWrapper {
  line-height: inherit;
  display: inline-block;
  vertical-align: top;
}

.ButtonFavorite--active {
  color: #e95958;
}

.ButtonFavorite:hover {
  color: #e95958;
}

.isTabUsing .ButtonFavorite:focus {
  outline: none;
  box-shadow: 0 0 0 2px #edf0f5;
}
