.UserPhoto {
  position: relative;
  width: 80px;
  height: 80px;
}

@media (max-width: 1100px) {
  .UserPhoto {
    width: 60px;
    height: 60px;
  }
}

.UserPhoto-diagram {
  width: 100%;
  height: 100%;
}

.UserPhoto-holder {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 32px;
}

.UserPhoto-placeholder {
  background-color: #e5ecf2;
  height: 100%;
  width: 100%;
  background-color: #e5ecf2;
  text-align: center;
  line-height: 80px;
  font-weight: bold;
  font-size: 32px;
}

.UserPhoto--noDiagram .UserPhoto-holder {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.UserPhoto-image {
  width: 100%;
  vertical-align: top;
}

.UserPhoto-circle {
  display: block;
  width: 100%;
  height: 100%;
  color: #ffd12c;
}

.UserPhoto-circle-trail {
  stroke: #ebedf5 !important;
}

.UserPhoto-circle-path {
  color: inherit;
  stroke: currentColor !important;
}
