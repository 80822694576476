.CityChoiceButton {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
  font: inherit;
  font-weight: 700;
  border: none;
  border-top: 1px solid #ebf0f6;
  width: 100%;
  text-align: left;
  background: none;
  margin: 0;
  padding: 12px;
  appearance: none;
}

body:not(.isTabUsing) .CityChoiceButton:focus {
  outline: none;
}

.CityChoiceButton-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin-right: 8px;
}

.CityChoiceButton-title {
  display: inline-block;
  vertical-align: middle;
}
