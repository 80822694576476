.ListReview {
}

.ListReview-sortOptions {
  border-bottom: 1px solid #edf0f5;
  padding: 16px 0;
}
.ListReview-list {
}

.ListReview-listItem {
  border-bottom: 1px solid #edf0f5;
  padding: 16px 0;
}

.ListReview-pagination {
  margin-top: 32px;
}
