.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
}

.Loader--size-small {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}

.Loader--size-large {
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
}

.Loader--type-default {
  animation: roll 4s 0.5s ease-in-out infinite;
}

.Loader_Goo {
  width: 48px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.Loader--type-spin {
  animation: spin 2s 0s ease-in-out infinite;
}

.Loader-inner {
  width: 48px;
  height: 48px;
  position: absolute;
  transform-origin: 50% 50%;
}

.Loader--type-default .Loader-inner {
  animation: small-in 4s 0.5s ease-out infinite;
}

.Loader--size-small .Loader-inner {
  width: 24px;
  height: 24px;
}

.Loader--size-large .Loader-inner {
  width: 100px;
  height: 100px;
}

.Loader-part {
  border: solid 24px transparent;
  width: 0px;
  height: 0px;
  position: absolute;
  box-sizing: border-box;
}

.Loader--type-default .Loader-part {
  opacity: 0;
}

.Loader--type-short .Loader-part {
  opacity: 0;
}

.Loader--size-small .Loader-part {
  border-width: 12px;
}

.Loader--size-large .Loader-part {
  border-width: 50px;
}

.Loader-left {
  border-left-color: #83c4c6;
  transform: translate(-5%, 0);
}

.Loader--secondary .Loader-left {
  border-left-color: #e4e8ec;
}

.Loader--type-default .Loader-left {
  animation: slide-left 4s 0.5s ease-in-out infinite;
}

.Loader--type-short .Loader-left {
  animation: left-enter-exit 2s 0.2s ease-in-out infinite;
}

.Loader--type-spin .Loader-left {
  animation: left-enter-exit-spin 2s 0s ease-in-out infinite;
}

.Loader-top {
  border-top-color: #d35a5b;
  transform: translate(0, -5%);
}

.Loader--secondary .Loader-top {
  border-top-color: #e4e8ec;
}

.Loader--type-default .Loader-top {
  animation: slide-top 4s 0.5s ease-in-out infinite;
}

.Loader--type-short .Loader-top {
  animation: top-enter-exit 2s 0.4s ease-in-out infinite;
}

.Loader--type-spin .Loader-top {
  animation: top-enter-exit-spin 2s 0s ease-in-out infinite;
}

.Loader-right {
  border-right-color: #9584bb;
  transform: translate(5%, 0);
}

.Loader--secondary .Loader-right {
  border-right-color: #e4e8ec;
}

.Loader--type-default .Loader-right {
  animation: slide-right 4s 0.5s ease-in-out infinite;
}

.Loader--type-short .Loader-right {
  animation: right-enter-exit 2s 0.6s ease-in-out infinite;
}

.Loader--type-spin .Loader-right {
  animation: right-enter-exit-spin 2s 0s ease-in-out infinite;
}

.Loader-bottom {
  border-bottom-color: #f5d55b;
  transform: translate(0, 5%);
}

.Loader--secondary .Loader-bottom {
  border-bottom-color: #e4e8ec;
}

.Loader--type-default .Loader-bottom {
  animation: slide-bottom 4s 0.5s ease-in-out infinite;
}

.Loader--type-short .Loader-bottom {
  animation: bottom-enter-exit 2s 0.8s ease-in-out infinite;
}

.Loader--type-spin .Loader-bottom {
  animation: bottom-enter-exit-spin 2s 0s ease-in-out infinite;
}

@keyframes top-enter-exit {
  0% {
    transform: translate(0, -40%) scale(1.5);
    opacity: 0;
  }
  20% {
    transform: translate(0, -5%) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(0, -5%) scale(1);
    opacity: 1;
  }
  80% {
    transform: translate(0, -40%) scale(1.5);
    opacity: 0;
  }
}

@keyframes right-enter-exit {
  0% {
    transform: translate(40%, 0) scale(1.5);
    opacity: 0;
  }
  20% {
    transform: translate(5%, 0) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(5%, 0) scale(1);
    opacity: 1;
  }
  80% {
    transform: translate(40%, 0) scale(1.5);
    opacity: 0;
  }
}

@keyframes bottom-enter-exit {
  0% {
    transform: translate(0, 40%) scale(1.5);
    opacity: 0;
  }
  20% {
    transform: translate(0, 5%) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(0, 5%) scale(1);
    opacity: 1;
  }
  80% {
    transform: translate(0, 40%) scale(1.5);
    opacity: 0;
  }
}

@keyframes left-enter-exit {
  0% {
    transform: translate(-40%, 0) scale(1.5);
    opacity: 0;
  }
  20% {
    transform: translate(-5%, 0) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(-5%, 0) scale(1);
    opacity: 1;
  }
  80% {
    transform: translate(-40%, 0) scale(1.5);
    opacity: 0;
  }
}

@keyframes top-enter-exit-spin {
  0% {
    transform: translate(0, -60%) scale(1.2);
    opacity: 0;
  }
  10% {
    transform: translate(0, -60%) scale(1.2);
    opacity: 0;
  }
  40% {
    transform: translate(0, -5%) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(0, -5%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(0, -60%) scale(0.6);
    opacity: 0;
  }
  100% {
    transform: translate(0, -60%) scale(0.6);
    opacity: 0;
  }
}

@keyframes right-enter-exit-spin {
  0% {
    transform: translate(60%, 0) scale(1.2);
    opacity: 0;
  }
  10% {
    transform: translate(60%, 0) scale(1.2);
    opacity: 0;
  }
  40% {
    transform: translate(5%, 0) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(5%, 0) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(60%, 0) scale(0.6);
    opacity: 0;
  }
  100% {
    transform: translate(60%, 0) scale(0.6);
    opacity: 0;
  }
}

@keyframes bottom-enter-exit-spin {
  0% {
    transform: translate(0, 60%) scale(1.2);
    opacity: 0;
  }
  10% {
    transform: translate(0, 60%) scale(1.2);
    opacity: 0;
  }
  40% {
    transform: translate(0, 5%) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(0, 5%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(0, 60%) scale(0.6);
    opacity: 0;
  }
  100% {
    transform: translate(0, 60%) scale(0.6);
    opacity: 0;
  }
}

@keyframes left-enter-exit-spin {
  0% {
    transform: translate(-60%, 0) scale(1.2);
    opacity: 0;
  }
  10% {
    transform: translate(-60%, 0) scale(1.2);
    opacity: 0;
  }
  40% {
    transform: translate(-5%, 0) scale(1);
    opacity: 1;
  }
  60% {
    transform: translate(-5%, 0) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(-60%, 0) scale(0.6);
    opacity: 0;
  }
  100% {
    transform: translate(-60%, 0) scale(0.6);
    opacity: 0;
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(480deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  /* 10% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  } */
  100% {
    transform: rotate(360deg);
  }
}

@keyframes small-in {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
  }
  90% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.6);
  }
}

@keyframes slide-left {
  0% {
    transform: translate(-40%, 0) scale(1.2);
    opacity: 0;
  }
  20% {
    transform: translate(-5%, 0) scale(1);
    opacity: 1;
  }
  75% {
    transform: translate(-5%, 0) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(-10%, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-100%, 0) scale(1.4);
    opacity: 0;
  }
}

@keyframes slide-top {
  0% {
    transform: translate(0, -40%) scale(1.2);
    opacity: 0;
  }
  15% {
    transform: translate(0, -40%) scale(1);
    opacity: 0;
  }
  35% {
    transform: translate(0, -5%) scale(1);
    opacity: 1;
  }
  75% {
    transform: translate(0, -5%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(0, -10%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, -100%) scale(1.4);
    opacity: 0;
  }
}

@keyframes slide-right {
  0% {
    transform: translate(40%, 0) scale(1.2);
    opacity: 0;
  }
  30% {
    transform: translate(40%, 0) scale(1);
    opacity: 0;
  }
  50% {
    transform: translate(5%, 0) scale(1);
    opacity: 1;
  }
  75% {
    transform: translate(5%, 0) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(10%, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(100%, 0) scale(1.4);
    opacity: 0;
  }
}

@keyframes slide-bottom {
  0% {
    transform: translate(0, 40%) scale(1.2);
    opacity: 0;
  }
  45% {
    transform: translate(0, 40%) scale(1);
    opacity: 0;
  }
  65% {
    transform: translate(0, 5%) scale(1);
    opacity: 1;
  }
  75% {
    transform: translate(0, 5%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(0, 10%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, 100%) scale(1.4);
    opacity: 0;
  }
}

.Loader--type-circle {
  border-radius: 50%;
  border: 2px solid #000;
  box-sizing: border-box;
  border-left-color: #5ac5c8;
  border-top-color: #f15b5b;
  border-right-color: #9d85be;
  border-bottom-color: #ffd64f;
  animation-name: spin-circle;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin-circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}
