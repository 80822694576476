.ArticleSection {
  display: flex;
}

@media screen and (max-width: 1020px) {
  .ArticleSection {
    display: block;
  }
}

.ArticleSection--withSideBar {
}

.ArticleSection-mainContent {
}

.ArticleSection-sideBar {
  max-width: 264px;
  margin-left: 32px;
  flex: 0 0 264px;
  position: relative;
}

@supports (position: sticky) {
  .ArticleSection-sideBar {
    display: flex;
    flex-direction: column;
  }
  .ArticleSection-sideBar::before {
    content: '';
    display: block;
    flex: 1 1 auto;
  }
}

@media screen and (max-width: 1020px) {
  .ArticleSection-sideBar {
    margin-left: 0;
    margin-top: 32px;
    max-width: 100%;
  }
}

@media screen and (max-width: 840px) {
  .ArticleSection-sideBar {
    display: none;
  }
}

.ArticleSection-sideBarSticky {
  position: relative;
}

@supports (position: sticky) {
  .ArticleSection-sideBarSticky {
    position: sticky;
    bottom: 16px;
  }
}
