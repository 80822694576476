.BasketProduct {
}

.BasketProduct-mainInfo {
  display: flex;
  align-items: center;
  position: relative;
}

.BasketProduct-imageWrapper {
  width: 100%;
  max-width: 100px;
  margin-right: 32px;
}

.BasketProduct-image {
  width: 100%;
}

.BasketProduct-descriptionHolder {
  font-size: 14px;
  max-width: 320px;
}

.BasketProduct-description {
}

.BasketProduct-features {
  position: relative;
  z-index: 1;
  cursor: default;
  margin-bottom: 8px;
  display: inline-block;
}

.BasketProduct-title {
  margin: 4px 0;
  color: #848fa5;
  line-height: 1.3;
}

.BasketProduct-link {
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  font-weight: normal;
  margin: 0;
}

.BasketProduct-link::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.BasketProduct-selectedVariant {
  margin-bottom: 8px;
  color: #848fa5;
}

.BasketProduct-labelList {
  margin: 0;
  padding: 0;
  cursor: default;
}

.BasketProduct-labelItem {
  display: inline-block;
  margin-right: 8px;
  list-style: none;
}

.BasketProduct-labelItem:last-child {
  margin-right: 0;
}

.BasketProduct-amount {
  font-size: 14px;
}

.BasketProduct-price {
  display: inline-flex;
  font-size: 18px;
}

.BasketProduct-pricePrevious {
  margin-right: 15px;
}

.BasketProduct-actions {
  display: flex;
  align-items: center;
  margin: -8px;
}

.BasketProduct-actionsItem {
  padding: 8px;
  font-size: 20px;
  line-height: 1;
  color: #5f646c;
}

.BasketProduct-priceDiscount {
  font-size: 14px;
  color: #e95958;
  text-align: right;
}

.BasketProduct-giftLabel {
  font-size: 14px;
  font-weight: 700;
  color: #25bc8b;
}
