.CategoryPanel_Button {
  display: inline-flex;
  width: 100%;
  margin: 0;
  align-items: center;
  padding: 12px 24px;
  border: none;
  line-height: 24px;
  font-size: 14px;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  vertical-align: top;
  transition: background-color 0.2s ease-out;
  appearance: none;
}

.isTabUsing .CategoryPanel_Button:focus {
  background-color: #edf0f5;
}

.CategoryPanel_Button:hover {
  background-color: #edf0f5;
}

.CategoryPanel_Button:active {
  background-color: #dbe1ec;
}

.CategoryPanel_Button-title {
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CategoryPanel_Button-counterHolder {
  display: inline-block;
  min-width: 16px;
  margin-right: 12px;
}

.CategoryPanel_Button-counter {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  text-align: center;
  background-color: #e8e8ef;
}

.CategoryPanel_Button-icon {
  display: inline-block;
  line-height: 1;
  font-size: 12px;
}
