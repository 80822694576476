.PaymentInfo {
  height: 100%;
}

.PaymentInfo-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 18px;
}

.PaymentInfo-titleIco {
  margin-right: 8px;
}

.PaymentInfo-titleText {
  margin: 0;
}

.PaymentInfo-timeList {
  margin-bottom: 16px;
}

.PaymentInfo-paymentList {
  margin-left: 16px;
  margin-bottom: 16px;
}

.PaymentInfo-freeCost {
  margin-bottom: 16px;
}

.PaymentInfo-description {
}
