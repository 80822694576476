.CountedList {
}

.CountedList-topLevelTitle {
  font-weight: bold;
  text-transform: uppercase;
  margin: 24px 0 16px;
  font-size: 24px;
}

.CountedList-topLevelHeader {
  display: inline;
  font-size: 24px;
}

.CountedList-nestedLevel {
  font-weight: normal;
  font-size: 16px;
  text-transform: none;
}

.CountedList ol {
  counter-reset: section;
  list-style-type: none;
  padding-left: 16px;
}

.CountedList li::before {
  counter-increment: section;
  content: counters(section, '.') '.';
  margin-right: 8px;
}

.CountedList-nestedLevelTitle {
  margin: 10px 0;
}

.CountedList-nestedLevelTitle--unordered li::before {
  content: none;
}
