.ButtonClose {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  line-height: 1;
  font-size: inherit;
}
