.AcquaintanceDialog {
  padding: 32px;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 680px) {
  .AcquaintanceDialog {
    max-width: 100%;
  }
}

.AcquaintanceDialog-welcome {
  margin: 0;
  font-size: 18px;
  color: #848fa5;
}

.AcquaintanceDialog-title {
  margin: 0;
}

.AcquaintanceDialog-label {
  margin: 0;
  margin-bottom: 8px;
  text-align: center;
  font-size: 18px;
}

.AcquaintanceDialog-image {
  fill: currentColor;
  width: 48px;
  height: 48px;
}

.AcquaintanceDialog-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AcquaintanceDialog-imageTab {
  appearance: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: 96px;
  height: 96px;
  padding: 24px;
  border: 1px solid #848fa5;
  border-radius: 64px;
  color: #848fa5;
  margin: 0 16px;
  outline: none;
}

.AcquaintanceDialog-imageTab:hover {
  background-color: #edf0f5;
}

.isTabUsing .AcquaintanceDialog-imageTab:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(47, 124, 192, 0.54);
}

.AcquaintanceDialog-imageTab--active {
  background-color: #ffd12c;
  border-color: #ffd12c;
  color: inherit;
}

.AcquaintanceDialog-imageTab--active:hover {
  background-color: #ffd12c;
}
