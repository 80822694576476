.BasketDropdown {
  width: 320px;
  max-height: 480px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 840px) {
  .BasketDropdown {
    width: 288px;
    max-height: calc(100vh - 140px);
  }
}

.BasketDropdown-header {
  padding: 8px 16px 0;
}

.BasketDropdown-title {
  margin: 0;
}

.BasketDropdown-listHolder {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.BasketDropdown-list {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
}

.BasketDropdown-item {
  border-bottom: 1px solid #edf0f5;
  padding: 16px;
}

.BasketDropdown-item:last-child {
  border-bottom: 0;
}

.BasketDropdown-totalGroup {
  padding: 0 16px;
  margin: 16px -8px;
  flex: 0 0 auto;
}

.BasketDropdown-priceItem {
  padding: 0 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.BasketDropdown-priceItem:last-child {
  margin-bottom: 0;
}

.BasketDropdown-priceAmount {
  font-weight: 700;
}

.BasketDropdown-priceItemTotal {
  padding: 0 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}

.BasketDropdown-actionGroup {
  padding: 0 16px;
  flex: 0 0 auto;
  margin-bottom: 24px;
}

.BasketDropdown-action {
  margin-bottom: 16px;
}

.BasketDropdown-action:last-child {
  margin-bottom: 0;
}

.BasketDropdown-empty {
  padding: 24px 16px;
  text-align: center;
  flex: 0 0 auto;
}

.BasketDropdown-emptyTitle {
  margin-bottom: 8px;
}

.BasketDropdown-emptyMessage {
  margin-top: 8px;
  margin-bottom: 24px;
}

.BasketDropdown-emptyTitle {
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
}

.BasketDropdown-emptyMesage {
  margin: 16px 0 24px;
}

.BasketDropdown-emptyParagraph {
}

.BasketDropdown-emptyAction {
}
