.InfoSign {
  display: inline-block;
  text-align: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #ffd12c;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 12px;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  cursor: default;
}

.InfoSign--size-large {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
}

.InfoSign--light {
  border: 1px solid #fff;
  color: #fff;
}

.InfoSign:hover {
  color: #fff;
  background-color: #ffd12c;
}

.InfoSign--light:hover {
  color: #262931;
  border: 1px solid #ffd12c;
}
