.Zoomer {
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-out;
}

.Zoomer * {
  box-sizing: border-box;
}

.Zoomer--out {
  overflow: visible;
}
.Zoomer--isHovered {
}

.Zoomer--underlay.Zoomer--isHovered {
  background-color: rgba(51, 47, 47, 0.2);
}

.Zoomer-main {
  width: 100%;
  opacity: 0;
}
.Zoomer--out .Zoomer-main {
  opacity: 1;
  cursor: crosshair;
}

.Zoomer-topHolder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Zoomer--out .Zoomer-topHolder {
  pointer-events: none;
  border: 1px solid black;
  overflow: hidden;
  opacity: 0;
  transition: 0.2s ease-out;
}

.Zoomer--out_right .Zoomer-topHolder {
  left: 100%;
  right: -100%;
}

.Zoomer--out_left .Zoomer-topHolder {
  right: 100%;
  left: -100%;
}

.Zoomer--out_top .Zoomer-topHolder {
  bottom: 100%;
  top: -100%;
}

.Zoomer--out_bottom .Zoomer-topHolder {
  top: 100%;
  bottom: -100%;
}

.Zoomer--isHovered.Zoomer--out .Zoomer-topHolder {
  opacity: 1;
}

.Zoomer-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  transform: scale(1);
  transform-origin: top left;
  transition: transform 0.05s linear;
  /* border: 1px solid; */
}

.Zoomer--isHovered .Zoomer-top {
  opacity: 1;
  pointer-events: none;
}

.Zoomer-topPointer {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: rgb(197, 146, 146);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: 0.05s linear;
}

.Zoomer-scalePanel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.Zoomer-scaleButton {
  cursor: pointer;
}
