.Article_ContentSection {
}

.Article_ContentSection-grid {
  display: flex;
  margin: -16px;
}

@media screen and (max-width: 1100px) {
  .Article_ContentSection-grid {
    display: block;
    margin: 0;
  }
}

.Article_ContentSection-leftColumn {
  flex: 0 0 196px;
  max-width: 196px;
  padding: 16px;
  box-sizing: border-box;
}

@media screen and (max-width: 1100px) {
  .Article_ContentSection-leftColumn {
    display: none;
  }
}

.Article_ContentSection-rightColumn {
  flex: 0 0 196px;
  max-width: 196px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (max-width: 1100px) {
  .Article_ContentSection-rightColumn {
    display: none;
  }
}

.Article_ContentSection-mainColumn {
  flex: 1 1 auto;
  padding: 16px;
}

@media screen and (max-width: 1100px) {
  .Article_ContentSection-mainColumn {
    padding: 0;
  }
}

.Article_ContentSection-leftSidebarGroup {
  position: sticky;
  top: 16px;
  margin-bottom: 60vh;
}

.Article_ContentSection-sidebarGroupItem {
  margin-bottom: 16px;
}

.Article_ContentSection-sidebarGroupItem:last-child {
  margin-bottom: 16px;
}
