.DotPaginationButton {
  width: 8px;
  height: 8px;
  background-color: #d2d5da;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  border: 1px solid #dfe3ea;
  outline: none;
  transition: all 0.3s ease-out;
}

.DotPaginationButton--active {
  background-color: #404751;
  border: 1px solid #404751;
}

.DotPaginationButton--medium {
  width: 6px;
  height: 6px;
  /* background-color: coral;
  border: 1px solid coral; */
}

.DotPaginationButton--small {
  width: 4px;
  height: 4px;
  /* background-color: red;
  border: 1px solid red; */
}

.DotPaginationButton--hidden {
  display: none;
}
