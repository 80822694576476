.ConsistencyLegend {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ConsistencyLegend-item {
}

.ConsistencyLegend-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 3px solid currentColor;
}

.ConsistencyLegend-text {
  display: inline-block;
  vertical-align: middle;
}
