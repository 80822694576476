.ArticleCard {
  position: relative;
  overflow: hidden;
}

.ArticleCard--small {
  /* max-width: 360px; */
}

.ArticleCard--medium {
  /* max-width: 555px; */
}

.ArticleCard--large {
}

.ArticleCard-backgroundHolder {
  overflow: hidden;
}

.ArticleCard-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s ease-out;
}

.ArticleCard:hover .ArticleCard-background {
  transform-origin: bottom left;
  transform: scale(1.04);
}

.ArticleCard--small .ArticleCard-background {
  padding-bottom: 65%;
}

.ArticleCard--medium .ArticleCard-background {
  padding-bottom: 64.865%;
}

.ArticleCard--large .ArticleCard-background {
  height: 487px;
}

.ArticleCard-contentHolder {
}

.ArticleCard-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 160px 32px 32px;
  background-image: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.005) 8.1%,
    hsla(0, 0%, 0%, 0.019) 15.5%,
    hsla(0, 0%, 0%, 0.042) 22.5%,
    hsla(0, 0%, 0%, 0.07) 29%,
    hsla(0, 0%, 0%, 0.104) 35.3%,
    hsla(0, 0%, 0%, 0.141) 41.2%,
    hsla(0, 0%, 0%, 0.18) 47.1%,
    hsla(0, 0%, 0%, 0.22) 52.9%,
    hsla(0, 0%, 0%, 0.259) 58.8%,
    hsla(0, 0%, 0%, 0.296) 64.7%,
    hsla(0, 0%, 0%, 0.33) 71%,
    hsla(0, 0%, 0%, 0.358) 77.5%,
    hsla(0, 0%, 0%, 0.381) 84.5%,
    hsla(0, 0%, 0%, 0.395) 91.9%,
    hsla(0, 0%, 0%, 0.4) 100%
  );
}

.ArticleCard--small .ArticleCard-content {
  position: static;
  margin-top: 8px;
  background: transparent;
  padding: 0;
}

.ArticleCard--medium .ArticleCard-content {
}

.ArticleCard--large .ArticleCard-content {
  color: #fff;
}

.ArticleCard-title {
  font-weight: bold;
  margin: 0;
}

.ArticleCard-link {
  text-decoration: none;
  display: inline-block;
}

.ArticleCard--small .ArticleCard-link {
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 840px) {
  .ArticleCard--small .ArticleCard-link {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .ArticleCard--small .ArticleCard-link {
    font-size: 14px;
    line-height: 18px;
  }
}

.ArticleCard--medium .ArticleCard-link {
  max-width: 360px;
  color: #fff;
  font-size: 24px;
  line-height: 30px;
}

.ArticleCard--large .ArticleCard-link {
  font-size: 32px;
  max-width: 555px;
  line-height: 40px;
  margin-top: 8px;
  color: #fff;
}

.ArticleCard-link::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ArticleCard-date {
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
}

.ArticleCard-date::before {
  content: '';
  display: block;
  height: 2px;
  width: 32px;
  background-color: #fff;
  margin-right: 16px;
  transition: 0.2s ease-out;
}

.ArticleCard-date::after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 0;
  opacity: 0;
  background-color: #ffd12c;
  transition: 0.2s ease-out;
}

.ArticleCard:hover .ArticleCard-date::after {
  width: 34px;
  opacity: 1;
  transform: translateX(4px);
}

.ArticleCard:hover .ArticleCard-date::before {
  transform: translateX(4px);
}
