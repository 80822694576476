.ProblemSkin_Products {
}

.ProblemSkin_Products-header {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  background: linear-gradient(107.59deg, #cfcfd0 1.17%, #f4f4f4 106.01%), #f1f1f1;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-header {
    flex-wrap: wrap;
  }
}

.ProblemSkin_Products-header_leftVisual {
  flex: 1 1 20%;
  /* background-color: red; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-header_leftVisual {
    height: 70vw;
    /* z-index: -1; */
    flex-grow: 50%;
  }
}

.ProblemSkin_Products-header_content {
  flex: 1 1 60%;
  text-align: center;
  /* background-color: green; */
  padding: 100px 0;
  position: relative;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-header_content {
    order: -1;
    flex-basis: 100%;
    padding-top: 48px;
    z-index: 1;
  }
}

.ProblemSkin_Products-header_description {
  font-weight: 600;
  font-size: 28px;
  max-width: 485px;
  margin: 0 auto 22px;
}
@media (max-width: 1100px) {
  .ProblemSkin_Products-header_title {
    font-size: 18px;
    margin: 0 auto 10px;
  }
}

.ProblemSkin_Products-header_title {
  color: #8e3387;
  font-weight: bold;
  font-size: 54px;
  margin-bottom: 54px;
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-header_title {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.ProblemSkin_Products-header_button {
}

.ProblemSkin_Products-header_rightVisual {
  flex: 1 1 20%;
  /* background-color: pink; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  transform-origin: bottom right;
  transform: scale(1.4);
}

@media (max-width: 1100px) {
  .ProblemSkin_Products-header_rightVisual {
    height: 70vw;
    /* z-index: -1; */
    flex-grow: 50%;
    transform: scale(1.4) translateX(10vw);
    position: relative;
    /* z-index: 1; */
  }
}
