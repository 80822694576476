.CountDown-time {
  display: inline-block;
}

.Expire-timeItem {
  display: inline-block;
  /* margin-right: 8px; */
}

.Expire-timeItem--theme-inBanner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.Expire-timeItem--fontSizeInherited .Expire-amount {
  font-weight: bold;
}

.Expire-timeItem--fontSizeInherited .Expire-amount,
.Expire-timeItem--fontSizeInherited .Expire-separator,
.Expire-timeItem--fontSizeInherited .Expire-unit {
  font-size: inherit;
}

.Expire-time--fontSizeInherited .Expire-separator {
  font-size: inherit;
}

.Expire-timeItem--fontSizeInherited .Expire-amount {
  font-size: inherit;
  width: 1.3em;
  font-variant-numeric: tabular-nums;
}

.Expire-amount {
  /* display: inline-block; */
  font-size: 32px;
  margin-right: 4px;
  width: 40px;
  display: inline-flex;
  justify-content: flex-end;
}

.Expire-timeItem--theme-inBanner .Expire-amount {
  color: #262931;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  padding: 12px;
  background-color: #f6f8fb;
  margin-bottom: 8px;
  margin-right: 0;
}

@media (max-width: 420px) {
  .Expire-amount {
    font-size: 24px;
    width: 30px;
  }
}

@media (max-width: 420px) {
  .Expire-timeItem--theme-inBanner .Expire-amount {
    font-size: 20px;
  }
}

.Expire-unit {
  display: inline-block;
  /* margin-right: 6px; */
}

.Expire-separator {
  display: inline-block;
  font-size: 32px;
  margin: 0 4px;
}

.Expire-time--size-small .Expire-separator {
  font-size: 18px;
}

@media (max-width: 420px) {
  .Expire-separator {
    font-size: 24px;
  }
}

.Expire-separator::before {
  content: ':';
}

/* .Expire-timeItem:last-child .Expire-separator {
  display: none;
  margin-right: 0;
} */
