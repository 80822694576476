.DashboardGrid {
  display: flex;
  flex-direction: column;
  margin: -16px;
}

.DashboardGrid-row {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
}

.DashboardGrid-row--strictRow {
}

.DashboardGrid-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.DashboardGrid-row--strictRow .DashboardGrid-cell {
  align-items: stretch;
}

.DashboardGrid-cell--strictCell {
  flex: 0 0 auto;
}

.DashboardGrid-cell--smallCell {
  flex: 0 0 25%;
  max-width: 25%;
}

.DashboardGrid-cell--mediumCell {
  flex: 0 0 35%;
  max-width: 35%;
}

.DashboardGrid-cell--halfCell {
  flex: 0 0 50%;
  max-width: 50%;
}

.DashboardGrid-cell--wideCell {
  flex: 0 0 75%;
  max-width: 75%;
}

.DashboardGrid-cell--fullCell {
  flex: 0 0 100%;
  max-width: 100%;
}
