.AddToWaitListConfirmationDialog {
  padding: 32px 32px 32px;
  max-width: 420px;
}

@media (max-width: 1100px) {
  .AddToWaitListConfirmationDialog {
    padding: 24px 24px 32px;
  }
}

@media (max-width: 1100px) {
  .AddToWaitListConfirmationDialog {
    max-width: none;
  }
}

.AddToWaitListConfirmationDialog-title {
  margin: 0;
  /* margin-bottom: 8px; */
  line-height: 1.2;
  font-size: 32px;
}

@media (max-width: 840px) {
  .AddToWaitListConfirmationDialog-title {
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .AddToWaitListConfirmationDialog-title {
    font-size: 20px;
  }
}

/* .AddToWaitListConfirmationDialog-info {
} */
