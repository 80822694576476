.UserOverview {
}

.UserOverview--fullSizeInteractive {
  position: relative;
  padding: 16px 8px;
}

.UserOverview--direction-column {
}

.UserOverview--direction-row {
  display: flex;
  align-items: center;
}

.UserOverview--nameWeight-normal {
}

.UserOverview--nameWeight-bold {
}

.UserOverview-visualGroup {
  display: inline-block;
  margin-bottom: 16px;
  vertical-align: top;
  text-decoration: none;
}

.UserOverview--fullSizeInteractive .UserOverview-visualGroup {
  position: relative;
  z-index: 1;
}

.UserOverview--direction-row .UserOverview-visualGroup {
  flex: 0 0 auto;
  margin-bottom: 0;
  margin-right: 24px;
}

.UserOverview-image {
}

.UserOverview-contentGroup {
}

.UserOverview--direction-row .UserOverview-contentGroup {
  flex: 0 1 auto;
}

.UserOverview-nameHolder {
  line-height: 20px;
}

.UserOverview-name {
  position: relative;
  display: inline-block;
  line-height: 20px;
  font-size: 16px;
  color: #444952;
  text-decoration: none;
}

.UserOverview--fullSizeInteractive .UserOverview-name {
  position: static;
}

.UserOverview--fullSizeInteractive .UserOverview-name::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f9ff;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.UserOverview:hover .UserOverview-name::before {
  opacity: 1;
}

.UserOverview-nameTitle {
  position: relative;
}

.UserOverview--nameWeight-bold .UserOverview-name {
  font-weight: 700;
}

.UserOverview-name::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  color: inherit;
  border-bottom: 1px solid currentColor;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.UserOverview-name:hover::after {
  opacity: 1;
}

.UserOverview--fullSizeInteractive .UserOverview-name::after {
  display: none;
}

.UserOverview-emailHolder {
}

.UserOverview-email {
  font-weight: 700;
}

.UserOverview-bonusesHolder {
  margin-top: 8px;
  line-height: 18px;
}

/* .UserOverview-bonuses {
  position: relative;
  display: inline-block;
  line-height: 18px;
  font-size: 14px;
  font-weight: 300;
  color: #818690;
  text-decoration: none;
}

.UserOverview-bonuses::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  color: inherit;
  border-bottom: 1px solid currentColor;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.UserOverview-bonuses:hover::after {
  opacity: 1;
} */
