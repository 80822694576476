.SectionProductStorage {
  background-color: #f5f8fb;
  padding: 50px 0 40px;
}

@media screen and (max-width: 840px) {
  .SectionProductStorage {
    background-color: #fff;
    padding: 0;
  }
}

.SectionProductStorage-title {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0;
}

@media screen and (max-width: 840px) {
  .SectionProductStorage-title {
    display: none;
  }
}
