.RewardsSections {
}

.RewardsSections-content {
  /* overflow: hidden; */
}

.RewardsSections-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
  list-style-type: none;
  padding: 0;
}

.RewardsSections-gridItem {
  flex: 1 1 25%;
  max-width: 25%;
  box-sizing: border-box;
  padding: 16px;
}

@media (max-width: 920px) {
  .RewardsSections-gridItem {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media (max-width: 580px) {
  .RewardsSections-gridItem {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
