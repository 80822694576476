.MobileProductCardSlider {
  position: relative;
}

.MobileProductCardSlider-pagination {
  margin-top: 24px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);

  margin-bottom: 12px;
  padding: 0 4px;
  /* margin-top: 4px; */
  display: flex;
  justify-content: center;
  transition: 0.2s ease-out;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 100px;
  padding: 0;
}

.MobileProductCardSlider-paginationOuterHolder {
  position: relative;
  overflow: hidden;
  /* opacity: 0.5; */
}

.MobileProductCardSlider-paginationHolder {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  transition: 0.2s ease-out;
}

.MobileProductCardSlider-paginationItem {
  margin: 4px;
  display: inline-flex;
  align-items: center;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.MobileProductCardSlider-paginationItem--hidden {
  display: none;
  /* width: 0px;
  height: 0px;
  opacity: 0;
  visibility: hidden; */
}

.ProductCardSlider-cosmopolitenMobileLabel {
  position: absolute;
  top: 24px;
  left: 0;
  height: 160px;
  z-index: 399;
}

@media (max-width: 480px) {
  .ProductCardSlider-cosmopolitenMobileLabel {
    height: 120px;
  }
}
