.HappyMasterLanding {
}

.HappyMasterLanding-section {
  padding: 72px 0;
}

.HappyMasterLanding-section--highlight {
  background-color: #fdf2f5;
}

.HappyMasterLanding-section--noGaps {
  padding: 0;
}

.HappyMasterLanding-navigationInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 840px) {
  .HappyMasterLanding-navigationInfo {
    flex-direction: column;
  }
}

.HappyMasterLanding-navigationInfoMedia {
  margin-right: 96px;
  border-radius: 50%;
  overflow: hidden;
  width: 350px;
  height: 350px;
}

@media (max-width: 840px) {
  .HappyMasterLanding-navigationInfoMedia {
    margin-right: 0;
    margin-bottom: 32px;
  }
}

@media (max-width: 480px) {
  .HappyMasterLanding-navigationInfoMedia {
    margin-bottom: 0;
  }
}

.HappyMasterLanding-navigationInfoMediaImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

@media (max-width: 480px) {
  .HappyMasterLanding-navigationInfoMediaImage {
    width: 100%;
  }
}

.HappyMasterLanding-navigationInfoContent {
  width: 270px;
}

@media (max-width: 840px) {
  .HappyMasterLanding-navigationInfoContent {
    width: auto;
  }
}

.HappyMasterLanding-navigationInfoTitle {
  margin: 0;
  padding: 0;
  font-size: 36px;
  margin-bottom: 16px;
  font-weight: 700;
}

@media (max-width: 840px) {
  .HappyMasterLanding-navigationInfoTitle {
    text-align: center;
  }
}

@media (max-width: 360px) {
  .HappyMasterLanding-navigationInfoTitle {
    margin-bottom: 0;
  }
}

.HappyMasterLanding-navigationInfoDescription {
  font-size: 24px;
  margin-bottom: 56px;
  font-weight: 700;
  line-height: 1.2;
}

@media (max-width: 840px) {
  .HappyMasterLanding-navigationInfoDescription {
    text-align: center;
    margin-bottom: 32px;
  }
}

@media (max-width: 360px) {
  .HappyMasterLanding-navigationInfoDescription {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

.HappyMasterLanding-navigationInfoList {
  margin: 0;
  padding: 0;
  padding-left: 24px;
}

.HappyMasterLanding-navigationInfoListItem {
  margin-bottom: 16px;
}

.HappyMasterLanding-navigationInfoListItem:last-child {
  margin-bottom: 0;
}

.HappyMasterLanding-acquaintance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1100px) {
  .HappyMasterLanding-acquaintance {
    flex-direction: column;
    justify-content: center;
  }
}

.HappyMasterLanding-acquaintanceInfo {
  width: 560px;
}

@media (max-width: 640px) {
  .HappyMasterLanding-acquaintanceInfo {
    width: 480px;
  }
}

@media (max-width: 520px) {
  .HappyMasterLanding-acquaintanceInfo {
    width: 100%;
  }
}

.HappyMasterLanding-acquaintanceInfo p {
  margin: 0;
  margin-bottom: 24px;
}

.HappyMasterLanding-acquaintanceInfo p:last-child {
  margin-bottom: 0;
}

.HappyMasterLanding-acquaintanceMedia {
  position: relative;
  width: 480px;
}

@media (max-width: 1100px) {
  .HappyMasterLanding-acquaintanceMedia {
    margin-top: 96px;
    margin-bottom: 48px;
  }
}

@media (max-width: 560px) {
  .HappyMasterLanding-acquaintanceMedia {
    width: 260px;
  }
}

@media (max-width: 560px) {
  .HappyMasterLanding-acquaintanceMediaEditionalImage {
    width: 100%;
  }
}

.HappyMasterLanding-acquaintanceMediaEditional {
  position: absolute;
  top: -44px;
  right: -8px;
}

@media (max-width: 560px) {
  .HappyMasterLanding-acquaintanceMediaEditional {
    width: 120px;
    height: 120px;
    top: -31px;
    right: -41px;
  }
}

@media (max-width: 560px) {
  .HappyMasterLanding-acquaintanceMediaImage {
    width: 100%;
  }
}

.HappyMasterLanding-thanksInfoContent {
  width: 560px;
  margin: 0 auto;
}

@media (max-width: 640px) {
  .HappyMasterLanding-thanksInfoContent {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .HappyMasterLanding-thanksInfoContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.HappyMasterLanding-thanksInfoContentTitle {
  margin: 0;
  padding: 0;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 42px;
  line-height: 1.2;
}

.HappyMasterLanding-thanksInfoContentText {
  margin-bottom: 32px;
}

.HappyMasterLanding-thanksInfoContentText :last-child {
  margin-bottom: 0;
}

@media (max-width: 5600px) {
  .HappyMasterLanding-thanksInfoContentText--centrAligned {
    margin-top: 32px;
    text-align: center;
  }
}

.HappyMasterLanding-thanksInfoFailGallery {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

@media (max-width: 840px) {
  .HappyMasterLanding-thanksInfoFailGallery {
    flex-wrap: wrap;
  }
}

@media (max-width: 560px) {
  .HappyMasterLanding-thanksInfoFailGallery {
    width: 320px;
    margin: -32px;
  }
}

@media (max-width: 360px) {
  .HappyMasterLanding-thanksInfoFailGallery {
    width: 280px;
  }
}

.HappyMasterLanding-thanksInfoFailGalleryItem {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 560px) {
  .HappyMasterLanding-thanksInfoFailGalleryItem {
    padding: 32px 0;
  }
}

.HappyMasterLanding-thanksInfoFailGalleryItemMedia {
  position: relative;
  height: 100px;
  width: 100px;
  border: 1px solid #dedede;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HappyMasterLanding-thanksInfoFailIcon {
  position: absolute;
  top: 3px;
  right: 5px;
  width: 23px;
  height: 23px;
  background-color: #e95958;
  border-radius: 50%;
}

.appyMasterLanding-thanksInfoFailIconTopItem {
  width: 12px;
  height: 2px;
  transform: translate(6px, 10px) rotate(45deg);
  background-color: #fff;
}

.appyMasterLanding-thanksInfoFailIconBottomItem {
  width: 12px;
  height: 2px;
  transform: translate(6px, 8px) rotate(-45deg);
  background-color: #fff;
}

.HappyMasterLanding-thanksInfoBasketContainer {
  height: 28px;
  color: #e93f77;
}

.HappyMasterLanding-thanksInfoProductContainer {
  height: 36px;
  color: #64bed3;
}

.HappyMasterLanding-thanksInfoMobileContainer {
  height: 32px;
  color: #8f659c;
}

.HappyMasterLanding-thanksInfoDeliveryContainer {
  height: 24px;
  color: #ffd12c;
}

.HappyMasterLanding-thanksInfoFailGalleryItemMedia {
  margin-bottom: 8px;
}

.HappyMasterLanding-thanksInfoFailGalleryItemDescription {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
}

.HappyMasterLanding-thanksPromiseContent {
  background-color: #fdf2f5;
  padding: 60px 0;
}

.HappyMasterLanding-thanksPromiseContentContainer {
  margin: 0 auto;
  width: 560px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 640px) {
  .HappyMasterLanding-thanksPromiseContentContainer {
    width: 100%;
  }
}

.HappyMasterLanding-feedback {
  padding: 116px 0;
  /* border-bottom: 1px solid #dedede; */
}

@media (max-width: 560px) {
  .HappyMasterLanding-feedback {
    padding: 72px 0;
  }
}

.HappyMasterLanding-feedbackContent {
  margin: 0 auto;
  width: 560px;
}

@media (max-width: 640px) {
  .HappyMasterLanding-feedbackContent {
    width: 100%;
  }
}

.HappyMasterLanding-feedbackTitle {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 16px;
}

.HappyMasterLanding-feedbackCaption {
  text-align: center;
  margin-bottom: 64px;
}

@media (max-width: 560px) {
  .HappyMasterLanding-feedbackCaption {
    margin-bottom: 32px;
  }
}

.HappyMasterLanding-feedbackPanel {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 500px;
}

@media (max-width: 560px) {
  .HappyMasterLanding-feedbackPanel {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .HappyMasterLanding-feedbackPanel {
    width: 320px;
  }
}

@media (max-width: 360px) {
  .HappyMasterLanding-feedbackPanel {
    width: 100%;
  }
}

.HappyMasterLanding-feedbackButton {
  padding: 0;
  margin: 0;
  background-color: inherit;
  border: none;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  color: #fff;
}

@media (max-width: 480px) {
  .HappyMasterLanding-feedbackButton {
    width: 48px;
    height: 48px;
  }
}

.HappyMasterLanding-feedbackButton--vk {
  background-color: #4680c2;
}

.HappyMasterLanding-feedbackButton--facebook {
  background-color: #1d7cee;
}

.HappyMasterLanding-feedbackButton--telegram {
  background-color: #4ab0df;
}

.HappyMasterLanding-feedbackButton--viber {
  background-color: #7b559e;
}

.HappyMasterLanding-feedbackButton--odnoklassniki {
  background-color: #f59331;
}

.HappyMasterLanding-feedbackIconContainer--vk {
  height: 36px;
}

@media (max-width: 480px) {
  .HappyMasterLanding-feedbackIconContainer--vk {
    height: 24px;
  }
}

.HappyMasterLanding-feedbackIconContainer--facebook {
  height: 36px;
}

@media (max-width: 480px) {
  .HappyMasterLanding-feedbackIconContainer--facebook {
    height: 24px;
  }
}

.HappyMasterLanding-feedbackIconContainer--odnoklassniki {
  height: 38px;
}

@media (max-width: 480px) {
  .HappyMasterLanding-feedbackIconContainer--odnoklassniki {
    height: 24px;
  }
}

.HappyMasterLanding-feedbackIconContainer--telegram {
  height: 24px;
}

@media (max-width: 480px) {
  .HappyMasterLanding-feedbackIconContainer--telegram {
    height: 16px;
  }
}

.HappyMasterLanding-feedbackIconContainer--viber {
  height: 32px;
}

@media (max-width: 480px) {
  .HappyMasterLanding-feedbackIconContainer--viber {
    height: 20px;
  }
}

.HappyMasterNavigation {
  background-color: #fdf2f5;
  padding-top: 30px;
}

.HappyMasterNavigation-fullContainer {
  background-color: #fdf2f5;
  padding: 20px 0;
  z-index: 100;
  transition: box-shadow 0.2s ease-out;
  display: none;
}
.HappyMasterNavigation-fullContainer--isSticky {
  /*padding: 20px 0;*/
  background-color: #fff;
  display: block;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}
.HappyMasterNavigation-container {
  padding: 0 20px;
  margin: 0 auto;
}

.HappyMasterNavigation-nav {
}

.HappyMasterLanding-feedbackOpen {
  display: flex;
  justify-content: center;
}
