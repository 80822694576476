.Ordering_ProductOverview {
}

.Ordering_ProductOverview-titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

@media screen and (max-width: 840px) {
  .Ordering_ProductOverview-titleRow {
    justify-content: flex-start;
  }
}

.Ordering_ProductOverview-title {
  margin: 0;
  line-height: 1.2;
  font-size: 18px;
}

@media screen and (max-width: 840px) {
  .Ordering_ProductOverview-title {
    margin-right: 12px;
  }
}

.Ordering_ProductOverview-link {
  font-size: 14px;
}

.Ordering_ProductOverview-productList {
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  max-height: 480px;
  overflow: hidden;
  /* overflow-y: auto; */
  border-top: 1px solid #dbe1ec;
}

@media screen and (max-width: 840px) {
  .Ordering_ProductOverview-productList {
    max-height: none;
    overflow-y: hidden;
    margin-bottom: 16px;
  }
}

.Ordering_ProductOverview-item {
  list-style: none;
  overflow: hidden;
  padding: 16px 0;
  border-bottom: 1px solid #dbe1ec;
}

.Ordering_ProductOverview-promo {
  margin-bottom: 16px;
  padding: 16px 0;
  border-bottom: 1px solid #dbe1ec;
  border-top: 1px solid #dbe1ec;
}

.Ordering_ProductOverview-summary {
  font-size: 16px;
}

@media (max-width: 840px) {
  .Ordering_ProductOverview-summary {
    font-size: 15px;
  }
}

.Ordering_ProductOverview-point {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.Ordering_ProductOverview-point:last-child {
  margin-bottom: 0;
}

.Ordering_ProductOverview-key {
  display: inline-block;
}

.Ordering_ProductOverview-value {
  display: inline-block;
}

.Ordering_ProductOverview-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 840px) {
  .Ordering_ProductOverview-total {
    margin-top: 8px;
  }
}

.Ordering_ProductOverview-totalKey {
  display: inline-block;
}

.Ordering_ProductOverview-totalValue {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
}
