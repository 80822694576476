.StaticTooltip {
}

.StaticTooltip-header {
  color: #fff;
}

.StaticTooltip-trigger {
  background-color: transparent;
  padding: 0;
  border: none;
  margin-left: 16px;
  outline: none;
  border-radius: 50%;
}

.isTabUsing .StaticTooltip-trigger:focus {
  box-shadow: 0 0 0 2px rgba(47, 124, 192, 0.54);
}

.StaticTooltip-contentHolder {
  position: relative;
  /* overflow: hidden; */
}

.StaticTooltip-contentHolder:before {
  content: '';
  border-radius: 4px 4px 0 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 10px;
  background-color: #fff;
  z-index: 1;
}

.StaticTooltip-content {
  position: relative;
  margin-top: 16px;
  color: #fff;
  background-color: currentColor;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 16px 0 rgba(47, 47, 71, 0.1);
  max-height: 30vh;
  overflow: auto;
}

.StaticTooltip-contentTitle {
  color: #262931;
  font-weight: bold;
}

.StaticTooltip-contentArrow {
  position: absolute;
  left: 50%;
  top: 0;
}

.StaticTooltip-contentArrow::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  margin: -8px;
  border-radius: 0 0 0 3px;
  box-sizing: border-box;
  background-color: #fff;
  transform: rotate(-45deg);
  box-shadow: 0 2px 16px 0 rgba(47, 47, 71, 0.1);
}

.StaticTooltip-contentArrow::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  margin: -8px;
  border-radius: 0 0 0 3px;
  box-sizing: border-box;
  background-color: #fff;
  transform: rotate(-45deg);

  z-index: 1;
}

.StaticTooltip-contentText {
  color: #262931;
}
