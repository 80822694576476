.IconLike {
  display: block;
  fill: none;
  stroke: currentColor;
  color: inherit;
  height: 100%;
}

.IconLike--filled {
  fill: currentColor;
  stroke: none;
}
