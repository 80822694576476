.Content {
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0.4px !important;
  color: #262931 !important;
}

@media (max-width: 1100px) {
  .Content {
    font-size: 18px !important;
  }
}

@media (max-width: 640px) {
  .Content {
    font-size: 16px !important;
  }
}

.Content--expanded {
  max-width: none;
}

.Content--customTextProps {
  font-size: inherit !important;
}

/* Header tags */

.Content h1 {
  font-size: 1.6em !important;
  line-height: 1.2 !important;
  margin-bottom: 1.3em !important;
  margin-top: 2.2em !important;
  font-weight: 700 !important;
}

@media (max-width: 1100px) {
  .Content h1 {
    margin-bottom: 1.1em !important;
  }
}

@media (max-width: 840px) {
  .Content h1 {
    font-size: 1.4em !important;
  }
}

@media (max-width: 640px) {
  .Content h1 {
    margin-top: 2em !important;
    margin-bottom: 1em !important;
  }
}

.Content h2 {
  font-size: 1.35em !important;
  line-height: 1.2 !important;
  margin-bottom: 0.88em !important;
  margin-top: 1.75em !important;
  font-weight: 700 !important;
}

@media (max-width: 1100px) {
  .Content h2 {
    margin-bottom: 0.48em !important;
  }
}

@media (max-width: 640px) {
  .Content h2 {
    margin-top: 1.4em !important;
  }
}

@media (max-width: 480px) {
  .Content h2 {
    font-size: 1.2em;
  }
}

.Content h2:first-child {
  margin-top: 0;
}

.Content h3 {
  font-size: 1.15em !important;
  line-height: 1.2 !important;
  margin-bottom: 0.64em !important;
  margin-top: 1.4em !important;
  font-weight: 700 !important;
}

@media (max-width: 1100px) {
  .Content h3 {
    margin-bottom: 0.32em !important;
  }
}

@media (max-width: 640px) {
  .Content h3 {
    margin-top: 1.1em !important;
  }
}

@media (max-width: 480px) {
  .Content h3 {
    font-size: 1.2em;
  }
}

.Content h3:first-child {
  margin-top: 0;
}

.Content h4 {
  font-size: 1em !important;
  line-height: 1.4 !important;
  font-weight: 700 !important;
  margin-top: 1em !important;
  margin-bottom: 0.44em !important;
}

.Content h5 {
}

.Content h6 {
}

/* Block tags */

.Content p {
  margin: 1em 0 !important;
}

@media (max-width: 1100px) {
  .Content p {
    margin: 0.8em 0 !important;
  }
}

@media (max-width: 480px) {
  .Content p {
    line-height: 1.5;
  }
}

.Content p:last-child {
  margin-bottom: 0 !important;
}

.Content ul {
  margin: 0 !important;
  margin-bottom: 1.77em !important;
  padding: 0 !important;
  padding-left: 2.66em !important;
}

.Content ol {
  margin: 0 !important;
  margin-bottom: 1.77em !important;
  padding: 0 !important;
  padding-left: 2.66em !important;
}

.Content li {
  margin-bottom: 0.44em !important;
}

.Content li:last-child {
  margin-bottom: 0;
}

.Content blockquote {
  position: relative;
  max-width: 450px;
  margin: 1em auto;
  line-height: 1.4;
  /* font-family: 'PT Serif', sans-serif; */
  font-size: 1.33em;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}

.Content pre {
}

.Content dl {
}

.Content dd {
}

.Content dt {
}

/* Inline tags */

.Content a {
}

.Content i {
}

.Content b {
  font-weight: 700;
}

.Content em {
}

.Content strong {
}

.Content mark {
}

.Content code {
}

.Content abbr {
}

.Content cite {
  position: relative;
  display: inline-block;
  margin-top: 0.88em;
  font-size: 0.66em;
  font-family: 'PT Serif';
  font-style: normal;
  color: #191d23;
}

.Content cite::before {
  content: '';
  position: absolute;
  left: -28px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 16px;
  border-top: 2px solid #ffd12c;
}

/* Embed tags */

.Content figure {
  margin: 1em 0;
  border-bottom: 1px solid #edf0f5;
}

.Content figcaption {
  padding: 0.88em 0;
  font-size: 0.75em;
  color: #262931;
  text-align: center;
}

.Content picture {
}

.Content video {
}

.Content img {
  display: inline-block;
  margin: 1em 0;
  max-width: 100% !important;
  vertical-align: top;
}

.Content figure img {
  margin: 0;
}

.Content iframe {
  display: block;
  margin: 0 auto;
}

/* Сили для старых статей */

.Content::after {
  content: '';
  display: table;
  clear: both;
}
