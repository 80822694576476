.ArticlePreview {
  display: flex;
}

@media screen and (max-width: 840px) {
  .ArticlePreview {
    display: block;
  }
}

.ArticlePreview-imageLink {
  flex: 0 0 auto;
  display: block;
  width: 360px;
  margin-right: 32px;
}

@media screen and (max-width: 840px) {
  .ArticlePreview-imageLink {
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
  }
}

.ArticlePreview-image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.ArticlePreview-info {
}

.ArticlePreview-date {
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 16px;
}

.ArticlePreview-date::before {
  content: '';
  display: block;
  height: 2px;
  width: 32px;
  background-color: #ffd12c;
  margin-right: 16px;
  transition: 0.2s ease-out;
}

.ArticlePreview-title {
  font-size: 24px;
  margin: 8px 0;
}

.ArticlePreview-titleLink {
  text-decoration: none;
  display: inline-block;
  line-height: 32px;
}

.ArticlePreview-content {
}

.ArticlePreview-toArticle {
  margin-top: 16px;
}
