.SelectCityMobile {
}

.SelectCityMobile-button {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 11px 24px;
  border-radius: 20px;
  height: 40px;
  line-height: 16px;
  font-size: 14px;
  font-family: inherit;
  background-color: #fff;
  border: 1px solid #dfe3ea;
  appearance: none;
  outline: none;
  text-align: left;
}
