.SubcategoryCarousel {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 8px 0;
}

.SubcategoryCarousel-content {
  margin: 0 -8px;
  display: inline-flex;
  overflow: visible;
  width: 100%;
  box-sizing: border-box;
  vertical-align: top;
}

.SubcategoryCarousel-item {
  flex: 0 0 30%;
  max-width: 30%;
  box-sizing: content-box;
  margin: 0 8px;
}

.SubcategoryCarousel-item:first-child {
  padding-left: 16px;
}

.SubcategoryCarousel-item:last-child {
  padding-right: 16px;
}

@media (max-width: 840px) {
  .SubcategoryCarousel-item {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 640px) {
  .SubcategoryCarousel-item {
    flex: 0 0 40%;
    max-width: 40%;
  }
}
