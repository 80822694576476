.ProblemSkin_Slider {
  background: rgba(142, 51, 134, 0.05);
  padding: 80px 0;
}

.ProblemSkin_Slider-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin: auto;
  margin-bottom: 60px;
  text-align: center;
  max-width: 600px;
}

.ProblemSkin_Slider-carousel {
}

.ProblemSkin_Slider-item {
}

.ProblemSkin_Slider-image {
  width: 100%;
  height: auto;
}
