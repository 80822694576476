.ReadNextArticle {
}

.ReadNextArticle-suggest {
  margin-bottom: 16px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  color: #262931;
}

.ReadNextArticle-contentGroup {
  position: relative;
}

.ReadNextArticle-imageHolder {
  margin-bottom: 12px;
}

.ReadNextArticle-image {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.ReadNextArticle-title {
  margin: 0;
  line-height: 1.2;
}

.ReadNextArticle-link {
  display: inline-block;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  color: #262931;
}

.ReadNextArticle-link::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
