.FilterTag {
  vertical-align: middle;
  display: inline-flex;
  box-sizing: border-box;
  padding: 2px;
  line-height: 24px;
  border: 1px solid #dfe2e7;
  border-radius: 32px;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
}

@media (max-width: 840px) {
  .FilterTag {
    padding: 0;
  }
}

.FilterTag-title {
  display: inline-block;
  padding-left: 16px;
  padding-right: 8px;
}

.FilterTag-remove {
}

.FilterTag-removeButton {
  appearance: none;
  font: inherit;
  border: none;
  background-color: transparent;
  padding: 4px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  vertical-align: top;
  line-height: 1;
  display: block;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
}

@media (max-width: 840px) {
  .FilterTag-removeButton {
    opacity: 0.5;
  }
}

.FilterTag-removeButton:focus {
  opacity: 1;
}

.FilterTag-removeButton:hover {
  background-color: #edf0f5;
  opacity: 1;
}

@media (max-width: 840px) {
  .FilterTag-removeButton:hover {
    opacity: 1;
  }
}
