.PaymentAndDeliverySection {
}

/* .PaymentAndDeliverySection-content {
  display: flex;
  padding-top: 40px;
  margin: -16px;
}

@media screen and (max-width: 1100px) {
  .PaymentAndDeliverySection-content {
    display: block;
    margin: 0;
  }
}

.PaymentAndDeliverySection-sidebarCol {
  flex: 0 0 140px;
  max-width: 140px;
  padding: 16px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .PaymentAndDeliverySection-sidebarCol {
    display: none;
  }
}

.PaymentAndDeliverySection-contentCol {
  flex: 1 1 auto;
  padding: 16px;
}

@media screen and (max-width: 1100px) {
  .PaymentAndDeliverySection-contentCol {
    padding: 0;
  }
} */

.PaymentAndDeliverySection-content {
}

.PaymentAndDeliverySection-exchangeAndReturn {
  margin-bottom: 16px;
}

.PaymentAndDeliverySection-deliveryMethods {
  margin-bottom: 64px;
}

@media (max-width: 1100px) {
  .PaymentAndDeliverySection-deliveryMethods {
    margin-bottom: 32px;
  }
}

@media (max-width: 840px) {
  .PaymentAndDeliverySection-deliveryMethods {
    margin-bottom: 0;
  }
}

.PaymentAndDeliverySection-deliveryMethodsGrid {
  display: flex;
  flex-wrap: wrap;
  margin: -32px;
  padding-top: 16px;
}

@media (max-width: 1100px) {
  .PaymentAndDeliverySection-deliveryMethodsGrid {
    margin: -16px;
  }
}

.PaymentAndDeliverySection-deliveryMethodsGridHolder {
  overflow: hidden;
}

.PaymentAndDeliverySection-deliveryMethod {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 32px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .PaymentAndDeliverySection-deliveryMethod {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 16px;
  }
}

.PaymentAndDeliverySection-pickPoints {
  display: flex;
  align-items: flex-start;
  margin: -40px;
  padding-bottom: 64px;
}

@media (max-width: 840px) {
  .PaymentAndDeliverySection-pickPoints {
    flex-wrap: wrap;
    margin: -16px;
    padding-bottom: 0;
  }
}

.PaymentAndDeliverySection-pickPointsHolder {
  overflow: hidden;
}

.PaymentAndDeliverySection-pickPointTextCol {
  flex: 1 1 48%;
  max-width: 48%;
  padding: 40px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .PaymentAndDeliverySection-pickPointTextCol {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 16px;
  }
}

.PaymentAndDeliverySection-pickPointVideoCol {
  flex: 1 1 52%;
  max-width: 52%;
  padding: 40px;
  padding-top: 120px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .PaymentAndDeliverySection-pickPointVideoCol {
    padding-top: 160px;
  }
}

@media (max-width: 840px) {
  .PaymentAndDeliverySection-pickPointVideoCol {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 16px;
  }
}

.PaymentAndDeliverySection-paymentMethods {
  margin-bottom: 32px;
}

.PaymentAndDeliverySection-paymentMethodsText {
  margin-bottom: 32px;
}

.PaymentAndDeliverySection-paymentMethodsMedia {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.PaymentAndDeliverySection-paymentMethodsMediaItem {
  height: 48px;
}

@media (max-width: 840px) {
  .PaymentAndDeliverySection-paymentMethodsMediaItem {
    height: 36px;
  }
}

@media (max-width: 390px) {
  .PaymentAndDeliverySection-paymentMethodsMediaItem {
    height: 28px;
  }
}

.PaymentAndDeliverySection-image {
  display: inline-block;
  height: 100px;
  margin-right: 24px;
}

@media (max-width: 360px) {
  .PaymentAndDeliverySection-image {
    height: 80px;
  }
}

.PaymentAndDeliverySection-image:last-child {
  margin-right: 0;
}

.PaymentAndDeliverySection-dataTransfer {
}

.PaymentAndDeliverySection-payment {
}

.PaymentAndDeliverySection-video {
  overflow: hidden;
  border-radius: 3px;
}
