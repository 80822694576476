.LandingBannerImages {
  display: flex;
  flex-direction: column;
}

.LandingBannerImages-main {
  margin-left: auto;
  z-index: 1;
  position: relative;
}

.LandingBannerImages--reverse .LandingBannerImages-main {
  margin-left: 0;
  margin-right: auto;
}

.LandingBannerImages-secondary {
  margin-right: auto;
  position: relative;
  z-index: 2;
}

.LandingBannerImages--reverse .LandingBannerImages-secondary {
  margin-right: 0;
  margin-left: auto;
}
