.VerticalSlider {
  display: flex;
  align-items: center;
}

.VerticalSlider-navigation {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.VerticalSlider-navigationButton {
  margin-bottom: 16px;
  outline: none;
  border: none;
  padding: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d1d6da;
  cursor: pointer;
  position: relative;
  transform: scale(1);
  transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;
}

.VerticalSlider-navigationButton:last-child {
  margin-bottom: 0;
}

.VerticalSlider-navigationButton:hover {
  box-shadow: 0 0 0 6px rgba(235, 235, 235, 0.6);
}

.isTabUsing .VerticalSlider-navigationButton:focus {
  box-shadow: 0 0 0 6px #fff, 0 0 0 8px #d1d6da;
}

.VerticalSlider-navigationButton::after {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -2px;
  transform: scale(0);
  background-color: #fff;
}

.VerticalSlider-navigationButton--isActive {
  background-color: #ffda4d;
  cursor: default;
  transform: scale(1.25);
}

.VerticalSlider-navigationButton--isActive:hover,
.VerticalSlider-navigationButton--isActive:focus {
  box-shadow: none;
}

.VerticalSlider-navigationButton--isActive::after {
  transform: scale(1);
}

.VerticalSlider-imageHolder {
  flex: 1 1 auto;
  position: relative;
  will-change: transform, opacity, position;
}

.VerticalSlider-image {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
