.CheckedList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
}

.CheckedList-option {
  margin-bottom: 2px;
}

.CheckedList-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: bottom;
  padding-bottom: 4px;
}

.CheckedList--yellow .CheckedList-icon {
  color: #fed835;
}
