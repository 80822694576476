.OpeningTimetable {
}

.OpeningTimetable-title {
  font-size: 30px;
}

.OpeningTimetable-listHolder {
}

.OpeningTimetable-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.OpeningTimetable-item {
  border-bottom: 1px solid #262931;
  font-size: 2px;
}

@media (max-width: 840px) {
  .OpeningTimetable-item {
    border-bottom: 1px solid rgba(38, 41, 49, 0.5);
  }
}
