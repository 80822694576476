.IconSpinner {
  display: block;
  fill: currentColor;
  color: inherit;
  height: 100%;
  stroke: currentColor;
  animation: 0.5s spin linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}
