.CategoryProducts {
}

.CategoryProducts-subcategorySlider {
  padding: 8px 0;
  border-bottom: 1px solid #e6e7e9;
}

.CategoryProducts-header {
}

.CategoryProducts-panel {
  top: 8px;
}

.CategoryProducts-sorting {
  padding: 16px 0;
  margin-top: 20px;
  border-top: 1px solid #eeeff3;
}

.CategoryProducts-appliedFilters {
  margin-top: 20px;
}

@media (max-width: 840px) {
  .CategoryProducts-appliedFilters {
    margin-top: 16px;
  }
}

.CategoryProducts-productList {
  margin-top: 48px;
}

@media (max-width: 1100px) {
  .CategoryProducts-productList {
    margin-top: 40px;
  }
}

@media (max-width: 840px) {
  .CategoryProducts-productList {
    margin-top: 32px;
  }
}

@media (max-width: 640px) {
  .CategoryProducts-productList {
    margin-top: 24px;
  }
}

@media (max-width: 4640px) {
  .CategoryProducts-productList {
    margin-top: 16px;
  }
}

.CategoryProducts-filterSummary {
  text-align: center;
}

@media (max-width: 1240px) {
  .CategoryProducts-filterSummary {
    margin-top: 8px;
  }
}
