.Input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.Input--small {
}

.Input--focused {
}

.Input-field {
  appearance: none;
  outline: none;
  display: inline-block;
  width: 100%;
  background: none;
  background-color: #fff;
  padding: 16px 32px;
  line-height: 16px;
  font-size: 14px;
  height: 50px;
  border: 1px solid #dfe3ea;
  border-radius: 25px;
  color: #262931;
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  cursor: default;
}

.Input--password .Input-field {
  /* font-size: 42px;
  padding-bottom: 16px; */
}

.Input-field[type='number']::-webkit-outer-spin-button,
.Input-field[type='number']::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.Input-field[type='number'] {
  -moz-appearance: textfield;
}

.Input--withoutStroke .Input-field {
  border-color: transparent;
}

.Input-field:focus {
  border-color: #a1a6af;
}

.Input--small .Input-field {
  padding: 11px 24px;
  border-radius: 20px;
  height: 40px;
}

.Input--nocontrols .Input-field::-webkit-clear-button,
/* .Input--nocontrols .Input-field::-webkit-calendar-picker-indicator, */
.Input--nocontrols .Input-field::-webkit-inner-spin-button {
  display: none;
}

.Input--focused .Input-field {
  border-color: #444952;
}

.Input--focused.Input--withoutStroke .Input-field {
  border-color: transparent;
}

.Input--withLeftGap .Input-field {
  padding-left: 50px;
}

.Input--small.Input--withLeftGap .Input-field {
  padding-left: 40px;
}

.Input--withRightGap .Input-field {
  padding-right: 50px;
}

.Input--small.Input--withRightGap .Input-field {
  padding-right: 40px;
}

.Input-button {
  padding: 4px;
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(-50%, -50%);
}

.Input-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: top;
}

.Input--small .Input-button {
  right: 4px;
}

.Input--small .Input-icon {
  width: 14px;
  height: 14px;
}

.Input-prepended,
.Input-appended {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  top: 0;
}

.Input-prepended {
  left: 0;
}

.Input-appended {
  right: 0;
}

.Input--small .Input-prepended,
.Input--small .Input-appended {
  width: 40px;
  height: 40px;
}

.Input--error .Input-field {
  /* color: #e74a5a; */
  border-color: #e74a5a;
}

.Input--selectable .Input-field {
  cursor: text;
}

.Input-field:disabled {
  cursor: default;
  background-color: #fafafa;
  color: #999999;
}

.Input-field:-webkit-autofill {
  animation-name: onAutoFillStart;
}

.Input-field:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

@keyframes onAutoFillStart {
}
@keyframes onAutoFillCancel {
}

.Input--autofilled .Input-field:-webkit-autofill {
}

.Input--fake {
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  display: 'none';
  opacity: 0;
  position: 'absolute';
  left: '-100000px';
}
