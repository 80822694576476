.BonusCard {
  background-color: #f4f8ff;
  height: 100%;
  padding: 24px;
  border-radius: 3px;
  box-sizing: border-box;
}

.BonusCard--type-default {
}

.BonusCard--type-extra {
  background-color: #eaf9ee;
}

.BonusCard-title {
  margin: 0;
  margin-bottom: 16px;
  line-height: 1.2;
}

.BonusCard-amount {
  line-height: 48px;
  font-size: 48px;
}

.BonusCard-currency {
  font-weight: 400;
}

.BonusCard-deadlineList {
  margin: 0;
  padding: 0;
}

.BonusCard-deadlineItem {
  margin-bottom: 8px;
  list-style: none;
}

.BonusCard-deadlineItem:last-child {
  margin-bottom: 0;
}

.BonusCard-deadlineEmpty {
  display: inline-block;
  width: 16px;
  border-top: 1px solid currentColor;
}
