.AppliedFilters {
}

@media (max-width: 840px) {
  .AppliedFilters {
    overflow-x: auto;
    padding-bottom: 8px;
    -webkit-overflow-scrolling: touch;
  }
}

.AppliedFilters-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 840px) {
  .AppliedFilters-content {
    display: inline-flex;
    overflow: hidden;
    overflow: visible;
    vertical-align: top;
  }
}

.AppliedFilters-list {
  list-style: none;
  padding: 0;
  font-size: 14px;
  display: flex;
  margin: -8px;
  flex-wrap: wrap;
  padding-right: 20px;
}

@media (max-width: 840px) {
  .AppliedFilters-list {
    display: inline-flex;
    flex-wrap: nowrap;
    overflow: visible;
    vertical-align: top;
  }
}

.AppliedFilters-item {
  margin: 8px;
  overflow: hidden;
}

@media (max-width: 840px) {
  .AppliedFilters-item:first-child {
    padding-left: 16px;
  }
}

.AppliedFilters-clear {
  flex: 0 0 auto;
  line-height: 30px;
  font-size: 14px;
}

@media (max-width: 840px) {
  .AppliedFilters-clear {
    line-height: 26px;
    padding-right: 16px;
  }
}
