.SpecialOfferPreview {
}

.SpecialOfferPreview--status-default {
}

.SpecialOfferPreview--status-expiring {
}

.SpecialOfferPreview--status-ended {
}

.SpecialOfferPreview-media {
  margin-bottom: 16px;
}

@media (max-width: 640px) {
  .SpecialOfferPreview-media {
    margin-bottom: 8px;
  }
}

.SpecialOfferPreview-link {
  text-decoration: none;
}

.SpecialOfferPreview-title {
  margin: 0;
  line-height: 1.2;
  font-size: 24px;
  margin-bottom: 8px;
}

@media (max-width: 1100px) {
  .SpecialOfferPreview-title {
    font-size: 18px;
  }
}

@media (max-width: 840px) {
  .SpecialOfferPreview-title {
    font-size: 24px;
  }
}

@media (max-width: 640px) {
  .SpecialOfferPreview-title {
    font-size: 20px;
  }
}

.SpecialOfferPreview-description {
  font-size: 16px;
  margin-bottom: 24px;
}

@media (max-width: 640px) {
  .SpecialOfferPreview-description {
    margin-bottom: 16px;
  }
}

.SpecialOfferPreview-promoCodeGroup {
  margin-bottom: 16px;
}

.SpecialOfferPreview-expireGroup {
}

.SpecialOfferPreview--status-expiring .SpecialOfferPreview-expireGroup {
  color: #e95958;
}

.SpecialOfferPreview--status-ended .SpecialOfferPreview-expireGroup {
  color: #818690;
}

.SpecialOfferPreview-expireIcon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.SpecialOfferPreview--status-ended .SpecialOfferPreview-expireIcon {
  display: none;
}

.SpecialOfferPreview-expireCaption {
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;
  font-size: 16px;
}

@media (max-width: 480px) {
  .SpecialOfferPreview-expireCaption {
    font-size: 14px;
  }
}

.SpecialOfferPreview a {
  text-decoration: none;
}
