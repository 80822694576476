.OrderInfo {
  margin-bottom: 16px;
}

.OrderInfo-state {
  text-align: center;
}

.OrderInfo-dialog {
}
