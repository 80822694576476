.Activities {
  overflow: hidden;
  padding-top: 64px;
}

.Activities-holder {
  display: flex;
  margin: -16px;
}

@media (max-width: 1100px) {
  .Activities-holder {
    flex-direction: column;
  }
}

.Activities-item {
  padding: 16px;
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
}

@media (max-width: 1100px) {
  .Activities-item {
    flex-basis: 100%;
    max-width: 100%;
  }

  .Activities-item:first-child {
    margin-bottom: 64px;
  }
}
