.SectionFeedbackForm {
  padding-top: 16px;
}

@media (max-width: 840px) {
  .SectionFeedbackForm {
    padding: 16px 0;
  }
}

.SectionFeedbackForm-title {
  text-align: center;
  font-size: 24px;
}

@media (max-width: 840px) {
  .SectionFeedbackForm-title {
    font-size: 18px;
  }
}
