.UTMDialog {
  padding: 16px 52px 48px;
  max-width: 550px;
}

@media (max-width: 840px) {
  .UTMDialog {
    padding: 16px 24px 24px;
  }
}

.UTMDialog-title {
  margin-top: 32px;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: bold;
}

@media (max-width: 840px) {
  .UTMDialog-title {
    font-size: 24px;
    line-height: 24px;
    margin-top: 8px;
  }
}

@media (max-width: 840px) {
  .UTMDialog-description {
    max-height: 200px;
    overflow-y: auto;
  }
}

.UTMDialog-promo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #262931;
}

.UTMDialog-promoTitle {
  margin-right: 16px;
}

.UTMDialog-close {
  margin-top: 16px;
}
