.BonusInputHeader {
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media (max-width: 840px) {
  .BonusInputHeader {
    justify-content: center;
    font-size: 14px;
  }
}

.BonusInputHeader-info {
  margin-left: 16px;
}

.BonusInputHeader-infoLabel {
  max-width: 200px;
}

.BonusInputHeader-action {
  margin-left: 16px;
  font-weight: 400;
}

.BonusInputHeader-loader {
  position: relative;
  width: 50px;
  margin-left: 16px;
}
