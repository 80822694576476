.SectionCategoryProducts {
  /* overflow: hidden; */
  padding-top: 50px;
}

@media (max-width: 1240px) {
  .SectionCategoryProducts {
    padding-top: 0;
  }
}
