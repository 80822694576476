.LandingButton {
  border: none;
  padding: 18px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-out;
  outline: none;
  appearance: none;
  text-decoration: none;
  position: relative;
}

.LandingButton--fullWidth {
  width: 100%;
}

.LandingButton--dark {
  color: black;
}

.LandingButton--small {
  padding: 12px;
}

.LandingButton-BG {
  /* background-color: #a04ab0; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0.2s ease-out;
}

.LandingButton:not(:disabled):hover .LandingButton-BG {
  opacity: 0.7;
}

.LandingButton-content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.LandingButton-icon {
  width: 24px;
  margin-right: 8px;
}

.LandingButton-title {
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  font-style: italic;
}

.LandingButton--small .LandingButton-title {
  font-size: 12px;
}
