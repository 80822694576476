.Profile_Navigation {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #818690;
}

.Profile_Navigation-item {
  display: block;
  position: relative;
  overflow: hidden;
  list-style: none;
  margin-bottom: 12px;
}

@media screen and (max-width: 840px) {
  .Profile_Navigation-item {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 840px) {
  .Profile_Navigation-item:last-child {
    margin-top: 16px;
    text-align: center;
  }
}

.Profile_Navigation-separator {
  margin: 8px 0;
  border-bottom: 1px solid #dfe2ee;
}

@media screen and (max-width: 840px) {
  .Profile_Navigation-separator {
    display: none;
  }
}

.Profile_Navigation-itemLabel {
  margin-left: 16px;
}
