.FeaturedBanner {
  position: relative;
  color: #262931;
  overflow: hidden;
  display: flex;
}

@media (max-width: 1100px) {
  .FeaturedBanner {
    flex-direction: column;
    text-align: center;
  }
}

.FeaturedBanner-mediaHolder {
  position: relative;
  flex: 0 0 830px;
  max-width: 830px;
  overflow: hidden;
  border-radius: 3px;
}

.FeaturedBanner-mediaLink {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: grab;
}

.FeaturedBanner-mediaLinkContent {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.FeaturedBanner-imageHolder {
  position: relative;
}

.FeaturedBanner-videoHolder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.FeaturedBanner--isVideoLoaded .FeaturedBanner-videoHolder {
  opacity: 1;
}

@media (max-width: 1200px) {
  .FeaturedBanner-mediaHolder {
    flex: 0 0 750px;
    max-width: 750px;
  }
}

@media (max-width: 1100px) {
  .FeaturedBanner-mediaHolder {
    flex: 0 0 auto;
    max-width: 100%;
  }
}

.FeaturedBanner-media {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.FeaturedBanner-contentHolder {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 24px;
  padding-bottom: 48px;
  color: #262931;
  flex: 1 1 auto;
}

@media (max-width: 1100px) {
  .FeaturedBanner-contentHolder {
    padding: 24px 0;
    align-items: flex-start;
  }
}

@media (max-width: 840px) {
  .FeaturedBanner-contentHolder {
    padding: 8px 0;
  }
}

.FeaturedBanner-label {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.FeaturedBanner-title {
  font-size: 42px;
  line-height: 48px;
  margin: 0;
}

@media (max-width: 1100px) {
  .FeaturedBanner-title {
    font-size: 32px;
    line-height: 42px;
    padding: 0 32px;
  }
}

@media (max-width: 840px) {
  .FeaturedBanner-title {
    font-size: 24px;
    line-height: 32px;
    padding: 0 8px;
  }
}

.FeaturedBanner-description {
  font-size: 18px;
}

@media (max-width: 1100px) {
  .FeaturedBanner-description {
    margin: 14px 0 32px;
    padding: 0 32px;
  }
}

@media (max-width: 840px) {
  .FeaturedBanner-description {
    margin: 8px 0 0;
    padding: 0 16px;
  }
}

.FeaturedBanner-button {
  margin-bottom: 16px;
}

.FeaturedBanner-button:last-child {
  margin-bottom: 0;
}

.FeaturedBanner-actions {
  display: inline-block;
  min-width: 240px;
}

.FeaturedBanner-countDownHolder {
  margin-bottom: 16px;
  display: flex;
  /* justify-content: center; */
}
@media (max-width: 1100px) {
  .FeaturedBanner-countDownHolder {
    justify-content: center;
  }
}

.FeaturedBanner-countDownExpired {
  display: inline-block;
}
.FeaturedBanner-countDown {
  display: inline-block;
}
