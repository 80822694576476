.RegistrationDialog {
}

.RegistrationDialog-content {
  padding: 48px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .RegistrationDialog-content {
    padding: 24px 48px;
  }
}

@media (max-width: 680px) {
  .RegistrationDialog-content {
    padding: 24px 32px;
  }
}

@media (max-width: 480px) {
  .RegistrationDialog-content {
    padding: 24px 16px;
  }
}
