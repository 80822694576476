.SubscriptionScene {
}

.SubscriptionScene-title {
  margin: 0;
  margin-bottom: 32px;
  line-height: 1.2;
  font-size: 22px;
}

.SubscriptionScene-channelList {
  margin: 0;
  padding: 0;
}

.SubscriptionScene-channelItem {
  margin-bottom: 42px;
  list-style: none;
}

.SubscriptionScene-loader {
  position: relative;
  padding: 120px 0;
}
