.IconSimpleBasketAdd {
  display: inline-block;
  color: inherit;
  height: 100%;
}

.IconSimpleBasketAdd path:first-child {
  fill: currentColor;
  stroke: none;
}

.IconSimpleBasketAdd path:last-child {
  fill: none;
  stroke: currentColor;
}
