.RegistrationForm {
}

@media (max-width: 640px) {
  .RegistrationForm {
    text-align: center;
  }
}

.RegistrationForm-userAgreement {
  text-align: left;
}

@media (max-width: 480px) {
  .RegistrationForm-userAgreement {
    font-size: 14px;
  }
}

.RegistrationForm-authorizationSuggest {
}

@media (max-width: 480px) {
  .RegistrationForm-authorizationSuggest {
    font-size: 14px;
  }
}

.RegistrationForm-authorizationSuggestText {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

@media (max-width: 480px) {
  .RegistrationForm-authorizationSuggestText {
    margin-right: 4px;
  }
}

.RegistrationForm-userAgreementLink {
}
