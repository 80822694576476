.ProblemSkin_ProductPlusGift {
  padding: 80px 0;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift {
    padding: 32px 0;
  }
}

.ProblemSkin_ProductPlusGift-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7vw;
  align-items: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-main {
    display: block;
    /* flex-direction: column; */
    grid-gap: inherit;
    align-items: inherit;
    grid-template-columns: inherit;
  }
}

.ProblemSkin_ProductPlusGift-visual {
  margin-bottom: 24px;
}

.ProblemSkin_ProductPlusGift-imageHolder {
  display: flex;
  justify-content: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-imageHolder {
    max-width: 300px;
    margin: 0 auto;
    display: block;
  }
}

.ProblemSkin_ProductPlusGift-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center center;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-image {
    /* max-width: 300px; */
  }
}

.ProblemSkin_ProductPlusGift-info {
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-info {
    width: 100%;
  }
}

.ProblemSkin_ProductPlusGift-tile {
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 22px;
  padding: 64px 32px;
  text-align: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-tile {
    padding: 42px 30px;
  }
}

.ProblemSkin_ProductPlusGift-title {
  font-size: 38px;
  line-height: 1.5;
  font-weight: bold;
  max-width: 740px;
  margin: auto;
  text-align: center;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-title {
    font-size: 20px;
  }
}

.ProblemSkin_ProductPlusGift-titleGift {
  font-weight: 600;
  font-size: 26px;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-titleGift {
    font-size: 20px;
  }
}

.ProblemSkin_ProductPlusGift-price {
  color: #8e3387;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 42px;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-price {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.ProblemSkin_ProductPlusGift-addToBasket {
}

.ProblemSkin_ProductPlusGift-giftLink {
  color: #8e3387;
  font-size: 42px;
  text-decoration: none;
}

@media (max-width: 1100px) {
  .ProblemSkin_ProductPlusGift-giftLink {
    font-size: 20px;
  }
}

.ProblemSkin_ProductPlusGift-promo {
  text-align: center;
  /* border: 1px dotted */
}

.ProblemSkin_ProductPlusGift-promoInfo {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 16px;
}

.ProblemSkin_ProductPlusGift-promoCode {
}
