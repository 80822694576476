.CategoryFieldTrigger {
  -webkit-appearance: none;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  outline: none;
  width: 100%;
}

.CategoryFieldTrigger-icon {
  vertical-align: middle;
  display: inline-block;
  line-height: 1;
  margin-right: 8px;
}

.CategoryFieldTrigger-text {
  vertical-align: middle;
  display: inline-block;
}

.CategoryFieldTrigger-count {
  vertical-align: middle;
  display: inline-block;
  margin-left: 8px;
  background-color: #ffd200;
  border-radius: 25px;
  padding: 2px 10px;
  line-height: 16px;
  font-size: 14px;
}
