.ProfileDropdown {
}

.ProfileDropdown-container {
  padding: 0 24px;
}

.ProfileDropdown-navigation {
  margin: 0;
  padding: 0;
}

.ProfileDropdown-item {
  display: block;
  position: relative;
  overflow: hidden;
  list-style: none;
  margin-bottom: 12px;
  padding-right: 8px;
}

.ProfileDropdown-item--noGap {
  padding-right: 0;
}

.ProfileDropdown-itemSeparator {
  margin: 8px 0;
  border-bottom: 1px solid #dfe2ee;
}

.ProfileDropdown-profilePanel {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  background-color: #f6f8fb;
}

.ProfileDropdown-avatar {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 18px;
  margin-right: 16px;
}

.ProfileDropdown-profileTitle {
  font-weight: 700;
}

.ProfileDropdown-profileLink {
  text-decoration: none;
}
