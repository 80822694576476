.UserWelcome {
}

.UserWelcome-greeting {
  line-height: 1.3;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .UserWelcome-greeting {
    font-size: inherit;
  }
}

.UserWelcome-bonusesPanel {
  margin-top: 12px;
}

.UserWelcome-cardsHolder {
  display: flex;
  align-items: center;
}

.UserWelcome-card {
  flex: 1 1 50%;
  max-width: 50%;
  text-align: center;
  cursor: default;
}

.UserWelcome-card--bonuses-remained {
  position: relative;
  padding-right: 32px;
}

@media screen and (max-width: 840px) {
  .UserWelcome-card--bonuses-remained {
    padding-right: 16px;
  }
}

.UserWelcome-card--bonuses-remained::after {
  content: '';
  position: absolute;
  display: inline-block;
  top: -10px;
  bottom: -10px;
  right: 0;
  border-right: 1px solid #e2e8f3;
}

.UserWelcome-card--bonuses-available {
  padding-left: 32px;
}

@media screen and (max-width: 840px) {
  .UserWelcome-card--bonuses-available {
    padding-right: 16px;
  }
}

.UserWelcome-cardTitle {
  line-height: 1.3;
  font-size: 14px;
}

.UserWelcome-bonusesAmount {
  font-size: 18px;
  font-weight: bold;
}

.UserWelcome-card--bonuses-available .UserWelcome-bonusesAmount {
  color: #25bc8b;
}
