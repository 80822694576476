.Preheader {
  background-color: #f6f8fb;
  padding: 8px 0;
}

@media (max-width: 1100px) {
  .Preheader {
    display: none;
  }
}

.Preheader-content {
  display: flex;
  justify-content: space-between;
}
