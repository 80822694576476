.MassMediaSortPanel {
  border-bottom: 1px solid #e6e7e9;
}

.MassMediaSortPanel-action {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: inherit;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  outline: none;
}

.MassMediaSortPanel-action:hover {
  background-color: #f8f8fc;
}

.MassMediaSortPanel-action:active {
  background-color: #eceff6;
}

.MassMediaSortPanel-actionTitle {
  margin-right: 8px;
}

.MassMediaSortPanel-actionIcon {
  height: 12px;
}
