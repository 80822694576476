.Hero {
  overflow: hidden;
}

.Hero--1cols {
}

.Hero-itemsWrapper {
  display: flex;
  margin: -16px;
}

@media (max-width: 840px) {
  .Hero-itemsWrapper {
    margin: -8px;
  }
}

.Hero-item {
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .Hero-item {
    padding: 8px;
  }
}

.Hero--2cols .Hero-item {
  flex: 1 1 50%;
  max-width: 50%;
}

.Hero--3cols .Hero-item {
  flex: 1 1 33.333%;
  max-width: 33.333%;
}
