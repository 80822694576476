.CitySuggestBottomSheet {
  padding: 12px 0 16px;
}

.CitySuggestBottomSheet-header {
  position: relative;
  padding: 12px 0;
}

.CitySuggestBottomSheet-buttonClose {
  position: absolute;
  top: -10px;
  right: -12px;
}

.CitySuggestBottomSheet-title {
  margin: 0;
  line-height: 1.2;
  font-size: 18px;
  letter-spacing: 0.3px;
}

.CitySuggestBottomSheet-info {
  margin-bottom: 24px;
  line-height: 1.4;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.CitySuggestBottomSheet-actions {
  display: flex;
  align-items: center;
  margin: -4px;
}

.CitySuggestBottomSheet-actionCol {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 4px;
  box-sizing: border-box;
}
