.SortOptions {
  overflow: hidden;
  padding: 0 16px;
}

.SortOptions-title {
  display: inline-block;
  padding-right: 24px;
  margin: 8px 0;
}

.SortOptions-holder {
  display: inline-block;
  margin: -4px;
}

.SortOptions-item {
  margin: 4px;
  padding: 4px;
  display: inline-block;
}
