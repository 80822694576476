.CategoryHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e7e9;
}

.CategoryHeader-item {
  flex: 1 0 auto;
  position: relative;
}

.CategoryHeader-narrowItem {
  flex: 0 0 auto;
}

.CategoryHeader-item::after {
  position: absolute;
  content: '';
  display: block;
  height: 30px;
  width: 1px;
  background-color: #eceff6;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.CategoryHeader-item:last-child::after {
  display: none;
}
