.BonusHistoryScene {
}

.BonusHistoryScene-suggestion {
  margin-top: 24px;
}

.BonusHistoryScene-suggestionText {
  margin-bottom: 16px;
  font-size: 18px;
}

@media (max-width: 640px) {
  .BonusHistoryScene-suggestionText {
    font-size: 16px;
  }
}

.BonusHistoryScene-suggestionButton {
  text-align: center;
}

.BonusHistoryScene-title {
  display: none;
}

@media (max-width: 840px) {
  .BonusHistoryScene-title {
    display: block;
    margin: 0;
    margin-bottom: 24px;
    line-height: 1.2;
    font-size: 24px;
    text-align: center;
  }
}

.BonusHistoryScene-loader {
  position: relative;
  padding: 160px 0;
}

@media (max-width: 840px) {
  .BonusHistoryScene-loader {
    padding: 80px 0;
  }
}

.BonusHistoryScene-emptyMessage {
}
