.SquareGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 360px;
  grid-gap: 24px;
}

.SquareGrid-item {
  grid-column-end: span 1;
  grid-row-end: span 1;
}

.SquareGrid-itemLarge {
  grid-column-end: span 2;
  grid-row-end: span 2;
}
