.Sorting {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.Sorting-info {
}

.Sorting-select {
}
