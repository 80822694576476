.DrawRollUp {
  font-family: 'RF Dewi', sans-serif;
  font-weight: 900;
}

.DrawRollUp-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 840px) {
  .DrawRollUp-content {
    flex-direction: column;
    align-items: stretch;
  }
}

.DrawRollUp-contentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 840px) {
  .DrawRollUp-contentInfo {
    align-items: flex-start;
  }
}

.DrawRollUp-action {
  margin-left: 60px;
}

@media (max-width: 840px) {
  .DrawRollUp-action {
    margin-left: 0;
    margin-top: 22px;
  }
}

.DrawRollUp-contentTitle {
  font-size: 22px;
  line-height: 22px;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
}

@media (max-width: 840px) {
  .DrawRollUp-contentTitle {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 8px;
  }
}

.DrawRollUp-contentCaptionCoverWrapper {
  position: relative;
}

.DrawRollUp-contentCaptionWrapper {
  width: 350px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

@media (max-width: 840px) {
  .DrawRollUp-contentCaptionWrapper {
    width: 265px;
  }
}

.DrawRollUp-contentCaptionBlock {
  position: relative;
  width: 278px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #fff;
  z-index: 1;
}

@media (max-width: 840px) {
  .DrawRollUp-contentCaptionBlock {
    font-size: 9px;
    height: 16px;
    width: 187px;
  }
}

.DrawRollUp-contentCaptionBlock:after {
  content: '';
  position: absolute;
  right: -24px;
  top: -21px;
  height: 56px;
  width: 25px;
  background-color: #fff;
  display: inline-block;
  transform: rotate(45deg);
}

.DrawRollUp-contentCaptionBlock:before {
  content: '';
  position: absolute;
  left: -20px;
  top: -7px;
  height: 56px;
  width: 25px;
  background-color: #fff;
  display: inline-block;
  transform: rotate(225deg);
}

.DrawRollUp-contentCaptionBlockCover {
  position: absolute;
  width: 316px;
  height: 4px;
  background-color: #fdb6db;
  top: 32px;
  left: 4px;
}

@media (max-width: 840px) {
  .DrawRollUp-contentCaptionBlockCover {
    top: 14px;
    left: 26px;
    width: 222px;
  }
}

.DrawRollUp-contentCaptionBlockCover:after {
  content: '';
  position: absolute;
  right: -17px;
  top: -34px;
  height: 42px;
  width: 6px;
  background-color: #fdb6db;
  display: inline-block;
  transform: rotate(45deg);
}

@media (max-width: 840px) {
  .DrawRollUp-contentCaptionBlockCover:after {
    right: -9px;
    top: -17px;
    height: 22px;
    width: 6px;
  }
}
