.DottedList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.DottedList--table {
  display: table;
  width: 100%;
}

.DottedList-row {
  display: table-row;
}

.DottedList--markedList .DottedList-row:before {
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  color: inherit;
  background-color: currentColor;
  border-radius: 50%;
}

.DottedList-titleCell {
  display: table-cell;
  padding: 4px;
  vertical-align: top;
}

.DottedList-valueCell {
  display: table-cell;
  padding: 4px;
  vertical-align: top;
}

.DottedList-valueCell {
  width: 1%;
  white-space: nowrap;
}

.DottedList-titleHolder {
  display: flex;
}

.DottedList-item {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 0;
}

.DottedList-dots {
  flex: 1 1 20px;
  margin: 0 5px;
  min-width: 40px;
  /* background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='#262931' width='10' height='10'><circle  cx='5' cy='5' r='1' /></svg>"); */
  background-image: url(/content/svg/dot.svg);
  background-repeat: repeat-x;
  background-position: left bottom 6px;
}

.DottedList-title {
  order: -1;
  white-space: nowrap;
}

.DottedList--highlightedTitle .DottedList-title {
  font-weight: bold;
}

.DottedList-importantTitle {
  margin-left: 8px;
  font-weight: bold;
}

.DottedList-value {
  display: inline-block;
  line-height: 1.2;
}

.DottedList-importantInfo {
  font-weight: bold;
}

.DottedList-importantValue {
  font-weight: bold;
}
