.ReviewReply {
  font-size: 14px;
}

.ReviewReply-header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.ReviewReply-name {
  font-weight: bold;
  font-size: 16px;
  margin-right: 16px;
}

.ReviewReply-date {
  font-weight: 400;
  color: #cacacc;
  margin-left: auto;
}
@media (max-width: 360px) {
  .ReviewReply-date {
    flex-basis: 100%;
    text-align: left;
  }
}

@media (max-width: 770px) {
  .ReviewReply-date {
    text-align: left;
    flex-basis: 100%;
    margin-top: 8px;
  }
}

.ReviewReply-text {
  margin-top: 8px;
}
