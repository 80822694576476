.IconEye {
  display: block;
  color: inherit;
  height: 100%;
}

.IconEye-coloredItem {
  fill: none;
  stroke: currentColor;
}
