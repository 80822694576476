.DropdownOption {
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 16px;
  line-height: 24px;
  cursor: pointer;
  text-align: left;
}

.DropdownOption--active {
  font-weight: 700;
}

.DropdownOption:hover {
  background-color: #dce4eb;
}

.DropdownOption:focus {
  outline: none;
  background-color: #dce4eb;
}

.DropdownOption-icon {
  width: 16px;
  height: 16px;
  font-size: 14px;
  margin-right: 16px;
}
