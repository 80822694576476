.Page {
  padding-bottom: 40px;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.Page--isLoading {
  opacity: 0.8;
}

@media (max-width: 1100px) {
  .Page {
    padding-bottom: 0;
  }
}

.Page-breadcrumbs {
  /* border-top: 1px solid #e8eaec; */
}

@media (max-width: 840px) {
  .Page-breadcrumbs {
    border-bottom: 1px solid #e8eaec;
  }
}

.Page-title {
  margin-bottom: 36px;
  margin-top: 16px;
  padding: 0 16px;
  text-align: center;
  font-size: 36px;
  line-height: 1.2;
  letter-spacing: 0.9px;
}

.Page-saleNotification {
  /* margin-bottom: 8px; */
}

/* @media (max-width: 840px) {
  .Page-title {
    font-size: 32px;
    margin-bottom: 16px;
  }
} */

.Page--noBreadcrumbs .Page-title {
  margin-top: 64px;
}

.Page-title--inBanner {
  z-index: 1;
  text-align: center;
  font-size: 65px;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media (max-width: 840px) {
  .Page-title--inBanner {
    font-size: 36px;
  }
}

.Page-description {
  font-size: 18px;
  text-align: center;
  padding: 16px 0;
}

@media (max-width: 840px) {
  .Page-description {
    font-size: 14px;
  }
}

.Page--noBreadcrumbs .Page-title--inBanner {
  margin-top: 16px;
}

.Page-note {
  text-align: center;
  margin-bottom: 32px;
  font-size: 16px;
}

.Page-basketTable {
  padding-top: 50px;
}

.Page-notification {
  padding-bottom: 10px;
}

.Page--url-basket .Page-notification {
  padding-bottom: 0;
}
.Page--url-ordering .Page-notification {
  padding-bottom: 0;
}

.Page-banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #262931;
  color: #fff;
  padding: 0 16px;
  height: 360px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Page-bannerContent {
  position: relative;
  z-index: 1;
}

.Page-banner--textExist {
  position: relative;
}

.Page-banner--textExist::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 41, 49, 0.4);
}

@media (max-width: 1200px) {
  .Page-banner {
    height: 360px;
  }
}

@media (max-width: 840px) {
  .Page-banner {
    height: 320px;
  }
}

@media (max-width: 420px) {
  .Page-banner {
    height: 160px;
  }
}

.Page-bannerDescription {
  text-align: center;
  z-index: 1;
  font-size: 18px;
}

@media (max-width: 840px) {
  .Page-bannerDescription {
    font-size: 16px;
  }
}

.Page-content {
}

.Page-loader {
  min-height: 360px;
  position: relative;
}

.PageCounter-countDown {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}
