.LandingSectionTitle {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  font-size: 84px;
  font-size: 2.8em;
  padding-top: 64px;
  padding-bottom: 42px;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1.2;
  text-align: center;
  /* overflow: hidden; */
}

.LandingSectionTitle--noGap {
  padding: 0;
}

@media (max-width: 1200px) {
  .LandingSectionTitle {
    font-size: 82px;
    font-size: 2.73em;
  }
}
@media (max-width: 1000px) {
  .LandingSectionTitle {
    font-size: 68px;
    font-size: 2.7em;
  }
}
@media (max-width: 840px) {
  .LandingSectionTitle {
    font-size: 54px;
    font-size: 1.8em;
  }
}

@media (max-width: 680px) {
  .LandingSectionTitle {
    font-size: 46px;
    font-size: 1.53em;
  }
}

@media (max-width: 620px) {
  .LandingSectionTitle {
    font-size: 42px;
    font-size: 1.4em;
  }
}

@media (max-width: 520px) {
  .LandingSectionTitle {
    font-size: 38px;
    font-size: 1.27em;
  }
}

@media (max-width: 480px) {
  .LandingSectionTitle {
    font-size: 30px;
    font-size: 1em;
  }
}

.LandingSectionTitle-holder {
}

.LandingSectionTitle-top {
  position: relative;
  text-align: left;
  left: 2vw;
}

.LandingSectionTitle:not(.LandingSectionTitle--verticalReverse) .LandingSectionTitle-top {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #262931;
}

.LandingSectionTitle--light:not(.LandingSectionTitle--verticalReverse) .LandingSectionTitle-top {
  -webkit-text-stroke-color: #fff;
}

.LandingSectionTitle--verticalReverse .LandingSectionTitle-top {
  color: #262931;
}

.LandingSectionTitle--light.LandingSectionTitle--verticalReverse .LandingSectionTitle-top {
  color: #fff;
}

.LandingSectionTitle--reverse .LandingSectionTitle-top {
  left: auto;
  right: 2vw;
  text-align: right;
}

/* @media screen and (-webkit-min-device-pixel-ratio: 0) {
  .LandingSectionTitle-top {
    color: #000;
  }
} */

.LandingSectionTitle-bottom {
  text-align: right;
  color: #262931;
  position: relative;
  right: 2vw;
}

.LandingSectionTitle--light .LandingSectionTitle-bottom {
  color: #fff;
}

.LandingSectionTitle--verticalReverse.LandingSectionTitle--light .LandingSectionTitle-bottom {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}
.LandingSectionTitle--verticalReverse .LandingSectionTitle-bottom {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #262931;
}

.LandingSectionTitle--reverse .LandingSectionTitle-bottom {
  right: auto;
  text-align: left;
  left: 2vw;
}

.LandingSectionTitle--centered .LandingSectionTitle-top {
  text-align: center;
}

.LandingSectionTitle--centered .LandingSectionTitle-bottom {
  text-align: center;
}
