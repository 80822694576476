.FooterMobileNavigation {
  margin: 0;
  padding: 0;
  list-style: none;
}

.FooterMobileNavigation-item {
  border-bottom: 1px solid #e8e9eb;
}

.FooterMobileNavigation-item:last-child {
  border-bottom: none;
}

.FooterMobileNavigationLink-link {
  display: inline-block;
  padding: 16px 24px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
}
