.Pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.Pagination-item {
  /* empty */
}
.Pagination-button {
  color: #262931;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  display: block;
  margin: 0;
  transition: 0.3s;
  background-color: transparent;
  border: none;
  padding: 20px;
  line-height: 1;
  cursor: pointer;
  border-radius: 50%;
  font-family: inherit;
}
.Pagination-current {
  color: #262931;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  display: block;
  margin: 0;
  transition: 0.3s;
  background-color: transparent;
  border: none;
  padding: 20px;
  line-height: 1;
  border-radius: 50%;
  font-weight: bold;
  cursor: default;
  font-family: inherit;
}

.Pagination-current::after {
  content: '';
  width: 45px;
  height: 45px;
  position: absolute;
  display: block;
  border: 1px solid #ffd12c;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* @media (max-width: 390px) {
  .Pagination-button {
    padding: 15px;
  }
} */

.Pagination-button {
  outline: none;
}
.Pagination-button::after {
  content: '';
  width: 45px;
  height: 45px;
  position: absolute;
  display: block;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.5s;
  opacity: 0;
  background-color: #f3f3f3;
}

.Pagination-page {
  position: relative;
  z-index: 3;
}

@media (max-width: 390px) {
  .Pagination-button::after {
    width: 38px;
    height: 38px;
  }
}

.Pagination-button:hover:after,
.isTabUsing .Pagination-button:focus:after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.Pagination-break {
  font-size: 18px;
  display: block;
  padding: 20px 0;
  line-height: 1;
  cursor: default;
}
