.DialogGrid {
  display: flex;
  margin: 0 -64px;
}

@media (max-width: 1100px) {
  .DialogGrid {
    display: block;
    margin: 0;
  }
}

.DialogGrid-coll {
  padding: 0 64px;
  flex-grow: 1;
  box-sizing: border-box;
  position: relative;
}

@media (max-width: 1100px) {
  .DialogGrid-coll {
    padding: 0;
  }
}

.DialogGrid-coll::after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: #dfe3ea;
}

.DialogGrid-coll:last-child::after {
  display: none;
}
