.BasketLabel {
  font-size: 14px;
  color: #848fa5;
}

.BasketLabel--feature-gift {
  color: #25bc8b;
}

.BasketLabel--feature-last {
  color: #f15a5b;
}
