.OrderingForm_Comment {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
}

.OrderingForm_Comment-button {
}

.OrderingForm_Comment-field {
  margin-top: 16px;
}
