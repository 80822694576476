.NewSubscribe {
  padding: 96px 0;
  background-image: url(/content/feedback/bg.png);
  background-position: center center;
  background-size: cover;
}
@media (max-width: 840px) {
  .NewSubscribe {
    padding: 0;
    padding-bottom: 42px;
    background-color: #f5f5f5;
    background-image: none;
  }
}

.NewSubscribe-media {
  height: 240px;
  background-image: url(/content/feedback/bg-mobile.png);
  background-position: center center;
  background-size: cover;
  margin-bottom: 24px;
}

.NewSubscribe-title {
  font-size: 34px;
  max-width: 570px;
  font-weight: 700;
  margin-bottom: 48px;
}

@media (max-width: 840px) {
  .NewSubscribe-title {
    font-size: 18px;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 18px;
    text-align: center;
  }
}

.NewSubscribe-form {
  flex: 0 1 600px;
}

.NewSubscribe-formContent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 600px;
  margin-bottom: 8px;
}

@media (max-width: 840px) {
  .NewSubscribe-formContent {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
  }
}

.NewSubscribe-formActionContent {
  display: flex;
}

@media (max-width: 840px) {
  .NewSubscribe-formActionContent {
    flex-direction: column;
    align-items: stretch;
    width: 420px;
  }
}

@media (max-width: 480px) {
  .NewSubscribe-formActionContent {
    width: 100%;
  }
}

.NewSubscribe-field {
  flex: 1 1 auto;
}

@media (max-width: 840px) {
  .NewSubscribe-field {
    margin-bottom: 28px;
  }
}

.NewSubscribe-button {
  flex: 0 0 auto;
  margin-left: 16px;
}

@media (max-width: 840px) {
  .NewSubscribe-button {
    margin-left: 0;
  }
}

.NewSubscribe-confirmation {
  flex: 1 1 auto;
}

.NewSubscribe-confirmation {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

@media (max-width: 840px) {
  .NewSubscribe-confirmation {
    max-width: 420px;
    font-size: 12px;
  }
}
