.AccordionItem {
}

.AccordionItem-heading {
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-out;
  padding: 8px 0;
  margin: 0;
  background-color: #fff;
  border: none;
  width: 100%;
  outline: none;
  font-family: inherit;
}

.AccordionItem--clickableHeading .AccordionItem-heading {
  cursor: pointer;
}

.AccordionItem-heading:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.isTabUsing .AccordionItem-heading:focus:after {
  box-shadow: 0 0 0 2px rgba(47, 124, 192, 0.54);
}

.AccordionItem-titleHolder {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.AccordionItem-title {
  flex: 0 1 auto;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
}

.AccordionItem-label {
  margin-left: auto;
  margin-right: 8px;
  flex: 0 0 auto;
  position: relative;
  z-index: 3;
  cursor: pointer;
}
.AccordionItem-trigger {
  background-color: transparent;
  font-family: inherit;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease-out;
  line-height: 1;
  z-index: 3;
}

.AccordionItem--clickableHeading .AccordionItem-trigger:hover {
  background-color: transparent;
}

.isTabUsing .AccordionItem-trigger:focus {
  box-shadow: inset 0 0 0 2px rgba(47, 124, 192, 0.54);
  position: relative;
  z-index: 1;
}

.AccordionItem-panel {
  padding: 8px 0;
}

.AccordionItem-panel--fadein {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
