.IconMinus {
  position: relative;
  width: 11px;
  height: 8px;
  display: inline-block;
  margin: 0;
}

.IconMinus::before {
  content: '';
  left: 1px;
  right: 1px;
  height: 2px;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
