.ButtonHeart {
  display: inline-block;
  padding: 12px;
  border-radius: 25px;
  line-height: 24px;
  border: 1px solid #d1d6db;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition-property: color, background-color, border-color, opacity,
    box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.ButtonHeart:hover {
  border: 1px solid #e85958;
  color: #fff;
  background-color: #e85958;
  opacity: 0.8;
}

.isTabUsing .ButtonHeart:focus {
  border: 1px solid transparent;
  box-shadow: 0 0 0 4px rgba(232, 89, 88, 0.4);
}

.ButtonHeart:active {
  opacity: 1;
}

.ButtonHeart--size-small {
  padding: 11px;
  line-height: 16px;
  border-radius: 20px;
}

.ButtonHeart--isActive {
  border: 1px solid #e85958;
  color: #e85958;
}
