.AnimatedLink {
  appearance: none;
  display: inline-block;
  padding: 5px 0px;
  line-height: 20px;
  color: inherit;
  text-decoration: none;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.2s linear;
  font-size: inherit;
  background-color: transparent;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  position: relative;
}

.AnimatedLink--noLink {
  cursor: text;
  color: #818690;
}

.AnimatedLink--inline {
}

.AnimatedLink--noGap {
  padding: 0;
}

.AnimatedLink:hover {
  /*background-color: #e5ecf2;*/
}

.AnimatedLink--tel {
  color: #818690;
}

.AnimatedLink-icon {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: -3px;
}

.AnimatedLink-value {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.AnimatedLink-value::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  transform-origin: right center;
  height: 1px;
  background: #818690;
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.AnimatedLink:hover .AnimatedLink-value::after {
  transform-origin: left center;
  transform: scale(1, 1);
}

.AnimatedLink--noLink:hover .AnimatedLink-value::after {
  transform: scale(0, 1);
}

.AnimatedLink--inline:hover .AnimatedLink-value {
  text-decoration: underline;
}

.AnimatedLink--inline:hover .AnimatedLink-value::after {
  transform: scale(0, 0);
}
