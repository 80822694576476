.FavouriteScene-content {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
  margin-top: 24px;
}

@media (max-width: 840px) {
  .FavouriteScene-content {
    margin: -8px;
    margin-top: 16px;
  }
}

.FavouriteScene-item {
  padding: 16px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  box-sizing: border-box;
  margin-bottom: 42px;
}

@media (max-width: 840px) {
  .FavouriteScene-item {
    padding: 8px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
  }
}

.FavouriteScene-emptyComment {
  margin-bottom: 32px;
}

@media (max-width: 840px) {
  .FavouriteScene-emptyComment {
    text-align: center;
  }
}

.FavouriteScene-emptyButton {
  text-align: center;
}

.FavouriteScene-loader {
  position: relative;
  padding: 120px 0;
}
