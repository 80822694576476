.UserBonuses {
}

.UserBonuses-item--type-default {
}

.UserBonuses-item--type-extra {
}

.UserBonuses-item {
  margin-bottom: 4px;
}

.UserBonuses-item:last-child {
  margin-bottom: 0;
}

.UserBonuses-itemTitle {
  color: #8b9bae;
}

.UserBonuses-itemAmount {
}

.UserBonuses-item--type-extra .UserBonuses-itemAmount {
  color: #00c090;
}
