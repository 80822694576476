.MixitLogo {
  text-align: center;
  position: relative;
  padding-top: 8px;
}

@media (max-width: 640px) {
  .MixitLogo {
    padding-top: 0;
  }
}

.MixitLogo-link {
  text-decoration: none;
  display: inline-block;
}

.MixitLogo-link::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.MixitLogo-image {
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.MixitLogo-image svg {
  vertical-align: top;
  height: 100%;
}

@media (max-width: 840px) {
  .MixitLogo-image {
    height: 28px;
  }
}

@media (max-width: 480px) {
  .MixitLogo-image {
    height: 24px;
  }
}

@media (max-width: 400px) {
  .MixitLogo-image {
    height: 20px;
  }
}

.MixitLogo-svg {
  width: 100%;
}

.MixitLogo-text {
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  color: #9899a3;
  margin-top: 8px;
}

.MixitLogo--quarantin .MixitLogo-text {
  opacity: 1;
  transition: 0.2s ease-out;
  animation: fadeOutText 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 2000ms;
}

@keyframes fadeOutText {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.MixitLogo-quarantinText {
  font-family: 'RFDewi';
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: -6px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  transition: 0.2s ease-out;
  animation: fadeInText 0.8s linear;
  animation-fill-mode: forwards;
  animation-delay: 2500ms;

  font-size: 14px;
  bottom: -2px;
}

@media (max-width: 640px) {
  .MixitLogo-quarantinText {
    font-size: 14px;
    bottom: -18px;
    font-size: 10px;
    bottom: -16px;
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .MixitLogo-text {
    display: none;
  }
}

.MixitLogo-FirTreeLogoAnimation {
  position: absolute;
  top: -2px;
  left: 125px;
}

@media (max-width: 640px) {
  .MixitLogo-FirTreeLogoAnimation {
    position: absolute;
    top: -10px;
    left: 52px;
  }
}

@media (max-width: 480px) {
  .MixitLogo-FirTreeLogoAnimation {
    position: absolute;
    top: -6px;
    left: 48px;
  }
}

@media (max-width: 400px) {
  .MixitLogo-FirTreeLogoAnimation {
    position: absolute;
    top: -9px;
    left: 39px;
  }
}

.MixitLogo-sparkleRainAnimation {
  position: absolute;
  top: -46px;
  left: -200px;
  z-index: -100;
}

.MixitLogo-letter {
  position: relative;
  transition: 0.5s linear;
}

.MixitLogo-letter_1 {
  animation: firstLetterMove 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 1500ms;
  transform: translateX(0px) rotate(0.01deg);
}

@keyframes firstLetterMove {
  from {
    transform: translateX(0px) rotate(0.01deg);
  }
  to {
    transform: translateX(-18px) rotate(0.01deg);
  }
}

.MixitLogo-letter_2 {
  animation: secondLetterMove 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 1500ms;
  transform: translateX(0px) rotate(0.01deg);
}

@keyframes secondLetterMove {
  from {
    transform: translateX(0px) rotate(0.01deg);
  }
  to {
    transform: translateX(-12px) rotate(0.01deg);
  }
}

.MixitLogo-letter_3 {
  animation: thirdLetterMove 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 1500ms;
  transform: translateX(0px) rotate(0.01deg);
}

@keyframes thirdLetterMove {
  from {
    transform: translateX(0px) rotate(0.01deg);
  }
  to {
    transform: translateX(12px) rotate(0.01deg);
  }
}

.MixitLogo-letter_4 {
  animation: fourthLetterMove 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 1500ms;
  transform: translateX(0px) rotate(0.01deg);
}

@keyframes fourthLetterMove {
  from {
    transform: translateX(0px) rotate(0.01deg);
  }
  to {
    transform: translateX(18px) rotate(0.01deg);
  }
}

.MixitLogo-quarantinAnimation {
  position: absolute;
  top: 6px;
  /* left: 125px; */
  transform: translateX(-50%);
  left: 51%;
}

@media (max-width: 640px) {
  .MixitLogo-quarantinAnimation {
    top: -4px;
    left: 52%;
  }
}
