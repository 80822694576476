.MainDescriptionSection {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.MainDescriptionSection-headColumn {
  margin-right: 24px;
  padding-top: 24px;
}

@media (max-width: 1200px) {
  .MainDescriptionSection-headColumn {
    margin-right: 0;
    padding-top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 840px) {
  .MainDescriptionSection-headColumn {
    flex-direction: column;
    margin-bottom: 32px;
  }
}

@media (max-width: 480px) {
  .MainDescriptionSection-headColumn {
    margin-bottom: 8px;
  }
}

.MainDescriptionSection-descriptionColumn {
  margin-right: 48px;
  width: 380px;
}

@media (max-width: 1200px) {
  .MainDescriptionSection-descriptionColumn {
    margin-right: 0px;
    box-sizing: border-box;
    width: 50%;
    flex: 1 1 50%;
    padding: 16px 32px;
  }
}

@media (max-width: 840px) {
  .MainDescriptionSection-descriptionColumn {
    width: 100%;
    flex: 1 1 auto;
    padding: 0;
  }
}

.MainDescriptionSection-descriptionColumn:last-child {
  margin-right: 0;
}

.MainDescriptionSection-descriptionColumn p {
  font-size: 14px;
}

@media (max-width: 360px) {
  .MainDescriptionSection-descriptionColumn p {
    font-size: 12px;
  }
}

.MainDescriptionSection-logo {
  margin-bottom: 24px;
}

@media (max-width: 1200px) {
  .MainDescriptionSection-logo {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

@media (max-width: 840px) {
  .MainDescriptionSection-logo {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.MainDescriptionSection-logoLinkImage {
  width: 200px;
}

@media (max-width: 480px) {
  .MainDescriptionSection-logoLinkImage {
    width: 160px;
  }
}

.MainDescriptionSection-titleWrapper {
  width: 320px;
}

@media (max-width: 1200px) {
  .MainDescriptionSection-titleWrapper {
    width: 400px;
  }
}

@media (max-width: 840px) {
  .MainDescriptionSection-titleWrapper {
    width: 460px;
  }
}

@media (max-width: 480px) {
  .MainDescriptionSection-titleWrapper {
    width: 100%;
  }
}

.MainDescriptionSection-title {
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 28px;
}

@media (max-width: 840px) {
  .MainDescriptionSection-title {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .MainDescriptionSection-title {
    font-size: 18px;
  }
}
