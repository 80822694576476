.BonusHistoryScene_Mobile {
}

.BonusHistoryScene_Mobile-emptyMessage {
}

.BonusHistoryScene_Mobile-title {
  margin: 0;
  margin-bottom: 24px;
  line-height: 1;
  font-size: 24px;
  text-align: center;
}

.BonusHistoryScene_Mobile-typeFilter {
  margin-bottom: 16px;
}

.BonusHistoryScene_Mobile-durationFilter {
  margin-bottom: 16px;
}

.BonusHistoryScene_Mobile-table {
}
