.SortingDropdown {
  min-width: 260px;
}

.SortingDropdown-list {
  margin: 0;
  padding: 8px 0;
}

.SortingDropdown-item {
  list-style: none;
}

.SortingDropdown-type {
  display: inline-block;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  font-family: inherit;
}

.SortingDropdown-type:last-child {
  border-bottom: none;
}

.SortingDropdown-type:hover {
  background-color: #edf0f5;
}
