.ProductFeatures {
  margin: -4px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 480px) {
  .ProductFeatures {
    margin: -2px;
  }
}

.ProductFeatures-item {
  position: relative;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  padding: 6px 12px;
  border-radius: 3px;
  display: inline-block;
  margin: 4px;
  overflow: hidden;
}

@media (max-width: 480px) {
  .ProductFeatures-item {
    margin: 2px;
  }
}

.ProductFeatures-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.ProductFeatures-holder {
  display: flex;
  align-items: center;
  margin: -2px;
}

.ProductFeatures-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 0 2px 0 0;
}

@media (max-width: 480px) {
  .ProductFeatures-icon {
    display: none;
  }
}

.ProductFeatures-title {
  padding: 2px;
}

.ProductFeatures--size-small .ProductFeatures-item {
  font-size: 12px;
  padding: 5px 8px 3px;
}

@media (max-width: 480px) {
  .ProductFeatures--size-small .ProductFeatures-item {
    padding: 3px 6px 2px;
    font-size: 10px;
  }
}

.ProductFeatures-item--sale {
  background-color: #e95959;
}

.ProductFeatures-item--new {
  background-color: #25bc8b;
}

.ProductFeatures-item--gift {
  background-color: #25bc8b;
}

.ProductFeatures-item--action {
  background-color: #9d85be;
}

.ProductFeatures-item--popular {
  background-color: #ffd64e;
  color: inherit;
}

.ProductFeatures-item--consumersChoice {
  background-color: #ffa800;
}

.ProductFeatures-item--art {
  background: linear-gradient(220deg, #ed1958 0%, #8055a4 100%);
}

.ProductFeatures-item--retail {
  background-color: #7cc3e3;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.ProductFeatures-item--retail:hover {
  opacity: 0.8;
}

.ProductFeatures-item--collaboration {
  background-color: #ffea47;
  color: #000;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.ProductFeatures-item--collaboration:hover {
  opacity: 0.7;
}

@media screen and (max-width: 840px) {
  .ProductFeatures-item {
    font-size: 10px;
    padding: 4px 8px;
    line-height: 12px;
  }
}
