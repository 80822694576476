.DeliverySummary {
}

.DeliverySummary-title {
}

.DeliverySummary-hint {
  font-size: 14px;
  color: #262931;
}

.DeliverySummary-row {
  max-width: 320px;
  margin-bottom: 8px;
  line-height: 1.3;
  font-size: 14px;
}

@media (max-width: 420px) {
  .DeliverySummary-row {
    max-width: 240px;
  }
}

@media (max-width: 360px) {
  .DeliverySummary-row {
    max-width: 220px;
  }
}

.DeliverySummary-titleRow {
  max-width: 320px;
  margin-bottom: 2px;
  line-height: 1.3;
  font-size: 14px;
  font-weight: 700;
}

@media (max-width: 420px) {
  .DeliverySummary-titleRow {
    max-width: 240px;
  }
}

@media (max-width: 360px) {
  .DeliverySummary-titleRow {
    max-width: 220px;
  }
}

.DeliverySummary-row:last-child {
  margin-bottom: 0;
}

.DeliverySummary-cost {
  color: #25bc8b;
}
