.InputPhoneWithAreaCode {
  display: flex;
}

.InputPhoneWithAreaCode-choiceCode {
  flex: 0 1 auto;
  margin-right: 8px;
  position: relative;
}

.InputPhoneWithAreaCode-phoneField {
  flex: 1 1 auto;
  position: relative;
}

.InputPhoneWithAreaCode-phoneFieldWarning {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 8px);
  transform: translateX(-50%);
  color: rgb(233, 89, 88);
  box-shadow: 0 2px 16px 0 rgba(47, 47, 71, 0.1);
  line-height: 1;
  padding: 16px 24px;
  max-width: 240px;
  width: 100%;
  background-color: #fff;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-out;
  pointer-events: none;
}

.InputPhoneWithAreaCode-phoneFieldWarningCloce {
  pointer-events: initial;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  border: none;
  font-size: 24px;
  outline: none;
  color: #262931;
  opacity: 0.7;
}

.InputPhoneWithAreaCode--isFullLength .InputPhoneWithAreaCode-phoneFieldWarning {
  opacity: 1;
}

.InputPhoneWithAreaCode-phoneFieldWarning::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 4px;
  width: 16px;
  height: 16px;
  margin: -8px;
  border-radius: 0 0 0 3px;
  box-sizing: border-box;
  background-color: #fff;
  transform: rotate(-45deg);
  box-shadow: 0 2px 16px 0 rgba(47, 47, 71, 0.1);
  z-index: -1;
}

.InputPhoneWithAreaCode-select {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 4px);
  z-index: 200;
}
