.ProductSetCard {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color: #f1f4f9;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
}

.ProductSetCard--transparentBackground {
  background-color: transparent;
}

@media (max-width: 840px) {
  .ProductSetCard:not(.ProductSetCard--allwaysSetBackground) {
    background-color: transparent;
  }
}

.ProductSetCard--2x {
}

.ProductSetCard-imageHolder {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProductSetCard-image {
  width: 240px;
}

.ProductSetCard--isWideMedia .ProductSetCard-image {
  width: 70%;
}

@media (max-width: 840px) {
  .ProductSetCard-image {
    width: auto;
  }
}

.ProductSetCard--2x .ProductSetCard-image {
  width: 480px;
}

.ProductSetCard-contentHolder {
  flex: 0 0 auto;
  padding: 0 32px 24px;
  max-width: 430px;
}

.ProductSetCard--transparentBackground .ProductSetCard-contentHolder {
  margin: auto;
}

@media (max-width: 840px) {
  .ProductSetCard-contentHolder {
    max-width: none;
  }
}

@media (max-width: 560px) {
  .ProductSetCard-contentHolder {
    padding: 0 24px 24px;
  }
}

@media (max-width: 480px) {
  .ProductSetCard-contentHolder {
    padding: 0 16px 16px;
  }
}

@media (max-width: 400px) {
  .ProductSetCard-contentHolder {
    padding: 0 12px 16px;
  }
}

.ProductSetCard-titleHolder {
  margin-bottom: 4px;
}

.ProductSetCard-title {
  margin: 0;
  margin-bottom: 4px;
  line-height: 1.2;
}

@media (max-width: 840px) {
  .ProductSetCard-title {
    text-align: center;
  }
}

.ProductSetCard-link {
  display: inline-block;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 300;
  color: #818690;
  text-decoration: none;
}

@media (max-width: 640px) {
  .ProductSetCard-link {
    font-size: 14px;
  }
}

.ProductSetCard--2x .ProductSetCard-link {
  font-size: 18px;
}

.ProductSetCard-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ProductSetCard-priceHolder {
  font-size: 16px;
}

.ProductSetCard--transparentBackground .ProductSetCard-priceHolder {
  text-align: center;
}

@media (max-width: 840px) {
  .ProductSetCard-priceHolder {
    font-size: 14px;
    text-align: center;
  }
}

.ProductSetCard-previousPrice {
  display: inline-block;
  margin-right: 4px;
}

.ProductSetCard-currentPrice {
  font-weight: 700;
}

.ProductSetCard-addToBasket {
  position: relative;
  z-index: 1;
  padding: 8px 0;
  text-align: center;
  /* pointer-events: none; */
  /* max-width: 220px; */
}

@media (max-width: 840px) {
  .ProductSetCard-addToBasket {
    margin: 0 auto;
  }
}

.ProductSetCard-addToBasket button {
  /* pointer-events: all; */
}
