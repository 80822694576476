.FAQ_Section {
}

.FAQ_Section-holder {
}

.FAQ_Section-navigation {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 4;
}

.FAQ_Section-main {
  padding-top: 48px;
}
@media (max-width: 840px) {
  .FAQ_Section-main {
    padding-top: 36px;
  }
}

.FAQ_Section-mainInner {
  display: flex;
}

@media (max-width: 840px) {
  .FAQ_Section-mainInner {
    flex-direction: column-reverse;
  }
}

.FAQ_Section-actions {
  flex: 0 1 300px;
  max-width: 300px;
  /* min-width: 260px; */
  box-sizing: border-box;
  /* background-color: pink; */
  /* display: none; */
  padding-right: 10vw;
}
@media (max-width: 840px) {
  .FAQ_Section-actions {
    padding-right: 0;
    max-width: 100%;
    flex: 1 1 auto;
  }
}

.FAQ_Section-actionsHolder {
  position: sticky;
  top: 64px;
  margin-bottom: 40vh;
  margin: -10px;
}

@media (max-width: 840px) {
  .FAQ_Section-actionsHolder {
    position: static;
    margin-bottom: 0;
    display: flex;
    padding-top: 54px;
  }
}

.FAQ_Section-action {
  width: 180px;
  min-height: 190px;
  display: inline-flex;
  padding: 10px;
}
@media (max-width: 840px) {
  .FAQ_Section-action {
    width: auto;
    flex-basis: 50%;
    min-height: auto;
  }
}

.FAQ_Section-content {
  flex: 1 1 auto;
}

.FAQ_Section-list {
}
