.IconPlus {
  position: relative;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0;
}

.IconPlus::before,
.IconPlus::after {
  content: '';
  width: 10px;
  height: 2px;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.IconPlus::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
