.TriggerSwitcher {
}

.TriggerSwitcher-active {
}

.TriggerSwitcher-label {
  display: flex;
  position: relative;
}

.TriggerSwitcher-outer {
  cursor: pointer;
  border: 1px solid #c7c9d6;
  padding: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding-right: 25px;
  transition: 0.3s ease-out;
}

.TriggerSwitcher-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.TriggerSwitcher-input:checked ~ .TriggerSwitcher-outer {
  border: 1px solid #ffd200;
}

.TriggerSwitcher-circle {
  background-color: #c7c9d6;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  transition: 0.2s ease-out;
}

.TriggerSwitcher-input:checked
  ~ .TriggerSwitcher-outer
  .TriggerSwitcher-circle {
  background-color: #ffd200;
  transform: translateX(23px);
}
