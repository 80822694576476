.ExploreArticleList {
  display: flex;
  margin: -16px;
  overflow: hidden;
}

@media (max-width: 1100px) {
  .ExploreArticleList {
    margin: -32px;
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .ExploreArticleList {
    margin: -24px;
  }
}

@media (max-width: 480px) {
  .ExploreArticleList {
    margin: -16px;
  }
}

.ExploreArticleList-item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .ExploreArticleList-item {
    flex: 0 0 auto;
    max-width: none;
    padding: 32px;
  }
}

@media (max-width: 640px) {
  .ExploreArticleList-item {
    padding: 24px;
  }
}

@media (max-width: 480px) {
  .ExploreArticleList-item {
    padding: 16px;
  }
}
