.ActionsGigts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding: 0 24px; */
  padding: 42px 0;
}

.ActionsGigts-itemHolder {
  flex: 1 1 240px;
  max-width: 240px;
  padding: 32px;
  height: auto;
  margin: auto;
}

.ActionsGigts-item {
  position: relative;
  padding-top: 100%;
}

.ActionsGigts-itemInner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
