.CityDialog {
  padding: 40px;
}

.CityDialog-title {
  margin: 0;
  margin-bottom: 24px;
  line-height: 1.2;
  font-size: 24px;
}

.CityDialog-grid {
  margin: 0 -36px;
  display: flex;
  margin-bottom: 32px;
}

.CityDialog-column {
  padding: 0 36px;
}

.CityDialog-list {
  margin: 0;
  padding: 0;
}

.CityDialog-item {
  margin-bottom: 16px;
  list-style: none;
}

.CityDialog-item:last-child {
  margin-bottom: 0;
}

.CityDialog-button {
  cursor: pointer;
  outline: none;
}

.CityDialog-seachTitle {
  margin: 0;
  margin-bottom: 16px;
  line-height: 1.2;
  font-size: 18px;
}

.CityDialog-seachField {
}

.CityDialog-iconWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
