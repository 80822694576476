.PickupList {
}

.PickPointList-header {
  display: flex;
  align-items: center;
  padding: 16px;
  padding-right: 32px;
  margin-right: -16px;
  overflow-y: scroll;
  font-size: 14px;
  color: #818690;
  cursor: default;
}

@media (max-width: 840px) {
  .PickPointList-header {
    display: none;
  }
}

.PickPointList-title {
  flex: 1 1 auto;
  padding: 0 16px;
}

.PickPointList-address {
  flex: 0 0 30%;
  padding: 0 16px;
  box-sizing: border-box;
}

.PickPointList-schedule {
  flex: 0 0 25%;
  padding: 0 16px;
  box-sizing: border-box;
}

.PickPointList-payment {
  flex: 0 0 20%;
  padding: 0 16px;
  box-sizing: border-box;
}

.PickPointList-content {
  max-height: 480px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

@media (max-width: 840px) {
  .PickPointList-content {
    max-height: 360px;
  }
}

.PickPointList--fullHeight .PickPointList-content {
  max-height: 100%;
}

.PickPointList-item {
  border-bottom: 1px solid #dfe3ea;
  list-style: none;
}

.PickPointList-item:first-child {
  border-top: 1px solid #dfe3ea;
}
