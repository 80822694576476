.InstagramPost {
  width: 100%;
  font-size: 14px;
}

.InstagramPost-visualGroup {
  position: relative;
  margin-bottom: 24px;
}

.InstagramPost-imageLink {
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.InstagramPost-image {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.InstagramPost-profileLink {
  text-decoration: none;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 8px 16px;
  color: #262931;
  background-color: #fff;
  border-radius: 0 3px 0 0;
  transition: color 0.3s linear;
}

@media (max-width: 720px) {
  .InstagramPost-profileLink {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.InstagramPost-profileLink:hover {
  color: #25bc8b;
}

@media (max-width: 720px) {
  .InstagramPost-profileLink:hover {
    color: #262931;
  }
}

.InstagramPost-actionItem {
  display: inline-block;
  margin-right: 20px;
}

.InstagramPost-actionItem:last-child {
  margin: 0;
}

.InstagramPost-actionGroup {
  margin-bottom: 24px;
}

.InstagramPost-caption {
  width: 100%;
  overflow: hidden;
  color: #262931;
  text-decoration: none;
  display: inline-block;
  /* height: 195px;
  overflow: hidden; */
  position: relative;
}
