.ProblemSkin_TopBanner {
  line-height: 1.2;
  display: flex;
  align-items: center;
  padding: 30px 0;
}

@media (max-width: 840px) {
  .ProblemSkin_TopBanner {
    flex-direction: column;
  }
}

.ProblemSkin_TopBanner-main {
  padding-right: 60px;
  flex: 1 1 60%;
}

@media (max-width: 840px) {
  .ProblemSkin_TopBanner-main {
    padding-right: 0;
  }
}

.ProblemSkin_TopBanner-visual {
  flex: 1 1 40%;
}

.ProblemSkin_TopBanner-title {
  font-size: 44px;

  font-weight: bold;
  margin-bottom: 56px;
}

@media (max-width: 840px) {
  .ProblemSkin_TopBanner-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 26px;
  }
}

.ProblemSkin_TopBanner-description {
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 24px;
}
@media (max-width: 840px) {
  .ProblemSkin_TopBanner-description {
    font-size: 18px;
    text-align: center;
  }
}

.ProblemSkin_TopBanner-image {
  width: 100%;
  height: auto;
}

.ProblemSkin_TopBanner-image--mob {
  display: none;
}
@media (max-width: 840px) {
  .ProblemSkin_TopBanner-image {
    display: none;
  }
  .ProblemSkin_TopBanner-image--mob {
    display: block;
  }
}

.ProblemSkin_TopBanner-toReasons {
  margin-bottom: 24px;
}

@media (max-width: 1100px) {
  .ProblemSkin_TopBanner-toReasons {
    text-align: center;
  }
}
