.SpecialOfferList {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: -16px;
}

@media (max-width: 840px) {
  .SpecialOfferList {
    flex-direction: column;
  }
}

.SpecialOfferList-item {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .SpecialOfferList-item {
    flex: 1 1 auto;
    max-width: 100%;
  }
}
