.SmallGapButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  outline: none;
  cursor: pointer;
  background-color: inherit;
  color: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  /* min-width: 180px; */
  max-width: 100%;
  border-radius: 25px;
  padding: 8px 40px;
  border: 1px solid #fbd146;
  box-sizing: border-box;
  transition: background-color 0.15s ease-out, border 0.15s ease-out, box-shadow 0.15s ease-out;
  text-decoration: none;
}

.SmallGapButton:hover {
  background-color: #ffd12c;
}

.SmallGapButton::-moz-focus-inner {
  border: 0;
}

.isTabUsing .SmallGapButton:focus {
  box-shadow: 0 0 0 5px rgba(255, 209, 44, 0.4);
}

.SmallGapButton:active {
  background-color: #fdc611;
}

.SmallGapButton:disabled {
  cursor: default;
  border: 1px solid #e4e7eb;
  color: #d1d5da;
  background-color: transparent;
}

.SmallGapButton--variant-primary {
  background-color: #ffd12c;
  font-weight: 700;
}

.SmallGapButton--variant-primary.SmallGapButton--inverted {
  background-color: #fff;
  border: 1px solid #fff;
}

.SmallGapButton--variant-primary:hover {
  background-color: #ffd905;
}

.SmallGapButton--variant-primary.SmallGapButton--inverted:hover {
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(255, 255, 255, 0.85);
}

.isTabUsing .SmallGapButton--variant-primary:focus {
  box-shadow: 0 0 0 5px rgba(255, 209, 44, 0.4);
}

.isTabUsing .SmallGapButton--variant-primary.SmallGapButton--inverted:focus {
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
}

.SmallGapButton--variant-primary:active {
  background-color: #fdc611;
}

.SmallGapButton--variant-primary.SmallGapButton--inverted:active {
  /* background-color: #fff;
  border: 1px solid #fff; */
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.95);
}

.SmallGapButton--variant-primary:disabled {
  border: 1px solid #ffd12c;
  color: #262931;
  background-color: #ffd12c;
  opacity: 0.5;
}

.SmallGapButton--variant-primary.SmallGapButton--inverted:disabled {
  background-color: #fff;
  border: 1px solid #fff;
  opacity: 0.5;
}

.SmallGapButton--variant-accent {
  /* min-width: 152px; */
  padding: 16px 28px;
  line-height: 16px;
  border: 1px solid #e74a5a;
  color: #fff;
  background-color: #e74a5a;
  font-size: 14px;
}

.SmallGapButton--variant-accent:hover {
  background-color: #cf3a4a;
}

.isTabUsing .SmallGapButton--variant-accent:focus {
  background-color: #e74a5a;
  box-shadow: 0 0 0 5px rgba(231, 74, 90, 0.4);
}

.SmallGapButton--variant-accent:active {
  background-color: #c73745;
}

.SmallGapButton--variant-accent:disabled {
  border: 1px solid #e74a5a;
  color: #fff;
  background-color: #e74a5a;
  opacity: 0.5;
}

.SmallGapButton--variant-secondary {
  /* color: #7f858f; */
  border-color: #d1d5da;
  /* border-color: #f3f3f3; */
}

.SmallGapButton--variant-secondary:hover {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.isTabUsing .SmallGapButton--variant-secondary:focus {
  box-shadow: 0 0 0 5px rgba(209, 213, 218, 0.4);
}

.SmallGapButton--variant-secondary:active {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.SmallGapButton--variant-secondary:disabled {
  border-color: #e4e7eb;
  background-color: transparent;
}

.SmallGapButton--gap-reduced {
  /* min-width: 120px; */
  padding-left: 24px;
  padding-right: 24px;
}

.SmallGapButton--size-small {
  /* min-width: 100px; */
  font-size: 12px;
  line-height: 14px;
  padding: 4px 26px;
}

.SmallGapButton--gap-reduced.SmallGapButton--size-small {
  /* min-width: 80px; */
  padding-left: 16px;
  padding-right: 16px;
}

.SmallGapButton--iconOnly {
  /* min-width: 24px; */
  border-radius: 25px;
  padding: 8px 24px;
}

.SmallGapButton--size-small.SmallGapButton--iconOnly {
  padding: 4px 16px;
}

.SmallGapButton--size-small.SmallGapButton--iconOnly {
  /* min-width: 16px; */
  border-radius: 20px;
}

.SmallGapButton--flat {
  border-color: transparent;
  background-color: transparent;
}

.SmallGapButton--flat.SmallGapButton--variant-default:hover {
  border-color: #ffd12c;
  background-color: transparent;
}

.SmallGapButton--flat:disabled {
  border: 1px solid transparent;
  background-color: transparent;
}

.SmallGapButton--expanded {
  width: 100%;
  justify-content: center;
}

.SmallGapButton-icon {
  flex: 0 0 auto;
  line-height: 16px;
}

.SmallGapButton--iconOnly .SmallGapButton-icon {
  margin-right: 0;
}

.SmallGapButton-title {
  padding: 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SmallGapButton--size-small .SmallGapButton-title {
  padding: 0 4px;
}
