.OrderStep {
}

.OrderStep--active {
}

.OrderStep-titleRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.OrderStep-buttonEdit {
  font-size: 14px;
}

.OrderStep-title {
  margin: 0;
  line-height: 1.2;
  font-size: 24px;
  font-weight: 700;
  color: #848fa5;
  flex: 1 1 auto;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 840px) {
  .OrderStep-title {
    font-size: 18px;
  }
}

.OrderStep-titleMark {
  flex: 0 0 32px;
}

.OrderStep-titleString {
  flex: 1 1 auto;
}

.OrderStep--active .OrderStep-title {
  color: #262931;
}

.OrderStep-stepNumber {
  display: inline-block;
  margin-right: 6px;
}

.OrderStep-titleString {
  display: inline-block;
  vertical-align: middle;
}

.OrderStep-paymentMethods {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 184px;
  flex-wrap: wrap;
}

@media (max-width: 960px) {
  .OrderStep-paymentMethods {
    width: 100%;
    justify-content: left;
    padding-top: 8px;
  }
}

.OrderStep-IconCardWorldContainer {
  height: 36px;
  margin-right: 8px;
}

@media (max-width: 960px) {
  .OrderStep-IconCardWorldContainer {
    height: 24px;
    margin-right: 4px;
  }
}

.OrderStep-IconApplePayContainer {
  height: 28px;
  margin-right: 4px;
}

@media (max-width: 960px) {
  .OrderStep-IconApplePayContainer {
    height: 24px;
    margin-right: 0;
  }
}

.OrderStep-IconGooglePayContainer {
  height: 28px;
}

@media (max-width: 960px) {
  .OrderStep-IconGooglePayContainer {
    height: 24px;
    margin-right: 4px;
  }
}

.OrderStep-IconMasterContainer {
  height: 28px;
}

@media (max-width: 960px) {
  .OrderStep-IconMasterContainer {
    height: 24px;
  }
}

.OrderStep-IconVisaContainer {
  height: 32px;
  margin-right: 4px;
}

@media (max-width: 960px) {
  .OrderStep-IconVisaContainer {
    height: 24px;
    margin-right: 0;
  }
}

.OrderStep-content {
  margin: 24px 0;
}

@media (max-width: 840px) {
  .OrderStep-content {
    margin: 16px 0;
  }
}

.OrderStep-summary {
  padding-left: 32px;
  margin-top: 8px;
}

.OrderStep-checkMark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #6abc8b;
  vertical-align: top;
}

.OrderStep-checkMark:before {
  content: '';
  position: absolute;
  left: 10px;
  bottom: 5px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 8px;
  height: 4px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
