.SearchResults {
}

.SearchResults-loaderHolder {
  position: relative;
  min-height: 320px;
}

.SearchResults-productList {
}
