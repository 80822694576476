.BonusTransactions {
}

.BonusTransactions-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #f2f5f9;
  color: #91a1b2;
}

.BonusTransactions-headerTitle {
}

.BonusTransactions-headerLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: 0.2s ease-out;
}

.BonusTransactions-headerLink::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.BonusTransactions-headerLink:hover {
  color: #25bc8b;
}

.BonusTransactions-headerLinkText {
  display: inline-block;
  margin-right: 8px;
}

.BonusTransactions-headerLinkIcon {
  display: flex;
  align-items: center;
}

.BonusTransactions-list {
  margin: 0;
  padding: 0;
}

.BonusTransactions-item {
  border-bottom: 1px solid #f2f5f9;
  list-style: none;
}

.BonusTransactions-emptyMessage {
}
