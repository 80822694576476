.CallbackConformationDialog {
  padding: 16px 52px 48px;
  max-width: 350px;
  margin: auto;
}
.CallbackConformationDialog-title {
  margin-top: 32px;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: bold;
}

@media (max-width: 840px) {
  .CallbackConformationDialog-title {
    font-size: 24px;
    line-height: 24px;
  }
}

.CallbackConformationDialog-info {
  max-width: 300px;
  margin: 0;
  text-align: center;
}

.CallbackConformationDialog-form {
  margin: -8px;
}

.CallbackConformationDialog-formItem {
  padding: 8px;
}

.CallbackConformationDialog-iconHolder {
  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(142, 51, 134, 0.05);
  /* display: inline-block; */
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 16px;
}

.CallbackConformationDialog-icon {
  width: 40px;
  height: 25px;
  color: #8e3387;
}

.CallbackConformationDialog-icon .IconCheck {
  font-size: 25px;
}

.CallbackConformationDialog-icon svg {
}
