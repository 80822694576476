.FilterOptionsMenu {
  text-align: center;
  margin: 0;
  padding: 0;
  cursor: default;
}

.FilterOptionsMenu-item {
  list-style: none;
  display: inline-block;
  margin-right: 4px;
}

.FilterOptionsMenu-item:last-child {
  margin-right: 0;
}
