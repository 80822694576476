.DoubledTitle {
  font-size: inherit;
  font-style: inherit;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  text-transform: uppercase;
  color: #262931;
}

.DoubledTitle--reverse {
}

.DoubledTitle--light {
  color: #fff;
}

.DoubledTitle-holder {
  font-size: 2.3em;
  font-style: inherit;
  position: relative;
}
@media (max-width: 1250px) {
  .DoubledTitle-holder {
    font-size: 1.875em;
  }
}
@media (max-width: 840px) {
  .DoubledTitle-holder {
    font-size: 1.333em;
  }
}
@media (max-width: 640px) {
  .DoubledTitle-holder {
    font-size: 1em;
  }
}

.DoubledTitle-top {
  position: relative;
  font-size: inherit;
  font-style: inherit;
  color: inherit;
  z-index: 1;

  color: inherit;
}

.DoubledTitle-bottom {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  position: absolute;
  font-size: inherit;
  font-style: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(0.07em, -0.07em);
}
