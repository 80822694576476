.ConfirmPasswordRecoveryDialog {
  padding: 0 32px 60px;
  max-width: 350px;
}
.ConfirmPasswordRecoveryDialog-title {
  margin-top: 40px;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: bold;
}

@media (max-width: 840px) {
  .ConfirmPasswordRecoveryDialog-title {
    font-size: 24px;
    line-height: 24px;
  }
}

.ConfirmPasswordRecoveryDialog-info {
  margin-bottom: 32px;
}

.ConfirmPasswordRecoveryDialog-form {
  margin: -8px;
}

.ConfirmPasswordRecoveryDialog-formItem {
  padding: 8px;
}
