.AddressChoice {
}

.AddressChoice-item {
  margin-bottom: 8px;
}

.AddressChoice-item:last-child {
  margin-bottom: 0;
}
