.SelectField {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.SelectField-label {
  margin-right: 8px;
  font-weight: 700;
}

.SelectField-label::after {
  content: ': ';
  display: inline-block;
}

.SelectField-dropdown {
}

.SelectField-button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  font-size: inherit;
}

.SelectField-buttonText {
  display: inline-block;
  margin-right: 0.5em;
  vertical-align: middle;
}

.SelectField-buttonIcon {
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
}
