.OrderingForm {
}

.OrderingForm-loader {
  position: relative;
  min-height: 480px;
}

.OrderingForm-step {
  padding: 16px 0;
  border-bottom: 1px solid #dbe1ec;
}

.OrderingForm-step:last-child {
  margin-bottom: 0;
}

.OrderingForm-submit {
}

.OrderingForm-comment {
  margin: 16px 0;
}

.OrderingForm-summary {
  margin-bottom: 16px;
}

.OrderingForm-confirmationMethod {
  margin: 16px 0;
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 16px;
}

.OrderingForm-confirmationMethodInfo {
  margin-left: 16px;
}

.OrderingForm-confirmationMethodInfoLabel {
  max-width: 400px;
}

.OrderingForm-submitButton {
  margin-bottom: 16px;
}

.OrderingForm-userAgreement {
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  font-weight: 300;
}

.OrderingForm-confirmationMethodInfoIcon {
  position: relative;
}

.OrderingForm-confirmationStaticTooltip {
  margin-bottom: 16px;
}
