.AuthForm {
}

@media (max-width: 640px) {
  .AuthForm {
    text-align: center;
  }
}

.AuthForm-registrationSuggest {
}

@media (max-width: 480px) {
  .AuthForm-registrationSuggest {
    font-size: 14px;
  }
}

.AuthForm-registrationSuggestText {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

@media (max-width: 480px) {
  .AuthForm-registrationSuggestText {
    margin-right: 4px;
  }
}

.AuthForm-quickLogin {
  display: flex;
  /* align-items: center; */
  align-items: flex-end;
}

@media (max-width: 640px) {
  .AuthForm-quickLogin {
    flex-direction: column;
    align-items: center;
  }
}

.AuthForm-quickLoginText {
  flex: 0 0 150px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .AuthForm-quickLoginText {
    flex: 0 1 auto;
    margin-bottom: 16px;
  }
}

.AuthForm-quickLoginSocialNetworkList {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  cursor: default;
}

.AuthForm-quickLoginSocialNetworkItem {
  display: inline-block;
  margin-right: 12px;
  list-style: none;
}

.AuthForm-quickLoginSocialNetworkItem:last-child {
  margin-right: 0;
}

.AuthForm-forgottenPassword {
  color: #848fa5;
  opacity: 0.8;
  font-size: 14px;
}
