.ScrollShadow-element {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;

  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.2s ease-out;
  z-index: 1;
}

.ScrollShadow-element--active {
  opacity: 1;
}

.ScrollShadow-element--top {
  top: 0;
  margin-top: -4px;
}

.ScrollShadow-element--bottom {
  bottom: 0;
  margin-bottom: -4px;
}
