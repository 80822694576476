.SectionError404 {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 80vh;
  overflow: hidden;
}

/* @media (max-width: 960px) {
  .SectionError {
    height: 75vh;
  }
} */

.SectionError404-content {
  margin-bottom: 80px;
}

@media (max-width: 720px) {
  .SectionError404-content {
    margin-bottom: 20px;
  }
}

.SectionError404-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}

@media (max-width: 1100px) {
  .SectionError404-background {
    flex-direction: column;
  }
}

.SectionError404-leftCol {
  flex: 1 1 400px;
  background-image: url('/content/404-error/404-bg-left-column.png');
  background-repeat: no-repeat;
  background-position: 0 0;
}

@media (max-width: 1860px) {
  .SectionError404-leftCol {
    background-position: 100% 0;
  }
}

@media (max-width: 1100px) {
  .SectionError404-leftCol {
    display: none;
  }
}

@media (max-width: 600px) {
  .SectionError404-leftCol {
    display: block;
    flex: 1 1 auto;
    background-position: 0 0;
    background-size: 36%;
  }
}

.SectionError404-centerCol {
  flex: 0 0 1000px;
  max-width: 1000px;
  background-image: url('/content/404-error/404-bg-center-column.png');
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1100px) {
  .SectionError404-centerCol {
    flex: 1 1 auto;
    max-width: none;
    background-size: 90%;
  }
}

@media (max-width: 960px) {
  .SectionError404-centerCol {
    background-size: 100%;
  }
}

@media (max-width: 850px) {
  .SectionError404-centerCol {
    background-size: 110%;
  }
}

@media (max-width: 720px) {
  .SectionError404-centerCol {
    background-size: 120%;
  }
}

@media (max-width: 600px) {
  .SectionError404-centerCol {
    flex: 0 1 auto;
    background: none;
  }
}

.SectionError404-rightCol {
  flex: 1 1 400px;
  background-image: url('/content/404-error/404-bg-right-column.png');
  background-repeat: no-repeat;
  background-position: 100% 0;
}

@media (max-width: 1860px) {
  .SectionError404-rightCol {
    background-position: 0 0;
  }
}

@media (max-width: 1100px) {
  .SectionError404-rightCol {
    display: none;
  }
}

@media (max-width: 600px) {
  .SectionError404-rightCol {
    display: block;
    flex: 1 1 auto;
    background-position: 100% 100%;
    background-size: 42%;
  }
}
