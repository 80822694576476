.IngredientDialog {
  padding: 64px;
}

.IngredientBottomSheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.IngredientBottomSheet-headerTitle {
  font-size: 22px;
  font-weight: 600;
}
