.SectionNextArticle {
  margin: 72px 0;
  padding: 48px 0;
  background-color: #f6f8fb;
}

@media (max-width: 1100px) {
  .SectionNextArticle {
    margin: 56px 0;
  }
}

@media (max-width: 840px) {
  .SectionNextArticle {
    padding: 16px 0;
  }
}

@media (max-width: 480px) {
  .SectionNextArticle {
    margin: 48px 0;
  }
}
