.ActivityCard {
  background-color: #fff;
  padding: 48px 22px;
  text-align: center;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
}

@media (max-width: 840px) {
  .ActivityCard {
    padding: 22px 16px;
  }
}

.ActivityCard-date {
  font-size: 34px;
  /* margin-bottom: 8px; */
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
}

@media (max-width: 840px) {
  .ActivityCard-date {
    font-size: 24px;
  }
}

.ActivityCard-specialist {
  font-size: 34px;
  margin-bottom: 32px;
  text-transform: uppercase;
}

@media (max-width: 840px) {
  .ActivityCard-specialist {
    font-size: 24px;
  }
}

.ActivityCard-schedule {
  font-family: 'Akzidenz-Grotesk Pro Med Ext';
  font-size: 28px;
  /* margin-bottom: 8px; */
}

@media (max-width: 840px) {
  .ActivityCard-schedule {
    font-size: 20px;
  }
}

.ActivityCard-location {
  font-family: 'Akzidenz-Grotesk Pro Med Ext';
  font-size: 28px;
}

@media (max-width: 840px) {
  .ActivityCard-location {
    font-size: 20px;
  }
}
