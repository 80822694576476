.TrueDottedList {
  display: flex;
}

.TrueDottedList::before {
}

.TrueDottedList-coll {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.TrueDottedList-coll:first-child {
  flex: 1 1 auto;
}

.TrueDottedList-coll:first-child .TrueDottedList-item {
  display: flex;
}

.TrueDottedList-coll:first-child .TrueDottedList-item::after {
  content: '';
  display: block;
  background-image: url(/content/svg/dot.svg);
  background-repeat: repeat-x;
  background-position: left bottom 6px;
  width: 20px;
  flex: 1 0 20px;
  margin: 0 5px;
}

.TrueDottedList-coll:last-child {
  overflow: hidden;
}

.TrueDottedList-item {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 0;
}

.TrueDottedList-title {
  white-space: nowrap;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}

.TrueDottedList--markedList .TrueDottedList-title:before {
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  color: inherit;
  background-color: currentColor;
  border-radius: 50%;
  margin-right: 8px;
}

.TrueDottedList--highlightedTitle .TrueDottedList-title {
  font-weight: bold;
}

.TrueDottedList-value {
  flex: 0 1 auto;
  white-space: nowrap;
}

.TrueDottedList-importantInfo {
  font-weight: bold;
}

.TrueDottedList-importantTitle {
  margin-left: 8px;
  font-weight: bold;
}
