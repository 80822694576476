.BonusHistoryData_OrderDetails {
}

.BonusHistoryData_OrderDetails-loader {
  position: relative;
  width: 160px;
  padding: 48px 0;
}

.BonusHistoryData_OrderDetails-errorMessage {
  padding-top: 8px;
  color: #bbbcbe;
}

.BonusHistoryData_OrderDetails-number {
  margin-bottom: 16px;
}

.BonusHistoryData_OrderDetails-title {
  font-size: 16px;
  font-weight: 700;
}

.BonusHistoryData_OrderDetails-list {
  margin: 8px 0;
  padding-left: 16px;
}

.BonusHistoryData_OrderDetails-item {
  list-style-type: disc;
}

.BonusHistoryData_OrderDetails-price {
  font-size: 16px;
  font-weight: 700;
}
