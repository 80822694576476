.CompactBreadcrumbs {
  display: block;
  text-decoration: none;
  color: #848fa5;
  padding: 8px 0;
  position: relative;
}

.CompactBreadcrumbs-icon {
  position: absolute;
  padding: 8px;
  left: 0;
  top: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.CompactBreadcrumbs-title {
  display: block;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 24px;
}
