.AccordionNotification {
  min-width: 24px;
  max-width: 100px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #f1f4f9;
  color: #262931;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
