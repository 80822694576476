.ProductCarousel {
  position: relative;
}

@media (max-width: 1100px) {
  .ProductCarousel {
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
  }
}

.ProductCarousel-content {
  z-index: 2;
  position: relative;
  margin: -32px -16px;
  overflow: hidden;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: -48px;
  padding-bottom: 24px;
  margin-bottom: 0;
}

@media (min-width: 1100px) {
  .ProductCarousel-content:hover {
    padding-bottom: 140px;
    margin-bottom: -116px;
  }
}

@media (max-width: 1100px) {
  .ProductCarousel-content {
    display: inline-flex;
    vertical-align: top;
    overflow: visible;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .ProductCarousel-content {
    margin: 0;
  }
}

.ProductCarousel-item {
  padding: 16px;
  box-sizing: border-box;
}

.ProductCarousel-item:first-child {
  padding-left: 16px;
}

.ProductCarousel-item:last-child {
  padding-right: 16px;
}

@media (max-width: 1100px) {
  .ProductCarousel-item {
    flex: 0 0 28%;
    max-width: 28%;
    box-sizing: content-box;
    padding: 0;
    margin-right: 16px;
  }

  .ProductCarousel-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .ProductCarousel-item {
    flex: 0 0 64%;
    max-width: 64%;
    box-sizing: content-box;
    padding: 0;
    overflow: hidden;
    margin-right: 8px;
  }

  .ProductCarousel-item:last-child {
    margin-right: 0;
  }
}

.ProductCarousel-controls {
  position: absolute;
  z-index: 1;
  top: 0;
  /* bottom: 0; */
  height: 520px;
  right: -45px;
  left: -45px;
  margin-top: -15px;
  display: flex;
  justify-content: space-between;
}

.ProductCarousel-prev,
.ProductCarousel-next {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #848fa5;
  opacity: 0.8;
  transition: opacity 0.2s ease-out, color 0.2s ease-out;
  height: 100%;
  width: 120px;
  box-sizing: content-box;
  outline: none;
  position: relative;
}

.ProductCarousel-prev {
  text-align: right;
  margin-left: -120px;
}

.ProductCarousel-next {
  text-align: left;
  margin-right: -120px;
}

.ProductCarousel-prev:hover,
.ProductCarousel-next:hover {
  opacity: 1;
  color: #444952;
}

.ProductCarousel-controlHolder {
  border-radius: 50%;
  border: 1px solid rgba(133, 144, 166, 0.5);
  display: inline-block;
  padding: 16px;
  position: absolute;
  top: 160px;
}

.ProductCarousel-prev .ProductCarousel-controlHolder {
  right: 8px;
}

.ProductCarousel-next .ProductCarousel-controlHolder {
  left: 8px;
}

.isTabUsing .ProductCarousel-prev:focus .ProductCarousel-controlHolder,
.isTabUsing .ProductCarousel-next:focus .ProductCarousel-controlHolder {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(218, 220, 221, 0.7);
}

.ProductCarousel-underControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProductCarousel-underControl {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 16px 0;
  cursor: pointer;
  outline: none;
  appearance: none;
}

.ProductCarousel-underControl:disabled {
  cursor: default;
}

.isTabUsing .ProductCarousel-underControl:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0),
    0 0 0 4px rgba(218, 220, 221, 0.7);
}

.ProductCarousel-rightHolder {
  position: relative;
}

.ProductCarousel-counter {
  position: absolute;
  left: -100px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
}

.ProductCarousel-progress {
  padding: 0 16px;
  flex: 1 1 auto;
}

.ProductCarousel-progressHolder {
  max-width: 500px;
  margin: 0 auto;
}
