.RibbonLabel {
  position: relative;
  padding-bottom: 7%;
  padding-top: 7%;
}

.RibbonLabel-inner {
  position: relative;
  transform: rotate(-6deg);
}

.RibbonLabel-inner::before {
  content: '';
  position: absolute;
  right: 99%;
  width: 100%;
  top: 0;
  bottom: 0;
  color: inherit;
  background-color: currentColor;
}

.RibbonLabel-inner::after {
  content: '';
  position: absolute;
  left: 99%;
  width: 100%;
  top: 0;
  bottom: 0;
  color: inherit;
  background-color: currentColor;
}

.RibbonLabel-inner {
  color: inherit;
  background-color: currentColor;
}

.RibbonLabel-list {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 58%;
  box-sizing: border-box;
  font-style: italic;
}

.RibbonLabel--onlyOne .RibbonLabel-list {
  justify-content: center;
}

@media (max-width: 840px) {
  .RibbonLabel-list {
    width: 100%;
  }
}

.RibbonLabel-item {
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  /* position: relative; */
  /* flex-grow: 1; */
  text-align: center;
}

.RibbonLabel-itemSeparator {
  flex-grow: 1;
  position: relative;
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.RibbonLabel-itemSeparator::after {
  content: '';
  /* position: absolute; */
  width: 8px;
  height: 8px;
  background-color: #fff;
  transform: rotate(45deg);
}
