.PickPointMap {
  position: relative;
}

.PickPointMap-map {
  min-height: 534px;
}

@media (max-width: 1100px) {
  .PickPointMap-map {
    min-height: 536px;
  }
}

@media (max-width: 840px) {
  .PickPointMap-map {
    min-height: 360px;
  }
}

.PickPointMap-infoCard {
  position: absolute;
  top: 24px;
  bottom: 24px;
  right: 24px;
}

@media (max-width: 720px) {
  .PickPointMap-infoCard {
    top: 24px;
    bottom: 24px;
    right: 16px;
  }
}

@media (max-width: 640px) {
  .PickPointMap-infoCard {
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
  }
}
