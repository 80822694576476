.ErrorMessage {
  text-align: center;
}

.ErrorMessage-title {
  margin: 0;
  font-size: 160px;
  line-height: 1.2;
  color: #000;
}

@media (max-width: 600px) {
  .ErrorMessage-title {
    font-size: 120px;
  }
}

@media (max-width: 480px) {
  .ErrorMessage-title {
    font-size: 100px;
  }
}

.ErrorMessage-number {
  display: inline-block;
  vertical-align: middle;
}

.ErrorMessage-imageNumber {
  display: inline-block;
  position: relative;
  left: 8px;
  height: 1.2em;
  vertical-align: middle;
}

.ErrorMessage-message {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  color: #2f333b;
}

@media (max-width: 600px) {
  .ErrorMessage-message {
    font-size: 20px;
  }
}

.ErrorMessage-action {
}
