.PaymentResult {
}

.PaymentResult-comment {
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
}

.PaymentResult-button {
  text-align: center;
}
