.ProblemSkin_VideoSection {
}

.ProblemSkin_VideoSection-title {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  padding: 100px 0 64px;
}

@media (max-width: 1100px) {
  .ProblemSkin_VideoSection-title {
    font-size: 18px;
    padding: 64px 0 42px;
  }
}

.ProblemSkin_VideoSection-video {
  max-width: 830px;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}
