.ProductList {
  overflow: visible;
}

.ProductList-content {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
}

@media (max-width: 640px) {
  .ProductList-content {
    margin: -8px;
  }
}

.ProductList--isLanding .ProductList-itemInnerWrapper {
  height: 100%;
  background-color: #fff;
}

.ProductList-item {
  flex-grow: 0;
  flex-basis: 25%;
  max-width: 25%;
  padding: 16px;
  box-sizing: border-box;
  overflow: visible;
}

.ProductList--isLanding .ProductList-item {
  /* background-color: #fff; */
  /* padding: 0; */
  /* margin: 16px;
  box-sizing: border-box; */
}

.ProductList--wide .ProductList-item {
  flex-basis: 25%;
  max-width: 25%;
}

@media (max-width: 1100px) {
  .ProductList-item {
    flex-basis: 33.33%;
    min-width: 33.33%;
    height: 50vw;
  }
}

@media (max-width: 840px) {
  .ProductList-item {
    flex-basis: 50%;
    min-width: 50%;
    height: auto;
    padding: 8px;
  }
}

.ProductList-pagination {
  margin-top: 50px;
}

.ProductList-loadMore {
  margin-top: 32px;
  text-align: center;
}

.ProductList-loader {
  min-height: 120px;
  position: relative;
}

.ProductList-dot {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #dbe1ec;
  margin: 32px auto 0;
}

.ProductList--singleProduct .ProductList-content {
  justify-content: center;
}

.ProductList--singleProduct .ProductList-item {
  flex-basis: 50%;
  max-width: 50%;
}

.ProductList--singleProduct .ProductSetCard-image {
  width: 70%;
}

@media (max-width: 840px) {
  .ProductList--singleProduct .ProductList-item {
    flex-basis: 80%;
    max-width: 80%;
  }
  .ProductList--singleProduct .ProductSetCard-image {
    width: 100%;
  }
}
