.FooterNavigationGroup {
  /* empty */
}

@media (max-width: 720px) {
  .FooterNavigationGroup {
    cursor: pointer;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
  }
}

.FooterNavigationGroup-title {
  font-size: 18px;
  color: #262931;
  margin: 0;
}

.FooterNavigationGroup-list {
  margin: 0;
  padding: 0;
}

@media (max-width: 720px) {
  .FooterNavigationGroup-list {
    display: none;
  }
}

.FooterNavigationGroup-item {
  list-style: none;
  margin: 12px 0;
  color: #818690;
}

.FooterNavigationGroup-item--number {
  margin: 18px 0;
}

.FooterNavigationGroup-link {
  text-decoration: none;
  color: #818690;
  font-size: 14px;
  padding: 4px 0;
  display: inline-block;
}

.FooterNavigationGroup-link:hover {
  color: #696e77;
  text-decoration: underline;
}

.FooterNavigationGroup-number {
  font-size: 18px;
  color: #262931;
}
