.InstagramGroup {
  display: flex;
  align-items: center;
  margin: -16px;
}

@media (max-width: 1100px) {
  .InstagramGroup {
    /* display: none; */
    flex-wrap: wrap;
  }
}

.InstagramGroup-item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}

@media (max-width: 1100px) {
  .InstagramGroup-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.InstagramGroup--withoutInstagramLinks .InstagramGroup-item {
  flex: 0 0 100%;
  max-width: 100%;
}
