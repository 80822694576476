.PickPointCard {
  max-height: 100%;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 hsla(0, 0%, 0%, 0.1);
}

@media (max-width: 640px) {
  .PickPointCard {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
  .PickPointCard {
    font-size: 14px;
  }
}

.PickPointCard-header {
  flex: 0 0 auto;
  padding: 16px 24px 8px;
  padding-right: 42px;

  position: relative;
}

@media (max-width: 720px) {
  .PickPointCard-header {
    padding: 16px 16px 8px;
    padding-right: 42px;
  }
}

@media (max-width: 640px) {
  .PickPointCard-header {
    padding: 16px 24px 8px;
    position: static;
  }
}

.PickPointCard-content {
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow-y: auto;
}

@media (max-width: 720px) {
  .PickPointCard-content {
    padding: 8px 16px;
  }
}

@media (max-width: 640px) {
  .PickPointCard-content {
    padding: 8px 24px;
  }
}

.PickPointCard-footer {
  flex: 0 0 auto;
  padding: 16px 16px 24px;
}

@media (max-width: 720px) {
  .PickPointCard-footer {
    padding: 16px 8px 24px;
  }
}

@media (max-width: 640px) {
  .PickPointCard-footer {
    padding: 16px 16px 24px;
  }
}

.PickPointCard-title {
  margin: 0;
  font-size: 18px;
  line-height: 1.2;
}

@media (max-width: 640px) {
  .PickPointCard-title {
    font-size: 20px;
  }
}

.PickPointCard-notification {
}

@media (max-width: 640px) {
  .PickPointCard-notification {
    display: none;
  }
}

.PickPointCard-infoGroup {
}

.PickPointCard-infoItem {
  margin-bottom: 16px;

  list-style: none;
}

.PickPointCard-infoItem:last-child {
  margin-bottom: 0;
}

.PickPointCard-infoItemTitle {
}

.PickPointCard-infoItemTitleIcon {
  display: inline-block;
  width: 32px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
}

.PickPointCard-infoItemTitleString {
  vertical-align: middle;
}

.PickPointCard-infoItemContent {
  display: inline-block;
  padding-left: 40px;
  font-weight: 300;
  color: #818690;
}

.PickPointCard-buttonSelect {
}

@media (max-width: 640px) {
  .PickPointCard-buttonSelect {
    margin-bottom: 8px;
  }
}

.PickPointCard-buttonBack {
  display: none;
}

@media (max-width: 640px) {
  .PickPointCard-buttonBack {
    display: block;
  }
}

.PickPointCard-buttonClose {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #a1a6af;
  font-size: 16px;
}

@media (max-width: 640px) {
  .PickPointCard-buttonClose {
    display: none;
  }
}
