.GroupListHeader {
  margin: 32px 0;
}

.GroupListHeader--widthTopBorder {
}

.GroupListHeader-desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.GroupListHeader--widthTopBorder .GroupListHeader-desktop {
  border-top: 1px solid #dfebf0;
}

.GroupListHeader-mobile {
  border-top: 1px solid #e6e7e9;
}

.GroupListHeader-sort {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
