.MainCard_TwoColumnGrid {
  display: flex;
  margin: -16px;
}

.MainCard_TwoColumnGrid-col {
  padding: 16px;
  box-sizing: border-box;
  flex: 0 0 60%;
  max-width: 60%;
  position: relative;
}

.MainCard_TwoColumnGrid-colWide {
  padding: 16px;
  box-sizing: border-box;
  flex: 0 0 40%;
  max-width: 40%;
}

.MainCard_TwoColumnGrid-breadcrumbs {
}

.MainCard_TwoColumnGrid-title h1 {
  margin: 0;
  margin-bottom: 8px;
  text-align: left;
  max-width: 550px;
  font-size: 24px;
  line-height: 32px;
}

.MainCard_TwoColumnGrid-review {
  margin-bottom: 32px;
  font-size: 14px;
}

.MainCard_TwoColumnGrid-description {
}

.MainCard_TwoColumnGrid-slider {
  max-width: 540px;
  margin: auto;
}

.MainCard_TwoColumnGrid-temporaryLabelIcon {
  position: absolute;
  left: 8px;
  top: 8px;
}

.MainCard_TwoColumnGrid-markers {
  margin: 0 0 20px 0;
}

.MainCard_TwoColumnGrid-priceHolder {
  margin-bottom: 16px;
  line-height: 1;
}

.MainCard_TwoColumnGrid-currentPrice {
  margin-right: 8px;
  font-size: 32px;
}
.MainCard_TwoColumnGrid-previousPrice {
  font-size: 24px;
}

.MainCard_TwoColumnGrid-switchGroup {
  display: inline-flex;
  align-items: center;
  margin-bottom: 32px;
}

.MainCard_TwoColumnGrid-switchHolder {
  flex: 0 0 auto;
  margin-right: 16px;
}

.MainCard_TwoColumnGrid-infoHolder {
  flex: 0 0 auto;
}

.MainCard_TwoColumnGrid-switch {
}

.MainCard_TwoColumnGrid-options {
  margin-bottom: 16px;
}

.MainCard_TwoColumnGrid-codeHolder {
  margin-top: 20px;
}

.MainCard_TwoColumnGrid-code {
  font-size: 14px;
}

.MainCard_TwoColumnGrid-availabilityHolder {
  margin-top: 5px;
  margin-bottom: 10px;
}

.MainCard_TwoColumnGrid-availability {
  font-size: 14px;
}

.MainCard_TwoColumnGrid-buy {
  display: flex;
  margin-bottom: 16px;
}

.MainCard_TwoColumnGrid-buyBtn {
  flex-grow: 1;
  margin-left: 10px;
  max-width: 185px;
}

.MainCard_TwoColumnGrid-row {
  display: inline-flex;
  margin-bottom: 32px;
}

.MainCard_TwoColumnGrid-rowLink {
  margin-right: 16px;
}
.MainCard_TwoColumnGrid-rowLink:last-child {
  margin-right: 0;
}

.MainCard_TwoColumnGrid-delivery {
}

.MainCard_TwoColumnGrid-description {
  margin-top: 16px;
}

.MainCard_TwoColumnGrid-reviewHolder {
  display: flex;
  align-items: center;
}
