.AddToWaitListDialog {
  padding: 32px 32px 60px;
  max-width: 420px;
}

@media (max-width: 1100px) {
  .AddToWaitListDialog {
    padding: 24px 24px 32px;
  }
}

@media (max-width: 1100px) {
  .AddToWaitListDialog {
    max-width: none;
  }
}

.AddToWaitListDialog-title {
  margin: 0;
  margin-bottom: 8px;
  line-height: 1.2;
  font-size: 32px;
}

@media (max-width: 840px) {
  .AddToWaitListDialog-title {
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .AddToWaitListDialog-title {
    font-size: 20px;
  }
}

.AddToWaitListDialog-info {
  margin-bottom: 16px;
}

.AddToWaitListDialog-form {
}
