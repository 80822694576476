.LinkNavigation {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  font-size: inherit;
  color: inherit;
  margin: 0 -3px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 1.5;
  outline: none;
  font-weight: inherit;
  font-family: inherit;
}

.LinkNavigation:hover {
  color: #666;
}

.LinkNavigation:active .LinkNavigation-text::after {
  opacity: 0;
}

.LinkNavigation-prependedIcon {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  color: #adaeb3;
  transition: 0.2s ease-out;
  margin-right: 8px;
}

.LinkNavigation:hover .LinkNavigation-prependedIcon {
  color: inherit;
  transform: translateX(-4px);
}

.LinkNavigation-appendedIcon {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  color: #adaeb3;
  transition: 0.2s ease-out;
  margin-left: 8px;
}

.LinkNavigation:hover .LinkNavigation-appendedIcon {
  color: inherit;
  transform: translateX(4px);
}

.LinkNavigation-text {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px;
  font-weight: inherit;
}

.LinkNavigation-text::after {
  content: '';
  position: absolute;
  display: block;
  background-color: currentColor;
  opacity: 0.5;
  height: 1px;
  right: 3px;
  left: 3px;
  bottom: 2px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.LinkNavigation-text:hover::after {
  opacity: 1;
}
