.BonusesConformationDialog {
  padding: 0 48px 24px;
  max-width: 360px;
}

@media (max-width: 480px) {
  .BonusesConformationDialog {
    padding: 0 24px 24px;
  }
}

.BonusesConformationDialog-title {
  margin-top: 48px;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: bold;
}

.BonusesConformationDialog--invalidCode .BonusesConformationDialog-title {
  color: #e95958;
}

@media (max-width: 480px) {
  .BonusesConformationDialog-title {
    margin-top: 24px;
    font-size: 24px;
  }
}

.BonusesConformationDialog-info {
  margin-bottom: 16px;
}

.BonusesConformationDialog-phone {
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
}

.BonusesConformationDialog-input {
  text-align: center;
  margin-bottom: 16px;
}

.BonusesConformationDialog-repeatConfirm {
  text-align: center;
}

.BonusesConformationDialog-countDown {
  display: inline-block;
  width: 20px;
  color: #e95958;
  font-weight: bold;
}

.BonusesConformationDialog-loader {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 8px;
}
