.CategoryPanel_ButtonBack {
  display: inline-flex;
  margin: 0;
  align-items: center;
  padding: 12px 24px;
  border: none;
  line-height: 24px;
  font-size: 14px;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease-out;
  appearance: none;
}

.CategoryPanel_ButtonBack-title {
  display: inline-block;
  margin-left: 8px;
}

.CategoryPanel_ButtonBack-icon {
  display: inline-block;
  line-height: 1;
  font-size: 12px;
}

.isTabUsing .CategoryPanel_ButtonBack:focus {
  background-color: #edf0f5;
}

.CategoryPanel_ButtonBack:hover {
  background-color: #edf0f5;
}

.CategoryPanel_ButtonBack:active {
  background-color: #dbe1ec;
}
