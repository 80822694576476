.CityChoiceBottomSheet {
}

.CityChoiceBottomSheet-searchTitle {
  margin: 0 0 8px;
  font-size: 14px;
}

.CityChoiceBottomSheet-list {
  margin: 0;
  padding: 0;
}

.CityChoiceBottomSheet-item {
  list-style: none;
}

.CityChoiceBottomSheet-iconWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.CityChoiceBottomSheet-button {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  border: none;
  font-size: 14px;
  font-family: inherit;
  letter-spacing: 0.4px;
  color: inherit;
  background-color: transparent;
  box-sizing: border-box;
  text-align: left;
  appearance: none;
  outline: none;
}

.CityChoiceBottomSheet-button--active {
  font-weight: 700;
}
