.BottomSheet {
  position: relative;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  height: 100%;
}

.BottomSheet--withBorder {
}

.BottomSheet-header {
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  z-index: 910;
}

.BottomSheet--withBorder .BottomSheet-header {
  border-bottom: 1px solid #dbe1ec;
}

.BottomSheet-title {
  margin: 0;
  font-size: 18px;
}

.BottomSheet-body {
  padding: 0 16px;
  background-color: #fff;
}

.BottomSheet-footer {
  position: sticky;
  bottom: 0;
  padding: 16px;
  background-color: #fff;
  z-index: 910;
}

.BottomSheet--withBorder .BottomSheet-footer {
  border-top: 1px solid #dbe1ec;
}
