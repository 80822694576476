.GiftSets {
}

.GiftSets-filterMenu {
  margin-bottom: 48px;
}

@media (max-width: 840px) {
  .GiftSets-filterMenu {
    margin-bottom: 24px;
  }
}

.GiftSets-content {
}

@media (max-width: 840px) {
  .GiftSets-content {
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    /* overflow-x: auto; */
    overflow-x: hidden;
  }
}

.GiftSets-carousel {
  display: inline-flex;
  vertical-align: top;
  overflow: visible;
  margin: 0;
  padding: 0;
  width: 100%;
}

.GiftSets-item {
  flex: 0 0 75%;
  max-width: 75%;
  box-sizing: border-box;
  padding: 4px;
}

.GiftSets-item:first-child {
  padding-left: 16px;
}

.GiftSets-item:last-child {
  padding-right: 16px;
}

.GiftSets-grid {
  display: flex;
  margin: 0 -8px;
  flex-wrap: wrap;
}

.GiftSets-gridItem {
  padding: 0 8px;
  flex-basis: 50%;
  box-sizing: border-box;
}
