.MainCard_OneColumnGrid {
  position: relative;
}

.MainCard_OneColumnGrid-header {
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MainCard_OneColumnGrid-category {
  color: #808792;
  text-align: center;
}

.MainCard_OneColumnGrid-title {
  margin: 8px 0;
  line-height: 24px;
  text-align: center;
  max-width: 480px;
}

.MainCard_OneColumnGrid-sliderHolder {
  position: relative;
}

.MainCard_OneColumnGrid-weight {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.MainCard_OneColumnGrid-size {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  text-align: center;
}

.MainCard_OneColumnGrid-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid #cacacc;
}
.MainCard_OneColumnGrid-review {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -8px;
}
.MainCard_OneColumnGrid-starsRating {
  padding: 8px;
}

.MainCard_OneColumnGrid-linkToReviews {
  padding: 8px;
}

.MainCard_OneColumnGrid-slider {
  margin-bottom: 16px;
}

.MainCard_OneColumnGrid-buy {
  padding: 16px 0;
}

.MainCard_OneColumnGrid-buyBtn {
  margin: auto;
  max-width: 480px;
}

.MainCard_OneColumnGrid-delivery {
  padding: 16px 0;
  border-top: 1px solid #cacacc;
}

.MainCard_OneColumnGrid-prices {
  text-align: center;
}

.MainCard_OneColumnGrid-temporaryLabelIcon {
  position: absolute;
  top: 48px;
  z-index: 1;
}

.MainCard_OneColumnGrid-metaHeader {
  position: absolute;
  padding: 16px 8px;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.MainCard_OneColumnGrid-labelHolder {
}

.MainCard_OneColumnGrid-label {
}

.MainCard_OneColumnGrid-quickActionHolder {
  position: relative;
  z-index: 1;
}

.MainCard_OneColumnGrid-quickAction {
  line-height: 1;
  font-size: 24px;
}

.MainCard_OneColumnGrid-description {
  padding: 16px 0;
  border-top: 1px solid #cacacc;
}
