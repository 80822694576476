.DeliveryForm {
}

.DeliveryForm-loader {
  min-height: 24px;
  position: relative;
}

.DeliveryForm-deliveryList {
}

.DeliveryForm-deliveryItem {
  margin-bottom: 16px;
}

.DeliveryForm-deliveryItem:last-child {
  margin-bottom: 0;
}

.DeliveryForm-method {
}

.DeliveryForm-methodTitleRow {
}

.DeliveryForm-methodTitleRow:last-child {
  margin-bottom: 0;
}

.DeliveryForm-methodTitle {
  font-weight: 700;
  display: inline-block;
}

.DeliveryForm-methodTitle:last-child {
  margin-bottom: 0;
}

.DeliveryForm-methodTime {
  font-size: 14px;
  font-weight: 300;
  color: #848fa5;
}

.DeliveryForm-methodHint {
  font-size: 14px;
}

.DeliveryForm-actionList {
  display: inline-block;
  font-size: 14px;
  margin-top: 4px;
  cursor: default;
}

.DeliveryForm-action {
  display: inline-block;
  margin-right: 16px;
}

.DeliveryForm-pickPoint {
  font-weight: 700;
  margin-right: 16px;
}

.DeliveryForm-action:last-child {
  margin-right: 0;
}

.DeliveryForm-addAddressRadio {
  display: inline-block;
  margin-top: 8px;
}

.DeliveryForm-freeDeliveryCaption {
  display: inline-block;
  color: #25bc8b;
}

.DeliveryForm-WarningMassege {
  margin-top: 8px;
  padding: 16px;
  background-color: #fcf1c9;
}

.DeliveryForm-recipientTooglerInfo {
  font-size: 14px;
  line-height: 1.3;
  color: rgba(38, 41, 49, 0.5);
  margin-left: 32px;
}

.DeliveryForm-deliveryMethodsListOpen {
}

@media (max-width: 840px) {
  .DeliveryForm-deliveryMethodsListOpen {
    font-size: 18px;
  }
}
