.InputStarRating {
  overflow: hidden;
}
.InputStarRating-holder {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.InputStarRating-item {
  color: #fed835;
  cursor: pointer;
}

.InputStarRating-button {
  padding: 0;
  cursor: inherit;
  vertical-align: top;
  margin: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  outline: none;
}
