.SpecialOffer {
  font-size: 18px;
}

.SpecialOffer--timeLeftAligned {
}

.SpecialOffer--timeColorGrey {
}

@media (max-width: 840px) {
  .SpecialOffer {
    font-size: 16px;
  }
}

.SpecialOffer-title {
  max-width: 480px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 16px;
  font-size: 36px;
  line-height: 1.2;
}

@media (max-width: 840px) {
  .SpecialOffer-title {
    font-size: 24px;
    max-width: 320px;
    margin-bottom: 8px;
  }
}

.SpecialOffer-metaInfo {
  text-align: center;
  margin-bottom: 24px;
}

@media (max-width: 840px) {
  .SpecialOffer-metaInfo {
    margin-bottom: 16px;
  }
}

.SpecialOffer-media {
  margin-bottom: 24px;
}

@media (max-width: 840px) {
  .SpecialOffer-media {
    margin-bottom: 16px;
  }
}

.SpecialOffer-description {
  margin-bottom: 16px;
}

@media (max-width: 840px) {
  .SpecialOffer-description {
    margin-bottom: 16px;
  }
}

@media (max-width: 840px) {
  .SpecialOffer-description {
    margin-bottom: 16px;
  }
}

.SpecialOffer-link {
  margin-bottom: 36px;
}

.SpecialOffer-linkText {
  color: #666;
  text-decoration: underline;
}

.SpecialOffer-time {
  text-align: center;
  margin-bottom: 24px;
  font-size: 18px;
}

.SpecialOffer--timeLeftAligned .SpecialOffer-time {
  color: #818690;
}

.SpecialOffer--timeColorGrey .SpecialOffer-time {
  text-align: left;
}

@media (max-width: 840px) {
  .SpecialOffer-time {
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.SpecialOfferPage-promoCodeGroup {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

@media (max-width: 480px) {
  .SpecialOfferPage-promoCodeGroup {
    display: block;
    margin-top: 16px;
  }
}
