.Tabs {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  margin: 0 -20px;
}
.Tabs-item {
  margin: 0 20px;
  position: relative;
}
.Tabs-link:after {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  height: 3px;
  background-color: #ffd12c;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.2s;
}
.Tabs-link--isActive::after,
.Tabs-link:hover::after {
  display: block;
  transform: translateX(-50%);
  width: 25px;
  opacity: 11;
}
.Tabs-link {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  transition: 0.3s;
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: #000;
}
.Tabs--nav .Tabs-link {
  font-size: 18px;
}
.Tabs-link--isActive {
  /*color: red;*/
}
.Tabs-link:hover {
  /* color: #e95959; */
}
.Tabs-link--isActive {
  /*pointer-events: none;*/
}
