.MainCard_ThreeColumnGrid {
  display: flex;
  margin: -16px;
}

.MainCard_ThreeColumnGrid-coll {
  padding: 16px;
  box-sizing: border-box;
}

.MainCard_ThreeColumnGrid-coll--properties {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 380px;
  max-width: 380px;
}

.MainCard_ThreeColumnGrid-coll--slider {
  flex-grow: 1;
  overflow: hidden;
}

.MainCard_ThreeColumnGrid-coll--options {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 380px;
  max-width: 380px;
}

.MainCard_ThreeColumnGrid--twoColumn .MainCard_ThreeColumnGrid-coll--slider,
.MainCard_ThreeColumnGrid--twoColumn .MainCard_ThreeColumnGrid-coll--options {
  flex: 1 0 50%;
  max-width: 50%;
  box-sizing: border-box;
}

.MainCard_ThreeColumnGrid-breadcrumbs {
}

.MainCard_ThreeColumnGrid-title {
  margin: 10px 0 15px;
  text-align: left;
  max-width: 550px;
}

.MainCard_ThreeColumnGrid-review {
  margin-bottom: 24px;
  font-size: 14px;
}

.MainCard_ThreeColumnGrid-description {
}

.MainCard_ThreeColumnGrid-slider {
}

.MainCard_ThreeColumnGrid-markers {
  margin: 0 0 20px 0;
}

.MainCard_ThreeColumnGrid-prices {
  display: flex;
  align-items: center;
  margin: -5px;
}

.MainCard_ThreeColumnGrid-currentPrice {
  margin: 5px;
  font-size: 32px;
}

.MainCard_ThreeColumnGrid-previousPrice {
  margin: 5px;
  font-size: 24px;
}

.MainCard_ThreeColumnGrid-switch {
  margin: 15px 0 15px;
}

.MainCard_ThreeColumnGrid-code {
  margin-top: 20px;
}

.MainCard_ThreeColumnGrid-availability {
  margin-top: 5px;
  margin-bottom: 10px;
}

.MainCard_ThreeColumnGrid-buy {
  display: flex;
  margin-top: 25px;
  margin-bottom: 15px;
}

.MainCard_ThreeColumnGrid-buyBtn {
  flex-grow: 1;
  margin-left: 10px;
  max-width: 185px;
}

.MainCard_ThreeColumnGrid-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 260px;
}

.MainCard_ThreeColumnGrid-rowLink {
  margin: 5px 0;
}

.MainCard_ThreeColumnGrid-delivery {
  padding-top: 16px;
}
