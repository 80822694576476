.Coupon {
  font-size: 14px;
}

.Coupon-loader {
  position: relative;
  margin: 0 auto;
}

.Coupon-result {
}

.Coupon-resultInfo {
  display: inline-flex;
  align-items: center;
}

.Coupon-changeGiftPanel {
  padding-left: 150px;
}

@media (max-width: 840px) {
  .Coupon-changeGiftPanel {
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 840px) {
  .Coupon-resultInfo {
    display: flex;
  }
}

.Coupon-changeGift {
  flex: 0 0 auto;
  margin-left: 16px;
}

.Coupon-resultReset {
  flex: 0 0 auto;
  margin-left: 16px;
}

.Coupon-description {
  flex: 0 1 auto;
  margin-right: 12px;
  line-height: 1.2;
}

@media (max-width: 840px) {
  .Coupon-description {
    margin-right: auto;
  }
}

.Coupon-code {
  flex: 0 0 auto;
  font-weight: 700;
  display: inline-block;
  padding: 3px 8px;
  border: 1px dashed #25bc8b;
  border-radius: 14px;
  margin: 0 4px;
}

.Coupon-infoButton {
  appearance: none;
  background: none;
  border: none;
  display: block;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 4px;
  font: inherit;
  margin: 0;
  line-height: 1;
  font-size: 16px;
  cursor: pointer;
}

body:not(.isTabUsing) .Coupon-infoButton:focus {
  outline: none;
}

@media (max-width: 360px) {
  .Coupon-infoButton {
    flex: 0 0 auto;
  }
}

.Coupon-codeIcon {
  flex: 0 0 auto;
  margin-right: 8px;
  line-height: 1;
  font-size: 20px;
  color: #25bc8b;
}

@media (max-width: 360px) {
  .Coupon-codeIcon {
    flex: 0 0 auto;
  }
}

.Coupon-infoIcon {
  color: #25bc8b;
  display: inline-block;
  width: 16px;
}

.Coupon-alertIcon {
  border: 1px solid #ffca38;
  border-radius: 50%;
  color: inherit;
  display: inline-block;
  padding: 4px;
  font-size: 12px;
  width: 12px;
}

.Coupon-buttonAcceptWrapper {
  display: inline-block;
  height: 40px;
  padding: 2px;
  box-sizing: border-box;
  overflow: hidden;
}

.Coupon-buttonAccept {
  display: inline-block;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  border: none;
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #e74a5a;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-out;
}

.Coupon-buttonAccept:hover {
  background-color: #cf3a4a;
}

.isTabUsing .Coupon-buttonAccept:focus {
  background-color: #e74a5a;
  box-shadow: 0 0 0 2px rgba(231, 74, 90, 0.4);
}

.Coupon-buttonAccept:active {
  background-color: #c73745;
}

.Coupon-buttonAccept:disabled {
  opacity: 0.5;
  cursor: default;
}

.Coupon-conditionsGrid {
  display: flex;
  align-items: center;
}

.Coupon-warning {
  flex: 0 0 auto;
  margin-right: 16px;
  width: 48px;
  height: 48px;
}

.Coupon-conditions {
  flex: 1 1 auto;
}

.Coupon-conditionsTitle {
  margin: 0;
  margin-bottom: 8px;
  line-height: 1.2;
}

@media (max-width: 840px) {
  .Coupon-conditionsTitle {
    font-size: 16px;
  }
}

.Coupon-conditionsDescription {
  margin: 0;
}

@media (max-width: 840px) {
  .Coupon-conditionsDescription {
    font-size: 14px;
  }
}

.Coupon-form {
  flex: 1 1 auto;
}

.Coupon-infoSignHolder {
  flex: 0 0 auto;
  margin-left: 16px;
}

.Coupon-labelInfo {
  max-width: 200px;
}
