.Shop {
  display: flex;
  padding: 24px 0;
  font-size: 14px;
  color: #18191a;
  overflow: hidden;
}

.Shop--active {
  animation-name: blink;
  animation-duration: 3s;
}

.Shop-contentCol {
  flex: 1 1 auto;
  display: flex;
  margin: -8px;
}

.Shop-col {
  flex: 1 0 50%;
  max-width: 50%;
  padding: 8px;
  box-sizing: border-box;
}

@media screen and (max-width: 840px) {
  .Shop-contentCol {
    flex-wrap: wrap;
    margin: 0;
  }
}

@media screen and (max-width: 840px) {
  .Shop-col {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 0;
  }
}

.Shop-title {
  margin: 0;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}

.Shop-address {
  margin-bottom: 4px;
}

.Shop-guideList {
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
}

.Shop-guideItem {
  list-style: none;
}

.Shop-guide {
}

.Shop-guideIcon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.Shop-guideTitle {
  display: inline-block;
  vertical-align: top;
  color: #444952;
}

.Shop-phoneList {
  margin: 0;
  padding: 0;
}

.Shop-phoneItem {
  list-style: none;
}

.Shop-scheduleList {
  margin: 0;
  padding: 0;
}

.Shop-scheduleItem {
  list-style: none;
}

.Shop-days {
  display: inline-block;
  margin-right: 8px;
}

.Shop-showOnMap {
  margin-bottom: 16px;
}

.Shop-labelCol {
  flex: 0 0 48px;
  display: flex;
  max-width: 48px;
  margin-left: 8px;
  justify-content: flex-end;
  align-items: flex-start;
}

.Shop-labelNew {
  display: inline-block;
  padding: 5px 8px 3px;
  border-radius: 3px;
  line-height: 1;
  font-size: 12px;
  font-weight: 700;
  background-color: #25bc8b;
  color: #fff;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .Shop-labelNew {
    padding: 5px 6px 3px;
    font-size: 10px;
  }
}

@keyframes blink {
  0% {
    background-color: transparent;
  }
  10% {
    background-color: rgba(255, 218, 78, 0.2);
  }
  100% {
    background-color: transparent;
  }
}

.Shop-featureList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Shop-feature {
  color: #262931;
  background-color: #ffd12c;
  line-height: 1;
  font-weight: 700;
  font-size: 12px;
  padding: 5px 8px 3px;
  margin-bottom: 8px;
  border-radius: 3px;
  display: inline-block;
}

.Shop-feature:last-child {
  margin-bottom: 0;
}

.Shop-feature--ppd {
  /* background-color: #25bc8b; */
}

.Shop-feature--store-opening {
  background-color: #25bc8b;
  color: #fff;
}
