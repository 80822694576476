.ActivityDetail {
  display: flex;
  align-items: center;
}

.ActivityDetail-imageHolder {
  margin-right: 32px;
}

@media (max-width: 680px) {
  .ActivityDetail-imageHolder {
    margin-right: 16px;
  }
}

.ActivityDetail-image {
  flex-shrink: 0;
  width: 130px;

  font-size: 10px;
  position: relative;
  padding-top: 100%;
}

@media (max-width: 680px) {
  .ActivityDetail-image {
    width: 75px;
  }
}

.ActivityDetail-imageInner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ActivityDetail-title {
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  font-size: 18px;
  line-height: 1.4;
  color: #fff;
  /* text-transform: uppercase; */
}

@media (max-width: 680px) {
  .ActivityDetail-title {
    font-size: 16px;
  }
}
