.SubcategoryList {
  margin-bottom: 24px;
}

.SubcategoryList:last-child {
  margin-bottom: 0;
}

.SubcategoryList-title {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  cursor: default;
}

.SubcategoryList-title--likeLink {
  position: relative;
  text-decoration: none;
}

.SubcategoryList-title::after {
  display: none;
}

.SubcategoryList-title--likeLink::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.SubcategoryList-title--likeLink:hover::after {
  opacity: 1;
}

.SubcategoryList-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.SubcategoryList-item {
  display: block;
  margin-bottom: 6px;
  position: relative;
}

.SubcategoryList-item:last-child {
  margin-bottom: 0;
}

.SubcategoryList-itemLink {
  text-decoration: none;
  font-size: 14px;
  position: relative;
}

.SubcategoryList-itemLink::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  height: 1px;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.SubcategoryList-itemLink:hover::after {
  opacity: 1;
}
