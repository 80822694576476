.ArticalVerticalPreview {
  position: relative;
  transition: all 0.1s linear;
}

.ArticalVerticalPreview:hover {
  transform: translateY(-4px);
}

@media (max-width: 840px) {
  .ArticalVerticalPreview:hover {
    transform: none;
  }
}

.ArticalVerticalPreview-imageHolder {
  margin-bottom: 16px;
  max-height: 240px;
  overflow: hidden;
}

@media (max-width: 840px) {
  .ArticalVerticalPreview-imageHolder {
    margin-bottom: 8px;
    max-height: 160px;
  }
}

.ArticalVerticalPreview-image {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.ArticalVerticalPreview-metaHolder {
  margin-bottom: 8px;
}

.ArticalVerticalPreview-publicationDate {
  color: #818690;
  font-size: 14px;
  font-weight: 300;
}

.ArticalVerticalPreview-publicationDate::before {
  content: '';
  display: inline-block;
  margin-right: 16px;
  width: 32px;
  border-top: 1px solid #fed835;
  border-bottom: 1px solid #fed835;
  vertical-align: middle;
}

@media (max-width: 400px) {
  .ArticalVerticalPreview-publicationDate {
    font-size: 12px;
  }
}
.ArticalVerticalPreview-contentHolder {
}

.ArticalVerticalPreview-title {
  margin: 0;
  margin-bottom: 16px;
  line-height: 1.2;
}

@media (max-width: 640px) {
  .ArticalVerticalPreview-title {
    margin-bottom: 12px;
  }
}

@media (max-width: 480px) {
  .ArticalVerticalPreview-title {
    margin-bottom: 8px;
  }
}

.ArticalVerticalPreview-link {
  font-size: 20px;
  font-weight: 700;
  color: #2f333b;
  text-decoration: none;
}

@media (max-width: 840px) {
  .ArticalVerticalPreview-link {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .ArticalVerticalPreview-link {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .ArticalVerticalPreview-link {
    font-size: 16px;
  }
}

.ArticalVerticalPreview-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ArticalVerticalPreview-description {
  line-height: 1.8;
  font-size: 14px;
  color: #444952;
}

@media (max-width: 400px) {
  .ArticalVerticalPreview-description {
    font-size: 12px;
  }
}
