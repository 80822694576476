.Basket {
  /* empty */
}

.Basket--dimmed {
  opacity: 0.5;
  pointer-events: none;
}

.Basket-alert {
  margin-bottom: 24px;
}

.Basket-section {
  border-bottom: 1px solid #edf0f5;
  padding: 24px 0;
}

.Basket-section:last-child {
  border-bottom: none;
}

@media (max-width: 840px) {
  .Basket-section {
    padding: 16px 0;
  }
}

.Basket-section--noUnderline {
  border-bottom: 0;
}

.Basket-product {
  padding: 16px 0;
  border-bottom: 1px solid #edf0f5;
}

.Basket-product.Basket-product--gift {
  padding: 16px 0;
}

.Basket-product:first-child {
  margin-top: -24px;
}

.Basket-product:last-child {
  border-bottom: none;
  margin-bottom: -24px;
}

.Basket-row {
  display: flex;
  align-items: center;
  margin: -16px;
}

.Basket-cellProduct {
  flex: 1 1 auto;
  padding: 16px;
}

.Basket-cellAmount {
  flex: 0 0 10%;
  max-width: 10%;
  display: flex;
  justify-content: center;
  padding: 16px;
}

.Basket-cellPrice {
  flex: 0 0 16%;
  max-width: 16%;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.Basket-cellActions {
  flex: 0 0 10%;
  max-width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
}

.Basket-line {
  padding: 8px;
}

@media (max-width: 840px) {
  .Basket-line {
    padding: 4px;
  }
}

.Basket-lineTotal {
  padding: 8px;
  padding-top: 16px;
}

@media (max-width: 840px) {
  .Basket-lineTotal {
    padding: 4px;
    padding-top: 8px;
  }
}

.Basket-giftChoice {
  font-weight: bold;
}

@media (max-width: 840px) {
  .Basket-giftChoice {
    font-size: 14px;
    text-align: center;
  }
}

.Basket-receiptEntry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 840px) {
  .Basket-receiptEntry {
    font-size: 14px;
  }
}

.Basket-receiptTotalEntry {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 840px) {
  .Basket-receiptTotalEntry {
    margin-top: 12px;
  }
}

.Basket-receiptValue {
  font-size: 1.125em;
  font-weight: 700;
}

.Basket-totalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
}

.Basket-specialOffers {
  flex: 1 1 auto;
  padding-right: 64px;
}

@media (max-width: 1100px) {
  .Basket-specialOffers {
    padding-right: 16px;
  }
}

.Basket-giftChoiceHolder {
  margin-bottom: 16px;
}

.Basket-promoHolder {
  /* max-width: 500px; */
  flex: 1 0 auto;
  /* padding: 8px 0; */
}

.Basket-promo {
  /* max-width: 500px; */
}

.Basket-totalHolder {
  min-width: 400px;
  flex: 0 0 auto;
  align-self: flex-end;
  position: relative;
}

.Basket-receiptCaptionTotal {
  margin-right: 24px;
}

@media (max-width: 840px) {
  .Basket-receiptCaptionTotal {
    margin-right: auto;
    flex: 0 0 auto;
    font-size: 16px;
  }
}

.Basket-receiptValueTotal {
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 840px) {
  .Basket-receiptValueTotal {
    flex: 0 0 auto;
    font-size: 18px;
  }
}

.Basket-willGettedBonuses {
  flex-basis: 100%;
  position: absolute;
  right: 0;
}

@media (max-width: 840px) {
  .Basket-willGettedBonuses {
    position: static;
    text-align: right;
  }
}

.Basket-willGettedBonusesMain {
  color: #25bc8b;
}

.Basket-actionList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 -8px;
}

@media (max-width: 840px) {
  .Basket-actionList {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
  }
}

.Basket-action {
  flex: 0 0 auto;
  padding: 0 8px;
}

@media (max-width: 840px) {
  .Basket-action {
    padding: 0;
    margin-bottom: 16px;
  }

  .Basket-action:last-child {
    margin-bottom: 0;
  }
}

.Basket-inputRow {
  max-width: 630px;
}

@media (max-width: 1100px) {
  .Basket-inputRow {
    max-width: 500px;
  }
}

.Basket-bonusHeader {
  margin-top: 16px;
  margin-bottom: 8px;
}

@media (max-width: 840px) {
  .Basket-bonusHeader {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.Basket-actionActionsHolder {
  /* overflow: hidden; */
}

.Basket-actionActions {
  margin: -8px 0;
}

.Basket-actionAction {
  padding: 8px 0;
}
