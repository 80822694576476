.LandingBanner {
  padding-top: 54px;
  position: relative;
  margin-bottom: 54px;
}

.LandingBanner-top {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  z-index: 2;
}

@media (max-width: 840px) {
  .LandingBanner-top {
    position: relative;
  }
}

.LandingBanner-bottom {
  position: absolute;
  z-index: 1;
  left: 50%;
  right: 0;
  bottom: 5%;
}

@media (max-width: 1100px) {
  .LandingBanner-bottom {
    bottom: 0;
  }
}
@media (max-width: 940px) {
  .LandingBanner-bottom {
    bottom: -24px;
  }
}
@media (max-width: 870px) {
  .LandingBanner-bottom {
    bottom: -52px;
  }
}

@media (max-width: 840px) {
  .LandingBanner-bottom {
    position: static;
    margin-top: 100px;
    position: static;
  }
}

.LandingBanner-label {
  font-size: 38px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

@media (max-width: 840px) {
  .LandingBanner-label {
    font-size: 30px;
  }
}

.LandingBanner-title {
  /* transform: translateX(45%); */
  position: relative;
  z-index: 2;
  padding-left: 20%;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 30px;
}

@media (max-width: 840px) {
  .LandingBanner-title {
    padding-left: 0;
  }
}

.LandingBanner-address {
  position: absolute;
  /* left: 70%; */
  right: 0;
  left: 10px;
  transform: rotate(-5deg);
  z-index: 0;
  top: 40%;
}

@media (max-width: 1100px) {
  .LandingBanner-address {
    top: 45%;
  }
}
@media (max-width: 920px) {
  .LandingBanner-address {
    top: 50%;
  }
}

.LandingBanner-address::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 5000px;
  background-color: #fff;
}
.LandingBanner-address::after {
  content: '';
  position: absolute;
  bottom: -8px;
  right: 100%;
  width: 5000px;
  height: 2px;
  background-color: #000;
}

@media (max-width: 840px) {
  .LandingBanner-address {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 54px;
    transform: rotate(-3deg) translateX(-25px);
  }
}

.LandingBanner-images {
  /* max-width: 670px; */
  max-width: 52%;
  position: relative;
  z-index: 1;
  /* opacity: 0.2; */
}

@media (max-width: 840px) {
  .LandingBanner-images {
    max-width: 100%;
  }
}

.LandingBanner-action {
  display: flex;
  justify-content: center;
}

.LandingBanner-addressNew {
  position: absolute;
  left: 40%;
  right: 24px;
  transform: rotate(-5deg);
  z-index: 0;
  top: 40%;
}

@media (max-width: 1100px) {
  .LandingBanner-addressNew {
    top: 45%;
  }
}
@media (max-width: 920px) {
  .LandingBanner-addressNew {
    top: 45%;
  }
}

/* .LandingBanner-addressNew::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 5000px;
  background-color: #fff;
}
.LandingBanner-addressNew::after {
  content: '';
  position: absolute;
  bottom: -8px;
  right: 100%;
  width: 5000px;
  height: 2px;
  background-color: #000;
} */

@media (max-width: 840px) {
  .LandingBanner-addressNew {
    position: relative;
    top: auto;
    left: -25px;
    right: auto;
    margin-bottom: 54px;
    transform: rotate(-3deg) translateX(0px);
    width: 106%;
  }
}
