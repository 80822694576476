.TableThanks {
  padding: 16px 24px;
  border: 1px solid #dbe1ec;
  border-radius: 4px;
  overflow: hidden;
}

.TableThanks-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.TableThanks-headerItem {
}

.TableThanks-list {
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;
}

@media (max-width: 840px) {
  .TableThanks-list {
    margin: 0;
  }
}

.TableThanks-listItem {
  border-top: 1px solid #dbe1ec;
  padding: 16px 0;
}

.TableThanks-listItem:last-child {
  border-bottom: 1px solid #dbe1ec;
}

.TableThanks-footer {
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.TableThanks-price {
  flex: 0 1 250px;
  max-width: 250px;
}

@media (max-width: 840px) {
  .TableThanks-price {
    flex: 1 1 auto;
    max-width: none;
  }
}

.TableThanks-priceRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 8px;
}

.TableThanks-priceRow--discount {
  color: #e8495a;
}

.TableThanks-priceRow:first-child {
  margin-top: 0;
}

.TableThanks-priceRow:last-child {
  margin-top: 16px;
}

.TableThanks-totalPriceTitle {
  font-size: 18px;
  font-weight: bold;
}

.TableThanks-totalPriceAmount {
  font-size: 24px;
}

@media (max-width: 840px) {
  .TableThanks-totalPriceAmount {
    font-size: 18px;
    font-weight: 700;
  }
}

.TableThanks-priceInfo {
}

.TableThanks-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: inherit;
  font-size: 18px;
  padding: 16px 8px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  border-top: 1px solid #dbe1ec;
  border-bottom: 1px solid #dbe1ec;
  transition: background-color 0.2s ease-out;
}

.TableThanks-button:hover {
  background-color: #f7fbff;
}

.TableThanks-buttonIcon {
  display: flex;
  align-items: center;
  margin: 0 16px;
}

@media (max-width: 840px) {
  .TableThanks {
    padding: 0;
    border: none;

    border-bottom: 1px solid #dbe1ec;
  }

  .TableThanks-header {
    display: none;
  }

  .TableThanks-listItem:first-child {
    border-top: none;
  }

  .TableThanks-listItem:last-child {
    border-bottom: none;
  }

  .TableThanks-totalPriceTitle {
    font-size: 16px;
  }

  .TableThanks-priceRow:last-child {
    margin-top: 8px;
  }
}
