.GiftProduct {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.GiftProduct-content {
  flex: 0 0 auto;
  margin-bottom: 16px;
  text-align: center;
}

@media (max-width: 1100px) {
  .GiftProduct-content {
    display: block;
    margin-bottom: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
}

.GiftProduct-visualGroup {
  margin-bottom: 32px;
}

@media (max-width: 1100px) {
  .GiftProduct-visualGroup {
    margin-bottom: 24px;
  }
}

@media (max-width: 840px) {
  .GiftProduct-visualGroup {
    margin-bottom: 16px;
  }
}

.GiftProduct-image {
}

.GiftProduct-contentGroup {
}

.GiftProduct-title {
  margin: 0;
  margin-bottom: 4px;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  color: #818690;
  text-align: center;
}

@media (max-width: 840px) {
  .GiftProduct-title {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .GiftProduct-title {
    margin-bottom: 4px;
    font-size: 12px;
  }
}

.GiftProduct-description {
  margin-bottom: 4px;
  line-height: 1.4;
}

@media (max-width: 480px) {
  .GiftProduct-description {
    margin-bottom: 4px;
    font-size: 14px;
  }
}

.GiftProduct-priceHolder {
  font-size: 16px;
  text-align: center;
}

@media (max-width: 640px) {
  .GiftProduct-priceHolder {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .GiftProduct-priceHolder {
    font-size: 12px;
  }
}

.Product-previousPrice {
  display: inline-block;
  margin-right: 4px;
}

.GiftProduct-currentPrice {
  display: inline-block;
  font-weight: 700;
}

.GiftProduct-action {
  flex: 0 0 auto;
}
