.ButtonDelete {
  background-color: transparent;
  border: none;
  /* width: 24px;
    height: 24px; */
  cursor: pointer;
  color: inherit;
  padding: 0;
  outline: none;
  border-radius: 50%;
  padding: 8px;
  box-sizing: content-box;
  vertical-align: top;
}

.ButtonDelete:hover {
  color: #e95958;
}

.ButtonDelete:focus {
  outline: none;
  /*TODO  разработать focus эффект*/
  /* box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(47, 124, 192, 0.54); */
}
