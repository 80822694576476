.OrderStepSummary {
}

.OrderStepSummary-titleRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OrderStepSummary-buttonEdit {
  flex: 0 0 auto;
  font-size: 14px;
}

.OrderStepSummary-title {
  margin: 0;
  line-height: 1.2;
  font-size: 24px;
  font-weight: 700;
  color: #848fa5;
  flex: 1 1 auto;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #262931;
  overflow: hidden;
  word-break: break-word;
  padding-right: 8px;
}

@media (max-width: 840px) {
  .OrderStepSummary-title {
    font-size: 16px;
  }
}

.OrderStepSummary-titleMark {
  flex: 0 0 32px;
  line-height: 1;
}

.OrderStepSummary-titleString {
  flex: 1 1 auto;
  display: inline-block;
  vertical-align: middle;
}

.OrderStepSummary-summary {
  padding-left: 32px;
  margin-top: 8px;
}

.OrderStepSummary-checkMark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #25bc8b;
  vertical-align: top;
}

.OrderStepSummary-checkMark:before {
  content: '';
  position: absolute;
  left: 10px;
  bottom: 5px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 8px;
  height: 4px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.OrderStepSummary-editButton {
  -webkit-appearance: none;
  border: none;
  background: none;
  opacity: 0.8;
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
}
