.ConditionList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 0;
}

.ConditionList-item {
  flex-grow: 1;
  flex-basis: 33.3333%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0;
  list-style-type: none;
}

.ConditionList-item:after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 75px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: #e3e6ed;
}

@media (max-width: 600px) {
  .ConditionList {
    justify-content: center;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .ConditionList-item {
    flex-basis: 100%;
    margin: 0;
    padding: 8px;
    min-height: 140px;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .ConditionList-item:after {
    height: 1px;
    width: 75px;
    max-width: 500px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
  }
}

.ConditionList-item:last-child:after {
  display: none;
}

.ConditionList-name {
  font-size: 16px;
  font-weight: bold;
  margin-top: 24px;
}

.ConditionList-descr {
  font-size: 14px;
  font-weight: normal;
}
.ConditionList-img {
  width: 32px;
  height: 32px;
}
