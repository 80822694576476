.AddressField {
  display: flex;
}

.AddressField-wideCol {
  margin-right: 8px;
}

.AddressField-wideCol:last-child {
  margin-right: 0;
}

.AddressField-narrowCol {
  width: 140px;
  margin-right: 8px;
}

.AddressField-narrowCol:last-child {
  margin-right: 0;
}
