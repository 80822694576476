.ProblemSkin_Reason {
  display: flex;
  align-items: stretch;
  /* background-color: pink; */
  height: 100%;
}

@media (max-width: 1100px) {
  .ProblemSkin_Reason {
    flex-direction: column;
  }
}

.ProblemSkin_Reason--mirrored {
}

.ProblemSkin_Reason-info {
  flex: 1 1 50%;
  background: rgba(244, 244, 244, 0.7);
  /* height: 100%; */
  padding: 70px 95px;
  box-sizing: border-box;
}
.ProblemSkin_Reason--mirrored .ProblemSkin_Reason-info {
  order: 1;
}
@media (max-width: 1100px) {
  .ProblemSkin_Reason-info {
    padding: 42px 14px;
  }
  .ProblemSkin_Reason--mirrored .ProblemSkin_Reason-info {
    order: inherit;
  }
}

.ProblemSkin_Reason-visual {
  flex: 1 1 50%;
}
.ProblemSkin_Reason-imageHolder {
  /* height: 100%; */
  /* padding-top: 55.56%; */
  /* padding-top: 50%; */
  padding-top: 20%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .ProblemSkin_Reason-imageHolder {
    padding-top: 72%;
  }
}

.ProblemSkin_Reason-image {
  width: 100%;
  height: 100%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.ProblemSkin_Reason-number {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 36px;
  color: rgba(142, 51, 134, 0.4);
  margin-bottom: 20px;
}
@media (max-width: 1100px) {
  .ProblemSkin_Reason-number {
    font-size: 38px;
    margin-bottom: 18px;
  }
}

.ProblemSkin_Reason-title {
  font-size: 24px;
  font-weight: bold;
}
@media (max-width: 1100px) {
  .ProblemSkin_Reason-title {
    font-size: 20px;
  }
}

.ProblemSkin_Reason-description {
  font-size: 18px;
}
@media (max-width: 1100px) {
  .ProblemSkin_Reason-description {
    font-size: 16px;
  }
}
