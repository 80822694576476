.Field {
  position: relative;
  cursor: default;
}

.Field--notAbsoluteLabel {
}

.Field-labelRow {
  position: absolute;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  left: 0;
  top: -32px;
  line-height: 1.4;
}

.Field--notAbsoluteLabel .Field-labelRow {
  position: static;
  margin-bottom: 8px;
  top: 0;
}

.Field-label {
  color: #262931;
}

.Field-instance {
}

.Field-meta {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 4px;
  /* padding-left: 8px; */
  font-size: 14px;
  font-weight: 300;
}

.Field-exampleList {
  height: 1.5em;
  height: 1.5;
  overflow: hidden;
  color: #797979;
}

.Field-exampleItem {
  display: inline-block;
  vertical-align: top;
}

.Field-wordItroduction {
  display: inline-block;
  padding-right: 12px;
  vertical-align: top;
}

.Field-wordSeparator {
  display: inline-block;
  padding: 0 12px;
  vertical-align: top;
}

.Field-error {
  color: #ff0000;
  font-size: 14px;
  font-weight: 300;
}
