.Form {
}

.Form-row {
  margin-bottom: 24px;
  padding-top: 16px;
}

.Form-row:first-child {
  padding-top: 0;
}

.Form-row:last-child {
  margin-bottom: 0;
}

.Form-row--noLabelGap {
  padding-top: 0;
}

.Form-row--labelTop {
  padding-top: 32px;
}

.Form-row:last-child {
  margin-bottom: 0;
}

.Form-row--labelTop:last-child {
  margin-bottom: 0;
}

.Form-title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 48px;
}

.Form-smalTitle {
  text-align: left;
}

.Form-labeledRow {
  margin-bottom: 24px;
  padding-top: 40px;
}

/* @media (max-width: 640px) {
  .Form-labeledRow {
    margin-bottom: 8px;
  }
} */

.Form-labeledRow:last-child {
  margin-bottom: 0;
}

.Form-actionRow {
  margin-bottom: 24px;
  padding-top: 24px;
  text-align: right;
}

@media (max-width: 640px) {
  .Form-actionRow {
    margin-bottom: 8px;
    padding-top: 16px;
  }
}

.Form-actionRow:last-child {
  margin-bottom: 0;
}

.Form-leftLabeledRow {
  margin-bottom: 24px;
  padding-top: 16px;
}

@media (max-width: 840px) {
  .Form-leftLabeledRow {
    margin-bottom: 24px;
    padding-top: 40px;
  }
}

@media (max-width: 640px) {
  .Form-leftLabeledRow {
    margin-bottom: 8px;
  }
}

.Form-leftLabeledRow--smallGap {
  margin-bottom: 8px;
  padding-top: 16px;
}

@media (max-width: 840px) {
  .Form-leftLabeledRow--smallGap {
    margin-bottom: 8px;
    padding-top: 16px;
  }
}

@media (max-width: 640px) {
  .Form-leftLabeledRow--smallGap {
    margin-bottom: 16px;
  }
}

.Form-part {
}

.Form-partLabel {
  text-align: center;
  font-weight: bold;
  margin: 24px 0 32px;
}

.Form-partContent {
}
