.PasswordRecoveryRequestDialog-content {
  padding: 48px;
  box-sizing: border-box;
}

.PasswordRecoveryRequestDialog-description {
  margin-bottom: 16px;
}

.PasswordRecoveryRequestDialog-form {
  margin-bottom: 16px;
}

.PasswordRecoveryRequestDialog-actions {
  text-align: center;
}
