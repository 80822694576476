.FilterBottomSheet {
}

.FilterBottomSheet-quickFiltersHolder {
}

.FilterBottomSheet-quickFilters {
}

.FilterBottomSheet-filterListHolder {
}

.FilterBottomSheet-filterList {
}

.FilterBottomSheet-filterItem {
}

.FilterBottomSheet-productFilters {
}

.FilterBottomSheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FilterBottomSheet-headerTitle {
  font-size: 22px;
  font-weight: 600;
}

.FilterBottomSheet-headerClose {
}

.FilterBottomSheet-footer {
}

.FilterBottomSheet-footerActions {
  display: flex;
  margin: -8px;
}
.FilterBottomSheet-footerActionsItem {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding: 8px;
}
