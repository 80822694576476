.PickPointDialog {
}

.PickPointDialog-alert {
  padding: 8px 32px;
}

@media (max-width: 720px) {
  .PickPointDialog-alert {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.PickPointDialog-loader {
  min-height: 120px;
  position: relative;
}

.PickPointDialog-headerHolder {
  /* overflow: hidden; */
  margin-top: 8px;
  margin-bottom: 24px;
}

@media (max-width: 840px) {
  .PickPointDialog-headerHolder {
    margin-bottom: 16px;
  }
}

.PickPointDialog-header {
  display: flex;
  align-items: center;
  padding: 0 16px;
}

@media (max-width: 840px) {
  .PickPointDialog-header {
    display: block;
    padding: 0;
  }
}

.PickPointDialog-search {
  flex: 1 1 auto;
  order: -1;
  padding: 0 16px;
}

.PickPointDialog-trigger {
  flex: 0 0 auto;
  order: 1;
  padding: 0 16px;
}

@media (max-width: 840px) {
  .PickPointDialog-trigger {
    margin-bottom: 16px;
  }
}

@media (max-width: 600px) {
  .PickPointDialog-trigger {
    margin-bottom: 8px;
  }
}

.PickPointDialog-contentHolder {
}
