.LB_Label {
  font-size: inherit;
  position: relative;
  display: inline-block;
  transform: rotate(-3deg);
}

.LB_Label-bg {
  position: absolute;

  background-color: #a04ab0;
  left: -16px;
  right: -16px;
  bottom: -10px;
  top: 16px;
  transform: skew(-25deg);
}

.LB_Label-inner {
  position: relative;
  color: #fff;
  text-shadow: 4px 5px 1px rgb(46, 46, 46);
  font-family: 'Akzidenz-Grotesk Pro Bold Ext';
  text-align: center;
  line-height: 1;
  font-style: italic;
}
